import clsx from 'clsx';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';
import { DragItem, DragItemType } from './DragItem';
import GroupPreparationItem from './GroupPreparationItem';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    dropHover: {
        background: theme.palette.grey[300],
    },
    empty: {
        color: theme.palette.grey[400],
        fontStyle: 'italic',
    },
    emptyIcon: {
        minWidth: 'unset',
        marginRight: theme.spacing(1),
    },
}));

interface Props {
    groupIndex: number;
    registrations: MatchplayRegistration[];

    onMove(item: DragItem, to: number): void;
}

const GroupPreparationList = ({ groupIndex, registrations, onMove }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const { t } = useTranslation();

    const [{ handlerId, dropHover }, drop] = useDrop({
        accept: DragItemType,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
                dropHover: monitor.canDrop() && monitor.isOver(),
            };
        },
        canDrop: (item: DragItem) => {
            return !!ref.current && item.groupIndex !== groupIndex;
        },
        drop: (item: DragItem) => {
            onMove(item, groupIndex);
        }
    });

    drop(ref);

    const renderedItems = registrations.map((registration, idx) => {
        return (
            <GroupPreparationItem registration={registration} groupIndex={groupIndex} key={idx} />
        );
    });
    const className = clsx(classes.root, {
        [`${classes.dropHover}`]: dropHover,
    });
    return (
        <List dense={true} innerRef={ref} data-handler-id={handlerId} className={className}>
            {renderedItems}
            {registrations.length === 0 && (
                <ListItem className={classes.empty} key="emptyDropZone">
                    <ListItemIcon className={classes.emptyIcon}>
                        <AddCircleOutlineIcon color="disabled" />
                    </ListItemIcon>
                    <ListItemText>{t('components.matchplay.preparation.groups.GroupPreparationList.dropZoneEmpty')}</ListItemText>
                </ListItem>
            )}
        </List>
    );
};

export default GroupPreparationList;
