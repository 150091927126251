import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TenantInformation } from '../../models/TenantInformation';
import MainNavigationBar from './MainNavigationBar';
import { User } from '../../models/User';

interface StoreProps {
    tenantInformation?: TenantInformation,
    currentUser?: User,
}

type Props = StoreProps;

class MainNavigationBarContainer extends Component<Props> {
    public render() {
        const { tenantInformation, currentUser } = this.props;
        if (!tenantInformation) {
            return null;
        }
        return <MainNavigationBar tenantInformation={tenantInformation} currentUser={currentUser}/>;
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(MainNavigationBarContainer);
