import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';

import {sortGroupStandings} from '../../../lib/sorting';
import {GroupStandings} from '../../../models/MatchViews';
import PlayerImage from '../../utils/PlayerImage';
import {getGroupName} from '../../utils/GroupName';

const useStyles = makeStyles(theme => ({
  groupHeader: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  rankColumn: {
    width: 50,
  },
  statsColumn: {
    width: 50,
  },
  playerNameCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface Props {
  standings: GroupStandings[];
}

const MatchplayGroupStandings = ({standings}: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const showStats = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const sortedStandings = useMemo(
      () => standings.map(s => ({...s, standings: sortGroupStandings(s.standings)})),
      [standings]);

  const renderedGroups = sortedStandings.map((s) => {
    const groupName = t('common.groupNameTemplate', {letter: getGroupName(s.groupIndex, sortedStandings.length)});

    return (
        <Grid item key={`matchplay-group-${s.groupIndex}`} xs={12}>
          <Typography className={classes.groupHeader}>{groupName}</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.rankColumn}/>
                <TableCell>{t('components.matchplay.common.playerName')}</TableCell>
                <Tooltip title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipPoints')}
                         placement="top-end" arrow>
                  <TableCell className={classes.statsColumn} align="right">
                    {t('components.matchplay.dashboard.MatchplayGroupStandings.headingPoints')}
                  </TableCell>
                </Tooltip>
                {showStats &&
                  <>
                    <Tooltip
                      title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipMatchesPlayed')}
                      placement="top-end" arrow>
                      <TableCell className={classes.statsColumn} align="right">
                        {t('components.matchplay.dashboard.MatchplayGroupStandings.headingMatchesPlayed')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipWins')}
                      placement="top-end" arrow>
                      <TableCell className={classes.statsColumn} align="right">
                        {t('components.matchplay.dashboard.MatchplayGroupStandings.headingWins')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipDraws')}
                      placement="top-end" arrow>
                      <TableCell className={classes.statsColumn} align="right">
                        {t('components.matchplay.dashboard.MatchplayGroupStandings.headingDraws')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipLosses')}
                      placement="top-end" arrow>
                      <TableCell className={classes.statsColumn} align="right">
                        {t('components.matchplay.dashboard.MatchplayGroupStandings.headingLosses')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipHoles')}
                      placement="top-end" arrow>
                      <TableCell className={classes.statsColumn} align="right">
                        {t('components.matchplay.dashboard.MatchplayGroupStandings.headingHoles')}
                      </TableCell>
                    </Tooltip>
                    <Tooltip
                      title={t('components.matchplay.dashboard.MatchplayGroupStandings.tooltipHolesDiff')}
                      placement="top-end" arrow>
                      <TableCell className={classes.statsColumn} align="right">
                        {t('components.matchplay.dashboard.MatchplayGroupStandings.headingHoleDiff')}
                      </TableCell>
                    </Tooltip>
                  </>}
              </TableRow>
            </TableHead>
            <TableBody>
              {s.standings.map((playerStandings, index) => (
                  <TableRow key={`matchplay-player-${playerStandings.user.id}`}>
                    <TableCell className={classes.rankColumn}>{index + 1}</TableCell>
                    <TableCell className={classes.playerNameCell}>
                      <PlayerImage user={playerStandings.user} size={25}/>
                      <span>{playerStandings.user.name}</span>
                    </TableCell>
                    <TableCell align="right">{playerStandings.points}</TableCell>
                    {showStats &&
                      <>
                        <TableCell align="right">{playerStandings.matchesPlayed}</TableCell>
                        <TableCell align="right">{playerStandings.matchesWon}</TableCell>
                        <TableCell align="right">{playerStandings.matchesDraw}</TableCell>
                        <TableCell align="right">{playerStandings.matchesLost}</TableCell>
                        <TableCell align="right">
                          {playerStandings.holesWon}:{playerStandings.holesLost}
                        </TableCell>
                        <TableCell align="right">{playerStandings.holeScore}</TableCell>
                      </>}
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
    )
  });

  return (
      <Grid container spacing={3}>
        {renderedGroups}
      </Grid>
  );
};

export default MatchplayGroupStandings;
