import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SortIcon from '@material-ui/icons/Sort';

interface Props {
    onShuffle(): void;
    onFirstComeFirstServe(): void;
}

const GroupPreparationActionButtons = ({ onShuffle, onFirstComeFirstServe }: Props) => {
    const [open, setOpen] = useState(false);
    const menuAnchorRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const closeMenu = useCallback(() => setOpen(false), []);
    const toggleMenu = useCallback(() => setOpen((prevOpen) => !prevOpen), []);
    const menuShuffle = useCallback(() => {
        onShuffle();
        closeMenu();
    }, [onShuffle, closeMenu]);
    const menuByRegistrationDate = useCallback(() => {
        onFirstComeFirstServe();
        closeMenu();
    }, [onFirstComeFirstServe, closeMenu]);

    return (
        <>
            <ButtonGroup ref={menuAnchorRef} variant="outlined">
                <Button onClick={onShuffle} variant="outlined" color="secondary">
                    {t('components.matchplay.preparation.common.shuffle')}
                </Button>
                <Button
                    size="small"
                    onClick={toggleMenu}
                    aria-controls="preparation-actions-menu"
                    aria-haspopup="true">
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Menu
                id="preparation-actions-menu"
                anchorEl={menuAnchorRef.current}
                getContentAnchorEl={null}
                open={open}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem onClick={menuShuffle}>
                    <ListItemIcon>
                        <ShuffleIcon fontSize="small" />
                    </ListItemIcon>
                    {t('components.matchplay.preparation.common.shuffle')}
                </MenuItem>
                <MenuItem onClick={menuByRegistrationDate}>
                    <ListItemIcon>
                        <SortIcon fontSize="small" />
                    </ListItemIcon>
                    {t('components.matchplay.preparation.common.byRegistrationDate')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default GroupPreparationActionButtons;
