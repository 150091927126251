import { useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';

import { MatchplayVariant } from '../../../models/Matchplay';
import { ReduxStoreState } from '../../../store';
import MatchplayGroupsPreparationContainer from './groups/MatchplayGroupsPreparationContainer';
import MatchplayPlayoffPreparationContainer from './playoff/MatchplayPlayoffPreparationContainer';

interface RouteParams {
    matchplayId: string | undefined;
}

const MatchplayPreparationContainer = () => {
    const routeMatch = useRouteMatch<RouteParams>();
    const matchplaysById = useSelector((state: ReduxStoreState) => state.matchplay.byId);

    if (!routeMatch.params.matchplayId) {
        return <Redirect to="/" />;
    }

    const matchplay = matchplaysById[routeMatch.params.matchplayId];
    if (!matchplay) {
        return <Redirect to="/" />;
    }

    switch (matchplay.variant) {
        case MatchplayVariant.GROUP: {
            return <MatchplayGroupsPreparationContainer />
        }
        case MatchplayVariant.PLAYOFF: {
            return <MatchplayPlayoffPreparationContainer />
        }
        default: {
            return <Redirect to="/" />
        }
    }
};

export default MatchplayPreparationContainer;
