import { Link, Theme, WithStyles, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

import { TenantInformation } from '../../models/TenantInformation';
import { PageLink } from '../utils/PageLink';

const styles = (theme: Theme) => ({
    root: {
        maxHeight: 72,
        maxWidth: 256,
    }
});

interface ComponentProps {
    tenantInformation: TenantInformation;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class TenantLogo extends Component<Props> {
    public render() {
        const { tenantInformation, classes } = this.props;
        return (
            <Link component={PageLink('/')}>
                <img src={tenantInformation.logoUrl} alt={tenantInformation.name} className={classes.root}/>
            </Link>
        );
    }
}

export default withStyles(styles)(TenantLogo);
