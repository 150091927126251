import React, { FC } from 'react';
import {
    Breadcrumbs as MaterialBreadcrumbs, IconButton, Link, Theme, Typography, useMediaQuery, useTheme, WithStyles,
    withStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';

import { PageLink } from '../utils/PageLink';

const styles = (theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    smallRoot: {
        display: 'flex',
        flexDirection: 'row' as const,
        alignItems: 'center',
    },
    adminStyle: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    }
});

interface BreadcrumbEntry {
    name: string;
    to: string;
    params: {};
}

interface ComponentProps {
    entries: BreadcrumbEntry[];
}

type Props = ComponentProps & WithStyles<typeof styles>;

const Breadcrumbs: FC<Props> = ({ classes, entries }: Props) => {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isAdmin = entries[0].to === '/admin';

    if (isXs && entries.length > 1) {
        const entry = entries[entries.length - 2];
        return (
            <Link className={classes.smallRoot} color="textSecondary" component={PageLink(entry.to, entry.params)}>
                <IconButton>
                    <ArrowBackIcon/>
                </IconButton>
                <Typography>
                    {entry && entry.name}
                </Typography>
            </Link>
        );
    }

    const classNames = clsx(classes.root, { [classes.adminStyle]: isAdmin });

    return (
        <MaterialBreadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb"
                             className={classNames}>
            {entries.map((entry, index, arr) => {
                if (index === arr.length - 1) {
                    return <Typography key={entry.to}>{entry.name}</Typography>;
                }
                const linkComponent = PageLink(entry.to, entry.params);
                return <Link key={entry.to} color={isAdmin ? 'inherit' : 'textPrimary'}
                             component={linkComponent}>{entry.name}</Link>
            })}
        </MaterialBreadcrumbs>
    );
};

export default withStyles(styles)(Breadcrumbs);
