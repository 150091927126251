import { ReactNode, ComponentType, ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import {
    makeStyles,
    Table, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip, useMediaQuery, useTheme
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/StarTwoTone';

import { HcpFormat } from '../../../lib/numberFormats';
import { stableSort } from '../../../lib/sorting';
import { MatchplayPhase } from '../../../models/Matchplay';
import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import { User } from '../../../models/User';
import PlayerImage from '../../utils/PlayerImage';
import Username from '../../utils/Username';
import SignOutPlayer from '../settings/SignOutPlayer';

const useStyles = makeStyles((theme: Theme) => ({
    selfIcon: {
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1),
    },
}));

interface Props {
    currentUser: User;
    registrations: MatchplayRegistration[];
    phase: MatchplayPhase;
    hideRegistrationDate?: boolean;
    hideHandicap?: boolean;
    emptyContent?: ReactNode;
    signOutPlayerComponent?: ComponentType<ComponentPropsWithoutRef<typeof SignOutPlayer>>;
    signOutPlayerAction(registration: MatchplayRegistration): void;
}

const MatchplayPlayerList = ({
    currentUser, registrations, phase, hideRegistrationDate, hideHandicap, emptyContent,
    signOutPlayerComponent, signOutPlayerAction,
}: Props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const inExecutionPhase = (phase === MatchplayPhase.EXECUTION);
    const isAdmin = currentUser.isAdmin;
    const SignOutPlayer = (phase === MatchplayPhase.REGISTRATION_OPEN || phase === MatchplayPhase.REGISTRATION_CLOSED) && signOutPlayerComponent;

    if (registrations.length <= 0) {
        return <>{emptyContent}</>;
    }

    const renderItem = (registration: MatchplayRegistration) => {
        const isSelf = (registration.user.email === currentUser.email);
        const isNotPlaying = (inExecutionPhase && !registration.promotedAt);
        if (isNotPlaying) {
            return;
        }
        return (
            <TableRow key={`${registration.matchplayId}--${registration.user.email}`}>
                <TableCell padding="none">
                    <PlayerImage user={registration.user} size={25} />
                </TableCell>
                <TableCell>
                    <Username user={registration.user} />
                    {isSelf &&
                        <Tooltip title={`${t('components.matchplay.dashboard.MatchplayPlayerList.tooltipYou')}`}>
                            <StarIcon className={classes.selfIcon} />
                        </Tooltip>}
                </TableCell>
                {!hideHandicap && !isMobile && (
                    <TableCell align="center">{HcpFormat.format(registration.user.hcp)}</TableCell>
                )}
                {!hideRegistrationDate && !isMobile && !inExecutionPhase && (
                    <TableCell align="right">
                        <Moment unix format={t('dateTime.matchDateTime')}>{registration.enqueuedAt / 1000}</Moment>
                    </TableCell>
                )}
                {inExecutionPhase &&
                    <TableCell align="right">
                        <Moment unix format={t('dateTime.matchDateTime')}>{registration.promotedAt / 1000}</Moment>
                    </TableCell>}
                {SignOutPlayer && isAdmin &&
                    <TableCell align="right">
                        <SignOutPlayer
                            registration={registration}
                            action={signOutPlayerAction}
                            size={isMobile ? 'small' : 'default'}
                        />
                    </TableCell>}
            </TableRow>
        );
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell padding="none" style={{ width: 25 }} />
                    <TableCell>
                        {t('components.matchplay.common.playerName')}
                    </TableCell>
                    {!hideHandicap && !isMobile && (
                        <TableCell align="center">
                            {t('components.matchplay.common.handicap')}
                        </TableCell>
                    )}
                    {!hideRegistrationDate && !isMobile && !inExecutionPhase && (
                        <TableCell align="right">
                            {t('components.matchplay.dashboard.MatchplayPlayerList.registeredSince')}
                        </TableCell>
                    )}
                    {inExecutionPhase &&
                        <TableCell align="right">
                            {t('components.matchplay.dashboard.MatchplayPlayerList.playerSince')}
                        </TableCell>}
                    {SignOutPlayer && isAdmin &&
                        <TableCell />}
                </TableRow>
            </TableHead>
            <TableBody>
                {stableSort(registrations, ['promotedAt', 'enqueuedAt']).map(renderItem)}
            </TableBody>
        </Table>
    );
};

export default MatchplayPlayerList;
