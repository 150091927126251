import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { TenantInformation } from '../../models/TenantInformation';
import { UserRegistration } from '../../models/User';
import { registerAction, resetUserStoreAction } from '../../store/actions/user';
import RegisterUserComplete from './RegisterUserComplete';
import RegisterUserForm from './RegisterUserForm';

interface StoreProps {
    tenantInformation?: TenantInformation;
    isSuccessfullyRegistered: boolean;
    registeredEmail: string;
    register(registration: UserRegistration): void;
    resetUserStore(): void;
}

type Props = StoreProps;

class RegisterUserContainer extends Component<Props> {
    public render() {
        const { tenantInformation, isSuccessfullyRegistered, registeredEmail } = this.props;
        if (!tenantInformation) {
            return null;
        }
        return (
            <React.Fragment>
                {isSuccessfullyRegistered &&
                    <RegisterUserComplete email={registeredEmail} />}
                {!isSuccessfullyRegistered &&
                    <RegisterUserForm tenantInformation={tenantInformation} onRegister={this.onRegister} />}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        this.props.resetUserStore();
    }

    private onRegister = (userRegistration: UserRegistration) => {
        this.props.register(userRegistration);
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
    isSuccessfullyRegistered: state.user.isSuccessfullyRegistered,
    registeredEmail: state.user.registeredEmail,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    register: registerAction(dispatch),
    resetUserStore: resetUserStoreAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUserContainer);
