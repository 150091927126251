import React from 'react';
import { useTranslation } from 'react-i18next';

import { Match } from '../../../models/Match';
import {
    AbandonReason, MatchActivity, MatchActivityPayloadAbandoned, MatchActivityType
} from '../../../models/MatchActivity';
import { User } from '../../../models/User';
import MatchActivityItemAcceptProposal from './MatchActivityItemAcceptProposal';
import MatchActivityItemComment from './MatchActivityItemComment';
import MatchActivityItemProposeDate from './MatchActivityItemProposeDate';
import { MessageItem } from '../../utils/Message';

export interface MatchActivityItemProps {
    match: Match;
    item: MatchActivity;
    currentUser: User;
    isMatchPlayer: boolean;
    isScheduled: boolean;
    isCurrentDateProposal: boolean;
    indexOfType: number;
    onActionAcceptProposedDate(): void;
    onProposeDate(date: Date): void;
}

const MatchActivityItem = (props: MatchActivityItemProps) => {
    const { item } = props;
    const { t } = useTranslation();

    switch (item.type) {
        case MatchActivityType.MATCH_CREATED:
            return <MessageItem user={item.user} createdAt={item.createdAt}
                                title={t('components.match.activities.MatchActivityItem.titleMatchCreated')}/>;
        case MatchActivityType.MATCH_ABANDONED:
            const reason = (item.payload as MatchActivityPayloadAbandoned).reason
            const title = (reason === AbandonReason.ABANDONED_BY_ORGANIZER)
                ? t('components.match.activities.MatchActivityItem.titleMatchAbandonedAdmin')
                : t('components.match.activities.MatchActivityItem.titleMatchAbandonedUser');
            return <MessageItem user={item.user} createdAt={item.createdAt} title={title}/>;
        case MatchActivityType.ACCEPT_PROPOSAL:
            return <MatchActivityItemAcceptProposal {...props} />;
        case MatchActivityType.COMMENT:
            return <MatchActivityItemComment {...props} />;
        case MatchActivityType.PROPOSE_DATE:
            return <MatchActivityItemProposeDate {...props} />;
        case MatchActivityType.MATCH_RESULT:
            return <MessageItem user={item.user} createdAt={item.createdAt}
                                title={t('components.match.activities.MatchActivityItem.titleMatchResult')}/>;
    }
    return null;
}

export default MatchActivityItem;
