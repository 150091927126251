import { IdentifiableResource } from '../lib/resourceSupport';
import { Sponsor } from './Sponsor';

export enum MatchplayPhase {
    INITIALIZATION = 'INITIALIZATION',
    REGISTRATION_OPEN = 'REGISTRATION_OPEN',
    REGISTRATION_CLOSED = 'REGISTRATION_CLOSED',
    EXECUTION_GROUP = 'EXECUTION_GROUP',
    EXECUTION_GROUP_FINISHED = 'EXECUTION_GROUP_FINISHED',
    EXECUTION = 'EXECUTION',
    AFTERMATH = 'AFTERMATH'
}

export enum MatchplayVariant {
    PLAYOFF = 'PLAYOFF',
    GROUP = 'GROUP',
}

export enum MatchplayType {
    HOLES_6 = 'HOLES_6',
    HOLES_9 = 'HOLES_9',
    HOLES_18 = 'HOLES_18'
}

export interface MatchplayPlayoffSettings {
    numberOfPlayers: number;
}

export interface MatchplayGroupSettings {
    numberOfGroups: number;
}

export interface MatchplayDescriptionSettings {
    startAt?: string;
    endAt?: string;
    rulesText?: string;
    pricesText?: string;
    sponsorsText?: string;
    participationTermsText?: string;
}

export interface Matchplay extends IdentifiableResource {
    name: string;
    phase: MatchplayPhase;
    variant: MatchplayVariant;
    type: MatchplayType;
    playoff: MatchplayPlayoffSettings;
    groupSettings: MatchplayGroupSettings;
    sponsors: Sponsor[];
    numberRegistrations: number;
    numberPlayers: number;
    description: MatchplayDescriptionSettings;
}

export const getMaxHoles = (matchplay: Matchplay) => {
    switch (matchplay.type) {
        case MatchplayType.HOLES_18: return 18;
        case MatchplayType.HOLES_9: return 9;
        case MatchplayType.HOLES_6: return 6;
    }
    return 18;
};
