import React, { Component } from 'react';

import { Field, FieldProps, getIn } from 'formik';

import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

class FormikTextField extends Component<TextFieldProps> {
    public render() {
        return (
            <Field component={InnerTextField} {...this.props} />
        );
    }
}

const InnerTextField = (props: FieldProps & TextFieldProps) => {
    const { field, form } = props;
    const formError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && Boolean(formError);
    return (
        <TextField {...props} {...field}
            error={showError}
            helperText={(showError) ? formError : props.helperText} />
    );
};

export default FormikTextField;
