export interface PagedResources<T> {
    content: T[],
    empty: boolean,
    first: boolean,
    last: boolean,
    totalPages: number,
    totalElements: number,
    size: number,
    numberOfElements: number,
    number: number,
}

export interface IdentifiableResource {
    id: string;
}

export interface ResourceIdMap<T extends IdentifiableResource> {
    [id: string]: T
}

export interface ResourceMap<T> {
    [key: string]: T
}

export interface ResourceGroup<T> {
    [group: string]: T[]
}

export interface ResourceNumberedGroup<T> {
    [group: number]: T[]
}

export function createResourceIdMap<T extends IdentifiableResource>(items: T[]): ResourceIdMap<T> {
    return items.reduce((result: ResourceIdMap<T>, item: T) => {
        if (item.id) {
            result[item.id] = item;
        }
        return result;
    }, {});
}

export function createResourceGroup<T>(items: T[], predicate: (item: T) => string): ResourceGroup<T> {
    return items.reduce((result: ResourceGroup<T>, item: T) => {
        const group = predicate(item);
        if (!result[group]) {
            result[group] = [];
        }
        result[group].push(item);
        return result;
    }, {});
}

export function createResourceNumberedGroup<T>(items: T[], predicate: (item: T) => number): ResourceNumberedGroup<T> {
    return items.reduce((result: ResourceNumberedGroup<T>, item: T) => {
        const group = predicate(item);
        if (!result[group]) {
            result[group] = [];
        }
        result[group].push(item);
        return result;
    }, {});
}
