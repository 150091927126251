import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { MenuItem, Select, Theme, WithStyles, withStyles } from '@material-ui/core';

import { User } from '../../../models/User';

const styles = (theme: Theme) => ({
    root: {
        ...theme.typography.body2,
    },
});

interface ComponentProps {
    user: User;
    onChangeRole(user: User, isAdmin: boolean): void;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

enum Roles {
    User,
    Administrator,
}

class UserRoleSelect extends Component<Props> {
    public render() {
        const { user, classes, t } = this.props;
        const role = this.getCurrentRole();
        return (
            <Select
                value={role}
                onChange={this.onChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Rolle' }}
                className={classes.root}
                disabled={!user.accountEnabled}
            >
                <MenuItem value={Roles.User}>{t('components.tenant.usermanagement.UserRoleSelect.user')}</MenuItem>
                <MenuItem value={Roles.Administrator}>{t('components.tenant.usermanagement.UserRoleSelect.admin')}</MenuItem>
            </Select>
        );
    }

    private getCurrentRole = () => {
        const { user } = this.props;
        return (user.isAdmin)
            ? Roles.Administrator
            : Roles.User;
    }

    private onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { user, onChangeRole } = this.props;

        const selectedRole = event.target.value as Roles;
        const currentRole = this.getCurrentRole();

        if (selectedRole === currentRole) {
            return;
        }

        onChangeRole(user, selectedRole === Roles.Administrator);
    }
}

export default withStyles(styles)(withTranslation()(UserRoleSelect));
