import React, { Component, createElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Helmet } from 'react-helmet';

import { Errors } from '../../models/Errors';
import { TenantInformation } from '../../models/TenantInformation';
import { getTenantInformationAction } from '../../store/actions/tenant';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ComponentProps {
    maintenanceComponent?: React.ComponentType;
}

interface StoreProps {
    children?: ReactNode,
    tenantInformation?: TenantInformation;
    lastError?: Errors;
    getTenantInformation(): void;
}

type Props = ComponentProps & StoreProps & WithTranslation;

class TenantContext extends Component<Props> {
    public render() {
        const { tenantInformation, maintenanceComponent, lastError, t } = this.props;
        const hasTenantInformation = Boolean(tenantInformation);

        if (lastError === Errors.InternalError && !!maintenanceComponent) {
            return createElement(maintenanceComponent);
        }

        if (hasTenantInformation) {
            const title = t('components.layout.Head.title', { tenantName: tenantInformation?.name });
            const description = t('components.layout.Head.description', { tenantName: tenantInformation?.name });
            return (
                <React.Fragment>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description}/>
                    </Helmet>
                    {this.props.children}
                </React.Fragment>
            );
        }

        return null;
    }

    public componentDidMount() {
        this.props.getTenantInformation();
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
    lastError: state.tenant.lastError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTenantInformation: getTenantInformationAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TenantContext));
