import React, { Component } from 'react';
import { User } from '../../models/User';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import LetterImage from './LetterImage';
import clsx from 'clsx';

const styles = (theme: Theme) => createStyles({
    img: {
        objectFit: 'cover',
        border: 0,
    },
    size16: {
        height: 16,
        width: 16,
        borderRadius: 16,
    },
    size25: {
        height: 25,
        width: 25,
        borderRadius: 25,
    },
    size40: {
        height: 40,
        width: 40,
        borderRadius: 40,
    },
    size48: {
        height: 48,
        width: 48,
        borderRadius: 48,
    },
    size64: {
        height: 64,
        width: 64,
        borderRadius: 64,
    },
    inline: {
        display: 'inline-flex',
        verticalAlign: 'bottom',
    },
    spacing1: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    spacing2: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    spacing3: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
    },
    spacing4: {
        marginRight: theme.spacing(4),
        marginLeft: theme.spacing(4),
    },
});

interface ComponentProps {
    user?: User,
    size: 16 | 25 | 40 | 48 | 64,
    inline?: boolean,
    spacing?: 1 | 2 | 3 | 4,
}

type Props = ComponentProps & WithStyles<typeof styles>;

class PlayerImage extends Component<Props> {
    static defaultProps = {
        size: 64 as const,
    };

    public render() {
        const { user, size, inline, classes, spacing } = this.props;
        if (!user) {
            return null;
        }

        const className = clsx({
            [classes.img]: user.imageUrl,
            [classes.size16]: size === 16,
            [classes.size25]: size === 25,
            [classes.size40]: size === 40,
            [classes.size48]: size === 48,
            [classes.size64]: size === 64,
            [classes.inline]: inline,
            [classes.spacing1]: spacing === 1,
            [classes.spacing2]: spacing === 2,
            [classes.spacing3]: spacing === 3,
            [classes.spacing4]: spacing === 4,
        });
        if (user.imageUrl) {
            return <img src={user.imageUrl} alt={user.name} className={className}/>;
        }
        return (
            <span className={className} title={user.name}>
                <LetterImage title={user.name} width={size} variant="circle"/>
            </span>
        );
    }
}

export default withStyles(styles)(PlayerImage);
