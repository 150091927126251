import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import MatchplayPlayoff from './MatchplayPlayoff';
import { ResourceIdMap, ResourceMap, ResourceNumberedGroup } from '../../../lib/resourceSupport';
import { Matchplay } from '../../../models/Matchplay';
import { Match } from '../../../models/Match';
import { getMatchplayAction, getMatchplayPlayoffMatchesAction } from '../../../store/actions/matchplays';

interface RouteProps {
    matchplayId: string;
}

interface StoreProps {
    matchplayById: ResourceIdMap<Matchplay>;
    matchesByMatchplayAndDepth: ResourceMap<ResourceNumberedGroup<Match>>;
    fetchMatchplay(matchplayId: string): Promise<void>;
    fetchMatches(matchplayId: string): Promise<void>;
}

type Props = & RouteComponentProps<RouteProps> & StoreProps;

class MatchplayPlayoffContainer extends Component<Props> {

    public render() {
        const { matchplayById, matchesByMatchplayAndDepth } = this.props;
        const { matchplayId } = this.props.match.params;
        if (!(matchplayId in matchplayById)) {
            return null;
        }
        const matchplay = matchplayById[matchplayId];
        const matches = matchesByMatchplayAndDepth[matchplayId] ?? {};
        return (
            <MatchplayPlayoff matchplay={matchplay} groupedMatches={matches}/>
        );
    }

    public async componentDidMount() {
        const { fetchMatchplay, fetchMatches } = this.props;
        const { matchplayId } = this.props.match.params;
        if (!matchplayId) {
            return;
        }
        await fetchMatchplay(matchplayId);
        await fetchMatches(matchplayId);
    }
}

const mapStateToProps = (state: any) => ({
    matchplayById: state.matchplay.byId,
    matchesByMatchplayAndDepth: state.match.byMatchplayAndDepth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchMatchplay: getMatchplayAction(dispatch),
    fetchMatches: getMatchplayPlayoffMatchesAction(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchplayPlayoffContainer));
