import { AnyAction } from 'redux';

import { Sponsor } from '../../models/Sponsor';

export const SPONSOR_LIST_REQUEST = 'sponsor/LIST_REQUEST';
export const SPONSOR_LIST_SUCCESS = 'sponsor/LIST_SUCCESS';
export const SPONSOR_LIST_FAILURE = 'sponsor/LIST_FAILURE';
export const SPONSOR_CREATE_REQUEST = 'sponsor/CREATE_REQUEST';
export const SPONSOR_CREATE_SUCCESS = 'sponsor/CREATE_SUCCESS';
export const SPONSOR_CREATE_FAILURE = 'sponsor/CREATE_FAILURE';
export const SPONSOR_DELETE_REQUEST = 'sponsor/DELETE_REQUEST';
export const SPONSOR_DELETE_SUCCESS = 'sponsor/DELETE_SUCCESS';
export const SPONSOR_DELETE_FAILURE = 'sponsor/DELETE_FAILURE';

interface SponsorState {
    items: Sponsor[],
}

const initialState: SponsorState = {
    items: [],
};

export const sponsorsReducer = (state: SponsorState = initialState, action: AnyAction) => {
    switch (action.type) {
        case SPONSOR_LIST_SUCCESS:
            return { ...state, items: action.payload };
        default:
            return state;
    }
};
