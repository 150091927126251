import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Grid } from '@material-ui/core';

import { MessageVariant } from '../../models/Message';
import { User, UserChangePasswordRequest, UserMatchStats } from '../../models/User';
import { changeMessageAction } from '../../store/actions/page';
import {
    changePasswordAction, deleteCurrentUserAction, deleteImageAction, getCurrentUserStatistics, updateCurrentUserAction,
    uploadImageAction
} from '../../store/actions/user';
import PlayerCard from '../utils/PlayerCard';
import UserDetails from './UserDetails';
import UserImage from './UserImage';
import ChangePassword from './ChangePassword';
import { Errors } from '../../models/Errors';
import { resetErrorAction } from '../../store/actions/error';
import DeleteAccount from './DeleteAccount';

interface StoreProps {
    currentUser?: User,
    currentUserStats?: UserMatchStats,
    error?: Errors,
    updateCurrentUser(user: User): void;
    changeMessage(variant: MessageVariant, text: string): void;
    uploadImage(file: File): Promise<Response | undefined>;
    deleteImage(): void;
    getCurrentUserStatistics(): void;
    changePassword(user: User, passwords: UserChangePasswordRequest): void;
    resetError(): void;
    deleteCurrentUser(): void;
}

type Props = StoreProps;

class AccountContainer extends Component<Props> {

    render() {
        const { currentUser, currentUserStats, error } = this.props;

        if (!currentUser) {
            return null;
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <PlayerCard user={currentUser} userStats={currentUserStats} gutterTop={true}/>
                </Grid>
                <Grid item xs={12} md={8}>
                    <UserDetails currentUser={currentUser} onSubmit={this.onSubmit}/>
                    <UserImage currentUser={currentUser}
                               onUploadImage={this.onUploadImage} onDeleteImage={this.onDeleteImage}/>
                    <ChangePassword onSubmit={this.onChangePassword} error={error} resetError={this.resetError}/>
                    <DeleteAccount deleteCurrentUser={this.props.deleteCurrentUser}/>
                </Grid>
            </Grid>
        );
    }

    componentDidMount() {
        this.props.getCurrentUserStatistics();
    }

    private onSubmit = async (user: User) => {
        await this.props.updateCurrentUser(user);
        this.props.changeMessage('success', 'Ihre Angaben wurde gespeichert');
    };

    private onUploadImage = async (file: File) => {
        const response = await this.props.uploadImage(file);
        if (response?.ok) {
            this.props.changeMessage('success', 'Bild gespeichert');
        } else {
            this.props.changeMessage('error', 'Das Bild war zu groß (max. 1MB) oder war kein JPEG oder PNG');
        }
    };

    private onDeleteImage = () => {
        this.props.deleteImage();
    }

    private onChangePassword = (passwords: UserChangePasswordRequest) => {
        this.props.currentUser && this.props.changePassword(this.props.currentUser, passwords);
    }

    private resetError = () => {
        this.props.resetError();
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    currentUserStats: state.user.currentUserStats,
    error: state.error.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateCurrentUser: updateCurrentUserAction(dispatch),
    changeMessage: changeMessageAction(dispatch),
    uploadImage: uploadImageAction(dispatch),
    deleteImage: deleteImageAction(dispatch),
    getCurrentUserStatistics: getCurrentUserStatistics(dispatch),
    changePassword: changePasswordAction(dispatch),
    resetError: resetErrorAction(dispatch),
    deleteCurrentUser: deleteCurrentUserAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
