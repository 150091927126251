import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

import { ResourceGroup, ResourceIdMap, ResourceMap } from '../../../lib/resourceSupport';
import { Errors } from '../../../models/Errors';
import { isPlayoffMatch, Match } from '../../../models/Match';
import { MatchActivityPayloadResult } from '../../../models/MatchActivity';
import { Matchplay, MatchplayPhase, MatchplayVariant } from '../../../models/Matchplay';
import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import { GroupView } from '../../../models/MatchViews';
import { User } from '../../../models/User';
import { ReduxStoreState } from '../../../store';
import { submitMatchResultAction } from '../../../store/actions/match';
import {
    getMatchplayAction, getMatchplayGroupMatchesAction, getMatchplayPlayoffMatchesAction, getMatchplayRegistrationsAction
} from '../../../store/actions/matchplays';
import MatchplayDashboard from './MatchplayDashboard';

interface RouteProps {
    matchplayId: string;
}

interface StoreProps {
    currentUser?: User;
    matchplayById: ResourceIdMap<Matchplay>;
    registrationsByMatchplayId: ResourceGroup<MatchplayRegistration>;
    matchesByMatchplayId: ResourceGroup<Match>;
    groupViewByMatchplayId: ResourceMap<GroupView>;
    lastError?: Errors;
    fetchMatchplay(matchplayId: string): Promise<void>;
    fetchRegistrations(matchplayId: string): Promise<void>;
    fetchPlayoffMatches(matchplayId: string): Promise<void>;
    fetchGroupMatches(matchplayId: string): Promise<void>;
    submitMatchResult(id: string, result: MatchActivityPayloadResult): Promise<void>
}

type Props = StoreProps & RouteComponentProps<RouteProps>;

class MatchplayDashboardContainer extends Component<Props> {
    public render() {
        const {
            currentUser, matchplayById, registrationsByMatchplayId, matchesByMatchplayId,
            groupViewByMatchplayId, lastError, submitMatchResult,
        } = this.props;
        const { matchplayId } = this.props.match.params;

        if (lastError === Errors.NotFound) {
            return <Redirect to="/" />;
        }

        if (!currentUser || !(matchplayId in matchplayById)) {
            return null;
        }

        const matchplay = matchplayById[matchplayId];
        const registrations = registrationsByMatchplayId[matchplayId] ?? [];
        const playoffMatches = (matchesByMatchplayId[matchplayId] ?? []).filter(isPlayoffMatch);
        const groupView = groupViewByMatchplayId[matchplayId];

        return (
            <Fragment>
                {matchplay &&
                    <MatchplayDashboard
                        matchplay={matchplay}
                        currentUser={currentUser}
                        registrations={registrations}
                        playoffMatches={playoffMatches}
                        groupView={groupView}
                        abandonMatch={submitMatchResult} />}
            </Fragment>);
    }

    public async componentDidMount() {
        const { fetchMatchplay, fetchRegistrations, } = this.props;
        const { matchplayId } = this.props.match.params;
        if (!matchplayId) {
            return;
        }
        await fetchMatchplay(matchplayId);
        await fetchRegistrations(matchplayId);
    }

    public async componentDidUpdate(prevProps: Props) {
        const { match, matchplayById, fetchPlayoffMatches, fetchGroupMatches } = this.props;
        const { matchplayId } = match.params;
        if (!matchplayId) {
            return;
        }
        const prevMatchplay = prevProps.matchplayById[matchplayId];
        const currMatchplay = matchplayById[matchplayId];

        if (currMatchplay !== prevMatchplay) {
            switch (currMatchplay.variant) {
                case MatchplayVariant.GROUP: {
                    await fetchGroupMatches(matchplayId);
                    if (currMatchplay.phase === MatchplayPhase.EXECUTION ||
                        currMatchplay.phase === MatchplayPhase.AFTERMATH) {
                        await fetchPlayoffMatches(matchplayId);
                    }
                    break;
                }

                case MatchplayVariant.PLAYOFF: {
                    await fetchPlayoffMatches(matchplayId);
                    break;
                }
            }
        }
    }
}

const mapStateToProps = (state: ReduxStoreState) => ({
    currentUser: state.user.currentUser,
    matchplayById: state.matchplay.byId,
    registrationsByMatchplayId: state.matchplay.registrationsByMatchplayId,
    matchesByMatchplayId: state.match.byMatchplayId,
    groupViewByMatchplayId: state.match.groupViewByMatchplayId,
    lastError: state.matchplay.lastError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchMatchplay: getMatchplayAction(dispatch),
    fetchRegistrations: getMatchplayRegistrationsAction(dispatch),
    fetchPlayoffMatches: getMatchplayPlayoffMatchesAction(dispatch),
    fetchGroupMatches: getMatchplayGroupMatchesAction(dispatch),
    submitMatchResult: submitMatchResultAction(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchplayDashboardContainer));
