import React, { Component } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Button, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    title: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(4)

    },
    subtitle: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3)
    },
    mailIcon: {
        textAlign: 'center' as const,
        fontSize: theme.typography.h1.fontSize
    },
});

interface ComponentProps {
    email: string;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class RegisterUserComplete extends Component<Props> {
    public render() {
        const {classes} = this.props;
        const loginLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
            (linkProps: any, ref: any) => <Link innerRef={ref} to="/login" {...linkProps} />,
        );
        return (
            <Grid container direction="column" className={classes.root}>
                <Typography className={classes.subtitle}>
                    <MailOutlineIcon className={classes.mailIcon} />
                </Typography>
                <Typography variant="h5" component="h2" className={classes.title}>
                    Du hast Post!
                </Typography>
                <Typography className={classes.subtitle}>
                    Um deinen Registrierungsprozess abzuschließen, aktiviere bitte deinen Account,
                    indem du auf den Link in unserer Bestätigungsemail klickst.
                </Typography>
                <Typography className={classes.subtitle}>Herzlich Willkommen bei MatchplayTime!</Typography>
                <Button component={loginLink} color="primary">Zurück zur Anmeldung</Button>
            </Grid>
        );
    }
}

export default withStyles(styles)(RegisterUserComplete);
