import React, { Component, ReactNode } from 'react';
import {
    Button, ButtonProps, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
    Theme, WithStyles, withStyles
} from '@material-ui/core';
import CtaButton from './CtaButton';
import { WithTranslation, withTranslation } from 'react-i18next';

const styles: any = (theme: Theme) => createStyles({
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    }
});

interface ComponentProps {
    variant: 'info' | 'success' | 'warning' | 'danger' | 'primary' | 'secondary';
    dialogTitle: ReactNode;
    dialogContentText: ReactNode;
    className?: string;
    icon?: boolean;
    onConfirm(): void;
}

interface State {
    open: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & Omit<ButtonProps, 'variant'> & WithTranslation;

class ConfirmationButton extends Component<Props, State> {

    state = {
        open: false,
    };

    public render() {
        const {
            variant, dialogTitle, dialogContentText, className, icon, onConfirm, tReady, classes, children, t, ...other
        } = this.props;
        const { open } = this.state;
        let button;
        if (icon && (variant === 'primary' || variant === 'secondary')) {
            const size = other.size === 'large' ? 'medium' : other.size;
            button = (
                <IconButton size={size} edge="end" color={variant} onClick={this.onClick} className={className}>
                    {children}
                </IconButton>);
        } else if (variant === 'primary' || variant === 'secondary') {
            button = (
                <Button variant="contained" color={variant} onClick={this.onClick} className={className} {...other}>
                    {children}
                </Button>);
        } else {
            button = (
                <CtaButton color={variant} onClick={this.onClick} className={className}>
                    {children}
                </CtaButton>);
        }
        return (
            <React.Fragment>
                {button}
                <Dialog open={open} onClose={this.onClose}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="body1" color="textPrimary">
                            {dialogContentText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} variant="contained" autoFocus>
                            {t('common.cancel')}
                        </Button>
                        <Button onClick={this.onConfirm} variant="contained" className={classes[variant]}>
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    private onClick = () => {
        this.setState({ open: true });
    };

    private onClose = () => {
        this.setState({ open: false });
    };

    private onConfirm = () => {
        this.props.onConfirm();
        this.onClose();
    }
}

export default withTranslation()(withStyles(styles)(ConfirmationButton));
