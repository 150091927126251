import React, { Component } from 'react';
import clsx from 'clsx';
import { Container, Theme, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => ({
    root: {
        flex: 1,
        display: 'flex' as const,
        flexDirection: 'column' as const,
        marginBottom: theme.spacing(3),
    },
    loginPageRoot: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '15vh'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(4)
        },
    },
});

interface ComponentProps {
    isLoginPage?: boolean;
    fullWidth?: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class ContentContainer extends Component<Props> {
    public render() {
        const { classes, isLoginPage, fullWidth } = this.props;
        const maxWidth = (isLoginPage) ? 'sm' : fullWidth ? false : 'md';
        const classNames = clsx(classes.root, { [classes.loginPageRoot]: isLoginPage });
        return (
            <Container maxWidth={maxWidth} className={classNames}>
                <>{this.props.children}</>
            </Container>
        );
    }
}

export default withStyles(styles)(ContentContainer);
