import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core';

import { Player } from '../../../../models/Player';
import { botUser } from '../../../../models/User';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'inline-block',
        padding: theme.spacing(2),
        cursor: 'grab',
    },
    isDragging: {
        opacity: 0,
        cursor: 'move'
    },
    dragPreview: {
        display: 'inline-block',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.grey[400],
        border: '1px solid',
        opacity: 0.75,
    },
    botPlayer: {
        textTransform: 'uppercase' as const,
        fontStyle: 'italic' as const,
        color: theme.palette.text.disabled,
    }
}));

export const DragItemType = 'MatchEncounter/Player';

export interface DragItem {
    player: Player;
}

interface Props {
    player: Player;
}

const PreparationItem = ({ player }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const classes = useStyles();

    const [{ isDragging }, drag, preview] = useDrag({
        type: DragItemType,
        item: () => {
            return { player };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    if (player.user === botUser) {
        return (
            <span className={classes.botPlayer}>
                {t('components.matchplay.preparation.playoff.MatchplayPlayoffPreparation.botPlayer')}
            </span>
        );
    }

    drag(ref);
    const className = clsx(classes.root, {
        [`${classes.isDragging}`]: isDragging
    });
    return (
        <div ref={ref} className={className}>
            <PlayerNameDisplay player={player}/>
        </div>
    );
};

const PlayerNameDisplay = ({ player }: Pick<Props, 'player'>) => {
    return <>{player.user.name} ({player.user.hcp})</>;
};

export const DragItemPreview = ({ player }: Pick<Props, 'player'>) => {
    const classes = useStyles();
    return (
        <div className={classes.dragPreview}>
            <PlayerNameDisplay player={player}/>
        </div>
    );
};

export default PreparationItem;
