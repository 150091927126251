import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Box, Button, createStyles, Divider, Theme, WithStyles, withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { NewSponsor, Sponsor } from '../../models/Sponsor';
import Section from '../utils/Section';
import AdminSponsorsItem from './AdminSponsorsItem';
import AdminSponsorsItemForm from './AdminSponsorsItemForm';

const styles = (theme: Theme) => createStyles({
    sponsorFragment: {
        marginBottom: theme.spacing(1),
    },
});

interface ComponentProps {
    sponsors: Sponsor[];
    createSponsor(sponsor: NewSponsor): void;
    deleteSponsor(sponsor: Sponsor): void;
}

interface State {
    isAddingSponsor?: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class AdminSponsors extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const { sponsors, classes, t } = this.props;
        const { isAddingSponsor } = this.state;

        return (
            <Section title={t('components.tenant.AdminSponsors.title')} id="admin-sponsors">
                {sponsors.map(sponsor => (
                    <React.Fragment key={sponsor.id}>
                        <div className={classes.sponsorFragment}>
                            <AdminSponsorsItem sponsor={sponsor}
                                deleteSponsor={this.onDeleteSponsor} />
                        </div>
                        <Divider className={classes.sponsorFragment} />
                    </React.Fragment>
                ))}
                {isAddingSponsor &&
                    <AdminSponsorsItemForm onSave={this.onSaveAddSponsor} onCancel={this.onCancelAddSponsor} />}
                {!isAddingSponsor &&
                    <Box>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={this.onAddSponsor}>
                            {t('components.tenant.AdminSponsors.create')}
                        </Button>
                    </Box>}
            </Section>
        );
    }

    private onAddSponsor = () => {
        if (this.state.isAddingSponsor) {
            return;
        }
        this.setState({ isAddingSponsor: true });
    };

    private onCancelAddSponsor = () => {
        this.setState({ isAddingSponsor: false });
    };

    private onSaveAddSponsor = (sponsor: NewSponsor) => {
        this.props.createSponsor(sponsor);
        this.setState({ isAddingSponsor: false });
    };

    private onDeleteSponsor = (sponsor: Sponsor) => {
        this.props.deleteSponsor(sponsor);
    }
}

export default withStyles(styles)(withTranslation()(AdminSponsors));
