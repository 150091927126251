import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    '@global': {
        '#root': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
    },
});

const MptCssBaseline = () => {
    useStyles();
    return null;
};

export default MptCssBaseline;
