import config from '../config';
import { mptFetch } from '../lib/mptFetch';
import { Matchplay } from '../models/Matchplay';
import { MatchplayRegistration } from '../models/MatchplayRegistration';
import { MatchEncounter } from '../models/MatchEncounter';
import { GroupView, PlayoffTree } from '../models/MatchViews';
import { NewSponsor, Sponsor } from '../models/Sponsor';
import { GroupAssignments } from '../models/GroupAssignment';

const listMatchplays = async (): Promise<Matchplay[]> => {
    const response = await mptFetch(`${config.baseUrl}/matchplays`);
    return (await response.json()) as Matchplay[];
};

const getMatchplay = async (id: string): Promise<Matchplay> => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${id}`);
    return (await response.json()) as Matchplay;
};

const getMatchplayRegistrations = async (matchplayId: string): Promise<MatchplayRegistration[]> => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplayId}/registrations`);
    return (await response.json()) as MatchplayRegistration[];
};

const getMatchplayPlayoffMatches = async (matchplayId: string): Promise<PlayoffTree> => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplayId}/matches?view=PLAYOFF`);
    return (await response.json()) as PlayoffTree;
};

const getMatchplayGroupMatches = async (matchplayId: string): Promise<GroupView> => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplayId}/matches?view=GROUP`);
    return (await response.json()) as GroupView;
};

const createMatchplay = async (matchplayName: string) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays`, {
        method: 'POST',
        body: JSON.stringify({ name: matchplayName }),
    });
    const newMatchplayResource = response.headers.get('location');
    if (!newMatchplayResource) {
        throw Error('No matchplay resource');
    }
    const matchplayResponse = await mptFetch(newMatchplayResource);
    return (await matchplayResponse.json()) as Matchplay;
};

const updateMatchplay = async (matchplay: Matchplay) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}`, {
        method: 'PUT',
        body: JSON.stringify(matchplay),
    });
    return (await response.json()) as Matchplay;
};

const commitMatchplayPhase = async (matchplay: Matchplay) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}/commit-phase`, {
        method: 'POST',
    });
    return (await response.json()) as Matchplay;
}

const registerMatchplayUser = async (matchplayId: string) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplayId}/registration`, {
        method: 'POST',
    });
    return (await response.json()) as MatchplayRegistration;
};

const unregisterMatchplayUser = async (matchplayId: string, username?: string) => {
    if (username && username.length > 0) {
        await mptFetch(`${config.baseUrl}/matchplays/${matchplayId}/registration/users/${username}`, {
            method: 'DELETE',
        });
    } else {
        await mptFetch(`${config.baseUrl}/matchplays/${matchplayId}/registration`, {
            method: 'DELETE',
        });
    }
};

const initializePlayoffMatchplay = async (matchplay: Matchplay, matches: MatchEncounter[]) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}/init-playoff-matches`, {
        method: 'POST',
        body: JSON.stringify([...matches].reverse()),
    });
    return (await response.json());
};

const initializeGroupMatchplay = async (matchplay: Matchplay, groupAssignments: GroupAssignments) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}/init-group-matches`, {
        method: 'POST',
        body: JSON.stringify(groupAssignments),
    });
    return (await response.json());
};

const deleteMatchplay = async (matchplay: Matchplay) => {
    await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}`, {
        method: 'DELETE',
    });
};

const addMatchplaySponsor = async (matchplay: Matchplay, sponsor: NewSponsor) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}/assign-sponsor/${sponsor.id}`, {
        method: 'POST',
    });
    return (await response.json()) as Matchplay;
}

const deleteMatchplaySponsor = async (matchplay: Matchplay, sponsor: Sponsor) => {
    const response = await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}/assign-sponsor/${sponsor.id}`, {
        method: 'DELETE',
    });
    return (await response.json()) as Matchplay;
}

const startGroupPlayoff = async (matchplay: Matchplay) => {
    await mptFetch(`${config.baseUrl}/matchplays/${matchplay.id}/start-playoff`, {
        method: 'POST',
    });
}

export const matchplayService = {
    listMatchplays,
    getMatchplay,
    getMatchplayRegistrations,
    getMatchplayPlayoffMatches,
    getMatchplayGroupMatches,
    createMatchplay,
    updateMatchplay,
    commitMatchplayPhase,
    registerMatchplayUser,
    unregisterMatchplayUser,
    initializePlayoffMatchplay,
    initializeGroupMatchplay,
    deleteMatchplay,
    addMatchplaySponsor,
    deleteMatchplaySponsor,
    startGroupPlayoff,
};
