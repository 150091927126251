import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Box, createStyles, Divider, Grid, Link, Theme, Typography, withStyles, WithStyles
} from '@material-ui/core';

import { Sponsor } from '../../models/Sponsor';
import LogoPreview from '../tenant/LogoPreview';

const styles = (theme: Theme) => createStyles({
    sponsorContainer: {
        marginTop: theme.spacing(3),
    },
    title: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
    }
});

interface ComponentProps {
    title?: string;
    sponsors: Sponsor[];
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class Sponsors extends Component<Props> {
    public render() {
        const { sponsors, classes, title, t } = this.props;

        if (!sponsors || sponsors.length === 0) {
            return null;
        }

        return (
            <Box className={classes.sponsorContainer} textAlign="center">
                <Divider />
                <Typography variant="body1" component="p" className={classes.title}>
                    {title && title}
                    {!title && <span>{t('components.sponsoring.Sponsors.title')}</span>}
                </Typography>
                <Grid container alignItems="center" justify="space-around">
                    {sponsors.map(sponsor =>
                        <Link href={sponsor.targetUrl} target="_blank" rel="noopener" key={sponsor.id}>
                            <LogoPreview url={sponsor.logoUrl} alt={sponsor.name} />
                        </Link>
                    )}
                </Grid>
                <Divider />
            </Box>
        );
    }
}

export default withStyles(styles)(withTranslation()(Sponsors));
