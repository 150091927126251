import { Trans, useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import ConfirmationButton from '../../utils/ConfirmationButton';

interface Props {
    registration: MatchplayRegistration;
    size?: 'default' | 'small';
    action(registration: MatchplayRegistration): void;
}

const SignOutPlayer = ({ action, registration, size = 'default' }: Props) => {
    const { t } = useTranslation();

    return (
        <ConfirmationButton
            variant="secondary"
            startIcon={<DeleteIcon/>}
            icon={size === 'small'}
            size="small"
            dialogTitle={t('components.matchplay.settings.SignOutPlayer.signOutPlayerDialogTitle')}
            dialogContentText={
                <Trans i18nKey="components.matchplay.settings.SignOutPlayer.signOutPlayerDialogText"
                       values={{ player: registration.user.name }}
                       components={{ strong: <strong/> }}/>
            }
            onConfirm={async () => await action(registration)}>
            {size !== 'small' && (
                <Trans i18nKey="components.matchplay.settings.SignOutPlayer.signOutPlayer"/>
            )}
            {size === 'small' && (
                <DeleteIcon/>
            )}
        </ConfirmationButton>
    );
};

export default SignOutPlayer;
