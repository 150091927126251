import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { ReduxStoreState } from '../store';

interface RouteParams {
    matchplayId: string | undefined;
}

const useCurrentMatchplay = () => {
    const routeMatch = useRouteMatch<RouteParams>();
    const matchplaysById = useSelector((state: ReduxStoreState) => state.matchplay.byId);
    const matchplayId = routeMatch.params.matchplayId ?? '';
    return matchplaysById[matchplayId];
};

export default useCurrentMatchplay;
