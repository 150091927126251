import clsx from 'clsx';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';

import { makeStyles, TableCell, TableCellProps, Theme } from '@material-ui/core';

import { Player } from '../../../../models/Player';
import { botUser } from '../../../../models/User';
import PreparationItem, { DragItem, DragItemType } from './PreparationItem';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
    },
    dropHover: {
        background: theme.palette.grey[300],
    },
}));

interface ComponentProps {
    player: Player;
    swapPlayers(player1: Player, player2: Player): void
}

type Props = ComponentProps & Pick<TableCellProps, 'align'>;

const PreparationItemCell = ({ player, swapPlayers, align }: Props) => {
    const ref = useRef<HTMLTableCellElement>(null);
    const classes = useStyles();

    const [{ handlerId, dropHover }, drop] = useDrop({
        accept: DragItemType,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
                dropHover: monitor.canDrop() && monitor.isOver(),
            };
        },
        canDrop: (item: DragItem) => {
            return !!ref.current
                && (item.player !== player)
                && (player.user !== botUser);
        },
        drop: (item: DragItem) => {
            swapPlayers(item.player, player);
        }
    });
    drop(ref);

    const className = clsx(classes.root, {
        [`${classes.dropHover}`]: dropHover,
    })
    return (
        <TableCell
            ref={ref}
            className={className}
            data-handler-id={handlerId}
            align={align}>
            <PreparationItem player={player}/>
        </TableCell>
    );
};

export default PreparationItemCell;
