import { useTranslation } from 'react-i18next';

import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { sortGroups } from '../../../lib/sorting';
import { MatchActivityPayloadResult } from '../../../models/MatchActivity';
import { Matchplay } from '../../../models/Matchplay';
import { GroupMatches } from '../../../models/MatchViews';
import MatchplayMatchList from './MatchplayMatchList';
import { getGroupName } from '../../utils/GroupName';

const useStyles = makeStyles((theme: Theme) => ({
    groupHeader: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1),
    },
}));

interface ComponentProps {
    matchplay: Matchplay;
    groupMatches: GroupMatches[];

    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

const MatchplayGroupMatchList = ({ groupMatches, abandonMatch }: ComponentProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            {sortGroups(groupMatches).map((matches, groupIndex) => {
                const groupName = t('common.groupNameTemplate', { letter: getGroupName(groupIndex, groupMatches.length) });
                return (
                    <Grid item key={groupName} xs={12}>
                        <Typography className={classes.groupHeader}>{groupName}</Typography>
                        <MatchplayMatchList
                            matches={matches.matches}
                            abandonMatch={abandonMatch}
                            hideMatchNumber={true}
                        />
                    </Grid>
                );
            })
            }
        </Grid>
    )
}

export default MatchplayGroupMatchList;
