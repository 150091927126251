import React from 'react';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';
import AdminWebPluginsContainer from '../tenant/AdminWebPluginsContainer';

const WebPluginsPage = () => {

    return (
        <ContentContainer>
            <MainNavigationContainer/>
            <BreadcrumbsContainer/>
            <AdminWebPluginsContainer/>
        </ContentContainer>);
}

export default WebPluginsPage;
