import React, { Component } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { MatchplayPhase } from '../../models/Matchplay';
import { DisplayArea } from '../../models/Sponsor';
import MatchCardContainer from '../match/MatchCardContainer';
import MatchplayListContainer from '../matchplay/MatchplayListContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import SponsorsContainer from '../sponsoring/SponsorsContainer';
import Section from '../utils/Section';
import ContentContainer from './ContentContainer';
import NotificationsContainer from '../notification/NotificationsContainer';
import { WithTranslation, withTranslation } from 'react-i18next';

class AppHomePage extends Component<WithTranslation> {
    public render() {
        const { t } = this.props;

        return (
            <ContentContainer>
                <MainNavigationContainer/>
                <NotificationsContainer/>
                <Section title={t('components.appHomePage.currentMatchplays')}>
                    <MatchplayListContainer
                        filterPhase={[
                            MatchplayPhase.REGISTRATION_OPEN,
                            MatchplayPhase.REGISTRATION_CLOSED,
                            MatchplayPhase.EXECUTION_GROUP,
                            MatchplayPhase.EXECUTION_GROUP_FINISHED,
                            MatchplayPhase.EXECUTION]}
                        maxEntries={5}
                        emptyContent={<Typography>{t('components.appHomePage.noCurrentMatchplays')}</Typography>}
                    />
                </Section>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <Section title={t('components.appHomePage.lastPlayed')}>
                            <MatchCardContainer
                                variant="last-played"
                                emptyContent={<Typography>{t('components.appHomePage.noLastPlayed')}</Typography>}
                            />
                        </Section>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Section title={t('components.appHomePage.nextGames')}>
                            <MatchCardContainer
                                variant="nextup"
                                emptyContent={<Typography>{t('components.appHomePage.noNextGames')}</Typography>}
                            />
                        </Section>
                    </Grid>
                </Grid>
                <SponsorsContainer displayArea={DisplayArea.DASHBOARD}/>
            </ContentContainer>
        );
    }
}

export default (withTranslation()(AppHomePage));
