import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ResourceIdMap } from '../../lib/resourceSupport';
import { Match } from '../../models/Match';
import { Matchplay } from '../../models/Matchplay';
import Breadcrumbs from './Breadcrumbs';

interface StoreProps {
    matchplaysById: ResourceIdMap<Matchplay>,
    matchesById: ResourceIdMap<Match>,
}

interface RouteParams {
    matchplayId: string | undefined;
    matchId: string | undefined;
}

type Props = StoreProps & RouteComponentProps<RouteParams> & WithTranslation;

type SiteMap = { [key: string]: SiteMap };
type ParentMap = { [key: string]: string | undefined };

const siteMap: SiteMap = {
    '/admin': {
        '/admin/matchplays': {
            '/admin/matchplays/:matchplayId': {
                '/admin/matchplays/:matchplayId/preparation': {}
            }
        },
        '/admin/users': {},
        '/admin/plugins': {},
        '/admin/notifications': {},
    },
    '/matchplays': {
        '/matchplays/:matchplayId': {
            '/matches/:matchId': {},
            '/matchplays/:matchplayId/rules': {},
            '/matchplays/:matchplayId/playoff': {},
        },
    }
};
const parentMap = generateParentMap(siteMap);

class BreadcrumbsContainer extends Component<Props> {

    private breadcrumbNameMap = (): { [key: string]: (string | (() => string | { name: string, params: {} })) } => {
        const { t } = this.props;
        return {
            '/matches/:matchId': () => {
                const { match, matchesById } = this.props;
                const matchElem = matchesById[match.params.matchId ?? ''];
                const userOne = matchElem && matchElem.playerOne && matchElem.playerOne.user;
                const userTwo = matchElem && matchElem.playerTwo && matchElem.playerTwo.user;
                return (matchElem) ? t('components.navigation.BreadcrumbsContainer.entryMatch', {
                    playerOne: userOne && !userOne.deleted ? userOne.name : t('components.utils.Username.deletedUser'),
                    playerTwo: userTwo && !userTwo.deleted ? userTwo.name : t('components.utils.Username.deletedUser'),
                }) : '';
            },
            '/matchplays': t('components.navigation.BreadcrumbsContainer.entryMatchplays'),
            '/matchplays/:matchplayId': () => {
                const { match, matchplaysById, matchesById } = this.props;
                if (match.params.matchplayId) {
                    return matchplaysById[match.params.matchplayId]?.name ?? '';
                }
                if (match.params.matchId) {
                    const matchElem = matchesById[match.params.matchId];
                    const name = matchplaysById[matchElem?.matchplayId]?.name ?? '';
                    const params = { ...match.params, matchplayId: matchElem?.matchplayId };
                    return { name, params };
                }
                return '';
            },
            '/matchplays/:matchplayId/rules': t('components.navigation.BreadcrumbsContainer.entryMatchplayRules'),
            '/matchplays/:matchplayId/playoff': t('components.navigation.BreadcrumbsContainer.entryMatchplayPlayoff'),
            '/admin': t('components.navigation.BreadcrumbsContainer.entryAdmin'),
            '/admin/matchplays': t('components.navigation.BreadcrumbsContainer.entryAdminMatchplays'),
            '/admin/matchplays/:matchplayId': () => {
                const { match, matchplaysById } = this.props;
                return ((match.params.matchplayId) ? matchplaysById[match.params.matchplayId]?.name : '') ?? '';
            },
            '/admin/matchplays/:matchplayId/preparation': t('components.navigation.BreadcrumbsContainer.entryAdminMatchplayPreparation'),
            '/admin/users': t('components.navigation.BreadcrumbsContainer.entryAdminUsers'),
            '/admin/plugins': t('components.navigation.BreadcrumbsContainer.entryAdminPlugins'),
            '/admin/notifications': t('components.navigation.BreadcrumbsContainer.entryAdminNotifications'),
        };
    }

    public render() {
        const entries = this.getEntries();
        if (entries.some(entry => entry.name === '')) {
            return null;
        }

        return <Breadcrumbs entries={entries}/>;
    }

    private getEntries = () => {
        const { match } = this.props;
        const result = [];
        const breadcrumbNameMap = this.breadcrumbNameMap();
        let item: string | undefined = match.path;
        while (item) {
            const resolver = breadcrumbNameMap[item];
            const resolved = (typeof resolver === 'function') ? resolver() : resolver ?? '';
            const params = (typeof resolved === 'object') ? resolved.params : match.params;
            const name = (typeof resolved === 'object') ? resolved.name : resolved;
            result.push({ name, to: item, params });
            item = parentMap[item];
        }

        return result.reverse();
    }
}

const mapStateToProps = (state: any) => ({
    matchplaysById: state.matchplay.byId,
    matchesById: state.match.byId,
});

function generateParentMap(tree: SiteMap): ParentMap {
    const parentMap: ParentMap = {};
    const generateMap = (parent: string | undefined, subTree: SiteMap) => {
        for (const [key, value] of Object.entries(subTree)) {
            if (!!parent) {
                parentMap[key] = parent;
            }
            generateMap(key, value);
        }
    };
    generateMap(undefined, tree);
    return parentMap;
}

export default withRouter(connect(mapStateToProps)(withTranslation()(BreadcrumbsContainer)));
