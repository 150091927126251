import { Field, FieldInputProps, FieldProps, getIn } from 'formik';
import { useTranslation } from 'react-i18next';

import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { MouseEvent, useCallback } from 'react';

type Props =
    Pick<FieldInputProps<any>, 'name'> &
    Pick<DatePickerProps,
        'format' |
        'label' |
        'helperText' |
        'maxDateMessage' |
        'disableFuture' |
        'className' |
        'fullWidth'>;

const FormikDateField = (props: Props) =>
    <Field component={InnerDateField} {...props} />;

const InnerDateField = (props: FieldProps & Props) => {

    const { field, form } = props;
    const formError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && Boolean(formError);
    const { t } = useTranslation();

    const onClear = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        form.setFieldValue(field.name, null)
    }, [form, field]);

    return (
        <DatePicker {...props} {...field}
                    error={showError}
                    helperText={(showError) ? formError : props.helperText}
                    cancelLabel={t('common.cancel')}
                    onChange={date => {
                        form.setFieldValue(field.name, date)
                    }}
                    onError={error => {
                        if (error && form.errors.date !== error) {
                            form.setFieldError(field.name, error ? String(error) : '');
                        }
                    }}
                    TextFieldComponent={(props: TextFieldProps) =>
                        <TextField
                            {...props}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton edge="end"
                                                    size="small"
                                                    disabled={!field.value}
                                                    onClick={onClear}>
                                            <ClearIcon/>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    }
        />
    );
}

export default FormikDateField;
