import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { Dispatch } from 'redux';

import { connect } from 'react-redux';
import { activateAction } from '../../store/actions/user';

interface StoreProps {
    isSuccessfullyActivated: boolean;
    activate(token: string): void;
}

type Props = StoreProps;

class ActivatePage extends Component<Props> {
    public render() {
        const { isSuccessfullyActivated } = this.props;
        const hasToken = Boolean(this.getToken());
        const shouldRedirect = isSuccessfullyActivated || !hasToken;
        return (
            <Fragment>
                {shouldRedirect && <Redirect to="/login" />}
            </Fragment>
        );
    }

    public componentDidMount() {
        const token = this.getToken();
        if (token) {
            this.props.activate(token);
        }
    }

    private getToken(): string | null {
        const url = new URL(window.location.toString());
        return url.searchParams.get('token');
    }
}

const mapStateToProps = (state: any) => ({
    isSuccessfullyActivated: state.user.isSuccessfullyActivated,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    activate: activateAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePage);
