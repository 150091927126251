import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createMatchplayAction } from '../../store/actions/matchplays';
import AdminMatchplays from './AdminMatchplays';

interface StoreProps {
    createMatchplay(matchplayName: string): void;
}

type Props = StoreProps;

class AdminMatchplayContainer extends Component<Props> {
    public render() {
        const { createMatchplay } = this.props;
        return (
            <AdminMatchplays onCreateMatchplay={createMatchplay}/>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createMatchplay: createMatchplayAction(dispatch),
});

export default connect(null, mapDispatchToProps)(AdminMatchplayContainer);
