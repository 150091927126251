import { Location } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PreviousLocationState } from '../../models/LocationState';

interface ComponentProps {
    fallbackTo?: string
}

interface StoreProps {
    isAuthenticated: boolean
}

type Props = ComponentProps & StoreProps & RouteProps;

class NonAuthenticatedRoute extends Component<Props> {
    public render() {
        const { isAuthenticated, fallbackTo, location } = this.props;
        if (!isAuthenticated) {
            return <Route {...this.props} />;
        }
        const from = (location as Location<PreviousLocationState>)?.state?.from;
        const to = from ?? (fallbackTo ? fallbackTo : '/');
        return <Redirect to={to} />;
    }
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(NonAuthenticatedRoute);
