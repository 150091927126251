import { IdentifiableResource } from '../lib/resourceSupport';
import { MatchActivity } from './MatchActivity';
import { Matchplay } from './Matchplay';
import { Player } from './Player';
import { PlayoffMatch } from './PlayoffMatch';
import { GroupMatch } from './GroupMatch';
import { User } from './User';

export interface Match extends IdentifiableResource {
    type: MatchType;
    phase: MatchPhase;
    number: number;
    scheduledAt?: number;
    result?: MatchResult;
    playerOne?: Player;
    playerTwo?: Player;
    activities: MatchActivity[];
    matchplayId: Matchplay['id'];
    loser?: User;
    winner?: User;
}

export enum MatchPhase {
    PROPOSAL = 'PROPOSAL',
    SCHEDULED = 'SCHEDULED',
    FINISHED = 'FINISHED',
    WALKTHROUGH = 'WALKTHROUGH',
}

export enum MatchResult {
    EMPTY = '',
    ONE_UP = 'ONE_UP',
    TWO_UP = 'TWO_UP',
    TWO_AND_ONE = 'TWO_AND_ONE',
    THREE_AND_ONE = 'THREE_AND_ONE',
    THREE_AND_TWO = 'THREE_AND_TWO',
    FOUR_AND_TWO = 'FOUR_AND_TWO',
    FOUR_AND_THREE = 'FOUR_AND_THREE',
    FIVE_AND_THREE = 'FIVE_AND_THREE',
    FIVE_AND_FOUR = 'FIVE_AND_FOUR',
    SIX_AND_FOUR = 'SIX_AND_FOUR',
    SIX_AND_FIVE = 'SIX_AND_FIVE',
    SEVEN_AND_FIVE = 'SEVEN_AND_FIVE',
    SEVEN_AND_SIX = 'SEVEN_AND_SIX',
    EIGHT_AND_SIX = 'EIGHT_AND_SIX',
    EIGHT_AND_SEVEN = 'EIGHT_AND_SEVEN',
    NINE_AND_SEVEN = 'NINE_AND_SEVEN',
    NINE_AND_EIGHT = 'NINE_AND_EIGHT',
    TEN_AND_EIGHT = 'TEN_AND_EIGHT',
    ABANDONED_BY_PLAYER = 'ABANDONED_BY_PLAYER',
    ABANDONED_BY_ORGANIZER = 'ABANDONED_BY_ORGANIZER',
}

export function getMatchResult(result: string): MatchResult {
    switch (result) {
        case '1 Up':
            return MatchResult.ONE_UP;
        case '2 Up':
            return MatchResult.TWO_UP;
        case '2 & 1':
            return MatchResult.TWO_AND_ONE;
        case '3 & 1':
            return MatchResult.THREE_AND_ONE;
        case '3 & 2':
            return MatchResult.THREE_AND_TWO;
        case '4 & 2':
            return MatchResult.FOUR_AND_TWO;
        case '4 & 3':
            return MatchResult.FOUR_AND_THREE;
        case '5 & 3':
            return MatchResult.FIVE_AND_THREE;
        case '5 & 4':
            return MatchResult.FIVE_AND_FOUR;
        case '6 & 4':
            return MatchResult.SIX_AND_FOUR;
        case '6 & 5':
            return MatchResult.SIX_AND_FIVE;
        case '7 & 5':
            return MatchResult.SEVEN_AND_FIVE;
        case '7 & 6':
            return MatchResult.SEVEN_AND_SIX;
        case '8 & 6':
            return MatchResult.EIGHT_AND_SIX;
        case '8 & 7':
            return MatchResult.EIGHT_AND_SEVEN;
        case '9 & 7':
            return MatchResult.NINE_AND_SEVEN;
        case '9 & 8':
            return MatchResult.NINE_AND_EIGHT;
        case '10 & 8':
            return MatchResult.TEN_AND_EIGHT;
        default:
            return MatchResult.EMPTY;
    }
}

export enum MatchType {
    GROUP = 'GROUP',
    PLAYOFF = 'PLAYOFF',
}

export const isGroupMatch = (match: Match): match is GroupMatch => (match.type === MatchType.GROUP);
export const isPlayoffMatch = (match: Match): match is PlayoffMatch => (match.type === MatchType.PLAYOFF);

export function getMatchWinner(match: Match): User | undefined {
    if (isPlayoffMatch(match)) {
        return match.winner;
    } else if (isGroupMatch(match)) {
        if (match.playerOne && match.playerTwo &&
            match.holesWonPlayerOne !== undefined &&
            match.holesWonPlayerTwo !== undefined) {
            if (match.holesWonPlayerOne > match.holesWonPlayerTwo) {
                return match.playerOne.user;
            } else if (match.holesWonPlayerTwo > match.holesWonPlayerOne) {
                return match.playerTwo.user;
            }
        }
    }
    return undefined;
}

export const getMatchWinnerEmail = (match: Match): string => getMatchWinner(match)?.email ?? '';
