import { User } from './User';
import { IdentifiableResource } from '../lib/resourceSupport';

export interface Notification extends IdentifiableResource {
    subject: string;
    message: string;
    state: NotificationState;
    createdAt: number;
    updatedAt: number;
    startAt?: number;
    endAt?: number;
    author: User,
}

export enum NotificationState {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export interface CreateOrUpdateNotification {
    subject: string;
    message: string;
    startAt?: number | null;
    endAt?: number | null;
}
