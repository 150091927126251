import moment from 'moment';

import config from '../config';
import { mptFetch } from '../lib/mptFetch';
import { Match } from '../models/Match';
import { MatchActivityPayloadGroupResult, MatchActivityPayloadResult } from '../models/MatchActivity';

const getMatch = async (id: string): Promise<Match> => {
    const response = await mptFetch(`${config.baseUrl}/matches/${id}`);
    return (await response.json()) as Match;
};

const getMyMatches = async (): Promise<Match[]> => {
    const response = await mptFetch(`${config.baseUrl}/users/me/matches`);
    return await response.json() as Match[];
}

const commentMatch = async (id: string, comment: string): Promise<Match> => {
    const response = await mptFetch(`${config.baseUrl}/matches/${id}/comment`, {
        method: 'POST',
        body: comment,
    });
    return (await response.json()) as Match;
}

const proposeMatchDate = async (id: string, date: Date): Promise<Match> => {
    const response = await mptFetch(`${config.baseUrl}/matches/${id}/propose-date`, {
        method: 'POST',
        body: String(moment(date).valueOf()),
    });
    return (await response.json()) as Match;
}

const acceptMatchDate = async (id: string): Promise<Match> => {
    const response = await mptFetch(`${config.baseUrl}/matches/${id}/accept-date`, {
        method: 'POST',
    });
    return (await response.json()) as Match;
}

const submitMatchResult = async (id: string, data: MatchActivityPayloadResult): Promise<Match> => {
    const response = await mptFetch(`${config.baseUrl}/matches/${id}/playoff-result`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    return (await response.json()) as Match;
}

const submitGroupMatchResult = async (id: string, data: MatchActivityPayloadGroupResult): Promise<Match> => {
    const response = await mptFetch(`${config.baseUrl}/matches/${id}/group-result`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
    return (await response.json()) as Match;
}

export const matchService = {
    getMatch,
    getMyMatches,
    commentMatch,
    proposeMatchDate,
    acceptMatchDate,
    submitMatchResult,
    submitGroupMatchResult,
};
