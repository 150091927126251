import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { TenantInformation } from '../../models/TenantInformation';
import { loginAction, resetUserStoreAction } from '../../store/actions/user';
import LoginForm from './LoginForm';
import { changeMessageAction } from '../../store/actions/page';
import { MessageVariant } from '../../models/Message';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface StoreProps {
    tenantInformation?: TenantInformation;
    logInError: boolean;
    login(email: string, password: string, rememberMe: boolean): void;
    changeMessage(variant: MessageVariant, text: string): void;
    resetUserStore(): void;
}

type Props = StoreProps;

class LoginContainer extends Component<Props> {
    public render() {
        const { tenantInformation, logInError } = this.props;
        if (!tenantInformation) {
            return null;
        }
        return (
            <React.Fragment>
                <LoginForm tenantInformation={tenantInformation}
                           onLogin={this.onLogin}/>
                <Snackbar open={logInError} autoHideDuration={3000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity="error">
                        Bei der Anmeldung hat etwas nicht geklappt.
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    }

    private onLogin = (email: string, password: string, rememberMe: boolean) => {
        this.props.login(email, password, rememberMe);
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
    logInError: state.user.isLoginError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    login: loginAction(dispatch),
    resetUserStore: resetUserStoreAction(dispatch),
    changeMessage: changeMessageAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
