import { Dispatch } from 'redux';

import { Message, MessageVariant } from '../../models/Message';
import { CHANGE_MESSAGE } from '../reducers/page';

export const changeMessageAction = (dispatch: Dispatch) => {
    return async (variant: MessageVariant, text: string) => {
        dispatch(action({ id: Math.random(), variant, text }));
    };

    // @formatter:off
    function action(message: Message) { return { type: CHANGE_MESSAGE, payload: message }; }
    // @formatter:on
};

export const clearMessageAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(action());
    };

    // @formatter:off
    function action() { return { type: CHANGE_MESSAGE, payload: undefined }; }
    // @formatter:on
};
