import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ListItem, ListItemText, makeStyles } from '@material-ui/core';

import { DragItem, DragItemType } from './DragItem';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
        cursor: 'grab',
    },
    isDragging: {
        opacity: 0,
        cursor: 'move'
    },
    dragPreview: {
        display: 'inline-block',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.grey[400],
        border: '1px solid',
        opacity: 0.75,
    },
}));

type Props = DragItem;

const GroupPreparationItem = ({ registration, groupIndex }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const [{ isDragging }, drag, preview] = useDrag({
        type: DragItemType,
        item: (): DragItem => {
            return { registration, groupIndex };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    drag(ref);

    const className = clsx(classes.root, {
        [`${classes.isDragging}`]: isDragging,
    });
    return (
        <ListItem innerRef={ref} className={className}>
            <ListItemText>
                <PreparationItemUserDisplay registration={registration} />
            </ListItemText>
        </ListItem>
    );
};

const PreparationItemUserDisplay = ({ registration }: Pick<Props, 'registration'>) => {
    return <>{registration.user.name} ({registration.user.hcp})</>;
};

export const DragItemPreview = ({ registration }: DragItem) => {
    const classes = useStyles();
    return (
        <div className={classes.dragPreview}>
            <PreparationItemUserDisplay registration={registration} />
        </div>
    );
};

export default GroupPreparationItem;
