import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Grid, Tab, Tabs } from '@material-ui/core';

import { Matchplay } from '../../../models/Matchplay';
import FormikTextField from '../../utils/forms/FormikTextField';
import Section from '../../utils/Section';

interface Props {
    matchplay: Matchplay;
    onSubmit(matchplay: Matchplay): void;
}

const MatchplayTextsSettings = ({ matchplay, onSubmit }: Props) => {
    const { t } = useTranslation();

    const schema = useMemo(() => Yup.object().shape({
        description: Yup.object().shape({
            rulesText: Yup.string().nullable(),
            pricesText: Yup.string().nullable(),
            sponsorsText: Yup.string().nullable(),
            participationTermsText: Yup.string().nullable(),
        }),
    }), []);

    const onFormFubmit = useCallback((values: Matchplay, formikHelpers: FormikHelpers<Matchplay>) => {
        onSubmit(values);
        formikHelpers.resetForm({ values });
    }, [onSubmit]);

    return (
        <>
            {matchplay &&
                <Section title={t('components.matchplay.settings.MatchplayTextsSettings.sectionTitle')}>
                    <Formik
                        component={TextsSettingsForm}
                        validationSchema={schema}
                        initialValues={matchplay}
                        onSubmit={onFormFubmit} />
                </Section>}
        </>
    );
};

const TextsSettingsForm = (props: FormikProps<Matchplay>) => {
    const { isSubmitting, isValidating, isValid, dirty } = props;
    const { t } = useTranslation();
    const submitButtonDisabled = isSubmitting || isValidating || !dirty || !isValid;

    return (
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormFragmentTexts {...props} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit" className="right"
                        disabled={submitButtonDisabled}>
                        {t('common.save')}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

enum TabValues {
    Terms,
    Rules,
    Prices,
    Sponsors
}

const FormFragmentTexts = (_props: FormikProps<Matchplay>) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(TabValues.Terms);

    const handleChange = useCallback((_event: any, newValue: TabValues) => {
        setSelectedTab(newValue);
    }, []);

    return (
        <>
            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
                <Tab label={t('components.matchplay.settings.MatchplayTextsSettings.participationTermsTextLabel')}
                    value={TabValues.Terms} />
                <Tab label={t('components.matchplay.settings.MatchplayTextsSettings.rulesTextLabelShort')}
                    value={TabValues.Rules} />
                <Tab label={t('components.matchplay.settings.MatchplayTextsSettings.pricesTextLabelShort')}
                    value={TabValues.Prices} />
                <Tab label={t('components.matchplay.settings.MatchplayTextsSettings.sponsorsTextLabelShort')}
                    value={TabValues.Sponsors} />
            </Tabs>
            {selectedTab === TabValues.Terms &&
                <FormikTextField
                    multiline rows={15} rowsMax={1000}
                    name="description.participationTermsText"
                    label={t('components.matchplay.settings.MatchplayTextsSettings.participationTermsTextLabel')}
                    margin="normal" variant="outlined" fullWidth />
            }
            {selectedTab === TabValues.Rules &&
                <FormikTextField
                    multiline rows={15} rowsMax={1000}
                    name="description.rulesText"
                    label={t('components.matchplay.settings.MatchplayTextsSettings.rulesTextLabel')}
                    margin="normal" variant="outlined" fullWidth />
            }
            {selectedTab === TabValues.Prices &&
                <FormikTextField
                    multiline rows={15} rowsMax={1000}
                    name="description.pricesText"
                    label={t('components.matchplay.settings.MatchplayTextsSettings.pricesTextLabel')}
                    margin="normal" variant="outlined" fullWidth />
            }
            {selectedTab === TabValues.Sponsors &&
                <FormikTextField
                    multiline rows={15} rowsMax={1000}
                    name="description.sponsorsText"
                    label={t('components.matchplay.settings.MatchplayTextsSettings.sponsorsTextLabel')}
                    margin="normal" variant="outlined" fullWidth />
            }
        </>
    );
};

export default MatchplayTextsSettings;
