import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './resources/translation.de.json';

export const resources = {
    de: { translation: de }
} as const;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'de',
        fallbackLng: 'de',
        react: {
            bindI18n: 'languageChanged loaded',
            bindI18nStore: 'added removed',
        },
        debug: false,
    });

export default i18n;
