import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ResourceGroup } from '../../../lib/resourceSupport';
import { Matchplay, MatchplayPhase } from '../../../models/Matchplay';
import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import { User } from '../../../models/User';
import { registerMatchplayUserAction, unregisterMatchplayUserAction } from '../../../store/actions/matchplays';
import MatchplayJoinButton from './MatchplayJoinButton';

interface ComponentProps {
    matchplay: Matchplay;
}

interface StoreProps {
    currentUser?: User;
    registrationsByMatchplayId: ResourceGroup<MatchplayRegistration>;
    register(matchplay: Matchplay): void;
    unregister(registration: MatchplayRegistration): void;
}

type Props = ComponentProps & StoreProps;

class MatchplayJoinButtonContainer extends Component<Props> {

    public render() {
        const { matchplay, registrationsByMatchplayId, currentUser } = this.props;
        const registrations = registrationsByMatchplayId[matchplay.id];
        const isJoined = !!registrations?.find(x => x.user.email === currentUser?.email);
        const isDisabled = matchplay.phase !== MatchplayPhase.REGISTRATION_OPEN;

        return (
            <MatchplayJoinButton isJoined={isJoined}
                                 isDisabled={isDisabled}
                                 onClick={this.onClick}
                                 participationTermsText={matchplay.description.participationTermsText}/>
        );
    }

    private onClick = () => {
        const { matchplay, currentUser, registrationsByMatchplayId, register, unregister } = this.props;
        const registrations = registrationsByMatchplayId[matchplay.id];
        const registration = registrations?.find(x => x.user.email === currentUser?.email);
        if (registration) {
            unregister(registration);
        } else {
            register(matchplay);
        }
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    registrationsByMatchplayId: state.matchplay.registrationsByMatchplayId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    register: registerMatchplayUserAction(dispatch),
    unregister: unregisterMatchplayUserAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchplayJoinButtonContainer);
