import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import config from '../../config';
import { ReduxStoreState } from '../../store';

const Analytics = () => {
    const location = useLocation();
    const isAuthenticated = useSelector((state: ReduxStoreState) => state.user.isAuthenticated);
    const currentUser = useSelector((state: ReduxStoreState) => state.user.currentUser);
    useEffect(() => {
        ReactGA.initialize(config.trackingId, { debug: false });
        ReactGA.set({ anonymizeIp: true });
    }, []);
    useEffect(() => {
        if (isAuthenticated && currentUser) {
            ReactGA.set({ userId: currentUser.id });
        }
    }, [isAuthenticated, currentUser]);
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return null;
};

export default Analytics;
