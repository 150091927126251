import React from 'react';
import { useTranslation } from 'react-i18next';

import { Matchplay, MatchplayPhase } from '../../models/Matchplay';

interface Props {
    matchplay: Matchplay;
}

const MatchplayNumberPlayersText = ({ matchplay }: Props) => {
    const { t } = useTranslation();
    switch (matchplay.phase) {
        case MatchplayPhase.EXECUTION:
        case MatchplayPhase.EXECUTION_GROUP:
        case MatchplayPhase.EXECUTION_GROUP_FINISHED:
        case MatchplayPhase.AFTERMATH:
            return <>{t('components.matchplay.common.numberOfPlayers', { count: matchplay.numberPlayers })}</>
        case MatchplayPhase.INITIALIZATION:
        case MatchplayPhase.REGISTRATION_OPEN:
        case MatchplayPhase.REGISTRATION_CLOSED:
            return <>{t('components.matchplay.common.numberOfRegistrations', { count: matchplay.numberRegistrations })}</>
    }
}

export default MatchplayNumberPlayersText;
