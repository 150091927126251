import React, { Component } from 'react';

import MatchplayPreparationContainer from '../matchplay/preparation/MatchplayPreparationContainer';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';

class MatchplayPreparationPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer />
                <BreadcrumbsContainer/>
                <MatchplayPreparationContainer />
            </ContentContainer>
        );
    }
}

export default MatchplayPreparationPage;
