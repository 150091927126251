import React, { Component } from 'react';

import { Box, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import MatchplayEndGame from '../../matchplay/settings/MatchplayAbandonMatch';
import { Match } from '../../../models/Match';
import { MatchActivityPayloadResult } from '../../../models/MatchActivity';

const styles = (theme: Theme) => ({
    box:{
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});

interface Props {
    match: Match;
    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

class MatchActivityActionAbandon extends Component<Props & WithStyles<typeof styles>> {

    public render() {
        const { match, abandonMatch, classes } = this.props;
        return (
            <Grid container direction="column" alignItems="flex-end" justify="space-around">
                <Box className={classes.box}>
                    <MatchplayEndGame isPlayer={true} match={match} abandonMatch={abandonMatch}/>
                </Box>
            </Grid>
        );
    }
}

export default withStyles(styles)(MatchActivityActionAbandon);
