/* tslint:disable: max-classes-per-file */

import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import * as Yup from 'yup';

import { Box, Button, Grid, IconButton, Theme, withStyles, WithStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import FormikTextField from '../utils/forms/FormikTextField';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme: Theme) => ({
    buttonArea: {
        marginTop: theme.spacing(2),
        height: 56,
    },
    actionButton: {
        marginRight: theme.spacing(1),
    },
    cancelButton: {
        alignSelf: 'center' as const,
    },
});

interface FormModel {
    matchplayName: string;
}

const initialValues: FormModel = { matchplayName: '' };

interface ComponentProps {
    onSave(matchplayName: string): void;
    onCancel(): void;
}

type Props = ComponentProps & WithTranslation;

class AdminMatchplaysItemForm extends Component<Props> {
    private schema = () => {
        const { t } = this.props;
        return Yup.object().shape({
            matchplayName: Yup
                .string()
                .required(t('validation.matchplayName.required'))
                .max(254, t('validation.matchplayName.max')),
        });
    }

    public render() {
        const { onCancel } = this.props;
        return (
            <Formik
                component={InnerForm}
                onSubmit={this.onSave}
                onReset={onCancel}
                initialValues={initialValues}
                validationSchema={this.schema()} />
        );
    }

    private onSave = (formData: FormModel) => {
        this.props.onSave(formData.matchplayName);
    }
}

type InnerFormProps = FormikProps<FormModel> & WithStyles<typeof styles> & WithTranslation;

class InnerFormType extends Component<InnerFormProps> {
    public render() {
        const { classes, isValid, dirty, t } = this.props;
        const disableSubmitButton = !(dirty && isValid);

        return (
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm>
                        <Box display="flex" flexDirection="column">
                            <FormikTextField name="matchplayName" label="Matchplayname" margin="normal"
                                variant="outlined" />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box className={classes.buttonArea} display="flex" flexDirection="row">
                            <Button type="submit" color="primary" variant="contained"
                                disabled={disableSubmitButton} className={classes.actionButton}>
                                {t('components.tenant.AdminMatchplays.create')}
                            </Button>
                            <IconButton className={classes.cancelButton} type="reset">
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        );
    }
}

const InnerForm = withStyles(styles)(withTranslation()(InnerFormType));

export default withTranslation()(AdminMatchplaysItemForm);
