import { Dispatch } from 'redux';

import { PasswordReset } from '../../models/PasswordReset';
import { User, UserChangePasswordRequest, UserMatchStats, UserRegistration } from '../../models/User';
import { userService } from '../../services/user';
import {
    ACTIVATE_FAILURE, ACTIVATE_REQUEST, ACTIVATE_SUCCESS, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS, DELETE_CURRENT_USER_FAILURE, DELETE_CURRENT_USER_REQUEST, DELETE_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_STATS_FAILURE, GET_CURRENT_USER_STATS_REQUEST,
    GET_CURRENT_USER_STATS_SUCCESS, GET_CURRENT_USER_SUCCESS, GET_PUBLISHED_NOTIFICATIONS_FAILURE,
    GET_PUBLISHED_NOTIFICATIONS_REQUEST, GET_PUBLISHED_NOTIFICATIONS_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST,
    LOGIN_RESTORED, LOGIN_SUCCESS, LOGIN_TOKEN, LOGOUT, PASSWORD_RESET_FAILURE, PASSWORD_RESET_SUCCESS,
    REGISTER_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, REQUEST_PASSWORD_RESET_FAILURE,
    REQUEST_PASSWORD_RESET_SUCCESS, RESET, UPDATE_CURRENT_USER_FAILURE, UPDATE_CURRENT_USER_REQUEST,
    UPDATE_CURRENT_USER_SUCCESS, UPLOAD_IMAGE_FAILURE, UPLOAD_IMAGE_SUCCESS
} from '../reducers/user';
import { Errors } from '../../models/Errors';
import { MptError } from '../../lib/mptFetch';
import { Notification } from '../../models/Notification';

export const resetUserStoreAction = (dispatch: Dispatch) => () => {
    dispatch(reset());

    // @formatter:off
    function reset() { return { type: RESET }; }
    // @formatter:on
};

export const loginAction = (dispatch: Dispatch) => {
    return async (email: string, password: string, rememberMe?: boolean) => {
        dispatch(request());
        try {
            const response = await userService.login(email, password, rememberMe);
            dispatch(token(response.token));

            const user = await userService.getCurrentUser();
            dispatch(success(user));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: LOGIN_REQUEST }; }
    function token(payload: string) { return { type: LOGIN_TOKEN, payload }; }
    function success(user: User) { return { type: LOGIN_SUCCESS, payload: user }; }
    function failure() { return { type: LOGIN_FAILURE }; }
    // @formatter:on
};

export const restoreAction = (dispatch: Dispatch) => {
    return async () => {
        try {
            const tok = userService.restore();
            if (tok) {
                dispatch(token(tok));
                const user = await userService.getCurrentUser();

                dispatch(restored(user));
                return;
            }
        } catch {
            // ignore
        }
        dispatch(restored(undefined));
    };

    // @formatter:off
    function token(payload: string) { return { type: LOGIN_TOKEN, payload }; }
    function restored(user?: User) { return { type: LOGIN_RESTORED, payload: user }; }
    // @formatter:on
};

export const logoutAction = (dispatch: Dispatch) => () => {
    userService.logout();
    dispatch(success());

    // @formatter:off
    function success() { return { type: LOGOUT }; }
    // @formatter:on
};

export const registerAction = (dispatch: Dispatch) => {
    return async (registration: UserRegistration) => {
        dispatch(request());
        try {
            await userService.register(registration);
            dispatch(success(registration.email));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: REGISTER_REQUEST }; }
    function success(email: string) { return { type: REGISTER_SUCCESS, payload: email }; }
    function failure() { return { type: REGISTER_FAILURE }; }
    // @formatter:on
};

export const activateAction = (dispatch: Dispatch) => {
    return async (token: string) => {
        dispatch(request());
        try {
            await userService.activate(token);
            dispatch(success());
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: ACTIVATE_REQUEST }; }
    function success() { return { type: ACTIVATE_SUCCESS }; }
    function failure() { return { type: ACTIVATE_FAILURE }; }
    // @formatter:on
};

export const requestPasswordResetAction = (dispatch: Dispatch) => {
    return async (user: User) => {
        try {
            await userService.requestPasswordReset(user);
            dispatch(success());
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function success() { return { type: REQUEST_PASSWORD_RESET_SUCCESS }; }
    function failure() { return { type: REQUEST_PASSWORD_RESET_FAILURE }; }
    // @formatter:on
};

export const passwordResetAction = (dispatch: Dispatch) => {
    return async (passwordReset: PasswordReset) => {
        try {
            await userService.passwordReset(passwordReset);
            dispatch(success());
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function success() { return { type: PASSWORD_RESET_SUCCESS }; }
    function failure() { return { type: PASSWORD_RESET_FAILURE }; }
    // @formatter:on
};

export const getCurrentUserAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const user = await userService.getCurrentUser();
            dispatch(success(user));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_CURRENT_USER_REQUEST }; }
    function success(user: User) { return { type: GET_CURRENT_USER_SUCCESS, payload: user }; }
    function failure() { return { type: GET_CURRENT_USER_FAILURE }; }
    // @formatter:on
};

export const updateCurrentUserAction = (dispatch: Dispatch) => {
    return async (user: User) => {
        dispatch(request());
        try {
            const updatedUser = await userService.updateCurrentUser(user);
            dispatch(success(updatedUser));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: UPDATE_CURRENT_USER_REQUEST }; }
    function success(user: User) { return { type: UPDATE_CURRENT_USER_SUCCESS, payload: user }; }
    function failure() { return { type: UPDATE_CURRENT_USER_FAILURE }; }
    // @formatter:on
};

export const deleteCurrentUserAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            await userService.deleteCurrentUser();
            dispatch(success());
            await logoutAction(dispatch)();
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: DELETE_CURRENT_USER_REQUEST }; }
    function success() { return { type: DELETE_CURRENT_USER_SUCCESS }; }
    function failure() { return { type: DELETE_CURRENT_USER_FAILURE }; }
    // @formatter:on
};

export const uploadImageAction = (dispatch: Dispatch) => {
    return async (file: File): Promise<Response | undefined> => {
        let response;
        try {
            response = await userService.uploadImage(file);
            const user = await userService.getCurrentUser();
            dispatch(success(user));
            return response;
        } catch (ex) {
            if (ex instanceof MptError) {
                response = ex.response;
            }
            dispatch(failure());
        }
        return response;
    };

    // @formatter:off
    function success(user: User) { return { type: UPLOAD_IMAGE_SUCCESS, payload: user }; }
    function failure() { return { type: UPLOAD_IMAGE_FAILURE }; }
    // @formatter:on
};

export const deleteImageAction = (dispatch: Dispatch) => {
    return async () => {
        try {
            await userService.deleteImage();
            const user = await userService.getCurrentUser();
            dispatch(success(user));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function success(user: User) { return { type: UPLOAD_IMAGE_SUCCESS, payload: user }; }
    function failure() { return { type: UPLOAD_IMAGE_FAILURE }; }
    // @formatter:on
};

export const getCurrentUserStatistics = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const userStats = await userService.getCurrentUserStats();
            dispatch(success(userStats));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_CURRENT_USER_STATS_REQUEST }; }
    function success(userStats: UserMatchStats) { return { type: GET_CURRENT_USER_STATS_SUCCESS, payload: userStats }; }
    function failure() { return { type: GET_CURRENT_USER_STATS_FAILURE }; }
    // @formatter:on
};

export const changePasswordAction = (dispatch: Dispatch) => {
    return async (user: User, passwords: UserChangePasswordRequest) => {
        dispatch(request());
        try {
            await userService.changePassword(passwords);
            dispatch(success());
        } catch (e) {
            if (e instanceof MptError && e.response.status === 400) {
                dispatch(failure(Errors.IncorrectCurrentPassword));
            } else {
                dispatch(failure(Errors.InternalError));
            }
            return;
        }
        await loginAction(dispatch)(user.email, passwords.newPassword);
    };

    // @formatter:off
    function request() { return { type: CHANGE_PASSWORD_REQUEST }; }
    function success() { return { type: CHANGE_PASSWORD_SUCCESS }; }
    function failure(error: Errors) { return { type: CHANGE_PASSWORD_FAILURE, error }; }
    // @formatter:on
};

export const getPublishedNotificationsAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const items = (await userService.getPublishedNotifications());
            dispatch(success(items));
        } catch (e) {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: GET_PUBLISHED_NOTIFICATIONS_REQUEST }; }
    function success(items: Notification[]) { return { type: GET_PUBLISHED_NOTIFICATIONS_SUCCESS, payload: items }; }
    function failure() { return { type: GET_PUBLISHED_NOTIFICATIONS_FAILURE }; }
    // @formatter:on
}
