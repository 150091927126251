import { DisplayArea } from '../../models/Sponsor';


export function displayAreasToDisplayText(displayArea: DisplayArea | string) : string {
    switch (displayArea) {
        case DisplayArea.DASHBOARD:
            return 'Dashboard';
        case DisplayArea.LOGIN:
            return 'Anmeldeseite';
    }
    return '';
}

export function displayAreasToCreateText(displayArea: DisplayArea | string) : string {
    return `Anzeigen auf ${displayAreasToDisplayText(displayArea)}`
}