import React, { Component } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
    placeholder: string;
    onSearch?(search: string): void;
}

interface State {
    value: string;
}

class SearchTextField extends Component<Props, State> {
    readonly state: State = { value: '' };

    public render() {
        const { value } = this.state;
        return (
            <TextField
                placeholder={this.props.placeholder}
                value={value}
                onChange={this.onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {!!value &&
                                <IconButton aria-label="clear" size="small" disabled={!value} onClick={this.onClear}>
                                    <ClearIcon />
                                </IconButton>}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    private onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { onSearch } = this.props;
        const value = evt.target.value;
        this.setState({ value })
        onSearch && onSearch(value);
    };

    private onClear = () => {
        const { onSearch } = this.props;
        this.setState({ value: '' });
        onSearch && onSearch('');
    }
}

export default SearchTextField;
