/* tslint:disable:class-name */
/* tslint:disable:max-classes-per-file */
import store from '../store';
import config from '../config';

export class MptError extends Error {
    readonly response: Response;

    constructor(response: Response) {
        super(response.statusText);
        this.response = response;
    }
}

export class HeadersBuilder {
    public headers: HeadersInit = {};

    public withAuthorization() {
        const { user } = store.getState();
        if (user && user.token) {
            this.headers = { ...this.headers, 'Authorization': `Bearer ${user.token}` };
        }
        return this;
    }

    public withTenant() {
        const { tenant } = store.getState();
        if (tenant && tenant.tenantInformation) {
            this.withTenantIdentifier(tenant.tenantInformation.id)
        }
        return this;
    }

    public withTenantIdentifier(tenantIdentifier: string) {
        this.headers = { ...this.headers, 'X-Tenant': tenantIdentifier };
        return this;
    }

    public withOAuthAuthorization() {
        this.headers = {
            ...this.headers,
            'Authorization': `Basic ${btoa(`${config.oauthClient}:${config.oauthClientSecret}`)}`
        };
        return this;
    }

    public asJSON() {
        this.headers = {
            ...this.headers,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        return this;
    }
}

export async function fetchOrThrow(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const response = await fetch(input, init);
    if (!response.ok) {
        throw new MptError(response);
    }
    return response;
}

export async function mptFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const defaultHeaders = new HeadersBuilder()
        .withAuthorization()
        .withTenant()
        .asJSON()
        .headers;
    const headers = {
        ...defaultHeaders,
        ...(init?.headers || {}),
    };
    return fetchOrThrow(input, { ...init, headers });
}
