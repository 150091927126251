import React, { Component } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Button, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    title: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(4)
    },
    subtitle: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3)
    },
});

type Props = WithStyles<typeof styles>;

class PasswordResetComplete extends Component<Props> {
    public render() {
        const { classes } = this.props;
        const loginLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
            (linkProps: any, ref: any) => <Link innerRef={ref} to="/login" {...linkProps} />,
        );
        return (
            <Grid container direction="column" className={classes.root}>
                <Typography variant="h5" component="h2" className={classes.title}>
                    Passwort gespeichert!
                </Typography>
                <Typography className={classes.subtitle}>
                    Kehre zum Login zurück, um dich mit deinem neuen Passwort anzumelden.
                </Typography>
                <Button component={loginLink} color="primary">Zurück zur Anmeldung</Button>
            </Grid>
        );
    }
}

export default withStyles(styles)(PasswordResetComplete);
