import React, { FC } from 'react';
import { TenantSettings } from '../../models/TenantSettings';
import Section from '../utils/Section';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Link, TextField, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmationButton from '../utils/ConfirmationButton';

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        marginTop: theme.spacing(3),
    },
    actionButton: {
        marginRight: theme.spacing(1),
    },
}));

interface ComponentProps {
    tenantSettings: TenantSettings;
    activateAction(): void;
    deactivateAction(): void;
    renewAction(): void;
}

type Props = ComponentProps;

const AdminWebPlugins: FC<Props> = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();

    if (!props.tenantSettings) {
        return null;
    }

    return (
        <Section title={t('components.tenant.AdminWebPlugins.title')}>
            <Typography>
                <Trans i18nKey="components.tenant.AdminWebPlugins.description"
                       components={{
                           helpLink: <Link href="//support.matchplaytime.com"
                                           target="_blank" rel="noopener noreferrer"/>
                       }}/>
            </Typography>
            {props.tenantSettings.apiKey &&
            <Box marginTop={3}>
                <TextField label="API Key" value={props.tenantSettings.apiKey}
                           variant="outlined" fullWidth={true} InputProps={{ readOnly: true }}/>
            </Box>}
            <Box className={classes.actions}>
                {!props.tenantSettings.apiKey &&
                <Button onClick={props.activateAction}
                        variant="contained"
                        className={classes.actionButton}
                        color="primary">
                    <Trans i18nKey="components.tenant.AdminWebPlugins.activate"/>
                </Button>}
                {props.tenantSettings.apiKey &&
                <>
                    <ConfirmationButton variant="secondary"
                                        className={classes.actionButton}
                                        dialogTitle={t('components.tenant.AdminWebPlugins.deactivateDialogTitle')}
                                        dialogContentText={t('components.tenant.AdminWebPlugins.deactivateDialogContent')}
                                        onConfirm={props.deactivateAction}>
                        <Trans i18nKey="components.tenant.AdminWebPlugins.deactivate"/>
                    </ConfirmationButton>
                    <ConfirmationButton variant="danger"
                                        className={classes.actionButton}
                                        dialogTitle={t('components.tenant.AdminWebPlugins.renewDialogTitle')}
                                        dialogContentText={t('components.tenant.AdminWebPlugins.renewDialogContent')}
                                        onConfirm={props.renewAction}>
                        <Trans i18nKey="components.tenant.AdminWebPlugins.renew"/>
                    </ConfirmationButton>
                </>}
            </Box>
        </Section>
    );
}

export default AdminWebPlugins;
