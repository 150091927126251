import React, { Component } from 'react';

import { CircularProgress, Theme, WithStyles, withStyles } from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import clsx from 'clsx';

const styles = (theme: Theme) => ({
    logo: {
        maxHeight: 72,
        maxWidth: 256,
    },
    logoPreviewArea: {
        display: 'inline-flex',
        // flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 72 + 2 * theme.spacing(2),
        minWidth: 256 + 2 * theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    logoBorder: {
        borderWidth: 1,
        borderColor: theme.palette.grey[500],
        borderStyle: 'solid',
    }
});

interface ComponentProps {
    url?: string;
    alt?: string;
    useBorder?: boolean;
    loading?: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class LogoPreview extends Component<Props> {
    public render() {
        const { url, alt, useBorder, classes, loading } = this.props;
        const classNames = clsx(classes.logoPreviewArea, { [classes.logoBorder]: useBorder });
        return (
            <div className={classNames}>
                {loading && <CircularProgress/>}
                {!loading && url && url !== '' &&
                <img
                    src={url}
                    alt={alt}
                    className={classes.logo}/>}
                {!loading && !url &&
                <BrokenImageIcon/>}
            </div>
        );
    }
}

export default withStyles(styles)(LogoPreview);
