import React from 'react';
import { connect } from 'react-redux';

import { Fade, LinearProgress } from '@material-ui/core';

import { ReduxStoreState } from '../../store';

interface StoreProps {
    tenantIsLoading: boolean;
    matchplayIsLoading: boolean;
    matchIsLoading: boolean;
    userIsLoading: boolean;
    userManagementIsLoading: boolean;
}

type Props = StoreProps;

const PageLoadingAnimationContainer = (props: Props) => {
    const {
        tenantIsLoading, matchplayIsLoading, matchIsLoading,
        userIsLoading, userManagementIsLoading,
    } = props;

    if (tenantIsLoading || matchplayIsLoading || matchIsLoading ||
        userIsLoading || userManagementIsLoading) {
        // Show the progress indicator - only if it takes longer than a certain time.
        return (
            <Fade in style={{ transitionDelay: '500ms' }} unmountOnExit>
                <LinearProgress />
            </Fade>
        );
    }

    // Not loading - display an empty box with the same height
    return <div style={{ height: 4 }} />;
};

const mapStateToProps = (state: ReduxStoreState) => ({
    tenantIsLoading: state.tenant.isLoading,
    matchplayIsLoading: state.matchplay.isLoading,
    matchIsLoading: state.match.isLoading,
    userIsLoading: state.user.isLoading,
    userManagementIsLoading: state.userManagement.isLoading,
});

export default connect(mapStateToProps)(PageLoadingAnimationContainer);
