import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';
import Section from '../../../utils/Section';
import { DragItem } from './DragItem';
import GroupPreparationItemDragLayer from './GroupPreparationItemDragLayer';
import GroupPreparationList from './GroupPreparationList';
import GroupPreparationActionButtons from './GroupPreparationActionButtons';
import { getGroupName } from '../../../utils/GroupName';

const useStyles = makeStyles(theme => ({
    groupHeader: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1),
    },
    groupItem: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

interface Props {
    groups: MatchplayRegistration[][];
    isPlayable: boolean;

    onStartMatchplay(): void;
    onMoveGroupRegistration(registration: MatchplayRegistration, from: number, to: number): void;
    onRegistrationsShuffle(): void;
    onRegistrationsFirstComeFirstServe(): void;
}

const MatchplayGroupsPreparation = ({
    groups, isPlayable,
    onStartMatchplay, onMoveGroupRegistration, onRegistrationsShuffle, onRegistrationsFirstComeFirstServe,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onMove = useCallback((dragItem: DragItem, to: number) => {
        onMoveGroupRegistration(dragItem.registration, dragItem.groupIndex, to);
    }, [onMoveGroupRegistration]);

    const renderedGroups = groups.map((group, idx) => {
        const groupName = t('common.groupNameTemplate', { letter: getGroupName(idx, group.length) });

        return (
            <Grid key={groupName} item xs={12} sm={6} md={4} className={classes.groupItem}>
                <Typography className={classes.groupHeader}>{groupName}</Typography>
                <Divider />
                <GroupPreparationList
                    registrations={group}
                    groupIndex={idx}
                    onMove={onMove} />
            </Grid>
        );
    })

    return (
        <Section
            title={`${t('components.matchplay.preparation.groups.MatchplayGroupsPreparation.title')}`}
            actions={(
                <>
                    <GroupPreparationActionButtons
                        onShuffle={onRegistrationsShuffle}
                        onFirstComeFirstServe={onRegistrationsFirstComeFirstServe} />
                    <Button
                        onClick={onStartMatchplay}
                        variant="contained"
                        color="secondary"
                        disabled={!isPlayable}>
                        {t('components.matchplay.preparation.common.actionStart')}
                    </Button>
                </>
            )}>
            <GroupPreparationItemDragLayer />
            <Grid container spacing={3}>
                {renderedGroups}
            </Grid>
        </Section>
    );
};

export default MatchplayGroupsPreparation;
