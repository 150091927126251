import React from 'react';
import CookieBanner from 'react-cookie-banner';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';

const styles = {
    banner: {
        height: 'auto',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    button: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '0.875rem',
        fontWeight: 500,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textTransform: 'uppercase',
    },
    message: {
        display: 'block',
        textAlign: 'left',
        lineHeight: 2,
        marginRight: theme.spacing(15),
    },
    link: {},
}

const CookieConsent = () => {
    const { t } = useTranslation();
    const link = (
        <a href="https://www.yoomani.me/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
            {t('components.utils.CookieConsent.linkTitle')}
        </a>
    );
    return (
        <CookieBanner message={t('components.utils.CookieConsent.message')}
                      buttonMessage={t('components.utils.CookieConsent.accept')}
                      link={link} dismissOnScroll={true} dismissOnClick={true}
                      styles={styles}
                      cookie="mpt-acceptance-cookie"/>
    );
}

export default CookieConsent;
