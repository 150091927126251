import React, { Component } from 'react';

import RegisterUserContainer from '../login/RegisterUserContainer';
import ContentContainer from './ContentContainer';

class SignupPage extends Component {
    public render() {
        return (
            <ContentContainer isLoginPage={true}>
                <RegisterUserContainer />
            </ContentContainer>
        );
    }
}

export default SignupPage;
