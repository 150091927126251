import React, { Component } from 'react';

import { Field, FieldProps, getIn } from 'formik';

import { FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';

class FormikPasswordField extends Component<TextFieldProps> {
    public render() {
        return (
            <Field component={InnerTextField} {...this.props} />
        );
    }
}

interface State {
    showPassword: boolean;
}

const InnerTextField = (props: FieldProps & OutlinedInputProps) => {

    const [values, setValues] = React.useState<State>({
        showPassword: false,
    });

    const { field, form } = props;
    const formError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && Boolean(formError);

    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <React.Fragment>
            <OutlinedInput
                {...props} {...field}
                type={values.showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}
                                    onMouseDown={handleMouseDown}
                                    edge="end">
                            {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                }
                error={showError}/>
            {showError && <FormHelperText error={true}>{formError}</FormHelperText>}
        </React.Fragment>
    );
};

export default FormikPasswordField;
