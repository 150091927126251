import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import {
    Card, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
    makeStyles, Tooltip
} from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailableTwoTone';
import LaunchIcon from '@material-ui/icons/Launch';

import { exportMatchEvent } from '../../../lib/calendar';
import { MatchActivityItemProps } from './MatchActivityItem';
import { MessageItemAvatar, MessageItemSubheader } from '../../utils/Message';
import { MatchPhase } from '../../../models/Match';

const useStyles = makeStyles(() => ({
    eventEntry: {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
    }
}));

const MatchActivityItemAcceptProposal = (props: MatchActivityItemProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { match, item, indexOfType } = props;
    const showScheduledDate = (indexOfType === 0) && (match.phase !== MatchPhase.PROPOSAL);

    const onExport = useCallback(async () => exportMatchEvent(match, t), [match, t]);

    return (
        <Card>
            <CardHeader
                avatar={<MessageItemAvatar user={item.user} />}
                title={t('components.match.activities.MatchActivityItemAcceptProposal.title')}
                subheader={<MessageItemSubheader user={item.user} createdAt={item.createdAt} />}
            />
            {showScheduledDate && (
                <>
                    <Divider />
                    <CardContent>
                        <List>
                            <ListItem className={classes.eventEntry}>
                                <ListItemIcon>
                                    <EventAvailableIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        match.scheduledAt &&
                                        <Moment unix format={t('dateTime.matchDateTime')}>
                                            {match.scheduledAt / 1000}
                                        </Moment>
                                    }
                                    secondary="Matchplay" />

                                <ListItemSecondaryAction>
                                    <Tooltip title={`${t('components.match.common.exportToCalendar')}`} aria-label="export">
                                        <IconButton edge="end" aria-label="export" onClick={onExport}>
                                            <LaunchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </CardContent>
                </>
            )}
        </Card>
    );
};

export default MatchActivityItemAcceptProposal;
