import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, List, Theme, WithStyles, withStyles } from '@material-ui/core';

import { Matchplay } from '../../models/Matchplay';
import { User } from '../../models/User';
import { PageLink } from '../utils/PageLink';
import MatchplayListItem from './MatchplayListItem';

const styles = (theme: Theme) => ({
    allEntriesButton: {
        marginTop: theme.spacing(2),
    },
});

interface ComponentProps {
    currentUser?: User,
    matchplays: Matchplay[];
    emptyContent?: ReactNode;
    linkPattern?: string;
    notAllEntries?: boolean;
    allEntriesLink?: string;
}

type Props = ComponentProps & WithStyles<typeof styles>;

const MatchplayList = (props: Props) => {
    const { classes, matchplays, emptyContent, currentUser, linkPattern, notAllEntries, allEntriesLink } = props;
    const { t } = useTranslation();
    const lastIndex = matchplays.length - 1;
    const matchplayElements = matchplays.map((matchplay: Matchplay, index: number) => (
        <MatchplayListItem currentUser={currentUser} matchplay={matchplay} key={matchplay.id}
            divider={(index < lastIndex) || notAllEntries} linkPattern={linkPattern} />
    ));
    const allEntriesPageLink = PageLink(allEntriesLink ?? '');

    return (
        <List component="nav">
            {matchplayElements.length > 0
                ? matchplayElements
                : emptyContent}
            {notAllEntries &&
                <Button component={allEntriesPageLink} className={classes.allEntriesButton}>
                    {t('components.matchplay.MatchplayList.showAll')}
                </Button>}
        </List>
    );
}

export default withStyles(styles)(MatchplayList);
