import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { MatchplayPhase } from '../../models/Matchplay';
import Section from '../utils/Section';
import MatchplayListContainer from './MatchplayListContainer';

const MatchplayOverview = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Section title={t('components.matchplay.MatchplayOverview.titleCurrent')}>
                <MatchplayListContainer
                    filterPhase={[
                        MatchplayPhase.REGISTRATION_OPEN,
                        MatchplayPhase.REGISTRATION_CLOSED,
                        MatchplayPhase.EXECUTION_GROUP,
                        MatchplayPhase.EXECUTION_GROUP_FINISHED,
                        MatchplayPhase.EXECUTION]}
                    emptyContent={<Typography>{t('components.matchplay.MatchplayOverview.emptyCurrent')}</Typography>} />
            </Section>
            <Section title={t('components.matchplay.MatchplayOverview.titlePast')}>
                <MatchplayListContainer
                    filterPhase={[MatchplayPhase.AFTERMATH]}
                    emptyContent={<Typography>{t('components.matchplay.MatchplayOverview.emptyPast')}</Typography>} />
            </Section>
        </Fragment>
    );
}

export default MatchplayOverview;
