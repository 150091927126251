/* tslint:disable: forin */

import { Match } from '../models/Match';
import { GroupMatches, Standing } from '../models/MatchViews';

function sortComp<T>(a: T, b: T, orderBy: keyof T) {
    const vA = a[orderBy];
    const vB = b[orderBy];
    if (vA < vB) {
        return -1;
    }
    if (vA > vB) {
        return 1;
    }
    return 0;
}

export function stableSort<T>(items: T[], orderBy: (keyof T)[]): T[] {
    const sortPredicate = (a: T, b: T) => {
        for (const o in orderBy) {
            const r = sortComp(a, b, o as keyof T);
            if (r) {
                return r;
            }
        }
        return 0;
    };
    return items.slice().sort(sortPredicate);
}

export function sortMatches<T extends Match>(matches: T[]): T[] {
    return matches.slice()
        .sort((a, b) => (a.number < b.number) ? -1 : (a.number === b.number) ? 0 : 1);
}

export function sortGroups(groupMatches: GroupMatches[]): GroupMatches[] {
    return groupMatches.slice()
        .sort((a: GroupMatches, b: GroupMatches) => (a.groupIndex < b.groupIndex) ? -1 : (a.groupIndex === b.groupIndex) ? 0 : 1)
}

export function sortGroupStandings(standings: Standing[]) {
    return standings.map(s => ({ points: s.matchesWon * 3 + s.matchesDraw, ...s }))
        .sort((lhs, rhs) => rhs.points - lhs.points);
}
