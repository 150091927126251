import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

import { ResourceIdMap } from '../../lib/resourceSupport';
import { Errors } from '../../models/Errors';
import { Match, MatchPhase } from '../../models/Match';
import { MatchActivityPayloadGroupResult, MatchActivityPayloadResult } from '../../models/MatchActivity';
import { Matchplay } from '../../models/Matchplay';
import { User } from '../../models/User';
import {
    acceptMatchDateAction, commentMatchAction, getMatchAction, proposeMatchDateAction,
    submitGroupMatchResultAction,
    submitMatchResultAction
} from '../../store/actions/match';
import { getMatchplayAction } from '../../store/actions/matchplays';
import MatchActivities from './activities/MatchActivities';
import { GroupFormSubmitData } from './activities/MatchActivityActionGroupResult';
import { PlayoffFormSubmitData } from './activities/MatchActivityActionPlayoffResult';
import MatchHeader from './MatchHeader';
import MatchResult from './MatchResult';
import {ChangeWinnerSubmitData} from './activities/MatchActivityActionChangeWinner';

interface StoreProps {
    currentUser: User;
    matchesById: ResourceIdMap<Match>;
    matchplaysById: ResourceIdMap<Matchplay>;
    lastError?: Errors;
    getMatch(matchId: string): void;
    getMatchplay(matchplayId: string): void;
    commentMatch(matchId: string, comment: string): void;
    proposeMatchDate(matchId: string, date: Date): void;
    acceptMatchDate(matchId: string): void;
    submitMatchResult(matchId: string, data: MatchActivityPayloadResult): void;
    submitGroupMatchResult(matchId: string, data: MatchActivityPayloadGroupResult): void;
}

interface RouteParams {
    matchId: string | undefined;
}

type Props = StoreProps & RouteComponentProps<RouteParams>;

class MatchContainer extends Component<Props> {

    public render() {
        const { currentUser, lastError } = this.props;

        if (lastError === Errors.NotFound) {
            return <Redirect to="/" />;
        }

        const match = this.getMatch();
        if (!match) {
            return null;
        }

        const matchplay = this.getMatchplay();
        if (!matchplay) {
            return null;
        }

        const currentUserIsPlayer = Boolean(!!currentUser &&
            (match.playerOne?.user.email === currentUser.email || match.playerTwo?.user.email === currentUser.email));
        const isScheduled = match.phase !== MatchPhase.PROPOSAL;
        return (
            <React.Fragment>
                <MatchHeader match={match} />
                <MatchResult match={match} />
                <MatchActivities currentUser={currentUser}
                    match={match}
                    matchplay={matchplay}
                    items={match.activities}
                    isMatchPlayer={currentUserIsPlayer}
                    isScheduled={isScheduled}
                    onComment={this.onComment}
                    onProposeDate={this.onProposeDate}
                    onAcceptDate={this.onAcceptDate}
                    onMatchResult={this.onMatchResult}
                    onMatchResultChange={this.onMatchResultChange}
                    onGroupMatchResult={this.onGroupMatchResult}
                    onChangeWinner={this.onChangeWinner}
                    abandonMatch={this.props.submitMatchResult} />
            </React.Fragment>
        );
    }

    public componentDidMount() {
        const { getMatch, match } = this.props;
        const id = match.params.matchId;
        if (id) {
            getMatch(id);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const { getMatchplay } = this.props;
        const match = this.getMatch();
        if (match && !prevProps.matchesById[match.id]) {
            getMatchplay(match.matchplayId);
        }
    }

    private getMatch = (): Match | null => {
        const { matchesById, match } = this.props;
        const id = match.params.matchId;
        if (id) {
            return matchesById[id];
        }
        return null;
    };

    private getMatchplay = (): Matchplay | null => {
        const { matchplaysById } = this.props;
        const match = this.getMatch();
        if (match) {
            return matchplaysById[match.matchplayId];
        }
        return null;
    };

    private onComment = (comment: string) => {
        const { commentMatch, match } = this.props;
        const id = match.params.matchId;
        if (id) {
            commentMatch(id, comment);
        }
    };

    private onProposeDate = (date: Date) => {
        const { proposeMatchDate, match } = this.props;
        const id = match.params.matchId;
        if (id) {
            proposeMatchDate(id, date);
        }
    };

    private onAcceptDate = async () => {
        const { acceptMatchDate, match } = this.props;
        const id = match.params.matchId;
        if (id) {
            acceptMatchDate(id);
        }
    };

    private prepareMatchResult = (data: PlayoffFormSubmitData): MatchActivityPayloadResult | null => {
        const match = this.getMatch();
        if (!match) {
            return null;
        }
        const player1 = match.playerOne;
        const player2 = match.playerTwo;
        if (!player1 || !player2) {
            return null;
        }
        return {
            result: data.result,
            winner: (data.winner === player1.user.email) ? player1.user : player2.user,
            loser: (data.winner === player1.user.email) ? player2.user : player1.user,
            playedAt: data.playedAt == null ? undefined : data.playedAt,
        };
    }

    private prepareChangeWinnerResult = (data: ChangeWinnerSubmitData): MatchActivityPayloadResult | null => {
        const match = this.getMatch();
        if (!match || !match.result) {
            return null;
        }
        const player1 = match.playerOne;
        const player2 = match.playerTwo;
        if (!player1 || !player2) {
            return null;
        }
        return {
            result: match.result,
            winner: (data.winner === player1.user.email) ? player1.user : player2.user,
            loser: (data.winner === player1.user.email) ? player2.user : player1.user,
            playedAt: undefined,
        };
    }

    private onMatchResult = async (data: PlayoffFormSubmitData) => {
        const match = this.getMatch();
        const resultData = this.prepareMatchResult(data);
        if (resultData && match) {
            await this.props.submitMatchResult(match.id, resultData);
        }
    };

    private onMatchResultChange = async (data: PlayoffFormSubmitData) => {
        const match = this.getMatch();
        const resultData = this.prepareMatchResult(data);
        if (resultData && match) {
            await this.props.submitMatchResult(match.id, resultData);
        }
    };

    private onGroupMatchResult = (data: GroupFormSubmitData) => {
        const match = this.getMatch();
        if (!match) {
            return;
        }
        this.props.submitGroupMatchResult(match.id, {
            holesWonPlayerOne: data.holesWonPlayerOne,
            holesWonPlayerTwo: data.holesWonPlayerTwo,
            playedAt: data.playedAt ?? undefined,
        });
    }

    private onChangeWinner = async (data: ChangeWinnerSubmitData) => {
        const match = this.getMatch();
        const resultData = this.prepareChangeWinnerResult(data);
        if (resultData && match) {
            await this.props.submitMatchResult(match.id, resultData);
        }
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    matchesById: state.match.byId,
    lastError: state.match.lastError,
    matchplaysById: state.matchplay.byId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMatch: getMatchAction(dispatch),
    getMatchplay: getMatchplayAction(dispatch),
    commentMatch: commentMatchAction(dispatch),
    proposeMatchDate: proposeMatchDateAction(dispatch),
    acceptMatchDate: acceptMatchDateAction(dispatch),
    submitMatchResult: submitMatchResultAction(dispatch),
    submitGroupMatchResult: submitGroupMatchResultAction(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchContainer));
