import React, { Component } from 'react';
import {
    Box, Button, createStyles, Divider, FormControl, InputLabel, Theme, withStyles, WithStyles
} from '@material-ui/core';
import { UserChangePasswordRequest } from '../../models/User';
import Section from '../utils/Section';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormikPasswordField from '../utils/forms/FormikPasswordField';
import { Errors } from '../../models/Errors';

const styles = (theme: Theme) => createStyles({
    margin: {
        marginBottom: theme.spacing(2),
    }
});

interface FormData {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
}

interface ComponentProps {
    error?: Errors;
    onSubmit(passwords: UserChangePasswordRequest): void;
    resetError(): void;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class ChangePassword extends Component<Props> {

    private formikRef?: FormikProps<FormData>;

    private schema = Yup.object().shape({
        currentPassword: Yup.string()
            .required('Bitte aktuelles Passwort eingeben'),
        password: Yup.string()
            .required('Bitte neues Passwort eingeben')
            .min(8, 'Passwort muss mindestens 8 Zeichen lang sein.')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, und ein Sonderzeichen (@$!%*#?&) enthalten'
            ),
        passwordConfirmation: Yup.string()
            .required('Bitte neues Passwort eingeben')
            .oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen'),
    });

    public componentDidUpdate(prevProps: Readonly<Props>) {
        const { error } = this.props;
        if (error !== prevProps.error && error === Errors.IncorrectCurrentPassword) {
            this.formikRef?.setFieldError('currentPassword', 'Falsches Passwort angegeben');
        }
    }

    public render() {
        const { classes } = this.props;
        return (
            <Section title="Password ändern">
                <Formik validationSchema={this.schema}
                        initialValues={{ currentPassword: '', password: '', passwordConfirmation: '' }}
                        onSubmit={this.onSubmit}
                        innerRef={(p: any) => (this.formikRef = p)}>
                    {(props: FormikProps<FormData>) => (
                        <Form>
                            <FormControl fullWidth variant="outlined" className={classes.margin}>
                                <InputLabel htmlFor="currentPassword">Aktuelles Passwort</InputLabel>
                                <FormikPasswordField id="currentPassword" name="currentPassword"
                                                     label="Aktuelles Password" variant="outlined"/>
                            </FormControl>
                            <Divider className={classes.margin}/>
                            <FormControl fullWidth variant="outlined" className={classes.margin}>
                                <InputLabel htmlFor="password">Neues Passwort</InputLabel>
                                <FormikPasswordField id="password" name="password" label="Neues Password"/>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" className={classes.margin}>
                                <InputLabel htmlFor="passwordConfirmation">Passwort wiederholen</InputLabel>
                                <FormikPasswordField id="passwordConfirmation" name="passwordConfirmation"
                                                     label="Password wiederholen"/>
                            </FormControl>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Button variant="contained" color="primary" type="submit" className="right"
                                        disabled={props.isSubmitting}>
                                    Password ändern
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Section>
        );
    }

    private onSubmit = async (formData: FormData) => {
        await this.props.resetError();
        await this.props.onSubmit({ currentPassword: formData.currentPassword, newPassword: formData.password });
    }
}

export default withStyles(styles)(ChangePassword);
