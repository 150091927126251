import { useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import { Matchplay, MatchplayPhase } from '../../models/Matchplay';

import 'letterimages/dist/letterimages.css';

const useStyles = makeStyles(() => ({
    letterimage: {
        display: 'flex',
        alignItems: 'center',
        height: 'inherit',
        '&::before, &::after': {
            top: 'inherit',
            left: 'inherit',
        },
        fontSize: 41,
        width: 56,
    },
    letterimageGreen: {
        backgroundColor: '#8fb055 !important',
        '&::before': {
            color: '#abd466  !important',
        },
        '&::after': {
            color: '#8fb055 !important',
        },
    },
}));

const dataNames = {
    [MatchplayPhase.INITIALIZATION]: 'A',
    [MatchplayPhase.REGISTRATION_OPEN]: 'C',
    [MatchplayPhase.REGISTRATION_CLOSED]: 'Q',
    [MatchplayPhase.EXECUTION_GROUP]: 'G',
    [MatchplayPhase.EXECUTION_GROUP_FINISHED]: 'P',
    [MatchplayPhase.EXECUTION]: 'O',
    [MatchplayPhase.AFTERMATH]: 'X',
};

interface Props {
    matchplay: Matchplay;
    variant?: 'rounded' | 'circle';
}

const MatchplayIcon = ({ matchplay, variant }: Props) => {
    const classes = useStyles();
    const themeClasses = useMemo(() => ({
        [MatchplayPhase.INITIALIZATION]: 'letterimages-grey',
        [MatchplayPhase.REGISTRATION_OPEN]: 'letterimages-blue',
        [MatchplayPhase.REGISTRATION_CLOSED]: 'letterimages-orange',
        [MatchplayPhase.EXECUTION_GROUP]: 'letterimages-teal',
        [MatchplayPhase.EXECUTION_GROUP_FINISHED]: 'letterimages-purple',
        [MatchplayPhase.EXECUTION]: classes.letterimageGreen,
        [MatchplayPhase.AFTERMATH]: 'letterimages-grey',
    }), [classes]);

    const className = clsx(
        'letterimages',
        themeClasses[matchplay.phase],
        classes.letterimage,
        { [`letterimages-${variant}`]: !!variant }
    );
    return (
        <div className={className} data-name={dataNames[matchplay.phase]} />
    );
};

export default MatchplayIcon;
