import { useMemo } from 'react';

import { Grid, makeStyles, Theme } from '@material-ui/core';

import { getMatchWinner, isGroupMatch, Match } from '../../models/Match';
import { Player } from '../../models/Player';
import PlayerCard from '../utils/PlayerCard';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));

interface Props {
    match: Match;
}

const MatchHeader = ({ match }: Props) => {
    const classes = useStyles();
    const matchWinner = useMemo(() => getMatchWinner(match), [match]);
    const isPlayerWinner = (player: Player) => matchWinner?.id === player.user.id;
    const holesWonPlayerOne = (isGroupMatch(match) ? match.holesWonPlayerOne : undefined);
    const holesWonPlayerTwo = (isGroupMatch(match) ? match.holesWonPlayerTwo : undefined);

    if (!match.playerOne || !match.playerTwo) {
        return null;
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item md={6} xs={12}>
                <PlayerCard
                    user={match.playerOne.user}
                    isWinner={isPlayerWinner(match.playerOne)}
                    playerHcp={match.playerOne.hcp}
                    equalHeight={true}
                    holesWon={holesWonPlayerOne}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <PlayerCard
                    user={match.playerTwo.user}
                    isWinner={isPlayerWinner(match.playerTwo)}
                    playerHcp={match.playerTwo.hcp}
                    equalHeight={true}
                    holesWon={holesWonPlayerTwo}
                />
            </Grid>
        </Grid>
    );
};

export default MatchHeader;
