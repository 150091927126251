import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';

import { MatchActivityPayloadComment } from '../../../models/MatchActivity';
import ReactMarkdownRenderer from '../../utils/ReactMarkdownRenderer';
import {
    MatchActivityItemProps
} from './MatchActivityItem';
import { MessageItemAvatar, MessageItemSubheader } from '../../utils/Message';

const useStyles = makeStyles(theme => ({
    content: {
        ...theme.typography.body1,
        '& p': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            }
        }
    }
}));

const MatchActivityItemComment = (props: MatchActivityItemProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { item } = props;
    const text = (item.payload as MatchActivityPayloadComment).text;
    return (
        <Card>
            <CardHeader
                avatar={<MessageItemAvatar user={item.user} />}
                title={t('components.match.activities.MatchActivityItem.titleComment')}
                subheader={<MessageItemSubheader user={item.user} createdAt={item.createdAt} />} />
            <Divider />
            <CardContent className={classes.content}>
                <ReactMarkdownRenderer value={text} />
            </CardContent>
        </Card>
    );
};

export default MatchActivityItemComment;
