import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { restoreAction } from '../../store/actions/user';

interface StoreProps {
    children?: ReactNode,
    isAuthenticated: boolean;
    isRestored: boolean;
    restore(): void;
}

type Props = StoreProps;

class TryRestoreAuthentication extends Component<Props> {
    public render() {
        const { isAuthenticated, isRestored, children } = this.props;
        if (isAuthenticated || isRestored) {
            return (children);
        }
        return null;
    }

    public componentDidMount() {
        this.props.restore();
    }
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.user.isAuthenticated,
    isRestored: state.user.isRestored,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    restore: restoreAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TryRestoreAuthentication);
