import config from '../config';
import { fetchOrThrow, HeadersBuilder, mptFetch } from '../lib/mptFetch';
import { TenantInformation, UpdateTenantRequest } from '../models/TenantInformation';
import { TenantSettings } from '../models/TenantSettings';

const TENANT_INFORMATION_ITEM_KEY = 'mptTenantInformation';

const getTenantIdentifier = () => {
    const url = document.location;
    const subdomain = url.hostname.match(/([\w-]+)\./);
    if (url.hostname === 'localhost' || !subdomain) {
        return 'yoomani';
    }
    return subdomain[1];
};

const storeTenantInformation = (tenantInformation: TenantInformation) => {
    window.sessionStorage.setItem(TENANT_INFORMATION_ITEM_KEY, JSON.stringify(tenantInformation));
};

const getTenantInformation = async (): Promise<TenantInformation> => {
    const identifier = getTenantIdentifier();
    const response = await fetchOrThrow(config.baseUrl, {
        method: 'GET',
        headers: new HeadersBuilder().withTenantIdentifier(identifier).headers,
    });
    const tenantInformation = (await response.json() as TenantInformation);
    storeTenantInformation(tenantInformation);
    return tenantInformation;
};

const updateTenantInformation = async (payload: UpdateTenantRequest): Promise<void> => {
    await mptFetch(`${config.baseUrl}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
    });
};

const uploadTenantImage = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    await fetchOrThrow(`${config.baseUrl}/upload-logo`, {
        method: 'POST',
        body: formData,
        headers: new HeadersBuilder()
            .withTenant()
            .withAuthorization()
            .headers
    });
};

const getTenantSettings = async (): Promise<TenantSettings> => {
    const response = await mptFetch(`${config.baseUrl}/settings`, { method: 'GET' });
    return (await response.json() as TenantSettings);
};

const createApiKey = async (): Promise<void> => {
    await mptFetch(`${config.baseUrl}/api-key`, { method: 'POST' });
};

const deleteApiKey = async (): Promise<void> => {
    await mptFetch(`${config.baseUrl}/api-key`, { method: 'DELETE' });
};

export const tenantService = {
    getTenantInformation,
    updateTenantInformation,
    uploadTenantImage,
    getTenantSettings,
    createApiKey,
    deleteApiKey,
};
