import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Menu, MenuItem, useMediaQuery, useTheme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { User } from '../../models/User';
import { PageLink } from '../utils/PageLink';
import PlayerImage from '../utils/PlayerImage';
import { Link } from 'react-router-dom';

interface Props {
    currentUser?: User,
}

const UserMenu = ({ currentUser }: Props) => {
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const { t } = useTranslation();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    const anchorEl = useRef<HTMLButtonElement>();
    const handleToggle = () => setOpen(!open);
    const handleClose = () => setOpen(false);

    const menuIcon = (isXs)
        ? <MenuIcon/>
        : <PlayerImage user={currentUser} size={40}/>;

    return (
        <>
            <Button buttonRef={anchorEl} onClick={handleToggle}>
                {menuIcon}
            </Button>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl.current}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {isXs &&
                <MenuItem component={PageLink('/')} onClick={handleClose}>
                    {t('components.navigation.UserMenu.home')}
                </MenuItem>}
                {isXs &&
                <MenuItem component={PageLink('/matchplays')} onClick={handleClose}>
                    {t('components.navigation.UserMenu.matchplays')}
                </MenuItem>}
                {currentUser?.isAdmin &&
                <MenuItem component={PageLink('/admin')} onClick={handleClose}>
                    {t('components.navigation.UserMenu.admin')}
                </MenuItem>}
                <MenuItem component={PageLink('/account')} onClick={handleClose}>
                    {t('components.navigation.UserMenu.profile')}
                </MenuItem>
                <MenuItem component={Link} to="//support.matchplaytime.com" target="_blank">
                    {t('components.layout.Footer.support')}
                </MenuItem>
                <MenuItem component={PageLink('/logout')} onClick={handleClose}>
                    {t('components.navigation.UserMenu.logout')}
                </MenuItem>
            </Menu>
        </>
    );
}

export default UserMenu;
