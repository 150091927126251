import React, { Component } from 'react';
import {  MatchActivityItemProps } from './MatchActivityItem';
import {
    Button, Card, CardActions, CardContent, CardHeader, Divider, ListItem, ListItemIcon, ListItemText, WithStyles,
    withStyles
} from '@material-ui/core';
import { MatchActivityPayloadProposeDate } from '../../../models/MatchActivity';
import EventIcon from '@material-ui/icons/EventTwoTone';
import Moment from 'react-moment';
import MatchActivityActionProposeDate from './MatchActivityActionProposeDate';
import { MessageItemAvatar, MessageItemSubheader } from '../../utils/Message';

const styles = () => ({
    eventEntry: {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
    }
});

interface State {
    newDateProposal: boolean;
}

type Props = MatchActivityItemProps & WithStyles<typeof styles>;

class MatchActivityItemProposeDate extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            newDateProposal: false,
        }
    }

    render() {
        const {
            item, currentUser, isMatchPlayer, isScheduled, classes,
            onActionAcceptProposedDate, isCurrentDateProposal
        } = this.props;
        const { newDateProposal } = this.state;
        const proposal = (item.payload as MatchActivityPayloadProposeDate);
        const showActions = (!isScheduled && isMatchPlayer && isCurrentDateProposal && !newDateProposal
            && item.user.email !== currentUser.email);
        return (
            <Card>
                <CardHeader
                    avatar={<MessageItemAvatar user={item.user} />}
                    title="Terminvorschlag"
                    subheader={<MessageItemSubheader user={item.user} createdAt={item.createdAt}/>}
                />
                <Divider/>
                <CardContent>
                    <ListItem className={classes.eventEntry}>
                        <ListItemIcon>
                            <EventIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Moment unix format="DD.MM.YYYY[,&ensp;]HH:mm[&nbsp;Uhr]">
                                    {proposal.date / 1000}
                                </Moment>
                            }
                            secondary="Matchplay"/>
                    </ListItem>
                </CardContent>
                {showActions &&
                <CardActions>
                    <Button
                        size="small" color="primary" variant="outlined"
                        onClick={onActionAcceptProposedDate}>
                        Terminvorschlag annehmen
                    </Button>
                    <Button size="small" onClick={this.onActionProposeDate}>
                        Anderen Termin vorschlagen
                    </Button>
                </CardActions>
                }
                {newDateProposal &&
                <MatchActivityActionProposeDate onSubmit={this.onProposeNewDate}
                                                onCancel={this.cancelNewDateProposal}/>}
            </Card>
        );
    }

    private onProposeNewDate = (date: Date) => {
        this.props.onProposeDate(date);
        this.cancelNewDateProposal();
    }

    private onActionProposeDate = () => {
        this.setState({ newDateProposal: true });
    }

    private cancelNewDateProposal = () => {
        this.setState({ newDateProposal: false })
    }
}

export default withStyles(styles)(MatchActivityItemProposeDate);
