import { Dispatch } from 'redux';

import { NewSponsor, Sponsor } from '../../models/Sponsor';
import { sponsorService } from '../../services/sponsor';
import {
    SPONSOR_CREATE_FAILURE, SPONSOR_CREATE_REQUEST, SPONSOR_CREATE_SUCCESS, SPONSOR_DELETE_FAILURE,
    SPONSOR_DELETE_REQUEST, SPONSOR_DELETE_SUCCESS, SPONSOR_LIST_FAILURE, SPONSOR_LIST_REQUEST, SPONSOR_LIST_SUCCESS
} from '../reducers/sponsors';

export const getSponsorsAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const items = await sponsorService.getSponsors();
            dispatch(success(items));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: SPONSOR_LIST_REQUEST }; }
    function success(items: Sponsor[]) { return { type: SPONSOR_LIST_SUCCESS, payload: items }; }
    function failure() { return { type: SPONSOR_LIST_FAILURE }; }
    // @formatter:on
};

export const createSponsorAction = (dispatch: Dispatch) => {
    return async (sponsor: NewSponsor) => {
        dispatch(request());
        try {
            await sponsorService.createSponsor(sponsor);
            dispatch(success());
            await getSponsorsAction(dispatch)();
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: SPONSOR_CREATE_REQUEST }; }
    function success() { return { type: SPONSOR_CREATE_SUCCESS }; }
    function failure() { return { type: SPONSOR_CREATE_FAILURE }; }
    // @formatter:on
};

export const deleteSponsorAction = (dispatch: Dispatch) => {
    return async (sponsor: NewSponsor) => {
        dispatch(request());
        try {
            await sponsorService.deleteSponsor(sponsor);
            dispatch(success());
            await getSponsorsAction(dispatch)();
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: SPONSOR_DELETE_REQUEST }; }
    function success() { return { type: SPONSOR_DELETE_SUCCESS }; }
    function failure() { return { type: SPONSOR_DELETE_FAILURE }; }
    // @formatter:on
};
