import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

import {isPlayoffMatch, Match} from '../../../models/Match';
import {MatchActivityPayloadResult} from '../../../models/MatchActivity';
import Section from '../../utils/Section';
import MatchplayMatchList from '../dashboard/MatchplayMatchList';
import MatchplayEndGame from './MatchplayAbandonMatch';
import {PlayoffMatch} from '../../../models/PlayoffMatch';

interface ComponentProps {
  matches: Match[];
  abandonMatch(id: string, result: MatchActivityPayloadResult): void
}

type Props = ComponentProps & WithTranslation;

class MatchplayGameAdministration extends Component<Props> {

  public render() {
    const {matches, abandonMatch, t} = this.props;

    const playoffMatches: PlayoffMatch[] = [];

    matches.forEach((match) => {
      if (isPlayoffMatch(match)) {
        playoffMatches.push(match)
      }
    });

    return (
        <Section title={`${t('components.matchplay.common.matches')}`}>
          <MatchplayMatchList matches={playoffMatches}
                              abandonMatch={abandonMatch}
                              additionalMatchFunctionality={MatchplayEndGame}/>
        </Section>
    );
  }
}

export default withTranslation()(MatchplayGameAdministration);
