import React from 'react';
import { useTranslation } from 'react-i18next';

import { Matchplay, MatchplayType } from '../../models/Matchplay';

interface Props {
    matchplay: Matchplay;
}

const MatchplayTypeText = ({ matchplay }: Props) => {
    const { t } = useTranslation();
    switch (matchplay.type) {
        case MatchplayType.HOLES_6:
            return <>{t('components.matchplay.MatchplayTypeText.holes6')}</>;
        case MatchplayType.HOLES_9:
            return <>{t('components.matchplay.MatchplayTypeText.holes9')}</>;
        case MatchplayType.HOLES_18:
            return <>{t('components.matchplay.MatchplayTypeText.holes18')}</>;
    }
}

export default MatchplayTypeText;
