import React, { Component } from 'react';

import {
    Avatar, Grid, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Theme, Typography,
    WithStyles, withStyles
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { Matchplay } from '../../models/Matchplay';
import { User } from '../../models/User';
import { PageLink } from '../utils/PageLink';
import MatchplayNumberPlayersText from './MatchplayNumberPlayersText';
import MatchplayPhaseText from './MatchplayPhaseText';
import MatchplayTypeText from './MatchplayTypeText';
import MatchplayIcon from '../utils/MatchplayIcon';

const styles = (theme: Theme) => ({
    secondary: {
        textAlign: 'right' as const,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
});

interface Props {
    matchplay: Matchplay;
    divider?: boolean;
    linkPattern?: string;
    currentUser?: User;
}

class MatchplayListItem extends Component<Props & WithStyles<typeof styles>> {
    public render() {
        const { matchplay, divider, currentUser, linkPattern, classes } = this.props;
        const itemLink = PageLink(linkPattern ?? '/matchplays/:id', { id: matchplay.id });
        const itemAdminLink = PageLink(linkPattern ?? '/admin/matchplays/:id', { id: matchplay.id });
        return (
            <ListItem button divider={divider} component={itemLink}>
                <ListItemAvatar>
                    <Avatar variant="rounded">
                        <MatchplayIcon matchplay={matchplay}/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={matchplay.name}
                              secondary={<MatchplayTypeText matchplay={matchplay}/>}/>
                <ListItemSecondaryAction className={classes.secondary}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="caption" display="block">
                                <MatchplayPhaseText phase={matchplay.phase}/>
                            </Typography>
                            <Typography variant="caption" display="block">
                                <MatchplayNumberPlayersText matchplay={matchplay}/>
                            </Typography>
                        </Grid>
                        {currentUser && currentUser.isAdmin &&
                        <Grid item>
                            <IconButton component={itemAdminLink}>
                                <SettingsIcon/>
                            </IconButton>
                        </Grid>}
                    </Grid>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }
}

export default withStyles(styles)(MatchplayListItem);
