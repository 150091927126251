import React, { Component } from 'react';
import { Matchplay } from '../../models/Matchplay';
import Section from '../utils/Section';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import ReactMarkdownRenderer from '../utils/ReactMarkdownRenderer';
import Sponsors from '../sponsoring/Sponsors';

interface ComponentProps {
    matchplay: Matchplay;
}

type Props = ComponentProps & WithTranslation;

class MatchplayRules extends Component<Props> {

    public render() {
        const { matchplay, t } = this.props;
        const showRulesText = matchplay.description.rulesText && matchplay.description.rulesText.length > 0;
        const showPricesText = matchplay.description.pricesText && matchplay.description.pricesText.length > 0;
        const showSponsorsText = matchplay.description.sponsorsText && matchplay.description.sponsorsText.length > 0;
        return (
            <>
                {showRulesText &&
                <Section title={t('components.matchplay.MatchplayRules.rulesTitle')}>
                    <Typography variant="body1" component="div">
                        <ReactMarkdownRenderer value={matchplay.description.rulesText}/>
                    </Typography>
                </Section>}
                {showPricesText &&
                <Section title={t('components.matchplay.MatchplayRules.pricesTitle')}>
                    <Typography variant="body1" component="div">
                        <ReactMarkdownRenderer value={matchplay.description.pricesText}/>
                    </Typography>
                </Section>}
                {showSponsorsText &&
                <Section title={t('components.matchplay.MatchplayRules.sponsorsTitle')}>
                    <Typography variant="body1" component="div">
                        <ReactMarkdownRenderer value={matchplay.description.sponsorsText}/>
                    </Typography>
                </Section>}
                <Sponsors sponsors={matchplay.sponsors}
                          title={t('components.matchplay.dashboard.MatchplayDashboard.sponsors')}/>
            </>
        );
    }
}

export default withTranslation()(MatchplayRules);
