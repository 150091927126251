import React, { Component } from 'react';
import { Link, LinkProps, withRouter, RouteComponentProps } from 'react-router-dom'
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => ({
    root: {
        alignSelf: 'center' as const,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid' as const,
        borderBottomColor: 'rgba(255,255,255,0)',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,

        '& + &': {
            marginLeft: theme.spacing(2)
        },

        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    activeLink: {
        borderBottomColor: theme.palette.primary.main,
    }
});

interface ComponentProps {
    to: string
}

type Props = ComponentProps & RouteComponentProps & WithStyles<typeof styles>;

class MainNavigationLink extends Component<Props> {
    public render() {
        const { to, children, classes } = this.props;
        const link = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
            (linkProps: any, ref: any) => <Link innerRef={ref} to={to} {...linkProps} />,
        );
        const classNames = [classes.root];
        if (this.isActive()) {
            classNames.push(classes.activeLink);
        }
        return <Button component={link} className={classNames.join(' ')}>{children}</Button>;
    }

    private isActive() {
        const { to, location } = this.props;
        if (to === '/') { return location.pathname === to; }
        return location.pathname.startsWith(to);
    }
}

export default withRouter(withStyles(styles)(MainNavigationLink));
