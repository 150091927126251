const dev = {
    baseUrl: 'http://127.0.0.1:8080',
};

const prod = {
    baseUrl: 'https://mpt-augusta.herokuapp.com',
};

// default to dev config
const devConfig = process.env.NODE_ENV === 'production' ? prod : dev;

const config = {
    oauthClient: 'app',
    oauthClientSecret: 'matchplaytime',
    trackingId: 'UA-189392942-2',
    freshchat: {
        token: '7d2743a0-fcbb-40ae-9b5a-3ab951c806f0',
        host: 'https://wchat.eu.freshchat.com',
        locale: 'de',
    },
    ...devConfig
};

export default config;
