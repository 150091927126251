import { useTranslation } from 'react-i18next';

import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { sortMatches } from '../../../lib/sorting';
import { PlayoffMatch } from '../../../models/PlayoffMatch';
import PlayoffMatchView from './PlayoffMatchView';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: 1,
        display: 'block',
        marginLeft: '-3px',

        '&:last-child > div': {
            border: 0,
        },
        '&:last-child > div > div::after': {
            border: 0,
        },
        '&:first-child > div > div > div > div::before': {
            border: 0,
        },
        '&:first-child > div > div > div > div::after': {
            border: 0,
        },
        '&:first-child > div > div > div > a::before': {
            border: 0,
        },
        '&:first-child > div > div > div > a::after': {
            border: 0,
        },
    },
    list: {
        display: 'flex',
        flexDirection: 'row' as const,
        flexFlow: 'row wrap',
        justifyContent: 'center',
        height: '100%',
        minHeight: '100%',
        borderRight: `1px dashed ${theme.palette.grey['300']}`,

        [theme.breakpoints.down('sm')]: {
            border: 0,
        },
    },
    item: {
        display: 'flex',
        flex: '0 1 auto',
        justifyContent: 'center',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        position: 'relative',
        width: '100%',

        '&::after': {
            position: 'absolute',
            right: 0,
            content: `''`,
            display: 'block',
            width: theme.spacing(4),
            height: '35%',
            borderRight: `2px solid ${theme.palette.grey['300']}`,
        },

        '&:nth-child(odd)::after': {
            top: '50%',
            borderTop: `2px solid ${theme.palette.grey['300']}`,
            transform: 'translateY(-1px)',
            borderTopRightRadius: theme.spacing(1),
        },

        '&:nth-child(even)::after': {
            bottom: '50%',
            borderBottom: `2px solid ${theme.palette.grey['300']}`,
            transform: 'translateY(1px)',
            borderBottomRightRadius: theme.spacing(1),
        },

        [theme.breakpoints.down('sm')]: {
            '&::after': {
                border: 0,
            },
            '&:nth-child(odd)::after': {
                border: 0,
            },

            '&:nth-child(even)::after': {
                border: 0,
            },
        },
    }
}));

interface Props {
    group: string;
    matches: PlayoffMatch[];
    maxDepth: number;
}

export default function PlayoffRound(props: Props) {

    const classes = useStyles();
    const { matches, maxDepth } = props;

    if (matches.length === 0) {
        return null;
    }

    const sortedMatches = sortMatches(matches).map((match: PlayoffMatch, index: number) => {
        return (
            <Box key={index} className={classes.item}>
                <PlayoffMatchView match={match} />
            </Box>
        );
    });

    return (
        <Box className={classes.root}>
            <Typography variant="h6" align="center">
                <RoundName depth={matches[0].depth} maxDepth={maxDepth} />
            </Typography>
            <Box className={classes.list}>
                {sortedMatches}
            </Box>
        </Box>
    );
}

interface RoundNameProps {
    depth: number;
    maxDepth: number;
}

const RoundName = ({ depth, maxDepth }: RoundNameProps) => {
    const { t } = useTranslation();
    switch (depth) {
        case 0:
            return <>{t('components.matchplay.common.final')}</>;
        case 1:
            return <>{t('components.matchplay.common.semifinal')}</>;
        case 2:
            return <>{t('components.matchplay.common.quarterfinal')}</>;
        case 3:
            return <>{t('components.matchplay.common.eighthfinal')}</>;
        default:
            return <>{t('components.matchplay.common.round', { number: maxDepth - depth })}</>;
    }
};
