import React, { Component } from 'react';

import AccountContainer from '../account/AccountContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';

class AccountPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer />
                <AccountContainer />
            </ContentContainer>
        );
    }
}

export default AccountPage;
