import React, { Component } from 'react';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

interface ComponentProps {
    value?: string;
}

type Props = ComponentProps;

class ReactMarkdownRenderer extends Component<Props> {

    public render() {
        const { value } = this.props;
        if (!value || value.trim().length === 0) {
            return null;
        }
        const renderers = {
            link: (props: any) => <a href={`/redirect?href=${props.href}`}
                                     rel="nofollow noopener noreferrer"
                                     target="_blank">{props.children}</a>
        }
        return (
            <ReactMarkdown plugins={[[gfm, { singleTilde: false }]]}
                           renderers={renderers}
                           children={`${value}`}/>
        );
    }
}

export default ReactMarkdownRenderer;
