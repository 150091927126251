import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '@material-ui/core';

interface ComponentProps {
    user: User;
}

type Props = ComponentProps & WithTranslation;

class Username extends Component<Props> {

    public render() {
        const { user, t } = this.props;
        let value = user.name;
        if (user.deleted) {
            value = t('components.utils.Username.deletedUser')
        }
        return (
            <Typography variant="inherit">{value}</Typography>
        );
    }
}

export default withTranslation()(Username);
