import { shuffle } from '../../../../lib/shuffle';
import { MatchEncounter } from '../../../../models/MatchEncounter';
import { Matchplay } from '../../../../models/Matchplay';
import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';
import { botUser } from '../../../../models/User';

interface EncounterStrategyParams {
    registrations: MatchplayRegistration[];
    matchplay: Matchplay;
}

interface EncounterStrategyResult {
    encounters: MatchEncounter[];
    usersNotPlaying: MatchplayRegistration[];
    isPlayable: boolean;
}

const defaultBuildResult = ({ registrations, matchplay }: EncounterStrategyParams): EncounterStrategyResult => {
    const numberOfPlayers = matchplay.playoff.numberOfPlayers;
    const numberOfMatches = numberOfPlayers / 2;
    const leftColumn = registrations.slice(0, numberOfMatches);
    const rightColumn = registrations.slice(numberOfMatches, numberOfPlayers);
    const usersNotPlaying = registrations.slice(numberOfPlayers);

    const encounters: MatchEncounter[] = [];
    for (let i = 0; i < leftColumn.length; i++) {
        const leftUser = leftColumn[i]?.user;
        const rightUser = rightColumn[i]?.user ?? botUser;
        if (leftUser) {
            encounters.push({
                playerOne: { user: leftUser, hcp: 0 },
                playerTwo: { user: rightUser, hcp: 0 },
            });
        }
    }

    const isPlayable = (encounters.length === numberOfMatches) && encounters.some(x => x.playerTwo.user !== botUser);

    return { encounters, usersNotPlaying, isPlayable };
};

// First-come, First-serve: Sorted by registration date.
// The players that registered first will be considered first.
export const firstComeFirstServe = ({ registrations, matchplay }: EncounterStrategyParams): EncounterStrategyResult => {
    if (!registrations || !matchplay?.playoff) {
        return { encounters: [], usersNotPlaying: [], isPlayable: false };
    }

    const sortedRegistrations = registrations
        .filter(x => !!x)
        .sort((lhs, rhs) => (lhs?.enqueuedAt ?? 0) - (rhs?.enqueuedAt ?? 0));
    return defaultBuildResult({ registrations: sortedRegistrations, matchplay });
};

// Shuffled: just randomly shuffle around the registrations.
export const shuffled = ({ registrations, matchplay }: EncounterStrategyParams): EncounterStrategyResult => {
    if (!registrations || !matchplay?.playoff) {
        return { encounters: [], usersNotPlaying: [], isPlayable: false };
    }

    const shuffledRegistrations = shuffle(registrations);
    return defaultBuildResult({ registrations: shuffledRegistrations, matchplay });
};
