import { Location } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PreviousLocationState } from '../../models/LocationState';

interface ComponentProps {
    fallbackTo?: string
}

interface StateProps {
    isAuthenticated: boolean
}

type Props = ComponentProps & StateProps & RouteProps;

class AuthenticatedRoute extends Component<Props> {
    public render() {
        const { isAuthenticated, fallbackTo, location } = this.props;
        if (isAuthenticated) {
            return <Route {...this.props} />;
        }
        const from = (location as Location<PreviousLocationState>)?.state?.from || location;
        return <Redirect to={{
            pathname: (fallbackTo) ? fallbackTo : '/login',
            state: { from }
        }} />
    }
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(AuthenticatedRoute);
