import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ResourceIdMap } from '../../lib/resourceSupport';
import { isPlayoffMatch, Match, MatchPhase } from '../../models/Match';
import { Matchplay } from '../../models/Matchplay';
import { getMyMatchesAction } from '../../store/actions/match';
import MatchCardMulti from './MatchCardMulti';
import MatchCardSingle from './MatchCardSingle';

interface ComponentProps {
    variant: 'last-played' | 'nextup';
    emptyContent?: ReactNode;
}

interface StoreProps {
    myMatches: Match[];
    matchplaysById: ResourceIdMap<Matchplay>,
    getMyMatches(): void;
}

type Props = ComponentProps & StoreProps;

class MatchCardContainer extends Component<Props> {
    public render() {
        const { variant, matchplaysById, emptyContent } = this.props;

        switch (variant) {
            case 'last-played': {
                const lastPlayed = this.getLastPlayed();
                const matchplay = matchplaysById[lastPlayed?.matchplayId || ''];
                if (lastPlayed && matchplay) {
                    return <MatchCardSingle match={lastPlayed} matchplay={matchplay} />;
                } else {
                    return emptyContent ?? null;
                }
            }
            case 'nextup': {
                const nextUp = this.getNextUp();
                if (nextUp.length > 0) {
                    return <MatchCardMulti matches={nextUp} />
                } else {
                    return emptyContent ?? null;
                }
            }
        }
    }

    public componentDidMount() {
        this.props.getMyMatches();
    }

    private getLastPlayed = () => {
        const { myMatches } = this.props;
        if (myMatches) {
            const finishedMatches = myMatches
                .filter(x => x.phase === MatchPhase.FINISHED)
                .filter(isPlayoffMatch)
                .sort((lhs, rhs) => 
                    (!!lhs.scheduledAt && !!rhs.scheduledAt)
                        ? (rhs.scheduledAt ?? 0) - (lhs.scheduledAt ?? 0)
                        : (lhs.depth - rhs.depth));
            if (finishedMatches.length > 0) {
                return finishedMatches[0];
            }
        }
        return undefined;
    }

    private getNextUp = () => {
        const { myMatches } = this.props;
        if (myMatches) {
            const nextMatches = myMatches
                .filter(m => m.phase === MatchPhase.PROPOSAL || m.phase === MatchPhase.SCHEDULED)
                .filter(m => m.playerOne && m.playerTwo)
                .sort((lhs, rhs) => (rhs.scheduledAt ?? 0) - (lhs.scheduledAt ?? 0));
            return nextMatches;
        }
        return [];
    }
}

const mapStateToProps = (state: any) => ({
    myMatches: state.match.mine,
    matchplaysById: state.matchplay.byId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMyMatches: getMyMatchesAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchCardContainer);
