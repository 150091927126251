import { Dispatch } from 'redux';

import { RESET_ERROR, SET_ERROR } from '../reducers/error';
import { Errors } from '../../models/Errors';

export const setErrorAction = (dispatch: Dispatch) => (error: Errors) => {
    dispatch(set(error))

    // @formatter:off
    function set(error: Errors) { return { type: SET_ERROR, error }; }
    // @formatter:on
};

export const resetErrorAction = (dispatch: Dispatch) => () => {
    dispatch(reset());

    // @formatter:off
    function reset() { return { type: RESET_ERROR, error: undefined }; }
    // @formatter:on
};
