import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { CreateOrUpdateNotification, Notification } from '../../../models/Notification';
import {
    createNotificationAction, deleteNotificationAction, getNotificationsAction, publishNotificationAction,
    updateNotificationAction
} from '../../../store/actions/notifications';
import AdminNotifications from './AdminNotifications';

interface StoreProps {
    notifications: Notification[];
    getNotifications(): void;
    createNotification(payload: CreateOrUpdateNotification): void;
    editNotification(notification: Notification, payload: CreateOrUpdateNotification): void;
    deleteNotification(notification: Notification): void;
    publishNotification(notification: Notification): void;
}

type Props = StoreProps;

class AdminNotificationsContainer extends Component<Props> {

    public render() {
        const {
            notifications, createNotification, editNotification, deleteNotification, publishNotification
        } = this.props;
        return (
            <AdminNotifications notifications={notifications}
                                createNotification={createNotification}
                                editNotification={editNotification}
                                deleteNotification={deleteNotification}
                                publishNotification={publishNotification}/>
        );
    }

    public componentDidMount() {
        this.props.getNotifications();
    }
}

const mapSateToProps = (state: any) => ({
    notifications: state.notification.items,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getNotifications: getNotificationsAction(dispatch),
    createNotification: createNotificationAction(dispatch),
    editNotification: updateNotificationAction(dispatch),
    deleteNotification: deleteNotificationAction(dispatch),
    publishNotification: publishNotificationAction(dispatch),
});

export default connect(mapSateToProps, mapDispatchToProps)(AdminNotificationsContainer);
