import { Dispatch } from 'redux';

import { Errors } from '../../models/Errors';
import { TenantInformation, UpdateTenantRequest } from '../../models/TenantInformation';
import { tenantService } from '../../services/tenant';
import {
    TENANT_API_KEY_CREATE_FAILURE, TENANT_API_KEY_CREATE_REQUEST, TENANT_API_KEY_CREATE_SUCCESS,
    TENANT_API_KEY_DELETE_FAILURE, TENANT_API_KEY_DELETE_REQUEST, TENANT_API_KEY_DELETE_SUCCESS,
    TENANT_INFORMATION_GET_FAILURE, TENANT_INFORMATION_GET_REQUEST, TENANT_INFORMATION_GET_SUCCESS,
    TENANT_INFORMATION_UPDATE_FAILURE, TENANT_INFORMATION_UPDATE_REQUEST, TENANT_INFORMATION_UPDATE_SUCCESS,
    TENANT_SETTINGS_GET_FAILURE, TENANT_SETTINGS_GET_REQUEST, TENANT_SETTINGS_GET_SUCCESS, TENANT_UPLOAD_LOGO_FAILURE,
    TENANT_UPLOAD_LOGO_SUCCESS
} from '../reducers/tenant';
import { TenantSettings } from '../../models/TenantSettings';

export const getTenantInformationAction = (dispatch: Dispatch) => {
    return async () => {
        try {
            dispatch(request());
            const resp = await tenantService.getTenantInformation();
            dispatch(success(resp));
        } catch {
            dispatch(failure(Errors.InternalError));
        }
    };

    // @formatter:off
    function request() { return { type: TENANT_INFORMATION_GET_REQUEST } }
    function success(tenantInfo: TenantInformation) { return { type: TENANT_INFORMATION_GET_SUCCESS, payload: tenantInfo } }
    function failure(error: Errors) { return { type: TENANT_INFORMATION_GET_FAILURE, payload: error } }
    // @formatter:on
};

export const updateTenantInformationAction = (dispatch: Dispatch) => {
    return async (payload: UpdateTenantRequest) => {
        try {
            dispatch(request());
            await tenantService.updateTenantInformation(payload);
            const tenantInfo = await tenantService.getTenantInformation();
            dispatch(success(tenantInfo));
        } catch {
            dispatch(failure(Errors.InternalError));
        }
    };

    // @formatter:off
    function request() { return { type: TENANT_INFORMATION_UPDATE_REQUEST } }
    function success(tenantInfo: TenantInformation) { return { type: TENANT_INFORMATION_UPDATE_SUCCESS, payload: tenantInfo } }
    function failure(error: Errors) { return { type: TENANT_INFORMATION_UPDATE_FAILURE, payload: error } }
    // @formatter:on
};

export const uploadTenantLogoAction = (dispatch: Dispatch) => {
    return async (file: File) => {
        try {
            await tenantService.uploadTenantImage(file);
            const tenantInfo = await tenantService.getTenantInformation();
            dispatch(success(tenantInfo));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function success(tenantInfo: TenantInformation) { return { type: TENANT_UPLOAD_LOGO_SUCCESS, payload: tenantInfo } }
    function failure() { return { type: TENANT_UPLOAD_LOGO_FAILURE }; }
    // @formatter:on
};

export const getTenantSettingsAction = (dispatch: Dispatch) => {
    return async () => {
        try {
            dispatch(request());
            const settings = await tenantService.getTenantSettings();
            dispatch(success(settings));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: TENANT_SETTINGS_GET_REQUEST } }
    function success(settings: TenantSettings) { return { type: TENANT_SETTINGS_GET_SUCCESS, payload: settings } }
    function failure() { return { type: TENANT_SETTINGS_GET_FAILURE } }
    // @formatter:on
};

export const createApiKeyAction = (dispatch: Dispatch) => {
    return async () => {
        try {
            dispatch(request());
            await tenantService.createApiKey();
            await getTenantSettingsAction(dispatch)();
            dispatch(success());
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: TENANT_API_KEY_CREATE_REQUEST } }
    function success() { return { type: TENANT_API_KEY_CREATE_SUCCESS } }
    function failure() { return { type: TENANT_API_KEY_CREATE_FAILURE } }
    // @formatter:on
}

export const deleteApiKeyAction = (dispatch: Dispatch) => {
    return async () => {
        try {
            dispatch(request());
            await tenantService.deleteApiKey();
            dispatch(success());
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: TENANT_API_KEY_DELETE_REQUEST } }
    function success() { return { type: TENANT_API_KEY_DELETE_SUCCESS } }
    function failure() { return { type: TENANT_API_KEY_DELETE_FAILURE } }
    // @formatter:on
}
