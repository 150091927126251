import { useCallback, useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
    Button, Collapse, createStyles, Divider, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, MenuItem,
    Radio, RadioGroup, TextField, Theme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { permitsEditing } from '../../../lib/utils';
import { Matchplay, MatchplayType, MatchplayVariant } from '../../../models/Matchplay';
import FormikDateField from '../../utils/forms/FormikDateField';
import FormikTextField from '../../utils/forms/FormikTextField';
import Section from '../../utils/Section';

const useStyles = makeStyles((theme: Theme) => createStyles({
    formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    radioGroup: {
        margin: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    insetRadioContent: {
        paddingLeft: 32,
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    matchplay: Matchplay;
    onSubmit(matchplay: Matchplay): void;
}

const MatchplayGeneralSettings = ({ matchplay, onSubmit }: Props) => {
    const { t } = useTranslation();

    const schema = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .min(2, t('validation.matchplayName.min'))
            .max(50, t('validation.matchplayName.max'))
            .required(t('validation.common.required')),
        playoff: Yup.object().shape({
            numberOfPlayers: Yup.number()
                .min(2, t('validation.common.numberMin', { value: 2 }))
                .max(512, t('validation.common.numberMax', { value: 512 }))
                .required(t('validation.common.required')),
        }),
        groupSettings: Yup.object().shape({
                numberOfGroups: Yup.number()
                    .min(2, t('validation.common.numberMin', { value: 2 }))
                    .max(20, t('validation.common.numberMax', { value: 20 }))
                    .required(t('validation.common.required')),
            }),
            description: Yup.object().shape({
                startAt: Yup.date()
                    .nullable()
                    .transform(function (castValue, originalValue) {
                        return this.isType(castValue) ? castValue : new Date(originalValue)
                    }),
                endAt: Yup.date()
                    .nullable()
                    .min(Yup.ref('startAt'), t('validation.common.endDate'))
                    .transform(function (castValue, originalValue) {
                        return this.isType(castValue) ? castValue : new Date(originalValue)
                    }),
                }),
    }), [t]);

    const onFormSubmit = useCallback(async (values: Matchplay, formikHelpers: FormikHelpers<Matchplay>) => {
        onSubmit(values);
        formikHelpers.resetForm({values});
    }, [onSubmit]);

    return (
        <>
            {matchplay &&
                <Section title={t('components.matchplay.settings.MatchplayGeneralSettings.title')}>
                    <Formik component={GeneralSettingsForm}
                            validationSchema={schema}
                            initialValues={matchplay}
                            onSubmit={onFormSubmit} />
                </Section>}
        </>
    );
};

export default MatchplayGeneralSettings;

const GeneralSettingsForm = (props: FormikProps<Matchplay>) => {

    const { t } = useTranslation();

    return (
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormikTextField type="text" name="name"
                        label={t('components.matchplay.settings.MatchplayGeneralSettings.name')}
                        margin="normal"
                        variant="outlined"
                        fullWidth />
                </Grid>
                <Grid item sm xs={12}>
                    <FormFragmentMatchplayType {...props} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item sm xs={12}>
                    <FormFragmentMatchplayVariant {...props} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item sm xs={12}>
                    <FormFragmentMatchplayBeginEnd {...props} />
                </Grid>
                <Grid item xs={12}>
                    <SaveButton {...props} />
                </Grid>
            </Grid>
        </Form>
    );
};

const FormFragmentMatchplayType = ({ values, handleBlur, handleChange }: FormikProps<Matchplay>) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const canEdit = permitsEditing(values.phase);

    return (
        <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormLabel component={'legend' as 'span'}>
                {t('components.matchplay.settings.MatchplayGeneralSettings.type')}
            </FormLabel>
            <RadioGroup
                id="type" name="type" className={classes.radioGroup}
                onChange={handleChange} onBlur={handleBlur} value={values.type}>
                <FormControlLabel
                    value={MatchplayType.HOLES_18}
                    control={<Radio color="primary" />}
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.typeHoles18')}
                    disabled={!canEdit} />
                <FormControlLabel
                    value={MatchplayType.HOLES_9}
                    control={<Radio color="primary" />}
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.typeHoles9')}
                    disabled={!canEdit} />
                <FormControlLabel
                    value={MatchplayType.HOLES_6}
                    control={<Radio color="primary" />}
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.typeHoles6')}
                    disabled={!canEdit} />
            </RadioGroup>
        </FormControl>
    );
};

const FormFragmentMatchplayVariant = ({ values, touched, errors, handleBlur, handleChange }: FormikProps<Matchplay>) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const canEdit = permitsEditing(values.phase);
    const groupNumbers = Array.from(Array(19).keys()).map(x => x + 2);

    // set group numbers to '4' if there is a wrong value
    const handleRadioChange = (event: any) => {
        if (values.variant === MatchplayVariant.GROUP && values.groupSettings.numberOfGroups === 0) {
            values.groupSettings.numberOfGroups = 4;
        }
        handleChange(event);
    };

    return (
        <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormLabel component={'legend' as 'span'}>
                {t('components.matchplay.settings.MatchplayGeneralSettings.variant')}
            </FormLabel>
            <RadioGroup
                id="variant" name="variant" value={values.variant}
                className={classes.radioGroup}
                onChange={handleRadioChange} onBlur={handleBlur}>
                <FormControlLabel
                    value={MatchplayVariant.PLAYOFF}
                    control={<Radio color="primary"/>}
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.playoff')}
                    disabled={!canEdit}
                />
                <Collapse in={values.variant === MatchplayVariant.PLAYOFF} className={classes.insetRadioContent}>
                    <TextField
                        type="number"
                        id="playoff.numberOfPlayers"
                        name="playoff.numberOfPlayers"
                        label={t('components.matchplay.settings.MatchplayGeneralSettings.playoffLabel')}
                        select={true}
                        className={classes.formField}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.playoff.numberOfPlayers}
                        helperText={
                            touched.playoff && errors.playoff &&
                            touched.playoff.numberOfPlayers
                                ? errors.playoff.numberOfPlayers
                                : ''
                        }
                        error={
                            touched.playoff && errors.playoff &&
                            touched.playoff.numberOfPlayers &&
                            !!errors.playoff.numberOfPlayers
                        }
                        disabled={!canEdit}>
                        {[4, 8, 16, 32, 64, 128, 256].map(x =>
                            <MenuItem key={`playoff-item-${x}`} value={x}>
                                {t('components.matchplay.common.numberOfPlayers', { count: x })}
                            </MenuItem>)}
                    </TextField>
                </Collapse>
                <FormControlLabel
                    value={MatchplayVariant.GROUP}
                    control={<Radio color="primary"/>}
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.groupPhase')}
                    disabled={!canEdit}
                />
                <Collapse in={values.variant === MatchplayVariant.GROUP} className={classes.insetRadioContent}>
                    <TextField
                        type="number"
                        id="groupSettings.numberOfGroups"
                        name="groupSettings.numberOfGroups"
                        label={t('components.matchplay.settings.MatchplayGeneralSettings.numberOfGroups')}
                        select={true}
                        className={classes.formField}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.groupSettings.numberOfGroups}
                        helperText={
                            touched.groupSettings && errors.groupSettings &&
                            touched.groupSettings.numberOfGroups
                                ? errors.groupSettings.numberOfGroups
                                : ''
                        }
                        error={
                            touched.groupSettings && errors.groupSettings &&
                            touched.groupSettings.numberOfGroups &&
                            !!errors.groupSettings.numberOfGroups
                        }
                        disabled={!canEdit}>
                        {groupNumbers.map(x =>
                            <MenuItem key={`playoff-item-${x}`} value={x}>
                                {t('components.matchplay.common.numberOfGroups', { count: x })}
                            </MenuItem>)}
                    </TextField>
                    <Alert variant="outlined" severity="info">
                        {t('components.matchplay.settings.MatchplayGeneralSettings.groupPhaseInfo')}
                    </Alert>
                </Collapse>
            </RadioGroup>
        </FormControl>
    );
};

const FormFragmentMatchplayBeginEnd = ({  touched, errors }: FormikProps<Matchplay>) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <FormLabel component={'legend' as 'span'}>
                {t('components.matchplay.settings.MatchplayGeneralSettings.startEndDate')}
            </FormLabel>
            <FormControl
                error={
                    (touched.description && touched.description.startAt) &&
                    Boolean(errors.description && errors.description.startAt)
                }
                fullWidth>
                <FormikDateField
                    name="description.startAt"
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.start')}
                    format={t('dateTime.matchDate')}
                    className={classes.formField}
                    fullWidth
                />
            </FormControl>
            <FormControl
                error={
                    (touched.description && touched.description.endAt) &&
                    Boolean(errors.description && errors.description.endAt)
                }
                fullWidth>
                <FormikDateField
                    name="description.endAt"
                    label={t('components.matchplay.settings.MatchplayGeneralSettings.end')}
                    format={t('dateTime.matchDate')}
                    className={classes.formField}
                    fullWidth
                />
            </FormControl>
        </div>
    );
};

const SaveButton = ({ isSubmitting, isValidating, isValid, dirty }: FormikProps<Matchplay>) => {
    const { t } = useTranslation();
    const submitButtonDisabled = isSubmitting || isValidating || !dirty || !isValid;

    return (
        <Button variant="contained" color="primary" type="submit" className="right"
            disabled={submitButtonDisabled}>
            {t('common.save')}
        </Button>
    );
};
