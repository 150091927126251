import { Avatar, Card, CardHeader } from '@material-ui/core';
import PlayerImage from './PlayerImage';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Username from './Username';
import Moment from 'react-moment';
import { User } from '../../models/User';

export interface MessageItemAvatarProps {
    user: User;
}

export interface MessageItemSubHeaderProps extends MessageItemAvatarProps {
    createdAt: number;
}

export interface MessageItemProps extends MessageItemSubHeaderProps {
    title: string;
}

export const MessageItemAvatar = (props: MessageItemAvatarProps) => {
    return (
        <Avatar>
            <PlayerImage user={props.user} size={40}/>
        </Avatar>
    );
};

export const MessageItemSubheader = (props: MessageItemSubHeaderProps) => {
    const { user, createdAt } = props;
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {t('common.by')} <Username user={user}/>,&ensp;
            <Moment unix format={t('dateTime.matchDateTime')}>
                {createdAt / 1000}
            </Moment>
        </React.Fragment>
    );
};

export const MessageItem = (props: MessageItemProps) => {
    const { title } = props
    return (
        <Card>
            <CardHeader avatar={<MessageItemAvatar {...props} />}
                        title={title}
                        subheader={<MessageItemSubheader {...props} />}/>
        </Card>
    );
};
