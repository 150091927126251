import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PlayoffMatch } from '../../../models/PlayoffMatch';
import { Box, Card, CardActionArea, CardContent, Divider, Theme, Typography } from '@material-ui/core';
import PlayerImage from '../../utils/PlayerImage';
import { Player } from '../../../models/Player';
import { useTranslation } from 'react-i18next';
import { PageLink } from '../../utils/PageLink';
import { botUser } from '../../../models/User';
import { MatchPhase, MatchResult } from '../../../models/Match';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& > *': {
            textDecoration: 'none',
        },
    },
    card: {
        width: '100%',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),

        '&::before': {
            position: 'absolute',
            left: 0,
            zIndex: 1,
            content: `''`,
            display: 'block',
            width: theme.spacing(4),
            height: '10%',
            borderLeft: `2px solid ${theme.palette.grey['300']}`,

            bottom: '50%',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
            transform: 'translate(0, 1px)',
            borderBottomLeftRadius: theme.spacing(1),
        },
        '&::after': {
            position: 'absolute',
            left: 0,
            zIndex: 1,
            content: `''`,
            display: 'block',
            width: theme.spacing(4),
            height: '10%',
            borderLeft: `2px solid ${theme.palette.grey['300']}`,

            top: '50%',
            borderTop: `1px solid ${theme.palette.grey['300']}`,
            transform: 'translate(0, -1px)',
            borderTopLeftRadius: theme.spacing(1),
        },

        [theme.breakpoints.down('sm')]: {
            '&::before': {
                border: 0,
            },
            '&::after': {
                border: 0,
            }
        },
    },
    cardContent: {
        padding: 0,
    },
    player: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        height: '41px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    playerNameWinner: {
        fontWeight: 600,
    },
    resultBox: {
        borderLeft: `1px solid ${theme.palette.grey['300']}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    resultContent: {
        padding: theme.spacing(1),
    },
    botPlayer: {
        textTransform: 'uppercase' as const,
        fontStyle: 'italic' as const,
        color: theme.palette.text.disabled,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(0.5),
    },
}));

interface Props {
    match: PlayoffMatch;
}

export default function PlayoffMatchView(props: Props) {

    const classes = useStyles();
    const { t } = useTranslation();
    const { match } = props;

    const player1 = match.playerOne;
    const player2 = match.playerTwo;

    const isPlayerWinner = (player: Player) => Boolean(player && match.winner && match.winner.email === player.user.email);

    const itemLinkComponent = PageLink('/matches/:id', { id: match.id });
    const clickable = Boolean(player1 && player2 && match.phase !== MatchPhase.WALKTHROUGH);
    const showResult = match.result && match.phase !== MatchPhase.WALKTHROUGH;

    let matchResult = '';
    if (match.result && match.result === MatchResult.ABANDONED_BY_ORGANIZER) {
        matchResult = t('components.matchplay.playoff.PlayoffMatch.abandonedByOrganizer');
    } else if (match.result) {
        matchResult = t(match.result)
    }

    return (
        <Box className={classes.root}>
            <Card className={classes.card} variant="outlined" square>
                <CardActionArea component={(clickable && itemLinkComponent) || 'div'} disabled={!clickable}>
                    <CardContent className={classes.cardContent}>
                        <Box display="flex">
                            <Box flexGrow="1">
                                <Box className={classes.player}>
                                    {player1 && <PlayerDisplay player={player1} isWinner={isPlayerWinner(player1)}/>}
                                </Box>
                                <Divider/>
                                <Box className={classes.player}>
                                    {player2 && <PlayerDisplay player={player2} isWinner={isPlayerWinner(player2)}/>}
                                </Box>
                            </Box>
                            {showResult &&
                            <Box className={classes.resultBox}>
                                <Box className={classes.resultContent}>
                                    <Typography variant="caption">
                                        {matchResult}
                                    </Typography>
                                </Box>
                            </Box>}
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    );
}

const PlayerDisplay = ({ player, isWinner, }: { player: Player, isWinner: boolean }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    if (!player) {
        return <></>;
    }
    if (player.user.email === botUser.email) {
        return (
            <div className={classes.botPlayer}>
                {t('components.matchplay.dashboard.MatchplayMatchList.walkthrough')}
            </div>
        );
    }
    return (
        <>
            <span className={isWinner ? classes.playerNameWinner : ''}>
                <PlayerImage user={player.user} size={25} inline={true} spacing={1}/>
                {player.user.name}
            </span>
            {isWinner ? ' 🏆' : ''}
        </>
    );

};
