import { ComponentType, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import {
    List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Paper, Theme, Tooltip,
    Typography
} from '@material-ui/core';
import FlagIcon from '@material-ui/icons/FlagTwoTone';

import { HcpFormat } from '../../../lib/numberFormats';
import { sortMatches } from '../../../lib/sorting';
import { getMatchWinner, isPlayoffMatch, Match, MatchPhase, MatchResult } from '../../../models/Match';
import { MatchActivityPayloadResult } from '../../../models/MatchActivity';
import { Player } from '../../../models/Player';
import { botUser } from '../../../models/User';
import { PageLink } from '../../utils/PageLink';
import PlayerImage from '../../utils/PlayerImage';
import Username from '../../utils/Username';
import MatchPhaseText from '../MatchPhaseText';

const styles = (theme: Theme) => ({
    playerLineContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    playerLineLeft: {
        flex: 1,
        marginLeft: 'auto',
        marginRight: theme.spacing(1),
        textAlign: 'right' as const,
    },
    playerLineRight: {
        flex: 1,
        marginRight: 'auto',
        marginLeft: theme.spacing(1),
    },
    playerLineSeparator: {
        flex: 1,
        flexGrow: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    playerNameWinner: {
        fontWeight: 600,
    },
    detailsLineContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    },
    matchNumber: {
        width: 64,
        textAlign: 'center' as const,
    },
    listItem: {
        color: theme.palette.text.primary,
    },
    botPlayer: {
        textTransform: 'uppercase' as const,
        fontStyle: 'italic' as const,
        color: theme.palette.text.disabled,
    },
});
const useStyles = makeStyles(styles);

interface Props {
    matches: Match[];
    additionalMatchFunctionality?: ComponentType<InnerMatch>;
    hideMatchNumber?: boolean;

    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

const MatchplayMatchList = ({ matches, abandonMatch, additionalMatchFunctionality, hideMatchNumber }: Props) => (
    <Paper>
        <List>
            {sortMatches(matches).map((match, index, arr) => (
                <MatchListItem
                    key={match.id}
                    match={match}
                    abandonMatch={abandonMatch}
                    additionalMatchFunctionality={additionalMatchFunctionality}
                    isLastItem={index === (arr.length - 1)}
                    hideMatchNumber={hideMatchNumber}
                />
            ))}
        </List>
    </Paper>
);

export interface InnerMatch {
    match?: Match;

    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

interface MatchListItemProps {
    match: Match;
    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
    additionalMatchFunctionality: ComponentType<InnerMatch> | undefined;
    isLastItem: boolean;
    hideMatchNumber?: boolean;
};

const MatchListItem = ({
    match, abandonMatch, additionalMatchFunctionality,
    isLastItem, hideMatchNumber,
}: MatchListItemProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const player1 = match.playerOne;
    const player2 = match.playerTwo;
    const AbandonMatch = additionalMatchFunctionality;
    const abandonMatchFn = abandonMatch;
    const itemLinkComponent = PageLink('/matches/:id', { id: match.id });
    const showDetails = match.phase !== MatchPhase.WALKTHROUGH;
    const clickable = Boolean(player1 && player2 && showDetails);
    const matchWinner = useMemo(() => getMatchWinner(match), [match]);

    const isPlayerWinner = (player: Player) => matchWinner?.id === player.user.id;
    const showPhase = !(match.result && (match.result === MatchResult.ABANDONED_BY_PLAYER || match.result === MatchResult.ABANDONED_BY_ORGANIZER));

    return (
        <ListItem divider={!isLastItem} component={(clickable && itemLinkComponent) || 'li'} disabled={!clickable}
            className={classes.listItem}>
            {!hideMatchNumber &&
                <Typography variant="h6" component="div" className={classes.matchNumber}>
                    <MatchNumber match={match} />
                </Typography>}
            <ListItemText
                primary={
                    <div className={classes.playerLineContainer}>
                        <div className={classes.playerLineLeft}>
                            {player1 ? <PlayerDisplay player={player1} isWinner={isPlayerWinner(player1)}
                                isLeft={true} /> : '-'}
                        </div>
                        <div className={classes.playerLineSeparator}>:</div>
                        <div className={classes.playerLineRight}>
                            {player2 ? <PlayerDisplay player={player2} isWinner={isPlayerWinner(player2)}
                                isLeft={false} /> : '-'}
                        </div>
                    </div>
                }
                secondary={
                    <>
                        {showDetails &&
                            <span className={classes.detailsLineContainer}>
                                {showPhase &&
                                    <MatchPhaseText phase={match.phase} />}
                                {match.result && showPhase &&
                                    <Fragment>&ensp;({t(match.result)})</Fragment>}
                                {match.result && !showPhase &&
                                    <Fragment>&ensp;{t(match.result)}</Fragment>}
                                {match.scheduledAt &&
                                    <Fragment>
                                        {t('components.matchplay.dashboard.MatchplayMatchList.scheduledAt')}&nbsp;
                                        <Moment unix
                                            format={t('dateTime.matchDateTime')}>{match.scheduledAt / 1000}</Moment>
                                    </Fragment>}
                            </span>}
                    </>
                }
            />
            {AbandonMatch && !match.result ?
                <ListItemSecondaryAction>
                    <AbandonMatch match={match} abandonMatch={abandonMatchFn} />
                </ListItemSecondaryAction>
                : null}
        </ListItem>
    );
};

interface MatchNumberProps {
    match: Match
}

const MatchNumber = ({ match }: MatchNumberProps) => {
    const { t } = useTranslation();
    if (isPlayoffMatch(match)) {
        switch (match.depth) {
            case 0:
                return (
                    <Tooltip title={`${t('components.matchplay.common.final')}`}>
                        <FlagIcon />
                    </Tooltip>
                );
            case 1:
                return (
                    <Tooltip title={`${t('components.matchplay.common.semifinal')}`}>
                        <span>{t('components.matchplay.common.semifinalAbbrev')}</span>
                    </Tooltip>
                );
        }

    }
    return <Fragment>{match.number}</Fragment>;
};

const PlayerDisplay = ({ player, isWinner, isLeft }: { player: Player, isWinner: boolean, isLeft: boolean }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (!player) {
        return <></>;
    }

    if (player.user.email === botUser.email) {
        return (
            <span className={classes.botPlayer}>
                {t('components.matchplay.dashboard.MatchplayMatchList.walkthrough')}
            </span>
        );
    }

    if (isLeft) {
        return (
            <>
                {isWinner ? '🏆 ' : ''}
                <span className={isWinner ? classes.playerNameWinner : ''}>
                    <PlayerImage user={player.user} size={25} inline={true} spacing={1} />
                    <Username user={player.user} />
                    &nbsp;({HcpFormat.format(player.hcp)})
                </span>
            </>
        );
    } else {
        return (
            <>
                <span className={isWinner ? classes.playerNameWinner : ''}>
                    ({HcpFormat.format(player.hcp)})&nbsp;
                    <Username user={player.user} />
                    <PlayerImage user={player.user} size={25} inline={true} spacing={1} />
                </span>
                {isWinner ? ' 🏆' : ''}
            </>
        );
    }
};

export default MatchplayMatchList;
