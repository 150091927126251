import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Hidden, IconButton, Link, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Alert } from '@material-ui/lab';

import { MatchActivityPayloadResult } from '../../../models/MatchActivity';
import { Matchplay, MatchplayPhase, MatchplayVariant } from '../../../models/Matchplay';
import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import { User } from '../../../models/User';
import Sponsors from '../../sponsoring/Sponsors';
import Section from '../../utils/Section';
import MatchplayJoinButtonContainer from './MatchplayJoinButtonContainer';
import MatchplayMatchList from './MatchplayMatchList';
import MatchplayPlayerList from './MatchplayPlayerList';
import { PageLink } from '../../utils/PageLink';
import { PlayoffMatch } from '../../../models/PlayoffMatch';
import { GroupView } from '../../../models/MatchViews';
import MatchplayGroupMatches from './MatchplayGroupMatches';
import MatchplayGroupStandings from './MatchplayGroupStandings';
import { CollapsibleSection } from '../../utils/CollapsibleSection';

const styles = (theme: Theme) => ({
    gutterTop: {
        marginTop: theme.spacing(3),
    },
});

interface ComponentProps {
    matchplay: Matchplay;
    currentUser: User;
    registrations: MatchplayRegistration[];
    playoffMatches?: PlayoffMatch[];
    groupView?: GroupView;
    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

type Props = ComponentProps & WithTranslation & WithStyles<typeof styles>;

class MatchplayDashboard extends Component<Props> {

    public render() {
        const { matchplay, currentUser, registrations, playoffMatches, groupView, abandonMatch, t, classes } = this.props;
        const showMatchList =
            (matchplay.phase === MatchplayPhase.EXECUTION) ||
            (matchplay.phase === MatchplayPhase.AFTERMATH);
        const showGroupPhase =
            (matchplay.variant === MatchplayVariant.GROUP) && (
                (matchplay.phase === MatchplayPhase.EXECUTION_GROUP) ||
                (matchplay.phase === MatchplayPhase.EXECUTION_GROUP_FINISHED) ||
                (matchplay.phase === MatchplayPhase.EXECUTION) ||
                (matchplay.phase === MatchplayPhase.AFTERMATH));
        const showPlayerList =
            matchplay.variant !== MatchplayVariant.GROUP || (
                matchplay.phase === MatchplayPhase.INITIALIZATION
                || matchplay.phase === MatchplayPhase.REGISTRATION_CLOSED
                || matchplay.phase === MatchplayPhase.REGISTRATION_OPEN);
        const showGroupPlayoffHint =
            matchplay.variant === MatchplayVariant.GROUP &&
            matchplay.phase === MatchplayPhase.EXECUTION_GROUP_FINISHED;
        const hasRulesText = matchplay.description.rulesText && matchplay.description.rulesText.length > 0;
        const hasPricesText = matchplay.description.pricesText && matchplay.description.pricesText.length > 0;
        const hasSponsorsText = matchplay.description.sponsorsText && matchplay.description.sponsorsText.length > 0;
        const rulePageLink = PageLink('/matchplays/:id/rules', { id: matchplay.id });
        const playoffTreeLink = PageLink('/matchplays/:id/playoff', { id: matchplay.id });
        const inPreparation = matchplay.phase === MatchplayPhase.REGISTRATION_CLOSED;
        const registrationOpen = matchplay.phase === MatchplayPhase.REGISTRATION_OPEN;

        return (
            <>
                <Hidden smUp>
                    <Typography variant="h5" component="h2">{matchplay.name}</Typography>
                </Hidden>

                {showGroupPlayoffHint && (
                    <Alert severity="info" className={classes.gutterTop} icon={false}>
                        {t('components.matchplay.dashboard.MatchplayDashboard.groupPlayoffHint')}
                    </Alert>
                )}

                {(hasRulesText || hasPricesText || hasSponsorsText) &&
                    <Typography variant="body1" className={classes.gutterTop}>
                        <Link component={rulePageLink}>
                            {t('components.matchplay.dashboard.MatchplayDashboard.rulesLink')}
                        </Link>
                    </Typography>}

                {showMatchList &&
                    <Section title={t('components.matchplay.common.matches')}
                        actions={
                            <IconButton color="secondary" component={playoffTreeLink}>
                                <AccountTreeIcon />
                            </IconButton>
                        }>
                        <MatchplayMatchList matches={playoffMatches ?? []} abandonMatch={abandonMatch} />
                    </Section>}

                {showGroupPhase &&
                    <CollapsibleSection
                        title={t('components.matchplay.common.matchesGroupPhase')}
                        showCollapseAction={showMatchList}
                        collapsed={showMatchList}>
                        <MatchplayGroupMatches matchplay={matchplay} groupMatches={groupView?.matches ?? []} abandonMatch={abandonMatch} />
                    </CollapsibleSection>}
                {showGroupPhase &&
                    <CollapsibleSection
                        title={t('components.matchplay.common.groupPhase')}
                        showCollapseAction={showMatchList}
                        collapsed={showMatchList}>
                        <MatchplayGroupStandings
                            standings={groupView?.standings ?? []} />
                    </CollapsibleSection>}

                {inPreparation &&
                    <Alert severity="info" className={classes.gutterTop} icon={false}>
                        <strong>{t('components.matchplay.dashboard.MatchplayDashboard.inPreparationTitle')}:</strong>&nbsp;
                        {t('components.matchplay.dashboard.MatchplayDashboard.inPreparationText')}
                    </Alert>}

                {registrationOpen &&
                    <Alert severity="success" className={classes.gutterTop} icon={false}>
                        <strong>{t('components.matchplay.dashboard.MatchplayDashboard.registrationOpenTitle')}</strong>&nbsp;
                        {t('components.matchplay.dashboard.MatchplayDashboard.registrationOpenText')}
                    </Alert>}

                <Sponsors sponsors={matchplay.sponsors}
                    title={t('components.matchplay.dashboard.MatchplayDashboard.sponsors')} />

                {showPlayerList &&
                    <Section title={t('components.matchplay.dashboard.MatchplayDashboard.registeredPlayers')}
                        actions={(
                            <MatchplayJoinButtonContainer matchplay={matchplay} />
                        )}>
                        <MatchplayPlayerList registrations={registrations}
                            currentUser={currentUser}
                            phase={matchplay.phase}
                            emptyContent={t('components.matchplay.dashboard.MatchplayDashboard.registeredPlayersEmpty')}
                            signOutPlayerAction={() => { /* do nothing */ }} />
                    </Section>}
            </>
        );
    }
}

export default withStyles(styles)(withTranslation()(MatchplayDashboard));
