import { Dispatch } from 'redux';

import { User } from '../../models/User';
import { userManagementService } from '../../services/usermanagement';
import {
    DISABLE_USER_FAILURE, DISABLE_USER_REQUEST, DISABLE_USER_SUCCESS, DOWNGRADE_USER_FAILURE,
    DOWNGRADE_USER_REQUEST, DOWNGRADE_USER_SUCCESS, ENABLE_USER_FAILURE, ENABLE_USER_REQUEST,
    ENABLE_USER_SUCCESS, GET_USERS_FAILURE, GET_USERS_REQUEST, GET_USERS_SUCCESS,
    PROMOTE_USER_FAILURE, PROMOTE_USER_REQUEST, PROMOTE_USER_SUCCESS
} from '../reducers/usermanagement';

export const getUsersAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const usersPage = await userManagementService.getUsers();
            dispatch(success(usersPage.content));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_USERS_REQUEST }; }
    function success(users: User[]) { return { type: GET_USERS_SUCCESS, payload: users }; }
    function failure() { return { type: GET_USERS_FAILURE }; }
    // @formatter:on
}

export const enableUserAction = (dispatch: Dispatch) => {
    return async (email: string) => {
        dispatch(request());
        try {
            await userManagementService.enableUser(email);
            dispatch(success(email));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: ENABLE_USER_REQUEST }; }
    function success(email: string) { return { type: ENABLE_USER_SUCCESS, payload: email }; }
    function failure() { return { type: ENABLE_USER_FAILURE }; }
    // @formatter:on
}

export const disableUserAction = (dispatch: Dispatch) => {
    return async (email: string) => {
        dispatch(request());
        try {
            await userManagementService.disableUser(email);
            dispatch(success(email));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: DISABLE_USER_REQUEST }; }
    function success(email: string) { return { type: DISABLE_USER_SUCCESS, payload: email }; }
    function failure() { return { type: DISABLE_USER_FAILURE }; }
    // @formatter:on
}

export const promoteUserAction = (dispatch: Dispatch) => {
    return async (email: string) => {
        dispatch(request());
        try {
            await userManagementService.promoteUser(email);
            dispatch(success(email));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: PROMOTE_USER_REQUEST }; }
    function success(email: string) { return { type: PROMOTE_USER_SUCCESS, payload: email }; }
    function failure() { return { type: PROMOTE_USER_FAILURE }; }
    // @formatter:on
}

export const downgradeUserAction = (dispatch: Dispatch) => {
    return async (email: string) => {
        dispatch(request());
        try {
            await userManagementService.downgradeUser(email);
            dispatch(success(email));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: DOWNGRADE_USER_REQUEST }; }
    function success(email: string) { return { type: DOWNGRADE_USER_SUCCESS, payload: email }; }
    function failure() { return { type: DOWNGRADE_USER_FAILURE }; }
    // @formatter:on
}
