import {
    Box, Card, CardActions, CardContent, Grid, makeStyles, Theme, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { HcpFormat } from '../../lib/numberFormats';
import { User, UserMatchStats } from '../../models/User';
import PlayerImage from './PlayerImage';
import Username from './Username';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        border: 0,
        borderRadius: 0,
        backgroundColor: theme.palette.background.paper,
    },
    box: {
        height: 64,
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'center',
        background: 'linear-gradient(180deg, #8fb055 calc(50% + 10px), transparent 0)',
    },
    gutterTop: {
        marginTop: theme.spacing(3),
    },
    equalHeight: {
        height: '100%',
    },
    holesWonLabel: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {
    user: User,
    userStats?: UserMatchStats,
    gutterTop?: boolean;
    equalHeight?: boolean;
    isWinner?: boolean;
    playerHcp?: number;
    holesWon?: number;
}

const PlayerCard = ({ user, userStats, gutterTop, isWinner = false, playerHcp, equalHeight, holesWon }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const rootClassNames = [classes.root];
    if (gutterTop) {
        rootClassNames.push(classes.gutterTop);
    }
    if (equalHeight) {
        rootClassNames.push(classes.equalHeight);
    }

    const hcp = HcpFormat.format((playerHcp) ? playerHcp : user.hcp);

    return (
        <Card variant="outlined" className={rootClassNames.join(' ')}>
            <Box display="flex" className={classes.box}>
                <PlayerImage user={user} />
            </Box>
            <CardContent>
                <Typography variant="h5" component="h2" noWrap={true} align="center">
                    {isWinner && <span role="img" aria-label="winner">🏆&ensp;</span>}
                    <Username user={user} /> ({hcp})
                </Typography>
                {user.club &&
                    <Typography align="center">
                        <span role="img" aria-label="club">⛳</span> {user.club}
                    </Typography>}
                <Typography color="textSecondary" align="center">
                    {user.status || 'Verfügbar'}
                </Typography>
                {holesWon !== undefined && (
                    <Typography align="center" className={classes.holesWonLabel}>
                        {t('components.utils.PlayerCard.holesWon', { holesWon })}
                    </Typography>
                )}
            </CardContent>
            {userStats &&
                <CardActions>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography align="center">
                                {userStats.winRatio}%
                            </Typography>
                            <Typography color="textSecondary" align="center">
                                {t('components.utils.PlayerCard.winrate')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="center">
                                {userStats.matchesWon}-{userStats.matchesLost}
                            </Typography>
                            <Typography color="textSecondary" align="center">
                                {t('components.utils.PlayerCard.stats')}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            }
        </Card>
    );
};

export default PlayerCard;
