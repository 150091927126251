import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { PasswordResetRequest } from '../../models/PasswordReset';
import { TenantInformation } from '../../models/TenantInformation';
import { User } from '../../models/User';
import { requestPasswordResetAction, resetUserStoreAction } from '../../store/actions/user';
import RequestPasswordResetComplete from './RequestPasswordResetComplete';
import RequestPasswordResetForm from './RequestPasswordResetForm';

interface StoreProps {
    tenantInformation?: TenantInformation;
    isRequestPasswordResetSuccessful: boolean;

    requestPasswordReset(payload: PasswordResetRequest): void;
    resetUserStore(): void;
}

type Props = StoreProps;

class RequestPasswordResetContainer extends Component<Props> {
    public render() {
        const { tenantInformation, isRequestPasswordResetSuccessful } = this.props;
        if (!tenantInformation) {
            return null;
        }
        return (
            <React.Fragment>
                {!isRequestPasswordResetSuccessful && <RequestPasswordResetForm
                    tenantInformation={tenantInformation}
                    onRequestPasswordReset={this.onRequestPasswordReset} />}
                {isRequestPasswordResetSuccessful && <RequestPasswordResetComplete />}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        this.props.resetUserStore();
    }

    private onRequestPasswordReset = (user: User) => {
        this.props.requestPasswordReset(user);
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
    isRequestPasswordResetSuccessful: state.user.isRequestPasswordResetSuccessful,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    requestPasswordReset: requestPasswordResetAction(dispatch),
    resetUserStore: resetUserStoreAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestPasswordResetContainer);
