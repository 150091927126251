import { useTranslation } from 'react-i18next';

import { User } from '../../../models/User';
import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import { MatchplayPhase } from '../../../models/Matchplay';
import Section from '../../utils/Section';
import MatchplayPlayerList from '../dashboard/MatchplayPlayerList';
import SignOutPlayer from './SignOutPlayer';

interface ComponentProps {
    currentUser: User;
    registrations: MatchplayRegistration[];
    phase: MatchplayPhase;
    signOutPlayerAction(registration: MatchplayRegistration): void;
}

type Props = ComponentProps;

const MatchplayPlayerSettings = ({ currentUser, registrations, phase, signOutPlayerAction }: Props) => {
    const { t } = useTranslation();

    return (
        <Section title={t('components.matchplay.dashboard.MatchplayDashboard.registeredPlayers')}>
            <MatchplayPlayerList
                currentUser={currentUser}
                registrations={registrations}
                phase={phase}
                emptyContent={t('components.matchplay.dashboard.MatchplayDashboard.registeredPlayersEmpty')}
                signOutPlayerComponent={SignOutPlayer}
                signOutPlayerAction={signOutPlayerAction} />
        </Section>
    );
}

export default MatchplayPlayerSettings;
