import React, { Component } from 'react';
import { Matchplay } from '../../models/Matchplay';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getMatchplayAction } from '../../store/actions/matchplays';
import { withRouter } from 'react-router-dom';
import { ResourceIdMap } from '../../lib/resourceSupport';
import { RouteComponentProps } from 'react-router';
import MatchplayRules from './MatchplayRules';

interface RouteProps {
    matchplayId: string;
}

interface StoreProps {
    matchplayById: ResourceIdMap<Matchplay>;
    fetchMatchplay(matchplayId: string): Promise<void>;
}

type Props = & RouteComponentProps<RouteProps> & StoreProps;

class MatchplayRulesContainer extends Component<Props> {

    public render() {
        const { matchplayById } = this.props;
        const { matchplayId } = this.props.match.params;

        if (!(matchplayId in matchplayById)) {
            return null;
        }
        const matchplay = matchplayById[matchplayId];

        return (
            <MatchplayRules matchplay={matchplay}/>
        );
    }

    public async componentDidMount() {
        const { fetchMatchplay } = this.props;
        const { matchplayId } = this.props.match.params;
        if (!matchplayId) {
            return;
        }
        await fetchMatchplay(matchplayId);
    }
}

const mapStateToProps = (state: any) => ({
    matchplayById: state.matchplay.byId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchMatchplay: getMatchplayAction(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchplayRulesContainer));
