import React, { Component, ReactNode } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Matchplay, MatchplayPhase } from '../../models/Matchplay';
import { listMatchplaysAction } from '../../store/actions/matchplays';
import MatchplayList from './MatchplayList';
import { User } from '../../models/User';

interface ComponentProps {
    filterPhase?: MatchplayPhase[];
    emptyContent?: ReactNode;
    linkPattern?: string;
    maxEntries?: number;
}

interface StoreProps {
    currentUser: User,
    matchplays: Matchplay[];
    listMatchplays(): void;
}

type Props = ComponentProps & StoreProps;

class MatchplayListContainer extends Component<Props> {

    public render() {
        const { filterPhase, matchplays, emptyContent, currentUser, linkPattern, maxEntries } = this.props;
        let filteredMatchplays = matchplays;
        if (filterPhase) {
            filteredMatchplays = matchplays.filter(matchplay => filterPhase.includes(matchplay.phase));
        }
        let sortedMatchplays = filteredMatchplays.sort(sortMatchplays);

        const notAllEntries = !!(maxEntries && (maxEntries > 0) && (sortedMatchplays.length > maxEntries));
        if (notAllEntries) {
            sortedMatchplays = sortedMatchplays.slice(0, maxEntries);
        }

        return (
            <MatchplayList currentUser={currentUser} matchplays={sortedMatchplays}
                           emptyContent={emptyContent} linkPattern={linkPattern}
                           notAllEntries={notAllEntries} allEntriesLink="/matchplays"/>
        );
    }

    public componentDidMount() {
        this.props.listMatchplays();
    }
}

const matchplayPhaseOrdering = [
    MatchplayPhase.EXECUTION,
    MatchplayPhase.EXECUTION_GROUP_FINISHED,
    MatchplayPhase.EXECUTION_GROUP,
    MatchplayPhase.REGISTRATION_OPEN,
    MatchplayPhase.REGISTRATION_CLOSED,
    MatchplayPhase.AFTERMATH,
    MatchplayPhase.INITIALIZATION,
]

const sortMatchplays = (lhs: Matchplay, rhs: Matchplay): number => {
    const lhsPhaseIndex = matchplayPhaseOrdering.indexOf(lhs.phase);
    const rhsPhaseIndex = matchplayPhaseOrdering.indexOf(rhs.phase);
    return (lhsPhaseIndex === rhsPhaseIndex)
        ? lhs.name.localeCompare(rhs.name)
        : lhsPhaseIndex - rhsPhaseIndex;
};

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    matchplays: state.matchplay.items,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    listMatchplays: listMatchplaysAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchplayListContainer);
