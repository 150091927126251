import { ComponentProps, PropsWithChildren, useCallback, useState } from 'react';

import { IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Section from './Section';

type SectionProps = ComponentProps<typeof Section>

interface AdditionalProps {
    collapsed?: boolean;
    showCollapseAction?: boolean;
}

type Props = SectionProps & AdditionalProps;

export const CollapsibleSection = ({ collapsed, showCollapseAction = true, children, ...other }: PropsWithChildren<Props>) => {
    const [internalCollapsed, setInternalCollapsed] = useState<boolean>(!!collapsed);

    const onClick = useCallback(() => {
        setInternalCollapsed(curr => !curr);
    }, []);

    return (
        <Section
            {...other}
            hideBody={internalCollapsed}
            actions={showCollapseAction && (
                <CollapseButton
                    collapsed={internalCollapsed}
                    onClick={onClick}
                />
            )}>
            {children}
        </Section>
    )
};

interface CollapseButtonProps {
    collapsed: boolean;
    onClick: () => void;
}

const CollapseButton = ({ collapsed, onClick }: CollapseButtonProps) => {
    return (
        <IconButton onClick={onClick}>
            {collapsed && (
                <ExpandMoreIcon />
            )}
            {!collapsed && (
                <ExpandLessIcon />
            )}
        </IconButton>
    );
};
