import React, { Component } from 'react';
import Moment from 'react-moment';

import { List, ListItem, ListItemText, Theme, withStyles, WithStyles } from '@material-ui/core';

import { Match } from '../../models/Match';
import MatchPhaseText from '../matchplay/MatchPhaseText';
import { PageLink } from '../utils/PageLink';
import PlayerImage from '../utils/PlayerImage';
import Username from '../utils/Username';

const styles = (theme: Theme) => ({
    playerLineContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    playerLineLeft: {
        flex: 1,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    playerLineRight: {
        flex: 1,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        textAlign: 'right' as const,
    },
    playerLineSeparator: {
        flex: 1,
        flexGrow: 0,
        whiteSpace: 'pre' as const,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
    },
    playerNameWinner: {
        fontWeight: 600,
    },
    detailsLineContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    },
});

interface ComponentProps {
    matches: Match[];
}

type Props = ComponentProps;

class MatchCardMulti extends Component<Props> {
    public render() {
        const { matches } = this.props;

        return (
            <List>
                {matches.map((match, index, arr) => {
                    const isLast = (index === arr.length - 1);
                    return <MatchListItem key={match.id} match={match} isLast={isLast} />
                })}
            </List>
        );
    }
}

interface MatchListItemProps {
    match: Match;
    isLast?: boolean;
}

const MatchListItem = withStyles(styles)(({ match, isLast, classes }: MatchListItemProps & WithStyles<typeof styles>) => {
    const player1 = match.playerOne;
    const player2 = match.playerTwo;
    const itemLink = PageLink('/matches/:id', { id: match.id });

    return (
        <ListItem divider={!isLast} component={itemLink} button={true}>
            <ListItemText
                primary={
                    <div className={classes.playerLineContainer}>
                        <PlayerImage user={player1?.user} size={40} />
                        <div className={classes.playerLineLeft}>
                            {player1 &&
                                <>{player1 ? <Username user={player1.user}/> : '-'}</>}
                        </div>
                        <div className={classes.playerLineSeparator}>&ndash; vs &ndash;</div>
                        <div className={classes.playerLineRight}>
                            {player2 &&
                                <>{player2 ? <Username user={player2.user}/> : '-'}</>}
                        </div>
                        <PlayerImage user={player2?.user} size={40} />
                    </div>
                }
                secondary={
                    <span className={classes.detailsLineContainer}>
                        <MatchPhaseText phase={match.phase} />
                        {match.result &&
                            <>&ensp;({match.result})</>}
                        {match.scheduledAt &&
                            <>
                                , Spieltermin:&nbsp;
                                <Moment unix format="DD.MM.YYYY [um] HH:mm [Uhr]">{match.scheduledAt / 1000}</Moment>
                            </>}
                    </span>
                } />
        </ListItem>
    );
});

export default MatchCardMulti;
