import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Box, Divider, IconButton, Theme, Typography, WithStyles, withStyles
} from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';

import { ResourceNumberedGroup } from '../../../lib/resourceSupport';
import { Match } from '../../../models/Match';
import { Matchplay } from '../../../models/Matchplay';
import Sponsors from '../../sponsoring/Sponsors';
import { PageLink } from '../../utils/PageLink';
import PlayoffRound from './PlayoffRound';

const styles = (theme: Theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flex: 1,
    },
    box: {
        display: 'flex',
        flexDirection: 'row' as const,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column' as const,
        },
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }
});

interface ComponentProps {
    matchplay: Matchplay;
    groupedMatches: ResourceNumberedGroup<Match>;
}

type Props = ComponentProps & WithTranslation & WithStyles<typeof styles>;

class MatchplayPlayoff extends Component<Props> {

    public render() {
        const { matchplay, groupedMatches, classes, t } = this.props;
        const maxDepth = Object.entries(groupedMatches).length;
        const backLink = PageLink('/matchplays/:id', { id: matchplay.id });

        const playoffRounds = Object.entries(groupedMatches).reverse().map((value, index) => {
            const group = value[0];
            const matches = value[1];
            return (
                <PlayoffRound group={group} matches={matches} key={index} maxDepth={maxDepth} />
            );
        });

        return (
            <>
                <Box className={classes.root}>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                        <Box flexGrow="1">
                            <Typography variant="h5" align="center">
                                {matchplay.name}
                            </Typography>
                        </Box>
                        <Typography align="right">
                            <IconButton color="secondary" component={backLink}>
                                <ViewListIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                    <Box className={classes.box}>
                        {playoffRounds}
                    </Box>
                </Box>
                <Sponsors
                    sponsors={matchplay.sponsors}
                    title={t('components.matchplay.dashboard.MatchplayDashboard.sponsors')} />
            </>
        );
    }
}

export default withStyles(styles)(withTranslation()(MatchplayPlayoff));
