import { AnyAction } from 'redux';
import { Errors } from '../../models/Errors';
import { Notification } from '../../models/Notification';
import { createResourceIdMap, ResourceIdMap } from '../../lib/resourceSupport';

export const LIST_NOTIFICATIONS_REQUEST = 'notification/LIST_REQUEST';
export const LIST_NOTIFICATIONS_SUCCESS = 'notification/LIST_SUCCESS';
export const LIST_NOTIFICATIONS_FAILURE = 'notification/LIST_FAILURE';
export const CREATE_NOTIFICATION_REQUEST = 'notification/CREATE_REQUEST';
export const CREATE_NOTIFICATION_SUCCESS = 'notification/CREATE_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'notification/CREATE_FAILURE';
export const UPDATE_NOTIFICATION_REQUEST = 'notification/UPDATE_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'notification/UPDATE_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'notification/UPDATE_FAILURE';
export const DELETE_NOTIFICATION_REQUEST = 'notification/DELETE_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'notification/DELETE_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'notification/DELETE_FAILURE';
export const PUBLISH_NOTIFICATION_REQUEST = 'notification/PUBLISH_REQUEST';
export const PUBLISH_NOTIFICATION_SUCCESS = 'notification/PUBLISH_SUCCESS';
export const PUBLISH_NOTIFICATION_FAILURE = 'notification/PUBLISH_FAILURE';

interface NotificationState {
    isLoading: boolean,
    items: Notification[]
    byId: ResourceIdMap<Notification>;
    lastError?: Errors;
}

const initialState: NotificationState = {
    isLoading: false,
    items: [],
    byId: {},
}

export const notificationReducer = (state: NotificationState = initialState, action: AnyAction): NotificationState => {
    switch (action.type) {

        case LIST_NOTIFICATIONS_REQUEST:
        case CREATE_NOTIFICATION_REQUEST:
        case UPDATE_NOTIFICATION_REQUEST:
        case DELETE_NOTIFICATION_REQUEST:
        case PUBLISH_NOTIFICATION_REQUEST:
            return { ...state, isLoading: true };

        case LIST_NOTIFICATIONS_SUCCESS: {
            const items = action.payload as Notification[];
            const byId = createResourceIdMap(items);
            return { ...state, isLoading: false, lastError: undefined, items, byId };
        }

        case CREATE_NOTIFICATION_SUCCESS:
        case UPDATE_NOTIFICATION_SUCCESS:
        case PUBLISH_NOTIFICATION_SUCCESS: {
            const payload = action.payload as Notification;
            const index = state.items.findIndex(item => item.id === payload.id);
            // create or update
            const items = index >= 0
                ? state.items.map(item => (item.id === payload.id) ? payload : item)
                : [...state.items, payload];
            const byId = createResourceIdMap(items);
            return { ...state, isLoading: false, lastError: undefined, items, byId };
        }

        case DELETE_NOTIFICATION_SUCCESS:
            return { ...state, isLoading: false, lastError: undefined };

        case LIST_NOTIFICATIONS_FAILURE:
        case CREATE_NOTIFICATION_FAILURE:
        case UPDATE_NOTIFICATION_FAILURE:
        case DELETE_NOTIFICATION_FAILURE:
        case PUBLISH_NOTIFICATION_FAILURE:
            return { ...state, isLoading: false, lastError: action.payload };
    }
    return state;
}
