import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Button, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { Omit } from '@material-ui/types';

import { PasswordResetRequest } from '../../models/PasswordReset';
import { TenantInformation } from '../../models/TenantInformation';
import TenantLogo from '../tenant/TenantLogo';
import FormikTextField from '../utils/forms/FormikTextField';

const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    title: {
        textAlign: 'center' as const
    },
    logoContainer: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3),
    },
    subtitle: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3)
    },
    actionRow: {
        marginTop: theme.spacing(2)
    },
});

interface ComponentProps {
    tenantInformation: TenantInformation;

    onRequestPasswordReset(payload: PasswordResetRequest): void;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class RequestPasswordResetForm extends Component<Props> {
    public render() {
        const { tenantInformation, classes, t } = this.props;
        const initialValues: PasswordResetRequest = {
            email: '',
        };
        return (
            <Grid container direction="column" className={classes.root}>
                <div className={classes.logoContainer}>
                    <TenantLogo tenantInformation={tenantInformation} />
                </div>
                <Typography variant="h5" component="h1" className={classes.title}>
                    {t('components.login.RequestPasswordResetForm.title')}
                </Typography>
                <Typography className={classes.subtitle}>
                    {t('components.login.common.subtitle', { tenantName: tenantInformation.name })}
                </Typography>
                <Formik
                    component={PasswordResetInnerForm}
                    onSubmit={this.onSubmit}
                    initialValues={initialValues}
                    validationSchema={this.schema()} />
            </Grid>
        );
    }

    private onSubmit = (formData: PasswordResetRequest) => {
        this.props.onRequestPasswordReset(formData);
    }

    private schema = () => {
        const { t } = this.props;
        return Yup.object().shape({
            email: Yup
                .string()
                .required(t('validation.email.required'))
                .email(t('validation.email.invalid')),
        });
    }
}

type RequestPasswordResetFormProps = WithStyles<typeof styles> & FormikProps<PasswordResetRequest> & WithTranslation;
const PasswordResetInnerForm = withStyles(styles)(withTranslation()((props: RequestPasswordResetFormProps) => {
    const { classes, t } = props;
    const loginLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
        (linkProps: any, ref: any) => <Link innerRef={ref} to="/login" {...linkProps} />,
    );
    return (
        <Form>
            <Grid container direction="column">
                <FormikTextField name="email" type="email"
                    label={t('components.login.common.email')} margin="normal" variant="outlined" />
                <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.actionRow}>
                    <Button component={loginLink} color="primary">
                        {t('components.login.common.backToLogin')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="right">
                        {t('components.login.RequestPasswordResetForm.submit')}
                    </Button>
                </Box>
            </Grid>
        </Form>
    );
}));

export default withStyles(styles)(withTranslation()(RequestPasswordResetForm));
