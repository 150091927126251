import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PropsWithChildren, useMemo } from 'react';

const defaultWidth = 250;

interface Props {
    width?: number;
}

const useStyles = ({ width = defaultWidth }: Props) => useMemo(() => makeStyles((theme: Theme) => ({
    root: {
        position: 'sticky',
        top: 0,
        marginLeft: -1 * (width + theme.spacing(4)),
        width,
        height: 0,
        [theme.breakpoints.down(theme.breakpoints.values.md + (2 * (width + theme.spacing(4))))]: {
            position: 'unset',
            top: 'unset',
            marginLeft: 'unset',
            width: 'unset',
            height: 'unset',
        },
    },
})), [width]);

const SideMenu = (props: PropsWithChildren<Props>) => {
    const { children } = props;
    const classes = useStyles(props)();

    return (
        <nav className={classes.root}>
            {children}
        </nav>
    )
};

export default SideMenu;
