import React, { Component } from 'react';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';
import MatchplayRulesContainer from '../matchplay/MatchplayRulesContainer';

class MatchplayRulesPage extends Component {

    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer/>
                <BreadcrumbsContainer/>
                <MatchplayRulesContainer/>
            </ContentContainer>
        );
    }
}

export default MatchplayRulesPage;
