import React, {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import Moment from 'react-moment';

import {Box, IconButton, Paper, Theme, Tooltip, Typography, withStyles, WithStyles} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

import {Match, MatchPhase} from '../../models/Match';
import {exportMatchEvent} from '../../lib/calendar';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
});

interface ComponentProps {
  match: Match;
}

type Props = ComponentProps & WithTranslation & WithStyles<typeof styles>;

class MatchResult extends Component<Props> {

  public render() {
    const {match, classes, t} = this.props;

    const showPaper = (match.phase === MatchPhase.SCHEDULED) || (match.phase === MatchPhase.FINISHED);
    const showSchedule = (!!match.scheduledAt)

    return (<>
      {showPaper && <Paper className={classes.root}>
        {match.result && (
            <>
              <Typography variant="h6">
                <strong>{t('components.match.MatchResult.resultLabel')}:</strong> {t(match.result)}
              </Typography>
            </>
        )}
        {showSchedule && <Box display="flex" flexDirection="row">
          <Typography variant="subtitle1">
            {t('components.match.MatchResult.scheduledAtLabel')}:&nbsp;
            {match.scheduledAt &&
              <Moment unix format={t('dateTime.matchDateTime')}>
                {match.scheduledAt / 1000}
              </Moment>}
          </Typography>
          <Tooltip title={`${t('components.match.common.exportToCalendar')}`} aria-label="export"
                   style={{marginLeft: 'auto'}}>
            <IconButton edge="end" aria-label="export" size="small" onClick={this.onExport}>
              <LaunchIcon/>
            </IconButton>
          </Tooltip>
        </Box>}
      </Paper>}
    </>);
  }

  private onExport = () => exportMatchEvent(this.props.match, this.props.t);
}

export default withStyles(styles)(withTranslation()(MatchResult));
