import { AnyAction } from 'redux';

import { Errors } from '../../models/Errors';
import { TenantInformation } from '../../models/TenantInformation';
import { TenantSettings } from '../../models/TenantSettings';

export const TENANT_UPLOAD_LOGO_SUCCESS = 'tenant/UPLOAD_LOGO_SUCCESS';
export const TENANT_UPLOAD_LOGO_FAILURE = 'tenant/UPLOAD_LOGO_FAILURE';
export const TENANT_INFORMATION_GET_REQUEST = 'tenant/INFORMATION_GET_REQUEST';
export const TENANT_INFORMATION_GET_SUCCESS = 'tenant/INFORMATION_GET_SUCCESS';
export const TENANT_INFORMATION_GET_FAILURE = 'tenant/INFORMATION_GET_FAILURE';
export const TENANT_INFORMATION_UPDATE_REQUEST = 'tenant/INFORMATION_UPDATE_REQUEST';
export const TENANT_INFORMATION_UPDATE_SUCCESS = 'tenant/INFORMATION_UPDATE_SUCCESS';
export const TENANT_INFORMATION_UPDATE_FAILURE = 'tenant/INFORMATION_UPDATE_FAILURE';
export const TENANT_SETTINGS_GET_REQUEST = 'tenant/SETTINGS_GET_REQUEST';
export const TENANT_SETTINGS_GET_SUCCESS = 'tenant/SETTINGS_GET_SUCCESS';
export const TENANT_SETTINGS_GET_FAILURE = 'tenant/SETTINGS_GET_FAILURE';

export const TENANT_API_KEY_CREATE_REQUEST = 'tenant/API_KEY_CREATE_REQUEST';
export const TENANT_API_KEY_CREATE_SUCCESS = 'tenant/API_KEY_CREATE_SUCCESS';
export const TENANT_API_KEY_CREATE_FAILURE = 'tenant/API_KEY_CREATE_FAILURE';
export const TENANT_API_KEY_DELETE_REQUEST = 'tenant/API_KEY_DELETE_REQUEST';
export const TENANT_API_KEY_DELETE_SUCCESS = 'tenant/API_KEY_DELETE_SUCCESS';
export const TENANT_API_KEY_DELETE_FAILURE = 'tenant/API_KEY_DELETE_FAILURE';

interface TenantState {
    tenantInformation?: TenantInformation;
    tenantSettings?: TenantSettings;
    isSuccessfullyUploadedLogo: boolean;
    lastError?: Errors;
    isLoading: boolean;
}

const initialState: TenantState = {
    isSuccessfullyUploadedLogo: false,
    isLoading: false,
};

export const tenantReducer = (state: TenantState = initialState, action: AnyAction) => {
    switch (action.type) {
        case TENANT_INFORMATION_GET_SUCCESS:
        case TENANT_INFORMATION_UPDATE_SUCCESS:
            return { ...state, lastError: undefined, isLoading: false, tenantInformation: action.payload };
        case TENANT_INFORMATION_GET_REQUEST:
        case TENANT_INFORMATION_UPDATE_REQUEST:
        case TENANT_SETTINGS_GET_REQUEST:
        case TENANT_API_KEY_CREATE_REQUEST:
        case TENANT_API_KEY_DELETE_REQUEST:
            return { ...state, isLoading: true };
        case TENANT_INFORMATION_GET_FAILURE:
        case TENANT_INFORMATION_UPDATE_FAILURE:
        case TENANT_SETTINGS_GET_FAILURE:
        case TENANT_API_KEY_CREATE_FAILURE:
        case TENANT_API_KEY_DELETE_FAILURE:
            return { ...state, isLoading: false, lastError: action.payload };
        case TENANT_SETTINGS_GET_SUCCESS:
            return { ...state, isLoading: false, tenantSettings: action.payload };
        case TENANT_API_KEY_CREATE_SUCCESS:
            return { ...state, isLoading: false };
        case TENANT_API_KEY_DELETE_SUCCESS:
            return { ...state, isLoading: false, tenantSettings: { ...state.tenantSettings, apiKey: undefined } };
        case TENANT_UPLOAD_LOGO_SUCCESS:
            return { ...state, isSuccessfullyUploadedLogo: true, tenantInformation: action.payload };
        case TENANT_UPLOAD_LOGO_FAILURE:
            return { ...state, isSuccessfullyUploadedLogo: false };
        default:
            return state;
    }
};
