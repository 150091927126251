import React, { Component } from 'react';
import { Notification } from '../../models/Notification';
import {
    Avatar, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Theme, withStyles,
    WithStyles
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ReactMarkdownRenderer from '../utils/ReactMarkdownRenderer';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

const styles = (theme: Theme) => ({
    subject: {
        paddingTop: theme.spacing(1),
        fontStyle: 'italic',
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    nestedList: {
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        borderBottom: 0,
    },
    nestedListBorderBottom: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    nestedListItemText: {
        margin: 0,
    },
});

interface ComponentProps {
    notification: Notification;
    isLastItem: boolean;
}

interface State {
    open: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class NotificationListItem extends Component<Props, State> {

    state = {
        open: false,
    };

    public render() {
        const { notification, classes, isLastItem } = this.props;
        const { open } = this.state;

        const nestedListClasses = clsx(classes.nestedList, {
            [`${classes.nestedListBorderBottom}`]: isLastItem
        });

        return (<>
            <ListItem key={notification.id}
                      role={undefined}
                      dense onClick={this.handleClick}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <MailOutlineIcon fontSize="small"/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText id={notification.id}
                              primary={notification.subject}/>
                <ListItemSecondaryAction>
                    {open ? <ExpandLess onClick={this.handleClick}/> : <ExpandMore onClick={this.handleClick}/>}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={open}>
                <List component="div" disablePadding className={nestedListClasses}>
                    <ListItem>
                        <ListItemText className={classes.nestedListItemText}>
                            <ReactMarkdownRenderer value={notification.message}/>
                        </ListItemText>
                    </ListItem>
                </List>
            </Collapse>
            {!isLastItem && <Divider/>}
        </>);
    }

    private handleClick = () => {
        this.setState({ open: !this.state.open });
    };
}

export default withStyles(styles)(NotificationListItem);
