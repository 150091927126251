import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Button, createStyles, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select, Theme,
    WithStyles, withStyles
} from '@material-ui/core';

import { Sponsor } from '../../../models/Sponsor';

const styles = (theme: Theme) => createStyles({
    actionButton: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(0),
        width: 400,
    },
});

interface ComponentProps {
    sponsors: Sponsor[];
    onSave(sponsor: Sponsor): void;
    onCancel(): void;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

interface SponsorSelect {
    sponsor: string;
}

const initialValues: SponsorSelect = {
    sponsor: '',
}

class MatchplaySponsorsLinkSponsor extends Component<Props> {

    public render() {
        const { sponsors, classes, onCancel, t } = this.props
        return <Formik onSubmit={this.onSubmit} onReset={onCancel} initialValues={initialValues} sponsors={sponsors}>
            {(props: FormikProps<SponsorSelect>) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <FormControl className={classes.formControl}>
                                <InputLabel id="sponsor-label">
                                    {t('components.matchplay.settings.MatchplaySponsorsLinkSponsor.selectSponsor')}
                                </InputLabel>
                                <Select id="sponsor" name="sponsor" labelId="sponsor-label"
                                    value={props.values.sponsor} onChange={props.handleChange}>
                                    <ListSubheader>
                                        {t('components.matchplay.settings.MatchplaySponsorsLinkSponsor.listSponsorSubheader')}
                                    </ListSubheader>
                                    {sponsors.map(sponsor =>
                                        <MenuItem value={sponsor.id} key={sponsor.id}>{sponsor.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" color="primary" variant="contained"
                                className={classes.actionButton}>
                                {t('common.save')}
                            </Button>
                            <Button type="reset" variant="outlined">
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>)
            }
        </Formik>
    }

    private onSubmit = (selectedSponsor: SponsorSelect) => {
        const sponsor = selectedSponsor.sponsor && this.props.sponsors.find(value => value.id === selectedSponsor.sponsor);
        sponsor && this.props.onSave(sponsor);
    }
}

export default withStyles(styles)(withTranslation()(MatchplaySponsorsLinkSponsor));
