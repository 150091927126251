import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Redirect } from 'react-router';
import { logoutAction } from '../../store/actions/user';

interface StoreProps {
    logout(): void;
}

type Props = StoreProps;

class LogoutPage extends Component<Props> {
    public render() {
        return <Redirect to="/" />
    }

    public componentDidMount() {
        this.props.logout();
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: logoutAction(dispatch),
});

export default connect(null, mapDispatchToProps)(LogoutPage);
