import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

import { ResourceGroup, ResourceIdMap } from '../../../lib/resourceSupport';
import { Match } from '../../../models/Match';
import { MatchActivityPayloadResult } from '../../../models/MatchActivity';
import { Matchplay, MatchplayPhase } from '../../../models/Matchplay';
import { MatchplayRegistration } from '../../../models/MatchplayRegistration';
import { MessageVariant } from '../../../models/Message';
import { NewSponsor, Sponsor } from '../../../models/Sponsor';
import { User } from '../../../models/User';
import { submitMatchResultAction } from '../../../store/actions/match';
import {
    addMatchplaySponsor, commitMatchplayPhaseAction, deleteMatchplayAction, deleteMatchplaySponsor, getMatchplayAction,
    getMatchplayGroupMatchesAction, getMatchplayPlayoffMatchesAction, getMatchplayRegistrationsAction,
    signOutPlayerAction, startGroupPlayoffAction, updateMatchplayAction
} from '../../../store/actions/matchplays';
import { changeMessageAction } from '../../../store/actions/page';
import { getSponsorsAction } from '../../../store/actions/sponsors';
import MatchplayGameAdministration from './MatchplayGameAdministration';
import MatchplayGeneralSettings from './MatchplayGeneralSettings';
import MatchplayPhaseSettings from './MatchplayPhaseSettings';
import MatchplayPlayerSettings from './MatchplayPlayerSettings';
import MatchplaySponsorsSettings from './MatchplaySponsorsSettings';
import MatchplayTextsSettings from './MatchplayTextsSettings';

interface RouteParams {
    matchplayId: string | undefined;
}

interface StoreProps {
    currentUser?: User;
    matchplaysById: ResourceIdMap<Matchplay>;
    matchesByMatchplayId: ResourceGroup<Match>;
    sponsors: Sponsor[];
    registrationsByMatchplayId: ResourceGroup<MatchplayRegistration>;
    getMatchplay(id: string): Promise<void>;
    updateMatchplay(matchplay: Matchplay): Promise<void>;
    commitPhase(matchplay: Matchplay): Promise<void>;
    deleteMatchplay(matchplay: Matchplay): Promise<void>;
    changeMessage(variant: MessageVariant, text: string): Promise<void>;
    fetchPlayoffMatches(matchplayId: string): Promise<void>;
    fetchGroupMatches(matchplayId: string): Promise<void>;
    fetchSponsors(): Promise<void>;
    removeSponsor(matchplay: Matchplay, sponsor: Sponsor): Promise<void>;
    addSponsor(matchplay: Matchplay, sponsor: NewSponsor): Promise<void>;
    submitMatchResult(id: string, result: MatchActivityPayloadResult): Promise<void>;
    fetchRegistrations(matchplayId: string): Promise<void>;
    signOutPlayer(registration: MatchplayRegistration): Promise<void>;
    startGroupPlayoff(matchplay: Matchplay): Promise<void>;
}

type Props = StoreProps & RouteComponentProps<RouteParams> & WithTranslation;

class MatchplaySettingsContainer extends Component<Props> {

    public render() {
        const {
            currentUser, matchplaysById, match, matchesByMatchplayId, submitMatchResult, sponsors,
            registrationsByMatchplayId, signOutPlayer
        } = this.props;

        const matchplayId = match.params.matchplayId;

        if (!currentUser || !matchplayId || !(matchplayId in matchplaysById)) {
            return null;
        }

        const matchplay = matchplayId && matchplaysById[matchplayId];
        const registrations = registrationsByMatchplayId[matchplayId] ?? [];
        const matches = matchplayId && matchesByMatchplayId[matchplayId] ? matchesByMatchplayId[matchplayId] : [];
        if (!matchplay) {
            return null;
        }

        const showGameAdministration =
            (matchplay.phase === MatchplayPhase.EXECUTION) ||
            (matchplay.phase === MatchplayPhase.AFTERMATH);

        return (
            <>
                <MatchplayPhaseSettings matchplay={matchplay}
                    commitPhase={this.commitPhase}
                    deleteMatchplay={this.deleteMatchplay}
                    onStartGroupPlayoff={this.startGroupPlayoff} />
                {showGameAdministration && (
                    <MatchplayGameAdministration matches={matches}
                        abandonMatch={submitMatchResult} />
                )}
                <MatchplayGeneralSettings matchplay={matchplay}
                    onSubmit={this.onSubmit} />
                <MatchplayTextsSettings matchplay={matchplay}
                    onSubmit={this.onSubmit} />
                <MatchplayPlayerSettings currentUser={currentUser}
                    phase={matchplay.phase}
                    registrations={registrations}
                    signOutPlayerAction={signOutPlayer} />
                <MatchplaySponsorsSettings matchplay={matchplay}
                    sponsors={sponsors}
                    onRemoveSponsor={this.onRemoveSponsor}
                    onAddSponsor={this.onAddSponsor} />
            </>
        );
    }

    public async componentDidMount() {
        const {
            matchplaysById, getMatchplay, match, matchesByMatchplayId, fetchPlayoffMatches, fetchGroupMatches,
            fetchSponsors, fetchRegistrations
        } = this.props;
        const matchplayId = match.params.matchplayId;
        if (matchplayId) {
            const matchplay = matchplaysById[matchplayId];
            if (!matchplay) {
                try {
                    await getMatchplay(matchplayId);
                } catch {
                    this.props.history.replace('/admin');
                    return;
                }
            }

            const matches = matchesByMatchplayId[matchplayId];
            if (!matches) {
                await fetchPlayoffMatches(matchplayId);
                await fetchGroupMatches(matchplayId);
            }

            await fetchRegistrations(matchplayId);
            await fetchSponsors();
        }
    }

    private onSubmit = async (matchplay: Matchplay) => {
        const { updateMatchplay, changeMessage, t } = this.props;
        await updateMatchplay(matchplay);
        await changeMessage('success', t('components.matchplay.settings.MatchplaySettingsContainer.submitted'));
    }

    private commitPhase = async (matchplay: Matchplay) => {
        const { commitPhase, changeMessage, t } = this.props;
        await commitPhase(matchplay);
        await changeMessage('success', t('components.matchplay.settings.MatchplaySettingsContainer.registrationCompleted'));
    }

    private deleteMatchplay = async (matchplay: Matchplay) => {
        const { deleteMatchplay, changeMessage, history, t } = this.props;
        await deleteMatchplay(matchplay);
        history.replace('/admin');
        await changeMessage('success', t('components.matchplay.settings.MatchplaySettingsContainer.deleted'));
    }

    private startGroupPlayoff = async (matchplay: Matchplay) => {
        const { startGroupPlayoff, fetchPlayoffMatches } = this.props;
        await startGroupPlayoff(matchplay);
        await fetchPlayoffMatches(matchplay.id);
    }

    private onRemoveSponsor = async (sponsor: Sponsor) => {
        const matchplayId = this.props.match.params.matchplayId;
        const matchplay = matchplayId && this.props.matchplaysById[matchplayId];
        matchplay && await this.props.removeSponsor(matchplay, sponsor);
    }

    private onAddSponsor = async (sponsor: NewSponsor) => {
        const matchplayId = this.props.match.params.matchplayId;
        const matchplay = matchplayId && this.props.matchplaysById[matchplayId];
        matchplay && await this.props.addSponsor(matchplay, sponsor);
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    matchplaysById: state.matchplay.byId,
    matchesByMatchplayId: state.match.byMatchplayId,
    registrationsByMatchplayId: state.matchplay.registrationsByMatchplayId,
    sponsors: state.sponsors.items,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMatchplay: getMatchplayAction(dispatch),
    updateMatchplay: updateMatchplayAction(dispatch),
    commitPhase: commitMatchplayPhaseAction(dispatch),
    deleteMatchplay: deleteMatchplayAction(dispatch),
    changeMessage: changeMessageAction(dispatch),
    fetchPlayoffMatches: getMatchplayPlayoffMatchesAction(dispatch),
    fetchGroupMatches: getMatchplayGroupMatchesAction(dispatch),
    fetchSponsors: getSponsorsAction(dispatch),
    removeSponsor: deleteMatchplaySponsor(dispatch),
    addSponsor: addMatchplaySponsor(dispatch),
    submitMatchResult: submitMatchResultAction(dispatch),
    fetchRegistrations: getMatchplayRegistrationsAction(dispatch),
    signOutPlayer: signOutPlayerAction(dispatch),
    startGroupPlayoff: startGroupPlayoffAction(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MatchplaySettingsContainer)));
