import React from 'react';
import { useTranslation } from 'react-i18next';

import { MatchPhase } from '../../models/Match';

const MatchPhaseText = (props: { phase: MatchPhase }) => {
    const { t } = useTranslation();
    switch (props.phase) {
        case MatchPhase.PROPOSAL:
            return <>{t('components.matchplay.MatchPhaseText.proposal')}</>;
        case MatchPhase.SCHEDULED:
            return <>{t('components.matchplay.MatchPhaseText.scheduled')}</>;
        case MatchPhase.FINISHED:
            return <>{t('components.matchplay.MatchPhaseText.finished')}</>;
        case MatchPhase.WALKTHROUGH:
            return <>{t('components.matchplay.MatchPhaseText.walkthrough')}</>;
    }
};

export default MatchPhaseText;
