import React, { Component, Fragment } from 'react';

import { DisplayArea } from '../../models/Sponsor';
import LoginContainer from '../login/LoginContainer';
import SponsorsContainer from '../sponsoring/SponsorsContainer';
import ContentContainer from './ContentContainer';

class LoginPage extends Component {
    public render() {
        return (
            <Fragment>
                <ContentContainer isLoginPage={true}>
                    <LoginContainer />
                </ContentContainer>
                <SponsorsContainer displayArea={DisplayArea.LOGIN} />
            </Fragment>
        );
    }
}

export default LoginPage;
