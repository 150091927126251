import config from '../config';
import { fetchOrThrow, HeadersBuilder, mptFetch } from '../lib/mptFetch';
import { PasswordReset, PasswordResetRequest } from '../models/PasswordReset';
import { User, UserChangePasswordRequest, UserMatchStats, UserRegistration } from '../models/User';
import { Notification } from '../models/Notification';

interface LoginResponse {
    token: string
}

interface OAuthTokenResponse {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
    jti: string;
}

const TOKEN_ITEM_KEY = 'mptUserToken';

const storeAuthToken = (token: string, rememberMe?: boolean) => {
    rememberMe = rememberMe || false;
    if (rememberMe) {
        window.localStorage.setItem(TOKEN_ITEM_KEY, token);
    } else {
        window.sessionStorage.setItem(TOKEN_ITEM_KEY, token);
    }
};

const loadAuthToken = (): string | null => {
    let token = window.sessionStorage.getItem(TOKEN_ITEM_KEY);
    if (!token) {
        token = window.localStorage.getItem(TOKEN_ITEM_KEY)
    }
    return token;
};

const clearAuthToken = () => {
    window.sessionStorage.removeItem(TOKEN_ITEM_KEY);
    window.localStorage.removeItem(TOKEN_ITEM_KEY);
};

const login = async (email: string, password: string, rememberMe?: boolean): Promise<LoginResponse> => {
    const formData = new FormData();
    formData.append('scope', 'any');
    formData.append('grant_type', 'password');
    formData.append('username', email);
    formData.append('password', password);

    const response = await fetchOrThrow(`${config.baseUrl}/oauth/token`, {
        method: 'POST',
        body: formData,
        headers: new HeadersBuilder()
            .withOAuthAuthorization()
            .withTenant()
            .headers
    });
    const oauthResponse = (await response.json() as OAuthTokenResponse);
    const token = oauthResponse.access_token;
    storeAuthToken(token, rememberMe);

    return { token };
};

const restore = (): string | null => {
    return loadAuthToken();
};

const logout = () => {
    clearAuthToken();
};

const register = async (registration: UserRegistration): Promise<void> => {
    await fetchOrThrow(`${config.baseUrl}/users/register`, {
        method: 'POST',
        body: JSON.stringify(registration),
        headers: new HeadersBuilder()
            .withTenant()
            .asJSON()
            .headers
    });
};

const activate = async (token: string): Promise<void> => {
    const url = new URL(`${config.baseUrl}/users/confirm`);
    url.search = new URLSearchParams({ token }).toString();
    await fetchOrThrow(url.toString(), {
        method: 'POST',
        headers: new HeadersBuilder()
            .withTenant()
            .headers
    });
};

const requestPasswordReset = async (payload: PasswordResetRequest): Promise<void> => {
    await fetchOrThrow(`${config.baseUrl}/users/reset-password`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new HeadersBuilder()
            .withTenant()
            .asJSON()
            .headers
    });
};

const passwordReset = async (payload: PasswordReset): Promise<void> => {
    await fetchOrThrow(`${config.baseUrl}/users/confirm-password-reset`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new HeadersBuilder()
            .withTenant()
            .asJSON()
            .headers
    });
};

const getCurrentUser = async (): Promise<User> => {
    const response = await mptFetch(`${config.baseUrl}/users/me`);
    return await response.json() as User;
};

const updateCurrentUser = async (user: User): Promise<User> => {
    const response = await mptFetch(`${config.baseUrl}/users/me`, {
        method: 'PUT',
        body: JSON.stringify(user),
    });
    return await response.json() as User;
};

const deleteCurrentUser = async (): Promise<void> => {
    await mptFetch(`${config.baseUrl}/users/me`, {
        method: 'DELETE',
    });
};

const uploadImage = async (file: File): Promise<Response> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return await fetchOrThrow(`${config.baseUrl}/users/me/image`, {
        method: 'POST',
        body: formData,
        headers: new HeadersBuilder()
            .withTenant()
            .withAuthorization()
            .headers
    });
};

const deleteImage = async (): Promise<void> => {
    await fetchOrThrow(`${config.baseUrl}/users/me/image`, {
        method: 'DELETE',
        headers: new HeadersBuilder()
            .withTenant()
            .withAuthorization()
            .headers
    });
};

const getCurrentUserStats = async (): Promise<UserMatchStats> => {
    const response = await mptFetch(`${config.baseUrl}/users/me/stats`);
    return await response.json() as UserMatchStats;
};

const changePassword = async (passwords: UserChangePasswordRequest): Promise<void> => {
    await mptFetch(`${config.baseUrl}/users/me/change-password`, {
        method: 'POST',
        body: JSON.stringify(passwords),
    });
};

const getPublishedNotifications = async (): Promise<Notification[]> => {
    const response = await mptFetch(`${config.baseUrl}/users/me/notifications`);
    return await response.json() as Notification[];
};

export const userService = {
    activate,
    login,
    logout,
    register,
    restore,
    requestPasswordReset,
    passwordReset,
    getCurrentUser,
    updateCurrentUser,
    deleteCurrentUser,
    uploadImage,
    deleteImage,
    getCurrentUserStats,
    changePassword,
    getPublishedNotifications,
};
