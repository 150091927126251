import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Button, Grid, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';

import { TenantInformation } from '../../models/TenantInformation';
import { UserLogin } from '../../models/User';
import TenantLogo from '../tenant/TenantLogo';
import FormikCheckboxField from '../utils/forms/FormikCheckboxField';
import FormikTextField from '../utils/forms/FormikTextField';

const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    title: {
        textAlign: 'center' as const
    },
    subtitle: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3)
    },
    logoContainer: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3),
    },
    actionRow: {
        marginTop: theme.spacing(3)
    }
});

interface ComponentProps {
    tenantInformation: TenantInformation;
    onLogin(email: string, password: string, rememberMe: boolean): void;
}

interface State {
    email: string;
    password: string;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class LoginForm extends Component<Props, State> {

    public render() {
        const { classes, tenantInformation, t } = this.props;
        const initialValues: UserLogin = {
            email: '',
            password: '',
            rememberMe: false,
        };

        return (
            <Grid container direction="column" className={classes.root}>
                <div className={classes.logoContainer}>
                    <TenantLogo tenantInformation={tenantInformation} />
                </div>
                <Typography variant="h5" component="h1" className={classes.title}>
                    {t('components.login.LoginForm.title')}
                </Typography>
                <Typography className={classes.subtitle}>
                    {t('components.login.common.subtitle', { tenantName: tenantInformation.name })}
                </Typography>
                <Formik component={LogInInnerForm}
                    onSubmit={this.onSubmit}
                    initialValues={initialValues}
                    validationSchema={this.schema()} />
            </Grid>
        );
    }

    private onSubmit = (formData: UserLogin) => {
        this.props.onLogin(formData.email, formData.password, formData.rememberMe);
    }

    private schema = () => {
        const { t } = this.props;
        return Yup.object().shape({
            email: Yup
                .string()
                .required(t('validation.email.required'))
                .email(t('validation.email.invalid')),
            password: Yup
                .string()
                .required(t('validation.password.required')),
        });
    }
}

type LogInInnerFormProps = WithStyles<typeof styles> & FormikProps<UserLogin> & WithTranslation;
const LogInInnerForm = withStyles(styles)(withTranslation()((props: LogInInnerFormProps) => {
    const { classes, t } = props;
    const signupLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
        (linkProps: any, ref: any) => <Link innerRef={ref} to={'/signup'} {...linkProps} />,
    );
    const passwordResetLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
        (linkProps: any, ref: any) => <Link innerRef={ref} to={'/request-password-reset'} {...linkProps} />,
    );
    return (
        <Form>
            <Grid container direction="column">
                <FormikTextField name="email" type="email"
                    label={t('components.login.common.email')} margin="normal" variant="outlined" />
                <FormikTextField name="password" type="password"
                    label={t('components.login.common.password')} margin="normal" variant="outlined" />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <FormikCheckboxField name="rememberMe" value="rememberMe" label={t('components.login.LoginForm.rememberMe')} />
                    <Button component={passwordResetLink} color="default" size="small">
                        {t('components.login.LoginForm.forgotPassword')}
                    </Button>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.actionRow}>
                    <Button component={signupLink} color="primary">
                        {t('components.login.LoginForm.register')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="right">
                        {t('components.login.LoginForm.submit')}
                    </Button>
                </Box>
            </Grid>
        </Form>
    );
}));

export default withStyles(styles)(withTranslation()(LoginForm));
