import {ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Card, makeStyles, Tab, Tabs} from '@material-ui/core';

import {isGroupMatch, isPlayoffMatch, Match, MatchPhase, MatchResult, MatchType} from '../../../models/Match';
import {MatchActivityPayloadResult} from '../../../models/MatchActivity';
import {Matchplay, MatchplayPhase} from '../../../models/Matchplay';
import {User} from '../../../models/User';
import MatchActivityActionAbandon from './MatchActivityActionAbandon';
import MatchActivityActionComment from './MatchActivityActionComment';
import MatchActivityActionProposeDate from './MatchActivityActionProposeDate';
import MatchActivityActionPlayoffResult, {PlayoffFormSubmitData} from './MatchActivityActionPlayoffResult';
import MatchActivityGroupResult, {GroupFormSubmitData} from './MatchActivityActionGroupResult';
import MatchActivityActionChangeWinner, {ChangeWinnerSubmitData} from './MatchActivityActionChangeWinner';

const useStyles = makeStyles(theme => ({
  tabs: {
    backgroundColor: theme.palette.grey[300],
  },
}));

enum TabValues {
  Comment = 'COMMENT',
  ProposeDate = 'PROPOSE_DATE',
  PlayoffResult = 'PLAYOFF_RESULT',
  GroupResult = 'GROUP_RESULT',
  ResultChange = 'RESULT_CHANGE',
  GroupResultChange = 'GROUP_RESULT_CHANGE',
  ChangeWinner = 'CHANGE_WINNER',
}

interface Props {
  match: Match;
  currentUser: User;
  matchplay: Matchplay;
  onComment(comment: string): void;
  onProposeDate(date: Date): void;
  onMatchResult(submitData: PlayoffFormSubmitData): void;
  onMatchResultChange(submitData: PlayoffFormSubmitData): void;
  onGroupMatchResult(submitData: GroupFormSubmitData): void;
  onChangeWinner(submitData: ChangeWinnerSubmitData): void;
  abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

const MatchActivityActions = (props: Props) => {
  const {
    match, currentUser, matchplay,
    onComment, abandonMatch, onMatchResult, onMatchResultChange, onGroupMatchResult,
    onProposeDate, onChangeWinner,
  } = props;
  const [tabValue, setTabValue] = useState(TabValues.Comment);
  const {t} = useTranslation();
  const classes = useStyles();

  const isPlayer =
      match.playerOne?.user.email === currentUser.email ||
      match.playerTwo?.user.email === currentUser.email;
  const showTabs = currentUser.isAdmin || isPlayer;
  const showPlayoffResultTab = (match && match.phase && match.phase !== MatchPhase.FINISHED && match.type === MatchType.PLAYOFF);
  const showGroupResultTab = (match && match.phase && match.phase !== MatchPhase.FINISHED && match.type === MatchType.GROUP);
  const showProposeDateTab = match && isPlayer && !match.result;
  const showPlayoffEditTab = currentUser.isAdmin
      && isPlayoffMatch(match)
      && match.phase === MatchPhase.FINISHED
      && matchplay.phase !== MatchplayPhase.AFTERMATH
      && match.result !== MatchResult.ABANDONED_BY_ORGANIZER
      && match.result !== MatchResult.ABANDONED_BY_PLAYER;
  const showGroupEditTab = currentUser.isAdmin
      && isGroupMatch(match)
      && match.phase === MatchPhase.FINISHED
      && (matchplay.phase === MatchplayPhase.EXECUTION_GROUP ||
          matchplay.phase === MatchplayPhase.EXECUTION_GROUP_FINISHED);
  const showAbandonButton = isPlayer && match.phase !== MatchPhase.FINISHED && match.type === MatchType.PLAYOFF;
  const showChangeWinnerTab = match
      && currentUser.isAdmin
      && match.type === MatchType.PLAYOFF
      && (match.result === MatchResult.ABANDONED_BY_ORGANIZER || match.result === MatchResult.ABANDONED_BY_PLAYER);

  const onTabChange = useCallback((_event: ChangeEvent<{}>, value: TabValues) => {
    setTabValue(value);
  }, [setTabValue]);

  const onMatchResultSubmit = useCallback((submitData: PlayoffFormSubmitData) => {
    onMatchResult(submitData);
    setTabValue(TabValues.Comment);
  }, [onMatchResult, setTabValue]);

  const onProposeDateSubmit = useCallback((date: Date): void => {
    onProposeDate(date);
    setTabValue(TabValues.Comment);
  }, [onProposeDate, setTabValue]);

  const onMatchResultChangeSubmit = useCallback((submitData: PlayoffFormSubmitData) => {
    onMatchResultChange(submitData);
    setTabValue(TabValues.Comment);
  }, [onMatchResultChange, setTabValue]);

  const onGroupMatchResultSubmit = useCallback((submitData: GroupFormSubmitData) => {
    onGroupMatchResult(submitData);
    setTabValue(TabValues.Comment);
  }, [onGroupMatchResult, setTabValue]);

  const onChangeWinnerSubmit = useCallback((submitData: ChangeWinnerSubmitData) => {
    onChangeWinner(submitData);
    setTabValue(TabValues.Comment);
  }, [onChangeWinner, setTabValue]);

  if (!showTabs) {
    // TODO: Find a better way of showing "Verlauf"...
    return (
        <Card>
          <Tabs>
            <Tab label={t('components.match.activities.MatchActivityActions.tabHistory')}/>
          </Tabs>
        </Card>
    );
  }

  return (
      <Card>
        <Tabs
            value={tabValue}
            onChange={onTabChange}
            variant="scrollable"
            className={classes.tabs}>
          <Tab label={t('components.match.activities.MatchActivityActions.tabComment')}
               value={TabValues.Comment}/>
          {showProposeDateTab &&
            <Tab label={t('components.match.activities.MatchActivityActions.tabProposeDate')}
                 value={TabValues.ProposeDate}/>}
          {showPlayoffResultTab &&
            <Tab label={t('components.match.activities.MatchActivityActions.tabResult')}
                 value={TabValues.PlayoffResult}/>}
          {showGroupResultTab &&
            <Tab label={t('components.match.activities.MatchActivityActions.tabResult')}
                 value={TabValues.GroupResult}/>}
          {showPlayoffEditTab &&
            <Tab label={t('components.match.activities.MatchActivityActions.tabResultChange')}
                 value={TabValues.ResultChange}/>}
          {showGroupEditTab &&
            <Tab label={t('components.match.activities.MatchActivityActions.tabResultChange')}
                 value={TabValues.GroupResultChange}/>}
          {showChangeWinnerTab &&
            <Tab label={t('components.match.activities.MatchActivityActions.tabChangeWinner')}
                 value={TabValues.ChangeWinner}/>}
          {showAbandonButton &&
            <MatchActivityActionAbandon
              match={match}
              abandonMatch={abandonMatch}/>}
        </Tabs>
        {tabValue === TabValues.Comment &&
          <MatchActivityActionComment onSubmit={onComment}/>}
        {tabValue === TabValues.ProposeDate &&
          <MatchActivityActionProposeDate onSubmit={onProposeDateSubmit}/>}
        {tabValue === TabValues.GroupResult &&
          <MatchActivityGroupResult match={match} matchplay={matchplay} onSubmit={onGroupMatchResultSubmit}/>}
        {tabValue === TabValues.PlayoffResult &&
          <MatchActivityActionPlayoffResult match={match} onSubmit={onMatchResultSubmit}/>}
        {tabValue === TabValues.ResultChange &&
          <MatchActivityActionPlayoffResult match={match} changeResult={true} onSubmit={onMatchResultChangeSubmit}/>}
        {tabValue === TabValues.GroupResultChange &&
          <MatchActivityGroupResult match={match} matchplay={matchplay} changeResult={true}
                                    onSubmit={onGroupMatchResultSubmit}/>}
        {tabValue === TabValues.ChangeWinner &&
          <MatchActivityActionChangeWinner match={match} onSubmit={onChangeWinnerSubmit}/>}
      </Card>
  );
}

export default MatchActivityActions;
