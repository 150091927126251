import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Theme, withStyles, WithStyles } from '@material-ui/core';

import { Match } from '../../../models/Match';
import {
    MatchActivity, MatchActivityPayloadProposeDate, MatchActivityPayloadResult, MatchActivityType
} from '../../../models/MatchActivity';
import { Matchplay } from '../../../models/Matchplay';
import { User } from '../../../models/User';
import { GroupFormSubmitData } from './MatchActivityActionGroupResult';
import { PlayoffFormSubmitData } from './MatchActivityActionPlayoffResult';
import MatchActivityActions from './MatchActivityActions';
import MatchActivityItem from './MatchActivityItem';
import {ChangeWinnerSubmitData} from './MatchActivityActionChangeWinner';

const styles = (theme: Theme) => ({
    spacer: {
        height: theme.spacing(3),
        borderLeftColor: theme.palette.grey[500],
        borderLeftWidth: 2,
        borderLeftStyle: 'solid' as 'solid',
        marginTop: 3,
        marginBottom: 2,
        marginLeft: theme.spacing(2) + 40 / 2,
    }
});

export interface MatchActivitiesProps {
    currentUser: User;
    match: Match;
    matchplay: Matchplay;
    items: MatchActivity[];
    isMatchPlayer: boolean;
    isScheduled: boolean;
    onComment(comment: string): void;
    onProposeDate(date: Date): void;
    onAcceptDate(): void;
    onMatchResult(submitData: PlayoffFormSubmitData): void;
    onMatchResultChange(submitData: PlayoffFormSubmitData): void;
    onGroupMatchResult(submitData: GroupFormSubmitData): void;
    onChangeWinner(submitData: ChangeWinnerSubmitData): void;
    abandonMatch(id: string, result: MatchActivityPayloadResult): void;
}

class MatchActivities extends Component<MatchActivitiesProps> {

    public render() {
        const { match, items, currentUser, isMatchPlayer, isScheduled, matchplay } = this.props;
        items.sort((a: MatchActivity, b: MatchActivity) => {
            if (a.createdAt > b.createdAt) {
                return -1;
            }
            if (a.createdAt < b.createdAt) {
                return 1;
            }
            return 0;
        });

        const indexOfType: { [k in MatchActivityType]: number } = {
            ACCEPT_PROPOSAL: 0,
            COMMENT: 0,
            MATCH_ABANDONED: 0,
            MATCH_CREATED: 0,
            MATCH_RESULT: 0,
            PROPOSE_DATE: 0,
        };

        return (
            <React.Fragment>
                <MatchActivityActions
                    match={match}
                    matchplay={matchplay}
                    currentUser={currentUser}
                    onComment={this.props.onComment}
                    onProposeDate={this.props.onProposeDate}
                    onMatchResult={this.props.onMatchResult}
                    onMatchResultChange={this.props.onMatchResultChange}
                    onGroupMatchResult={this.props.onGroupMatchResult}
                    onChangeWinner={this.props.onChangeWinner}
                    abandonMatch={this.props.abandonMatch} />
                {items.map((x) => {
                    return (
                        <React.Fragment key={uuidv4()}>
                            <MatchActivitySpacer />
                            <MatchActivityItem
                                match={match}
                                item={x}
                                indexOfType={indexOfType[x.type]++}
                                currentUser={currentUser}
                                isMatchPlayer={isMatchPlayer}
                                isCurrentDateProposal={
                                    x.type === MatchActivityType.PROPOSE_DATE
                                    && (x.payload as MatchActivityPayloadProposeDate).date === match.scheduledAt}
                                isScheduled={isScheduled}
                                onActionAcceptProposedDate={this.props.onAcceptDate}
                                onProposeDate={this.props.onProposeDate} />
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
}

const MatchActivitySpacer = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (<div className={props.classes.spacer} />);
});

export default MatchActivities;
