import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { NewSponsor, Sponsor } from '../../models/Sponsor';
import { createSponsorAction, deleteSponsorAction, getSponsorsAction } from '../../store/actions/sponsors';

import AdminSponsors from './AdminSponsors';

interface StoreProps {
    sponsors: Sponsor[];
    createSponsor(sponsor: NewSponsor): void;
    deleteSponsor(sponsor: Sponsor): void;
    getSponsors(): void;
}

type Props = StoreProps;

class AdminSponsorsContainer extends Component<Props> {
    public render() {
        const { sponsors, createSponsor, deleteSponsor } = this.props;
        return (
            <AdminSponsors sponsors={sponsors}
                           createSponsor={createSponsor}
                           deleteSponsor={deleteSponsor}/>
        );
    }

    public componentDidMount() {
        this.props.getSponsors();
    }
}

const mapStateToProps = (state: any) => ({
    sponsors: state.sponsors.items,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createSponsor: createSponsorAction(dispatch),
    deleteSponsor: deleteSponsorAction(dispatch),
    getSponsors: getSponsorsAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSponsorsContainer);
