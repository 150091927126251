import React from 'react';
import { useTranslation } from 'react-i18next';

import { MatchplayPhase } from '../../models/Matchplay';

const MatchplayPhaseText = (props: { phase: MatchplayPhase }) => {
    const { t } = useTranslation();
    switch (props.phase) {
        case MatchplayPhase.INITIALIZATION:
            return <>{t('components.matchplay.MatchplayPhaseText.initialization')}</>;
        case MatchplayPhase.REGISTRATION_OPEN:
            return <>{t('components.matchplay.MatchplayPhaseText.registrationOpen')}</>;
        case MatchplayPhase.REGISTRATION_CLOSED:
            return <>{t('components.matchplay.MatchplayPhaseText.registrationClosed')}</>;
        case MatchplayPhase.EXECUTION_GROUP:
            return <>{t('components.matchplay.MatchplayPhaseText.executionGroup')}</>;
        case MatchplayPhase.EXECUTION_GROUP_FINISHED:
            return <>{t('components.matchplay.MatchplayPhaseText.executionGroupFinished')}</>;
        case MatchplayPhase.EXECUTION:
            return <>{t('components.matchplay.MatchplayPhaseText.execution')}</>;
        case MatchplayPhase.AFTERMATH:
            return <>{t('components.matchplay.MatchplayPhaseText.aftermath')}</>;
    }
}

export default MatchplayPhaseText;
