import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { User } from '../../../models/User';
import {
    disableUserAction, downgradeUserAction, enableUserAction, getUsersAction, promoteUserAction
} from '../../../store/actions/usermanagement';
import UserManagement from './UserManagement';

interface StoreProps {
    users: User[];
    getUsers(): void;
    enableUser(email: string): void;
    disableUser(email: string): void;
    promoteUser(email: string): void;
    downgradeUser(email: string): void;
}

type Props = StoreProps;

interface State {
    search: string;
}

class UserManagementContainer extends Component<Props, State> {
    readonly state: State = { search: '' };

    public render() {
        const users = this.filteredUsers();
        return (
            <UserManagement
                users={users}
                onToggleUserEnable={this.onToggleUserEnable}
                onChangeRole={this.onUserChangeRole}
                onSearch={this.onSearch} />
        );
    }

    public componentDidMount() {
        const { getUsers } = this.props;
        getUsers();
    }

    private onToggleUserEnable = (user: User) => {
        const { enableUser, disableUser } = this.props;
        (user.accountEnabled)
            ? disableUser(user.email)
            : enableUser(user.email);
    }

    private onUserChangeRole = (user: User, isAdmin: boolean) => {
        const { promoteUser, downgradeUser } = this.props;
        (isAdmin)
            ? promoteUser(user.email)
            : downgradeUser(user.email);
    }

    private onSearch = (search: string) => {
        this.setState({ search });
    }

    private filteredUsers = () => {
        const { users } = this.props;
        const { search } = this.state;
        if (!search) {
            return users;
        }

        const lowerSearch = search.toLocaleLowerCase();
        return users.filter(user =>
            user.email.toLocaleLowerCase().includes(lowerSearch) ||
            user.name.toLocaleLowerCase().includes(lowerSearch));
    }
}

const mapStateToProps = (state: any) => ({
    users: state.userManagement.items,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUsers: getUsersAction(dispatch),
    enableUser: enableUserAction(dispatch),
    disableUser: disableUserAction(dispatch),
    promoteUser: promoteUserAction(dispatch),
    downgradeUser: downgradeUserAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementContainer);
