import React, { Component, FC, ReactNode } from 'react';
import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { Field, FieldProps, getIn } from 'formik';

interface Props {
    label: ReactNode;
}

class FormikCheckboxField extends Component<Props & CheckboxProps> {

    public render() {
        return (
            <Field component={InnerCheckboxField} {...this.props} />
        );
    }
}

const InnerCheckboxField: FC<Props & FieldProps & CheckboxProps> = (props) => {
    const { value, field, form, label, className } = props;
    const formError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && Boolean(formError);
    return (
        <FormControl error={showError} className={className}>
            <FormControlLabel label={label} control={<Checkbox {...props} {...field} value={value}/>}/>
            {showError && <FormHelperText error={true}>{formError}</FormHelperText>}
        </FormControl>
    )
}

export default FormikCheckboxField;
