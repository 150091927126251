import React, { Component } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Box, Button, createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import { User } from '../../models/User';
import FormikTextField from '../utils/forms/FormikTextField';
import Section from '../utils/Section';

const styles = (theme: Theme) => createStyles({
    actions: {
        marginTop: theme.spacing(2)
    },
});

interface ComponentProps {
    currentUser: User;
    onSubmit(user: User): void;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class UserDetails extends Component<Props> {

    private schema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Dein Name ist zu kurz')
            .max(50, 'Dein Name ist zu lang')
            .required('Dieses Feld muss ausgefüllt sein'),
        club: Yup.string()
            .max(250, 'Dein Heimatclub kann nur 250 Zeichen lang sein'),
        status: Yup.string()
            .max(250, 'Dein Status kann nur 250 Zeichen lang sein'),
        hcp: Yup.number()
            .transform((_, value) => {
                const hcp = String(value);
                if (hcp.includes('.')) {
                    return +hcp;
                }
                return +hcp.replace(/,/, '.');
            })
            .min(-5, 'Dein Handicap muss zwischen -5 und 54 liegen')
            .max(54, 'Dein Handicap muss zwischen -5 und 54 liegen')
            .required('Dieses Feld muss ausgefüllt sein'),
    });

    public render() {
        const { currentUser } = this.props;
        return (
            <Section title="Mein Konto">
                <Formik component={UserDetailsForm} validationSchema={this.schema} initialValues={currentUser}
                        onSubmit={this.onSubmit}/>
            </Section>
        );
    }

    private onSubmit = async (user: User) => {
        const hcp = String(user.hcp);
        user.hcp = +hcp.replace(/,/, '.');
        await this.props.onSubmit(user);
    }
}

type UserDetailsFormProps = WithStyles<typeof styles> & FormikProps<User>;

const UserDetailsForm = withStyles(styles)((props: UserDetailsFormProps) => {
    const { classes } = props;
    return (
        <Form>
            <FormikTextField type="text" name="email" label="Email" margin="normal" variant="outlined" fullWidth
                             disabled/>
            <FormikTextField type="text" name="name" label="Name" margin="normal" variant="outlined" fullWidth/>
            <FormikTextField type="text" name="club" label="Heimatclub" margin="normal" variant="outlined" fullWidth/>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <FormikTextField type="text" name="status" label="Status" margin="normal" variant="outlined"
                                     fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormikTextField type="text" inputMode="numeric" inputProps={{ pattern: '[-0-9.,]*' }}
                                     name="hcp" label="Handicap" margin="normal" variant="outlined"
                                     fullWidth/>
                </Grid>
            </Grid>
            <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.actions}>
                <Button variant="contained" color="primary" type="submit" className="right"
                        disabled={props.isSubmitting}>
                    Speichern
                </Button>
            </Box>
        </Form>
    );
});

export default withStyles(styles)(UserDetails);
