import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import NotificationPage from './components/layout/NotificationPage';
import FreshchatWidget from './components/freshchat/FreshchatWidget';
import AccountPage from './components/layout/AccountPage';
import ActivatePage from './components/layout/ActivatePage';
import AdminPage from './components/layout/AdminPage';
import AppHomePage from './components/layout/AppHomePage';
import Footer from './components/layout/Footer';
import LoginPage from './components/layout/LoginPage';
import LogoutPage from './components/layout/LogoutPage';
import MaintenancePage from './components/layout/MaintenancePage';
import MatchPage from './components/layout/MatchPage';
import MatchplayListPage from './components/layout/MatchplayListPage';
import MatchplayPage from './components/layout/MatchplayPage';
import MatchplayPlayoffPage from './components/layout/MatchplayPlayoffPage';
import MatchplayPreparationPage from './components/layout/MatchplayPreparationPage';
import MatchplayRulesPage from './components/layout/MatchplayRulesPage';
import MatchplaySettingsPage from './components/layout/MatchplaySettingsPage';
import ConfirmPasswordResetPage from './components/layout/PasswordResetPage';
import RedirectPage from './components/layout/RedirectPage';
import RequestPasswordResetPage from './components/layout/RequestPasswordResetPage';
import SignupPage from './components/layout/SignupPage';
import UserManagementPage from './components/layout/UserManagementPage';
import WebPluginsPage from './components/layout/WebPluginsPage';
import MessageContainer from './components/message/MessageContainer';
import AdminRoute from './components/utils/AdminRoute';
import Analytics from './components/utils/Analytics';
import AuthenticatedRoute from './components/utils/AuthenticatedRoute';
import CookieConsent from './components/utils/CookieConsent';
import MptCssBaseline from './components/utils/MptCssBaseline';
import NonAuthenticatedRoute from './components/utils/NonAuthenticatedRoute';
import PageLoadingAnimationContainer from './components/utils/PageLoadingAnimationContainer';
import TenantContext from './components/utils/TenantContext';
import TryRestoreAuthentication from './components/utils/TryRestoreAuthentication';
import i18n from './localization/i18n';
import store from './store';
import theme from './theme';

const routes = (
    <Switch>
        <NonAuthenticatedRoute path="/login" component={LoginPage} />
        <NonAuthenticatedRoute path="/signup" component={SignupPage} />
        <NonAuthenticatedRoute path="/activate" component={ActivatePage} />
        <NonAuthenticatedRoute path="/request-password-reset" component={RequestPasswordResetPage} />
        <NonAuthenticatedRoute path="/password-reset" component={ConfirmPasswordResetPage} />
        <Route path="/redirect" component={RedirectPage} />
        <Route path="/logout" component={LogoutPage} />
        <AuthenticatedRoute path="/account" component={AccountPage} />
        <AuthenticatedRoute path="/matches/:matchId" component={MatchPage} />
        <AuthenticatedRoute path="/matchplays/:matchplayId/playoff" component={MatchplayPlayoffPage} />
        <AuthenticatedRoute path="/matchplays/:matchplayId/rules" component={MatchplayRulesPage} />
        <AuthenticatedRoute path="/matchplays/:matchplayId" component={MatchplayPage} />
        <AuthenticatedRoute path="/matchplays" component={MatchplayListPage} />
        <AdminRoute path="/admin/matchplays/:matchplayId/preparation" component={MatchplayPreparationPage} />
        <AdminRoute path="/admin/matchplays/:matchplayId" component={MatchplaySettingsPage} />
        <AdminRoute path="/admin/users" component={UserManagementPage} />
        <AdminRoute path="/admin/plugins" component={WebPluginsPage} />
        <AdminRoute path="/admin/notifications" component={NotificationPage} />
        <AdminRoute path="/admin" component={AdminPage} />
        <AuthenticatedRoute exact path="/" component={AppHomePage} />
        <Redirect to="/" />
    </Switch>
);

const App = () => (
    <DndProvider backend={HTML5Backend}>
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ReduxProvider store={store}>
                        <Router>
                            <CssBaseline />
                            <MptCssBaseline />
                            <CookieConsent />
                            <PageLoadingAnimationContainer />
                            <TenantContext maintenanceComponent={MaintenancePage}>
                                <Analytics />
                                <TryRestoreAuthentication>
                                    {routes}
                                    <MessageContainer />
                                    <Footer />
                                </TryRestoreAuthentication>
                            </TenantContext>
                            <FreshchatWidget />
                        </Router>
                    </ReduxProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </I18nextProvider>
    </DndProvider>
);

export default App;
