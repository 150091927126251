import React, { ChangeEvent, Component } from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

import {
    Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, ListItemIcon, makeStyles, Menu,
    MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, withStyles,
    WithStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SortIcon from '@material-ui/icons/Sort';
import { Alert } from '@material-ui/lab';

import { MatchEncounter } from '../../../../models/MatchEncounter';
import { Matchplay, MatchplayPhase } from '../../../../models/Matchplay';
import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';
import { Player } from '../../../../models/Player';
import { User } from '../../../../models/User';
import Section from '../../../utils/Section';
import MatchplayPlayerList from '../../dashboard/MatchplayPlayerList';
import PreparationItemCell from './PreparationItemCell';
import PreparationItemDragLayer from './PreparationItemDragLayer';

const styles = (theme: Theme) => ({
    sectionHeader: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(),
    },
    sortActionMenu: {
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: theme.spacing(2),
        }
    },
    fillWithBotOption: {
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        '& > label': {
            marginRight: 0,
        }
    }
});
const useStyles = makeStyles(styles);

interface ComponentProps {
    currentUser: User;
    encounters: MatchEncounter[];
    matchplay: Matchplay;
    usersNotPlaying: MatchplayRegistration[];
    isPlayable: boolean;
    shouldFillWithBots: boolean;

    shuffle(): void;
    firstComeFirstServe(): void;
    onFillWithBots(shouldFillWithBots: boolean): void;
    swapPlayers(player1: Player, player2: Player): void;

    commitPhase(): void;
}

interface State {
    isSubmitting: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class MatchplayPlayoffPreparation extends Component<Props, State> {

    state = {
        isSubmitting: false,
    };

    public render() {
        const {
            currentUser, encounters, usersNotPlaying, isPlayable, shouldFillWithBots,
            matchplay, classes, shuffle, firstComeFirstServe, swapPlayers, t
        } = this.props;
        const { isSubmitting } = this.state;

        const showUsersNotPlaying = (usersNotPlaying.length > 0);
        const registrationsRequiredForSelectedGame = matchplay.playoff.numberOfPlayers - matchplay.numberRegistrations;
        const showTooLessRegistrationsWarning = registrationsRequiredForSelectedGame > 0;
        const saveButtonEnabled = !isSubmitting && (!showTooLessRegistrationsWarning || shouldFillWithBots);

        const renderItem = (encounter: MatchEncounter, index: number) => {
            const { playerOne, playerTwo } = encounter;
            return (
                <TableRow key={`${playerOne.user.email}--${playerTwo.user.email}`}>
                    <TableCell>{index + 1}</TableCell>
                    <PreparationItemCell align={'right'} player={playerOne} swapPlayers={swapPlayers} />
                    <TableCell align={'center'}>:</TableCell>
                    <PreparationItemCell align={'left'} player={playerTwo} swapPlayers={swapPlayers} />
                </TableRow>
            );
        };

        return (
            <>
                {showTooLessRegistrationsWarning &&
                    <Alert severity={'warning'} className={classes.sectionHeader}>
                        {t('components.matchplay.preparation.playoff.MatchplayPlayoffPreparation.alert', {
                            missingPlayers: registrationsRequiredForSelectedGame,
                            maxPlayers: matchplay.playoff.numberOfPlayers
                        })}
                        {isPlayable &&
                            <FormGroup row className={classes.fillWithBotOption}>
                                <FormControlLabel
                                    control={<Checkbox onChange={this.onChangeFillWithBots} />}
                                    label={<Typography variant="body2">
                                        {t('components.matchplay.preparation.playoff.MatchplayPlayoffPreparation.fillWithBotOption')}
                                    </Typography>}
                                    checked={shouldFillWithBots}
                                />
                            </FormGroup>}
                    </Alert>}
                <Section title={`${t('components.matchplay.preparation.playoff.MatchplayPlayoffPreparation.title')}`}
                    actions={(
                        <>
                            <SortActionsButton onShuffle={shuffle} onFirstComeFirstServe={firstComeFirstServe} />
                            <Button onClick={this.startMatchplay} variant="contained" color="secondary"
                                disabled={!saveButtonEnabled}>
                                {t('components.matchplay.preparation.common.actionStart')}
                            </Button>
                        </>
                    )}>
                    <PreparationItemDragLayer />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '6%' }}>#</TableCell>
                                <TableCell style={{ width: '41%' }} align={'right'}>
                                    {t('components.matchplay.common.playerOne')}
                                </TableCell>
                                <TableCell style={{ width: '12%' }} />
                                <TableCell style={{ width: '41%' }} align={'left'}>
                                    {t('components.matchplay.common.playerTwo')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {encounters.map(renderItem)}
                        </TableBody>
                    </Table>
                </Section>
                {showUsersNotPlaying &&
                    <Section title={`${t('components.matchplay.preparation.playoff.MatchplayPlayoffPreparation.playersNotConsidered')}`}>
                        <MatchplayPlayerList currentUser={currentUser} registrations={usersNotPlaying}
                            phase={MatchplayPhase.REGISTRATION_CLOSED} hideRegistrationDate={true}
                            signOutPlayerAction={() => { /* do nothing */ }} />
                    </Section>}
            </>
        );
    }

    private startMatchplay = async () => {
        const { commitPhase } = this.props;
        this.setState({ isSubmitting: true });
        await commitPhase();
        this.setState({ isSubmitting: false });
    };

    private onChangeFillWithBots = (e: ChangeEvent<HTMLInputElement>) => {
        const { onFillWithBots } = this.props;
        onFillWithBots(e.target.checked);
    }
}

interface SortActionsButtonProps {
    onShuffle(): void;
    onFirstComeFirstServe(): void;
}

const SortActionsButton = ({ onShuffle, onFirstComeFirstServe }: SortActionsButtonProps) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const menuAnchorRef = React.useRef<HTMLDivElement>(null);

    const closeMenu = () => setOpen(false);
    const toggleMenu = () => setOpen((prevOpen) => !prevOpen);
    const menuShuffle = () => {
        onShuffle();
        closeMenu();
    };
    const menuByRegistrationDate = () => {
        onFirstComeFirstServe();
        closeMenu();
    };

    return (
        <>
            <ButtonGroup ref={menuAnchorRef} variant="outlined">
                <Button onClick={onShuffle} variant="outlined" color="secondary">
                    {t('components.matchplay.preparation.common.shuffle')}
                </Button>
                <Button size="small" onClick={toggleMenu} aria-controls="sort-actions-menu" aria-haspopup="true">
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Menu
                id="sort-actions-menu"
                className={classes.sortActionMenu}
                anchorEl={menuAnchorRef.current}
                getContentAnchorEl={null}
                open={open}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem onClick={menuShuffle}>
                    <ListItemIcon>
                        <ShuffleIcon fontSize="small" />
                    </ListItemIcon>
                    {t('components.matchplay.preparation.common.shuffle')}
                </MenuItem>
                <MenuItem onClick={menuByRegistrationDate}>
                    <ListItemIcon>
                        <SortIcon fontSize="small" />
                    </ListItemIcon>
                    {t('components.matchplay.preparation.common.byRegistrationDate')}
                </MenuItem>
            </Menu>
        </>
    );
}

export default withStyles(styles)(withTranslation()(MatchplayPlayoffPreparation));
