import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';

import { MatchplayPhase, MatchplayVariant } from '../../../models/Matchplay';

const useStyles = makeStyles((theme: Theme) => ({
    phaseContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        gap: 3,
    },
    phaseItem: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        backgroundColor: theme.palette.action.disabledBackground,
    },
    phaseItemActive: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[6],
    },
    phaseItemDone: {
        backgroundColor: theme.palette.primary.light,
    },
    phaseChannel: {
        flexGrow: 1,
        backgroundColor: theme.palette.action.disabledBackground,
        height: 2,
    },
    phaseChannelDone: {
        backgroundColor: theme.palette.primary.light,
    }
}));

interface Props {
    phase: MatchplayPhase;
    variant?: MatchplayVariant;
}

const MatchplayPhases = (props: Props) => {
    const { phase } = props;
    const { t } = useTranslation();

    let title = '';
    let description;
    switch (phase) {
        case MatchplayPhase.INITIALIZATION:
            title = t('components.matchplay.settings.MatchplayPhases.initializationTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.initializationDescription')}</span>;
            break;
        case MatchplayPhase.REGISTRATION_OPEN:
            title = t('components.matchplay.settings.MatchplayPhases.registrationOpenTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.registrationOpenDescription')}</span>;
            break;
        case MatchplayPhase.REGISTRATION_CLOSED:
            title = t('components.matchplay.settings.MatchplayPhases.registrationClosedTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.registrationClosedDescription')}</span>;
            break;
        case MatchplayPhase.EXECUTION_GROUP:
            title = t('components.matchplay.settings.MatchplayPhases.executionGroupTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.executionGroupDescription')}</span>;
            break;
        case MatchplayPhase.EXECUTION_GROUP_FINISHED:
            title = t('components.matchplay.settings.MatchplayPhases.executionGroupFinishedTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.executionGroupFinishedDescription')}</span>;
            break;
        case MatchplayPhase.EXECUTION:
            title = t('components.matchplay.settings.MatchplayPhases.executionTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.executionDescription')}</span>;
            break;
        case MatchplayPhase.AFTERMATH:
            title = t('components.matchplay.settings.MatchplayPhases.aftermathTitle');
            description = <span>{t('components.matchplay.settings.MatchplayPhases.aftermathDescription')}</span>;
            break;
    }
    return (
        <div>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
            <PhaseVisualization {...props} />
        </div>
    );
}

interface StepProps {
    active?: boolean;
    completed?: boolean;
    isFirst?: boolean;
    tooltip?: string;
}

const Step = ({ active, completed, isFirst, tooltip }: StepProps) => {
    const classes = useStyles();
    const className = clsx(classes.phaseItem, {
        [classes.phaseItemActive]: active,
        [classes.phaseItemDone]: completed,
    });
    const channelClassName = clsx(classes.phaseChannel, {
        [classes.phaseChannelDone]: completed || active,
    });
    return (
        <>
            {!isFirst && (
                <span className={channelClassName} />
            )}
            <Tooltip title={tooltip ?? ''}>
                <span className={className} />
            </Tooltip>
        </>
    );
};

const PhaseVisualization = ({ phase, variant }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const steps = useMemo(() => ([
        {
            phase: MatchplayPhase.INITIALIZATION,
            title: t('components.matchplay.settings.MatchplayPhases.initializationTitle'),
        },
        {
            phase: MatchplayPhase.REGISTRATION_OPEN,
            title: t('components.matchplay.settings.MatchplayPhases.registrationOpenTitle'),
        },
        {
            phase: MatchplayPhase.REGISTRATION_CLOSED,
            title: t('components.matchplay.settings.MatchplayPhases.registrationClosedTitle'),
        },
        {
            phase: MatchplayPhase.EXECUTION_GROUP,
            title: t('components.matchplay.settings.MatchplayPhases.executionGroupTitle'),
            hidden: variant !== MatchplayVariant.GROUP,
        },
        {
            phase: MatchplayPhase.EXECUTION_GROUP_FINISHED,
            title: t('components.matchplay.settings.MatchplayPhases.executionGroupFinishedTitle'),
            hidden: variant !== MatchplayVariant.GROUP,
        },
        {
            phase: MatchplayPhase.EXECUTION,
            title: t('components.matchplay.settings.MatchplayPhases.executionTitle'),
        },
        {
            phase: MatchplayPhase.AFTERMATH,
            title: t('components.matchplay.settings.MatchplayPhases.aftermathTitle'),
        },
    ].filter(x => !x.hidden)), [t, variant]);

    const activeStep = steps.findIndex(x => x.phase === phase);

    return (
        <div className={classes.phaseContainer}>
            {steps.map((step, idx, _arr) => (
                <Step
                    key={step.phase}
                    isFirst={idx === 0}
                    tooltip={step.title}
                    active={activeStep === idx}
                    completed={activeStep > idx}
                />
            ))}
        </div>
    );
};

export default MatchplayPhases;
