import React, { ChangeEvent, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Button, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { TenantInformation } from '../../models/TenantInformation';
import Section from '../utils/Section';
import LogoPreview from './LogoPreview';

const styles = (theme: Theme) => ({
    fileInput: {
        display: 'none'
    },
    fileSelectButton: {
        flexGrow: 1,
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
});

interface ComponentProps {
    tenantInformation: TenantInformation;
    uploadLogo(file: File): void;
}

interface State {
    file?: File,
    preview?: string,
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class AdminTenantLogo extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const { tenantInformation, classes, t } = this.props;
        const { preview } = this.state;
        const isValidFileSelected = this.checkFile();

        return (
            <Section title={t('components.tenant.AdminTenantLogo.title')} id="admin-logo">
                <Grid container spacing={3}>
                    <Grid item>
                        <LogoPreview
                            url={preview ?? tenantInformation.logoUrl}
                            alt={tenantInformation.name}
                            useBorder={true} />
                    </Grid>
                    <Grid item>
                        <input
                            id="logo-file-input"
                            type="file"
                            accept="image/png,image/jpeg"
                            className={classes.fileInput}
                            onChange={this.onChange}
                        />
                        <Box display="flex" height="100%" justifyContent="space-between" flexDirection="column">
                            <label htmlFor="logo-file-input" className={classes.fileSelectButton}>
                                <Button variant="outlined" startIcon={<PhotoCamera />} component="span">
                                    {t('components.tenant.AdminTenantLogo.selectLogo')}
                                </Button>
                            </label>
                            <Button type="submit" disabled={!isValidFileSelected} onClick={this.onSubmit}
                                color="primary" variant="contained">
                                {t('common.save')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Section>
        );
    }

    private onSubmit = () => {
        if (this.checkFile() && this.state.file) {
            this.props.uploadLogo(this.state.file);
            this.setState({ file: undefined });
        }
    };

    private onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget && event.currentTarget.files && event.currentTarget.files.length > 0) {
            const file = event.currentTarget.files[0];
            const preview = await this.preview(file);
            this.setState({ file, preview });
        }
    };

    private preview = async (file: File): Promise<string | undefined> => {
        if (file) {
            return await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = reject;
                reader.onload = () => resolve(reader.result as string);
                reader.readAsDataURL(file);
            });
        }
    };

    private checkFile = () => {
        const { file } = this.state;
        if (!file) {
            return false;
        }
        const extension = file.name.split('.').pop();
        return extension === 'png' || extension === 'jpg' || extension === 'jpeg';
    }
}

export default withStyles(styles)(withTranslation()(AdminTenantLogo));
