import { Component } from 'react';

import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import AdminMatchplaysContainer from '../tenant/AdminMatchplaysContainer';
import AdminSponsorsContainer from '../tenant/AdminSponsorsContainer';
import AdminTenantInformationContainer from '../tenant/AdminTenantInformationContainer';
import ContentContainer from './ContentContainer';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import SideMenu from '../navigation/SideMenu';
import AdminMenu from '../tenant/AdminMenu';

class AdminPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer />
                <BreadcrumbsContainer />
                <SideMenu>
                    <AdminMenu />
                </SideMenu>
                <AdminMatchplaysContainer />
                <AdminSponsorsContainer />
                <AdminTenantInformationContainer />
            </ContentContainer>
        );
    }
}

export default AdminPage;
