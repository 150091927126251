import { AnyAction } from 'redux';

import { createResourceGroup, ResourceGroup } from '../../lib/resourceSupport';
import { User } from '../../models/User';

export const GET_USERS_REQUEST = 'usermanagement/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'usermanagement/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'usermanagement/GET_USERS_FAILURE';
export const ENABLE_USER_REQUEST = 'usermanagement/ENABLE_USER_REQUEST';
export const ENABLE_USER_SUCCESS = 'usermanagement/ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'usermanagement/ENABLE_USER_FAILURE';
export const DISABLE_USER_REQUEST = 'usermanagement/DISABLE_USER_REQUEST';
export const DISABLE_USER_SUCCESS = 'usermanagement/DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILURE = 'usermanagement/DISABLE_USER_FAILURE';
export const PROMOTE_USER_REQUEST = 'usermanagement/PROMOTE_USER_REQUEST';
export const PROMOTE_USER_SUCCESS = 'usermanagement/PROMOTE_USER_SUCCESS';
export const PROMOTE_USER_FAILURE = 'usermanagement/PROMOTE_USER_FAILURE';
export const DOWNGRADE_USER_REQUEST = 'usermanagement/DOWNGRADE_USER_REQUEST';
export const DOWNGRADE_USER_SUCCESS = 'usermanagement/DOWNGRADE_USER_SUCCESS';
export const DOWNGRADE_USER_FAILURE = 'usermanagement/DOWNGRADE_USER_FAILURE';

interface UserManagementState {
    isLoading: boolean;
    items: User[];
    byEmail: ResourceGroup<User>;
}

const initialState: UserManagementState = {
    isLoading: false,
    items: [],
    byEmail: {},
};

export const userManagementReducer = (state: UserManagementState = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
        case ENABLE_USER_REQUEST:
        case DISABLE_USER_REQUEST:
        case PROMOTE_USER_REQUEST:
        case DOWNGRADE_USER_REQUEST:
            return { ...state, isLoading: true };

        case GET_USERS_SUCCESS: {
            const items = action.payload as User[];
            const byEmail = createResourceGroup(items, x => x.email);
            return { ...state, items, byEmail, isLoading: false };
        }

        case ENABLE_USER_SUCCESS:
        case DISABLE_USER_SUCCESS: {
            const email = action.payload as string;
            const items = state.items.map(x => {
                if (x.email === email) {
                    x.accountEnabled = (action.type === ENABLE_USER_SUCCESS);
                }
                return x;
            });
            const byEmail = createResourceGroup(items, x => x.email);
            return { ...state, items, byEmail, isLoading: false };
        }

        case PROMOTE_USER_SUCCESS:
        case DOWNGRADE_USER_SUCCESS: {
            const email = action.payload as string;
            const items = state.items.map(x => {
                if (x.email === email) {
                    x.isAdmin = (action.type === PROMOTE_USER_SUCCESS);
                }
                return x;
            });
            const byEmail = createResourceGroup(items, x => x.email);
            return { ...state, items, byEmail, isLoading: false };
        }

        case GET_USERS_FAILURE:
        case ENABLE_USER_FAILURE:
        case DISABLE_USER_FAILURE:
        case PROMOTE_USER_FAILURE:
        case DOWNGRADE_USER_FAILURE:
            return { ...state, isLoading: false };

        default:
            return state;
    }
};
