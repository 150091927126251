import { User } from './User';
import { MatchResult } from './Match';

export interface MatchActivity {
    type: MatchActivityType;
    user: User;
    createdAt: number;
    payload?: MatchActivityPayloadComment | MatchActivityPayloadProposeDate | MatchActivityPayloadResult | MatchActivityPayloadAbandoned;
}

export enum MatchActivityType {
    COMMENT = 'COMMENT',
    PROPOSE_DATE = 'PROPOSE_DATE',
    ACCEPT_PROPOSAL = 'ACCEPT_PROPOSAL',
    MATCH_ABANDONED = 'MATCH_ABANDONED',
    MATCH_CREATED = 'MATCH_CREATED',
    MATCH_RESULT = 'MATCH_RESULT',
}

export interface MatchActivityPayloadComment {
    text: string;
}

export interface MatchActivityPayloadProposeDate {
    date: number;
}

export interface MatchActivityPayloadResult {
    winner: User;
    loser: User;
    result: MatchResult;
    playedAt?: Date;
}

export interface MatchActivityPayloadAbandoned {
    reason: AbandonReason;
}

export enum AbandonReason {
    ABANDONED_BY_PLAYER = 'ABANDONED_BY_PLAYER',
    ABANDONED_BY_ORGANIZER = 'ABANDONED_BY_ORGANIZER',
}

export interface MatchActivityPayloadGroupResult {
    holesWonPlayerOne: number;
    holesWonPlayerTwo: number;
    playedAt?: Date;
}
