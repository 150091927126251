import { AnyAction } from 'redux';

import {
    createResourceGroup, createResourceIdMap, createResourceNumberedGroup, ResourceGroup, ResourceIdMap, ResourceMap,
    ResourceNumberedGroup
} from '../../lib/resourceSupport';
import { Errors } from '../../models/Errors';
import { isPlayoffMatch, Match } from '../../models/Match';
import { GroupView, PlayoffTree } from '../../models/MatchViews';

export const GET_PLAYOFF_MATCHES_REQUEST = 'match/GET_PLAYOFF_MATCHES_REQUEST';
export const GET_PLAYOFF_MATCHES_SUCCESS = 'match/GET_PLAYOFF_MATCHES_SUCCESS';
export const GET_PLAYOFF_MATCHES_FAILURE = 'match/GET_PLAYOFF_MATCHES_FAILURE';
export const GET_GROUP_MATCHES_REQUEST = 'match/GET_GROUP_MATCHES_REQUEST'
export const GET_GROUP_MATCHES_SUCCESS = 'match/GET_GROUP_MATCHES_SUCCESS'
export const GET_GROUP_MATCHES_FAILURE = 'match/GET_GROUP_MATCHES_FAILURE'
export const GET_MATCH_REQUEST = 'match/GET_REQUEST';
export const GET_MATCH_SUCCESS = 'match/GET_SUCCESS';
export const GET_MATCH_FAILURE = 'match/GET_FAILURE';
export const GET_MY_MATCHES_REQUEST = 'match/GET_MY_MATCHES_REQUEST';
export const GET_MY_MATCHES_SUCCESS = 'match/GET_MY_MATCHES_SUCCESS';
export const GET_MY_MATCHES_FAILURE = 'match/GET_MY_MATCHES_FAILURE';
export const COMMENT_MATCH_REQUEST = 'match/COMMENT_REQUEST';
export const COMMENT_MATCH_SUCCESS = 'match/COMMENT_SUCCESS';
export const COMMENT_MATCH_FAILURE = 'match/COMMENT_FAILURE';
export const PROPOSE_MATCH_DATE_REQUEST = 'match/PROPOSE_DATE_REQUEST';
export const PROPOSE_MATCH_DATE_SUCCESS = 'match/PROPOSE_DATE_SUCCESS';
export const PROPOSE_MATCH_DATE_FAILURE = 'match/PROPOSE_DATE_FAILURE';
export const ACCEPT_MATCH_DATE_REQUEST = 'match/ACCEPT_DATE_REQUEST';
export const ACCEPT_MATCH_DATE_SUCCESS = 'match/ACCEPT_DATE_SUCCESS';
export const ACCEPT_MATCH_DATE_FAILURE = 'match/ACCEPT_DATE_FAILURE';
export const SUBMIT_MATCH_RESULT_REQUEST = 'match/SUBMIT_RESULT_REQUEST';
export const SUBMIT_MATCH_RESULT_SUCCESS = 'match/SUBMIT_RESULT_SUCCESS';
export const SUBMIT_MATCH_RESULT_FAILURE = 'match/SUBMIT_RESULT_FAILURE';

interface MatchState {
    isLoading: boolean,
    items: Match[];
    mine: Match[];
    byId: ResourceIdMap<Match>;
    byMatchplayId: ResourceGroup<Match>;
    byMatchplayAndDepth: ResourceMap<ResourceNumberedGroup<Match>>;
    groupViewByMatchplayId: ResourceMap<GroupView>;
    lastError?: Errors;
}

const initialState: MatchState = {
    isLoading: false,
    items: [],
    mine: [],
    byId: {},
    byMatchplayId: {},
    byMatchplayAndDepth: {},
    groupViewByMatchplayId: {},
};

export const matchReducer = (state: MatchState = initialState, action: AnyAction): MatchState => {
    switch (action.type) {
        case GET_GROUP_MATCHES_REQUEST:
        case GET_PLAYOFF_MATCHES_REQUEST:
        case GET_MATCH_REQUEST:
        case GET_MY_MATCHES_REQUEST:
        case COMMENT_MATCH_REQUEST:
        case PROPOSE_MATCH_DATE_REQUEST:
        case ACCEPT_MATCH_DATE_REQUEST:
        case SUBMIT_MATCH_RESULT_REQUEST:
            return { ...state, isLoading: true };
        case GET_PLAYOFF_MATCHES_SUCCESS: {
            const payload = action.payload as PlayoffTree;
            const items = [
                ...state.items.filter(item => !payload.matches.find(x => x.id === item.id)),
                ...payload.matches,
            ];
            const byId = createResourceIdMap(items);
            const byMatchplayId = createResourceGroup(items, item => item.matchplayId);
            const byMatchplayAndDepth = items.reduce((result: ResourceMap<ResourceNumberedGroup<Match>>, item) => {
                if (isPlayoffMatch(item)) {
                    if (item.matchplayId && item.depth) {
                        result[item.matchplayId] = createResourceNumberedGroup(
                            byMatchplayId[item.matchplayId].filter(isPlayoffMatch),
                            x => x.depth);
                    }
                }
                return result;
            }, {});
            return {
                ...state, isLoading: false, lastError: undefined, items, byId, byMatchplayId, byMatchplayAndDepth
            };
        }
        case GET_GROUP_MATCHES_SUCCESS: {
            const { view, matchplayId } = action.payload as { view: GroupView, matchplayId: string };
            const flatGroupMatches = view.matches.flatMap(x => x.matches);
            const items = [
                ...state.items.filter(item => !flatGroupMatches.find(x => x.id === item.id)),
                ...flatGroupMatches,
            ];
            const byId = createResourceIdMap(items);
            const byMatchplayId = createResourceGroup(items, item => item.matchplayId);
            const groupViewByMatchplayId = {
                ...state.groupViewByMatchplayId,
                [matchplayId]: view,
            };
            return {
                ...state, isLoading: false, lastError: undefined, items, byId, byMatchplayId, groupViewByMatchplayId,
            };
        }
        case GET_MATCH_SUCCESS:
        case COMMENT_MATCH_SUCCESS:
        case PROPOSE_MATCH_DATE_SUCCESS:
        case ACCEPT_MATCH_DATE_SUCCESS:
        case SUBMIT_MATCH_RESULT_SUCCESS: {
            const payload = action.payload as Match;
            const index = state.items.findIndex(item => item.id === payload.id);
            // create or update
            const items = index >= 0
                ? state.items.map(item => (item.id === payload.id) ? payload : item)
                : [...state.items, payload];
            const byId = createResourceIdMap(items);
            const byMatchplayId = createResourceGroup(items, item => item.matchplayId);
            return { ...state, isLoading: false, lastError: undefined, items, byId, byMatchplayId };
        }
        case GET_MY_MATCHES_SUCCESS: {
            const payload = action.payload as Match[];
            const mine = payload;
            const items = [
                ...state.items,
                ...mine.filter(x => !state.items.find(y => y.id === x.id)),
            ];
            const byId = createResourceIdMap(items);
            const byMatchplayId = createResourceGroup(items, x => x.matchplayId);
            return { ...state, isLoading: false, lastError: undefined, items, byId, byMatchplayId, mine };
        }
        case GET_GROUP_MATCHES_FAILURE:
        case GET_PLAYOFF_MATCHES_FAILURE:
        case GET_MATCH_FAILURE:
        case GET_MY_MATCHES_FAILURE:
        case COMMENT_MATCH_FAILURE:
        case PROPOSE_MATCH_DATE_FAILURE:
        case ACCEPT_MATCH_DATE_FAILURE:
        case SUBMIT_MATCH_RESULT_FAILURE:
            return { ...state, isLoading: false, lastError: action.payload };
        default:
            return state;
    }
};
