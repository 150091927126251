import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';
import { Box, Button, Grid, Link as MuiLink, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { Omit } from '@material-ui/types';

import { TenantInformation } from '../../models/TenantInformation';
import { UserRegistration } from '../../models/User';
import TenantLogo from '../tenant/TenantLogo';
import FormikCheckboxField from '../utils/forms/FormikCheckboxField';
import FormikTextField from '../utils/forms/FormikTextField';

const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid',
    },
    title: {
        textAlign: 'center' as const,
    },
    subtitle: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3),
    },
    logoContainer: {
        textAlign: 'center' as const,
        marginBottom: theme.spacing(3),
    },
    actionRow: {
        marginTop: theme.spacing(3),
    },
    passwordField: {
        flexGrow: 1,
        '& + &': {
            marginLeft: theme.spacing(2),
        }
    },
    checkboxField: {
        marginTop: theme.spacing(2),
    },
});

interface PasswordConfirmation {
    passwordConfirm: string;
    termsOfService: boolean;
}

interface ComponentProps {
    tenantInformation: TenantInformation;
    onRegister(registration: UserRegistration): void;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class RegisterUserForm extends Component<Props> {
    public render() {
        const { tenantInformation, classes, t } = this.props;
        const initialValues: UserRegistration & PasswordConfirmation = {
            email: '',
            name: '',
            password: '',
            passwordConfirm: '',
            termsOfService: false,
        };
        return (
            <Grid container direction="column" className={classes.root}>
                <div className={classes.logoContainer}>
                    <TenantLogo tenantInformation={tenantInformation}/>
                </div>
                <Typography variant="h5" component="h1" className={classes.title}>
                    {t('components.login.RegisterUserForm.title')}
                </Typography>
                <Typography className={classes.subtitle}>
                    {t('components.login.common.subtitle', { tenantName: tenantInformation.name })}
                </Typography>
                <Formik component={RegisterInnerForm}
                        onSubmit={this.onSubmit}
                        initialValues={initialValues}
                        validationSchema={this.schema()}/>
            </Grid>
        );
    }

    private onSubmit = (formData: UserRegistration & PasswordConfirmation) => {
        const { passwordConfirm, ...registration } = formData;
        this.props.onRegister(registration);
    }

    private schema = () => {
        const { t } = this.props;
        return Yup.object().shape({
            email: Yup
                .string()
                .required(t('validation.email.required'))
                .email(t('validation.email.invalid')),
            name: Yup
                .string()
                .required(t('validation.userName.required'))
                .max(250, t('validation.userName.max')),
            password: Yup
                .string()
                .required(t('validation.password.required'))
                .min(8, t('validation.password.min'))
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    t('validation.password.matches')
                ),
            passwordConfirm: Yup
                .string()
                .required(t('validation.passwordConfirm.required'))
                .oneOf([Yup.ref('password'), null], t('validation.passwordConfirm.invalid')),
            termsOfService: Yup
                .bool()
                .required(t('validation.termsOfService.required'))
                .oneOf([true], t('validation.termsOfService.required')),
        });
    }
}

type RegisterInnerFormProps =
    WithStyles<typeof styles>
    & FormikProps<UserRegistration & PasswordConfirmation>
    & WithTranslation;
const RegisterInnerForm = withStyles(styles)(withTranslation()((props: RegisterInnerFormProps) => {
    const { classes, t } = props;
    const loginLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
        (linkProps: any, ref: any) => <Link innerRef={ref} to={'/login'} {...linkProps} />,
    );
    return (
        <Form>
            <Grid container direction="column">
                <FormikTextField name="email" type="email"
                                 label={t('components.login.common.email')} margin="normal" variant="outlined"/>
                <FormikTextField name="name"
                                 label={t('components.login.common.name')} margin="normal" variant="outlined"/>
                <Box display="flex" flexDirection="row">
                    <FormikTextField name="password" type="password"
                                     label={t('components.login.common.password')} margin="normal" variant="outlined"
                                     className={classes.passwordField}/>
                    <FormikTextField name="passwordConfirm" type="password"
                                     label={t('components.login.common.passwordConfirm')} margin="normal"
                                     variant="outlined"
                                     className={classes.passwordField}/>
                </Box>
                <FormikCheckboxField name="termsOfService" value="termsOfService"
                                     label={
                                         <Trans i18nKey="components.login.RegisterUserForm.termsOfService"
                                                components={{
                                                    termsOfServiceLink: <MuiLink
                                                        href="//www.yoomani.me/nutzungsbedingungen"
                                                        target="_blank" rel="noopener noreferrer"/>
                                                }}/>
                                     }
                                     className={classes.checkboxField}/>
                <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.actionRow}>
                    <Button component={loginLink} color="primary">
                        {t('components.login.common.backToLogin')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="right">
                        {t('components.login.RegisterUserForm.submit')}
                    </Button>
                </Box>
            </Grid>
        </Form>
    );
}));

export default withStyles(styles)(withTranslation()(RegisterUserForm));
