import React from 'react';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';
import NotificationsContainer from '../tenant/notification/AdminNotificationsContainer';

const NotificationPage = () => {

    return (
        <ContentContainer>
            <MainNavigationContainer/>
            <BreadcrumbsContainer/>
            <NotificationsContainer/>
        </ContentContainer>);
}

export default NotificationPage;
