export interface Sponsor {
    id?: string;
    name: string;
    targetUrl: string;
    logoUrl?: string;
    displayArea?: DisplayArea[];
}

export enum DisplayArea {
    DASHBOARD = 'DASHBOARD',
    LOGIN = 'LOGIN'
}

export interface NewSponsor {
    id?: string;
    name: string;
    targetUrl: string;
    logo?: File;
    displayArea: DisplayArea[];
}
