import { TFunction } from 'i18next';
import * as ics from 'ics';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { Match } from '../models/Match';
import store from '../store';

export const icsEventFromMatch = async (match: Match, t: TFunction) => {
    if (!match.scheduledAt) {
        throw new Error('Unscheduled match');
    }

    const start = moment.unix(match.scheduledAt / 1000);
    const matchplay = store.getState().matchplay.byId[match.matchplayId];
    const tenant = store.getState().tenant;
    const options = {
        playerOne: match.playerOne?.user.name,
        playerTwo: match.playerTwo?.user.name,
        matchplayName: matchplay.name,
        tenantName: tenant.tenantInformation.name,
    };
    const title = t('lib.calendar.icsTitle', options);
    const description = t('lib.calendar.icsDescription', options);
    const location = `${window.location.protocol}//${window.location.host}/matches/${match.id}`;

    return new Promise<string>((resolve, reject) => {
        ics.createEvent({
            start: [start.year(), start.month() + 1, start.date(), start.hour(), start.minute()],
            duration: { hours: 4 },
            title,
            description,
            location,
            status: 'CONFIRMED',
        }, (error, value) => {
            if (error) {
                reject(error);
                return;
            }
            resolve(value);
        });
    });
};

export const exportMatchEvent = async (match: Match, t: TFunction) => {
    const icsEvent = await icsEventFromMatch(match, t);
    const blob = new Blob([icsEvent], { type: 'text/calendar' });
    const filename = `Matchplay - ${match.playerOne?.user.name} vs ${match.playerTwo?.user.name}.ics`;
    saveAs(blob, filename);
};
