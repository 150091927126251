import React, { Component } from 'react';
import { DisplayArea, Sponsor } from '../../models/Sponsor';
import { connect } from 'react-redux';
import Sponsors from './Sponsors';
import { Dispatch } from 'redux';
import { getSponsorsAction } from '../../store/actions/sponsors';

interface StoreProps {
    sponsors: Sponsor[];
    displayArea: DisplayArea;

    getSponsors(): void;
}

type Props = StoreProps;

class SponsorsContainer extends Component<Props> {

    public render() {
        const { sponsors, displayArea } = this.props;

        if (sponsors && sponsors.length > 0) {
            const sponsorsToDisplay = sponsors.filter(sponsor => sponsor.displayArea?.includes(displayArea));
            return (
                <Sponsors sponsors={sponsorsToDisplay}/>
            );
        }

        return null;
    }

    componentDidMount(): void {
        this.props.getSponsors();
    }
}

const mapStateToProps = (state: any) => ({
    sponsors: state.sponsors.items,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getSponsors: getSponsorsAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsContainer);
