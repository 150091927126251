import 'letterimages/dist/letterimages.css';

import clsx from 'clsx';
import React, { PureComponent } from 'react';

import { Theme, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) => ({
    letterimage: {
        display: 'flex',
        alignItems: 'center',
        height: 'inherit',
        '&:before, &:after': {
            top: 'inherit',
            left: 'inherit',
        }
    }
});

interface Props {
    identifier?: string;
    title: string;
    width: number;
    variant?: 'rounded' | 'circle';
}

const themeClasses = ['teal', 'blue', 'purple', 'orange', 'grey'];

function getThemeClass(identifier: string): string {
    let hash = 5381;
    for (let i = 0; i < identifier.length; ++i) {
        // tslint:disable-next-line:no-bitwise
        hash = ((hash << 5) + hash) + identifier.charCodeAt(i);
    }
    return themeClasses[Math.abs(hash) % themeClasses.length];
}

const numericTitles = ['Null', 'Eins', 'Zwei', 'Drei', 'Vier', 'Fuenf', 'Sechs', 'Sieben', 'Acht', 'Neun'];

function substituteTitle(title: string) {
    if (/^\d.*/.test(title)) {
        return numericTitles[+title[0]];
    }
    return title;
}

class LetterImage extends PureComponent<Props & WithStyles<typeof styles>> {

    public render() {
        const { identifier, title, width, classes, variant } = this.props;
        const className = clsx(
            'letterimages',
            `letterimages-${getThemeClass(identifier || title)}`,
            classes.letterimage,
            { [`letterimages-${variant}`]: !!variant });
        return (
            <div
                className={className}
                data-name={substituteTitle(title)}
                style={{ width, fontSize: width }}
            />
        );
    }
}

export default withStyles(styles)(LetterImage);
