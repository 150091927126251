import { AnyAction } from 'redux';

import { User, UserMatchStats } from '../../models/User';
import { DOWNGRADE_USER_SUCCESS, PROMOTE_USER_SUCCESS } from './usermanagement';
import { Notification } from '../../models/Notification';

export const RESET = 'user/RESET';
export const LOGIN_REQUEST = 'user/LOGIN_REQUEST';
export const LOGIN_TOKEN = 'user/LOGIN_TOKEN';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';
export const LOGIN_RESTORED = 'user/LOGIN_RESTORED';
export const LOGOUT = 'user/LOGOUT';
export const REGISTER_REQUEST = 'user/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'user/REGISTER_FAILURE';
export const ACTIVATE_REQUEST = 'user/ACTIVATE_REQUEST';
export const ACTIVATE_SUCCESS = 'user/ACTIVATE_SUCCESS';
export const ACTIVATE_FAILURE = 'user/ACTIVATE_FAILURE';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'user/REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'user/REQUEST_PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_SUCCESS = 'user/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'user/PASSWORD_RESET_FAILURE';
export const GET_CURRENT_USER_REQUEST = 'user/GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'user/GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'user/GET_CURRENT_USER_FAILURE';
export const UPDATE_CURRENT_USER_REQUEST = 'user/UPDATE_CURRENT_USER_REQUEST';
export const UPDATE_CURRENT_USER_SUCCESS = 'user/UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_FAILURE = 'user/UPDATE_CURRENT_USER_FAILURE';
export const DELETE_CURRENT_USER_REQUEST = 'user/DELETE_CURRENT_USER_SUCCESS';
export const DELETE_CURRENT_USER_SUCCESS = 'user/DELETE_CURRENT_USER_SUCCESS';
export const DELETE_CURRENT_USER_FAILURE = 'user/DELETE_CURRENT_USER_FAILURE';
export const UPLOAD_IMAGE_SUCCESS = 'user/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'user/UPLOAD_IMAGE_FAILURE';
export const GET_CURRENT_USER_STATS_REQUEST = 'user/GET_CURRENT_USER_STATS_REQUEST';
export const GET_CURRENT_USER_STATS_SUCCESS = 'user/GET_CURRENT_USER_STATS_SUCCESS';
export const GET_CURRENT_USER_STATS_FAILURE = 'user/GET_CURRENT_USER_STATS_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'user/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'user/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'user/CHANGE_PASSWORD_FAILURE';
export const GET_PUBLISHED_NOTIFICATIONS_REQUEST = 'notification/GET_PUBLISHED_REQUEST';
export const GET_PUBLISHED_NOTIFICATIONS_SUCCESS = 'notification/GET_PUBLISHED_SUCCESS';
export const GET_PUBLISHED_NOTIFICATIONS_FAILURE = 'notification/GET_PUBLISHED_FAILURE';

interface UserState {
    isLoading: boolean;
    isAuthenticated: boolean;
    isLoginError: boolean;
    isRestored: boolean;
    isSuccessfullyRegistered: boolean;
    isSuccessfullyActivated: boolean;
    isRequestPasswordResetSuccessful: boolean;
    isPasswordResetSuccessful: boolean;
    token: string;
    registeredEmail: string;
    currentUser?: User;
    currentUserStats?: UserMatchStats;
    publishedNotifications: Notification[];
}

const initialState: UserState = {
    isLoading: false,
    isAuthenticated: false,
    isLoginError: false,
    isRestored: false,
    isSuccessfullyRegistered: false,
    isSuccessfullyActivated: false,
    isRequestPasswordResetSuccessful: false,
    isPasswordResetSuccessful: false,
    token: '',
    registeredEmail: '',
    publishedNotifications: [],
};

export const userReducer = (state: UserState = initialState, action: AnyAction): UserState => {
    switch (action.type) {
        case RESET:
            return { ...initialState, isRestored: state.isRestored };

        // Login
        case LOGIN_REQUEST:
            return { ...state, isLoading: true, isLoginError: false }
        case LOGIN_SUCCESS:
            return {
                ...state, isLoading: false, isAuthenticated: true, currentUser: action.payload, isLoginError: false
            };
        case LOGIN_TOKEN:
            return { ...state, isLoading: false, token: action.payload, isLoginError: false };
        case LOGIN_FAILURE:
            return { ...state, isLoading: false, isAuthenticated: false, isLoginError: true }
        case LOGIN_RESTORED:
            return {
                ...state, isLoading: false, isRestored: true, isAuthenticated: Boolean(action.payload),
                currentUser: action.payload
            };
        case LOGOUT:
            return { ...state, isAuthenticated: false, token: '' };

        // Registration
        case REGISTER_SUCCESS:
            return { ...state, isSuccessfullyRegistered: true, registeredEmail: action.payload };
        case REGISTER_FAILURE:
            return { ...state, isSuccessfullyRegistered: false, registeredEmail: '' };

        // Activation
        case ACTIVATE_SUCCESS:
            return { ...state, isSuccessfullyActivated: true };
        case ACTIVATE_FAILURE:
            return { ...state, isSuccessfullyActivated: false };

        // Password Reset
        case REQUEST_PASSWORD_RESET_SUCCESS:
            return { ...state, isRequestPasswordResetSuccessful: true };
        case PASSWORD_RESET_SUCCESS:
            return { ...state, isPasswordResetSuccessful: true };

        // Get current user
        case GET_CURRENT_USER_SUCCESS:
            return { ...state, currentUser: action.payload };
        case GET_CURRENT_USER_FAILURE:
            return { ...state, currentUser: undefined };

        // Update current user
        case UPDATE_CURRENT_USER_SUCCESS:
            if (!state.currentUser) {
                return state;
            }
            const user = action.payload;
            return {
                ...state, currentUser: {
                    ...state.currentUser, name: user.name, hcp: user.hcp, status: user.status, club: user.club
                }
            };
        case UPLOAD_IMAGE_SUCCESS:
            if (!state.currentUser) {
                return state;
            }
            return {
                ...state, currentUser: { ...state.currentUser, imageUrl: action.payload.imageUrl }
            };

        // Current user statistics:
        case GET_CURRENT_USER_STATS_SUCCESS:
            return { ...state, currentUserStats: action.payload };
        case GET_CURRENT_USER_STATS_FAILURE:
            return { ...state, currentUserStats: undefined };

        // React to promotions/downgrades of current user:
        case PROMOTE_USER_SUCCESS:
        case DOWNGRADE_USER_SUCCESS: {
            if (!state.currentUser || action.payload !== state.currentUser?.email) {
                return state;
            }
            const isAdmin = (action.type === PROMOTE_USER_SUCCESS);
            return { ...state, currentUser: { ...state.currentUser, isAdmin } };
        }

        // Delete current user
        case DELETE_CURRENT_USER_REQUEST:
            return { ...state, isLoading: true }
        case DELETE_CURRENT_USER_SUCCESS:
        case DELETE_CURRENT_USER_FAILURE:
            return { ...state, isLoading: false, currentUser: undefined }

        // Get published notifications
        case GET_PUBLISHED_NOTIFICATIONS_REQUEST:
            return { ...state, isLoading: true }
        case GET_PUBLISHED_NOTIFICATIONS_SUCCESS: {
            const items = action.payload as Notification[];
            return { ...state, isLoading: false, publishedNotifications: items };
        }
        case GET_PUBLISHED_NOTIFICATIONS_FAILURE:
            return { ...state, isLoading: false }

        default:
            return state;
    }
};
