import React, { Component } from 'react';
import { Notification } from '../../models/Notification';
import { List, WithStyles, withStyles } from '@material-ui/core';
import { stableSort } from '../../lib/sorting';
import NotificationListItem from './NotificationListItem';

const styles = () => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

interface ComponentProps {
    notifications: Notification[];
}

type Props = ComponentProps & WithStyles<typeof styles>;

class NotificationsList extends Component<Props> {

    public render() {
        const { notifications, classes } = this.props;

        return (
            <List className={classes.root}>
                {stableSort(notifications, ['createdAt']).reverse().map((item: Notification, index: number) => {
                    const isLastItem = index === notifications.length - 1;
                    return (<NotificationListItem notification={item} isLastItem={isLastItem} key={index}/>);
                })}
            </List>
        );
    }
}

export default withStyles(styles)(NotificationsList);
