import React, { ChangeEvent, Component } from 'react';

import { Box, Button, createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { User } from '../../models/User';
import LogoPreview from '../tenant/LogoPreview';
import ConfirmationButton from '../utils/ConfirmationButton';
import Section from '../utils/Section';

const styles = (theme: Theme) => createStyles({
    fileInput: {
        display: 'none'
    },
    fileSelectButton: {
        flexGrow: 1,
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    deleteButton: {
        marginTop: theme.spacing(1),
    }
});

interface ComponentProps {
    currentUser: User,
    onUploadImage(file: File): void;
    onDeleteImage(): void;
}

interface State {
    file?: File;
    preview?: string;
    loading: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class UserImage extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    public render() {
        const { currentUser, classes } = this.props;
        const { preview, loading } = this.state;
        const isValidFileSelected = this.checkFile();

        return (
            <Section title="Profilbild">
                <Grid container spacing={3}>
                    <Grid item>
                        <LogoPreview url={preview ?? currentUser.imageUrl} alt={currentUser.name} useBorder={true}
                                     loading={loading}/>
                    </Grid>
                    <Grid item>
                        <input id="logo-file-input" type="file" accept="image/png,image/jpeg"
                               className={classes.fileInput} onChange={this.onChange} onClick={this.onClick}/>
                        <Box display="flex" height="100%" justifyContent="space-between" flexDirection="column">
                            <Box>Max. 10MB, JPEG oder PNG</Box>
                            <label htmlFor="logo-file-input" className={classes.fileSelectButton}>
                                <Button variant="outlined" startIcon={<PhotoCamera/>} component="span">
                                    Bild auswählen
                                </Button>
                            </label>
                            <Button type="submit" disabled={!isValidFileSelected} onClick={this.onSubmit}
                                    color="primary" variant="contained">
                                Speichern
                            </Button>
                            {currentUser.imageUrl &&
                            <ConfirmationButton variant="danger" className={classes.deleteButton}
                                                dialogTitle="Profilbild entfernen?"
                                                dialogContentText="Wollen Sie ihr Profilbild wirklich entfernen?"
                                                onConfirm={this.handleDelete}>
                                Löschen
                            </ConfirmationButton>}
                        </Box>
                    </Grid>
                </Grid>
            </Section>
        );
    }

    private onSubmit = () => {
        if (this.checkFile() && this.state.file) {
            this.props.onUploadImage(this.state.file);
            this.setState({ file: undefined });
        }
    };

    private handleDelete = () => {
        this.props.onDeleteImage();
        this.setState({ file: undefined, preview: undefined });
    };

    private onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget && event.currentTarget.files && event.currentTarget.files.length > 0) {
            const file = event.currentTarget.files[0];
            const preview = await this.preview(file);
            this.setState({ file, preview });
        }
    };

    private onClick = () => {
        this.setState({ loading: true });
        document.body.onfocus = this.checkCancel;
    }

    private checkCancel = () => {
        this.setState({ loading: false });
        document.body.onfocus = null;
    }

    private preview = async (file: File): Promise<string | undefined> => {
        if (file) {
            return await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = reject;
                reader.onload = () => resolve(reader.result as string);
                reader.readAsDataURL(file);
            });
        }
    };

    private checkFile = () => {
        const { file } = this.state;
        if (!file) {
            return false;
        }
        const extension = file.name.split('.').pop()?.toLowerCase();
        return extension === 'png' || extension === 'jpg' || extension === 'jpeg';
    }
}

export default withStyles(styles)(UserImage);
