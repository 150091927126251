import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';

const useStylesContained = makeStyles((theme: Theme) => ({
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
}));

const useStylesOutlined = makeStyles((theme: Theme) => ({
    root: {
        borderWidth: 1,
        borderStyle: 'solid',
    },
    info: {
        color: theme.palette.info.main,
        borderColor: theme.palette.info.dark,
        '&:hover': {
            backgroundColor: fade(theme.palette.info.main, theme.palette.action.hoverOpacity),
        },
    },
    success: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.dark,
        '&:hover': {
            backgroundColor: fade(theme.palette.success.main, theme.palette.action.hoverOpacity),
        },
    },
    warning: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.dark,
        '&:hover': {
            backgroundColor: fade(theme.palette.warning.main, theme.palette.action.hoverOpacity),
        },
    },
    danger: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.dark,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
        },
    },
}));

const useStylesText = makeStyles((theme: Theme) => ({
    info: {
        color: theme.palette.info.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.info.main, theme.palette.action.hoverOpacity),
        },
    },
    success: {
        color: theme.palette.success.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.success.main, theme.palette.action.hoverOpacity),
        },
    },
    warning: {
        color: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.warning.main, theme.palette.action.hoverOpacity),
        },
    },
    danger: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
        },
    },
}));

interface ComponentProps {
    color: 'info' | 'success' | 'warning' | 'danger';
}

type Props = ComponentProps & Omit<ButtonProps, 'color'>;

const CtaButton = ({ color, children, variant = 'contained', ...other }: Props) => {
    const classesContained = useStylesContained();
    const classesOutlined = useStylesOutlined();
    const classesText = useStylesText();
    const className = clsx(other.className, {
        [`${classesContained[color]}`]: variant === 'contained',
        [`${classesOutlined[color]}`]: variant === 'outlined',
        [`${classesText[color]}`]: variant === 'text',
        [classesOutlined.root]: variant === 'outlined',
    });
    return (
        <Button {...other} className={className} >
            {children}
        </Button>
    );
};

export default CtaButton;
