import { Dispatch } from 'redux';

import { Errors } from '../../models/Errors';
import { Match } from '../../models/Match';
import { MatchActivityPayloadGroupResult, MatchActivityPayloadResult } from '../../models/MatchActivity';
import { matchService } from '../../services/match';
import {
    ACCEPT_MATCH_DATE_FAILURE, ACCEPT_MATCH_DATE_REQUEST, ACCEPT_MATCH_DATE_SUCCESS, COMMENT_MATCH_FAILURE,
    COMMENT_MATCH_REQUEST, COMMENT_MATCH_SUCCESS, GET_MATCH_FAILURE, GET_MATCH_REQUEST, GET_MATCH_SUCCESS,
    GET_MY_MATCHES_FAILURE, GET_MY_MATCHES_REQUEST, GET_MY_MATCHES_SUCCESS, PROPOSE_MATCH_DATE_FAILURE,
    PROPOSE_MATCH_DATE_REQUEST, PROPOSE_MATCH_DATE_SUCCESS, SUBMIT_MATCH_RESULT_FAILURE, SUBMIT_MATCH_RESULT_REQUEST,
    SUBMIT_MATCH_RESULT_SUCCESS
} from '../reducers/match';

export const getMatchAction = (dispatch: Dispatch) => {
    return async (id: string) => {
        dispatch(request());
        try {
            const match = await matchService.getMatch(id);
            dispatch(success(match));
        } catch (e) {
            (e.response.status === 404)
                ? dispatch(failure(Errors.NotFound))
                : dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_MATCH_REQUEST }; }
    function success(match: Match) { return { type: GET_MATCH_SUCCESS, payload: match }; }
    function failure(error?: Errors) { return { type: GET_MATCH_FAILURE, payload: error }; }
    // @formatter:on
}

export const getMyMatchesAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const matches = await matchService.getMyMatches();
            dispatch(success(matches));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: GET_MY_MATCHES_REQUEST }; }
    function success(match: Match[]) { return { type: GET_MY_MATCHES_SUCCESS, payload: match }; }
    function failure() { return { type: GET_MY_MATCHES_FAILURE }; }
    // @formatter:on
};

export const commentMatchAction = (dispatch: Dispatch) => {
    return async (id: string, comment: string) => {
        dispatch(request());
        try {
            const match = await matchService.commentMatch(id, comment);
            dispatch(success(match));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: COMMENT_MATCH_REQUEST }; }
    function success(match: Match) { return { type: COMMENT_MATCH_SUCCESS, payload: match }; }
    function failure() { return { type: COMMENT_MATCH_FAILURE }; }
    // @formatter:on
}

export const proposeMatchDateAction = (dispatch: Dispatch) => {
    return async (id: string, date: Date) => {
        dispatch(request());
        try {
            const match = await matchService.proposeMatchDate(id, date);
            dispatch(success(match));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: PROPOSE_MATCH_DATE_REQUEST }; }
    function success(match: Match) { return { type: PROPOSE_MATCH_DATE_SUCCESS, payload: match }; }
    function failure() { return { type: PROPOSE_MATCH_DATE_FAILURE }; }
    // @formatter:on
}

export const acceptMatchDateAction = (dispatch: Dispatch) => {
    return async (id: string) => {
        dispatch(request());
        try {
            const match = await matchService.acceptMatchDate(id);
            dispatch(success(match));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: ACCEPT_MATCH_DATE_REQUEST }; }
    function success(match: Match) { return { type: ACCEPT_MATCH_DATE_SUCCESS, payload: match }; }
    function failure() { return { type: ACCEPT_MATCH_DATE_FAILURE }; }
    // @formatter:on
}

export const submitMatchResultAction = (dispatch: Dispatch) => {
    return async (id: string, data: MatchActivityPayloadResult) => {
        dispatch(request());
        try {
            const match = await matchService.submitMatchResult(id, data);
            dispatch(success(match));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: SUBMIT_MATCH_RESULT_REQUEST }; }
    function success(match: Match) { return { type: SUBMIT_MATCH_RESULT_SUCCESS, payload: match }; }
    function failure() { return { type: SUBMIT_MATCH_RESULT_FAILURE }; }
    // @formatter:on
}

export const submitGroupMatchResultAction = (dispatch: Dispatch) => {
    return async (id: string, data: MatchActivityPayloadGroupResult) => {
        dispatch(request());
        try {
            const match = await matchService.submitGroupMatchResult(id, data);
            dispatch(success(match));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: SUBMIT_MATCH_RESULT_REQUEST }; }
    function success(match: Match) { return { type: SUBMIT_MATCH_RESULT_SUCCESS, payload: match }; }
    function failure() { return { type: SUBMIT_MATCH_RESULT_FAILURE }; }
    // @formatter:on
};
