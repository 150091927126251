import config from '../config';
import { mptFetch } from '../lib/mptFetch';
import { PagedResources } from '../lib/resourceSupport';
import { User } from '../models/User';

const usersUrl = `${config.baseUrl}/users`;

const getUsers = async () => {
    const url = new URL(usersUrl);
    url.searchParams.append('sort', 'username');
    url.searchParams.append('size', '-1');
    const users = await mptFetch(url.toString());
    return await users.json() as PagedResources<User>;
};

const enableUser = async (email: string) => {
    await mptFetch(`${usersUrl}/${email}/enable`, {
        method: 'POST',
    });
}

const disableUser = async (email: string) => {
    await mptFetch(`${usersUrl}/${email}/disable`, {
        method: 'POST',
    });
}

const promoteUser = async (email: string) => {
    await mptFetch(`${usersUrl}/${email}/promote`, {
        method: 'POST',
    });
}

const downgradeUser = async (email: string) => {
    await mptFetch(`${usersUrl}/${email}/downgrade`, {
        method: 'POST',
    });
}

export const userManagementService = {
    getUsers,
    enableUser,
    disableUser,
    promoteUser,
    downgradeUser,
};
