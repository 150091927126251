import config from '../config';
import { mptFetch } from '../lib/mptFetch';
import { CreateOrUpdateNotification, Notification } from '../models/Notification';

const getNotifications = async (): Promise<Notification[]> => {
    const response = await mptFetch(`${config.baseUrl}/notifications`);
    return await response.json() as Notification[];
};

const createNotification = async (payload: CreateOrUpdateNotification) => {
    const response = await mptFetch(`${config.baseUrl}/notifications`, {
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return (await response.json()) as Notification;
}

const updateNotification = async (id: string, payload: CreateOrUpdateNotification) => {
    const response = await mptFetch(`${config.baseUrl}/notifications/${id}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
    });
    return (await response.json()) as Notification;
}

const deleteNotification = async (id: string) => {
    await mptFetch(`${config.baseUrl}/notifications/${id}`, {
        method: 'DELETE',
    });
}

const publishNotification = async (id: string) => {
    const response = await mptFetch(`${config.baseUrl}/notifications/${id}/publish`, {
        method: 'POST',
    });
    return (await response.json()) as Notification;
}

export const notificationService = {
    getNotifications,
    createNotification,
    updateNotification,
    deleteNotification,
    publishNotification,
};
