import { shuffle } from '../../../../lib/shuffle';
import { Matchplay } from '../../../../models/Matchplay';
import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';

interface GroupStrategyParams {
    registrations: MatchplayRegistration[];
    matchplay: Matchplay;
}

interface GroupStrategyResult {
    groups: MatchplayRegistration[][];
    isPlayable: boolean;
}

function fillGroups(registrations: MatchplayRegistration[], numberOfGroups: number) {
    const groups: MatchplayRegistration[][] = [];
    for (let i = 0; i < numberOfGroups; i++) {
        groups.push([]);
    }
    for (let i = 0; i < registrations.length; i++) {
        const groupIndex = i % numberOfGroups;
        const registration = registrations[i];
        groups[groupIndex].push(registration);
    }
    return groups;
}

export function isPlayable(groups: MatchplayRegistration[][]) {
    const numberOfGroups = groups.length;
    if (numberOfGroups < 2) {
        return false;
    }
    const hasEnoughPlayers = groups.every(group => group.length >= 3);
    return hasEnoughPlayers;
}

// First-come, First-serve: Sorted by registration date.
export const firstComeFirstServe = ({ registrations, matchplay }: GroupStrategyParams): GroupStrategyResult => {
    if (!registrations || !matchplay?.groupSettings) {
        return { groups: [], isPlayable: false };
    }

    const sortedRegistrations = registrations
        .filter(x => !!x)
        .sort((lhs, rhs) => (lhs?.enqueuedAt ?? 0) - (rhs?.enqueuedAt ?? 0));
    const groups = fillGroups(sortedRegistrations, matchplay.groupSettings.numberOfGroups);
    return { groups, isPlayable: isPlayable(groups) };
};

// Shuffle: randomly put players into groups.
export const shuffled = ({ registrations, matchplay }: GroupStrategyParams): GroupStrategyResult => {
    if (!registrations || !matchplay?.groupSettings) {
        return { groups: [], isPlayable: false };
    }

    const shuffledRegistrations = shuffle(registrations);
    const groups = fillGroups(shuffledRegistrations, matchplay.groupSettings.numberOfGroups);
    return { groups, isPlayable: isPlayable(groups) };
};
