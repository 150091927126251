import React, { Component } from 'react';

import MatchplaySettingsContainer from '../matchplay/settings/MatchplaySettingsContainer';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';

class MatchplaySettingsPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer/>
                <BreadcrumbsContainer/>
                <MatchplaySettingsContainer/>
            </ContentContainer>
        );
    }
}

export default MatchplaySettingsPage;
