import React, { Component, ReactNode } from 'react';

import { Theme, Typography, withStyles, WithStyles } from '@material-ui/core';

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    gutterTop: {
        marginTop: theme.spacing(3),
    },
    gutterBottom: {
        marginBottom: theme.spacing(3),
    },
    titleBar: {
        display: 'flex' as const,
        flexDirection: 'row' as const,
        alignItems: 'center' as const,
        margin: 0,
        padding: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& button': {
            marginLeft: theme.spacing(2),
        },
    },
    titleBarPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    titleBarSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    title: {
        textTransform: 'uppercase' as const,
        flex: 1,
        margin: 0,
        padding: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    body: {
        padding: theme.spacing(2),
    },
    noPaddingBody: {
        padding: theme.spacing(0),
        paddingTop: theme.spacing(0),
    },
});

interface ComponentProps {
    title: string;
    gutterTop?: boolean;
    gutterBottom?: boolean;
    actions?: ReactNode;
    color?: 'primary' | 'secondary';
    hideBody?: boolean;
    noPadding?: boolean;
    id?: string;
}

type Props = ComponentProps & WithStyles<typeof styles>;

class Section extends Component<Props> {

    public static defaultProps: ComponentProps = {
        title: '',
        gutterTop: true,
    };

    public render() {
        const { children, title, gutterTop, gutterBottom, actions, color, hideBody, noPadding, id, classes } = this.props;

        const rootClassNames = [classes.root];
        if (gutterTop) {
            rootClassNames.push(classes.gutterTop);
        }
        if (gutterBottom) {
            rootClassNames.push(classes.gutterBottom);
        }

        const titleBarClassNames = [classes.titleBar];
        if (color === 'secondary') {
            titleBarClassNames.push(classes.titleBarSecondary);
        } else {
            titleBarClassNames.push(classes.titleBarPrimary);
        }

        const bodyClass = noPadding ? classes.noPaddingBody : classes.body;

        return (
            <section className={rootClassNames.join(' ')} id={id}>
                <h1 className={titleBarClassNames.join(' ')}>
                    <Typography className={classes.title}>{title}</Typography>
                    {actions}
                </h1>
                {!hideBody && <div className={bodyClass}>
                    {children}
                </div>}
            </section>
        );
    }
}

export default withStyles(styles)(Section);
