import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Table,
    TableBody, TableCell, TableRow, Theme, WithStyles, withStyles
} from '@material-ui/core';

import { Matchplay, MatchplayPhase } from '../../../models/Matchplay';
import CtaButton from '../../utils/CtaButton';
import { PageLink } from '../../utils/PageLink';
import Section from '../../utils/Section';
import MatchplayPhases from './MatchplayPhases';

const styles = (theme: Theme) => ({
    gridLeft: {
        paddingRight: theme.spacing(2),
    },
    gridRight: {
        paddingLeft: theme.spacing(2),
    },
    actions: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button, & a': {
            marginBottom: theme.spacing(1),
        },
        '& button:last-child': {
            marginBottom: theme.spacing(0),
        },
    },
});

interface ComponentProps {
    matchplay: Matchplay;
    commitPhase(matchplay: Matchplay): void;
    deleteMatchplay(matchplay: Matchplay): void;
    onStartGroupPlayoff(matchplay: Matchplay): void;
}

interface State {
    openOpenRegistrationConfirmation: boolean;
    openCloseRegistrationConfirmation: boolean;
    openDeleteConfirmation: boolean;
    openGroupPlayoffConfirmation: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class MatchplayPhaseSettings extends Component<Props, State> {

    state: State = {
        openOpenRegistrationConfirmation: false,
        openCloseRegistrationConfirmation: false,
        openDeleteConfirmation: false,
        openGroupPlayoffConfirmation: false,
    };

    public render() {
        const { matchplay, classes, t } = this.props;
        const {
            openOpenRegistrationConfirmation,
            openCloseRegistrationConfirmation,
            openDeleteConfirmation,
            openGroupPlayoffConfirmation,
        } = this.state;

        const showNumberOfRegistrations = (
            matchplay.phase === MatchplayPhase.REGISTRATION_OPEN ||
            matchplay.phase === MatchplayPhase.REGISTRATION_CLOSED);
        const showNumberOfPlayers = (
            matchplay.phase === MatchplayPhase.EXECUTION_GROUP ||
            matchplay.phase === MatchplayPhase.EXECUTION_GROUP_FINISHED ||
            matchplay.phase === MatchplayPhase.EXECUTION ||
            matchplay.phase === MatchplayPhase.AFTERMATH
        );
        const showOpenRegistrationAction = (matchplay.phase === MatchplayPhase.INITIALIZATION);
        const showCloseRegistrationAction = (matchplay.phase === MatchplayPhase.REGISTRATION_OPEN);
        const showPreparationAction = (matchplay.phase === MatchplayPhase.REGISTRATION_CLOSED);
        const showGroupPlayoffAction = (matchplay.phase === MatchplayPhase.EXECUTION_GROUP_FINISHED);
        const PreparationActionLink = PageLink('/admin/matchplays/:id/preparation', { id: matchplay.id });

        return (
            <Section title={matchplay.name}>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12} className={classes.gridLeft}>
                        <MatchplayPhases
                            phase={matchplay.phase}
                            variant={matchplay.variant} />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridRight}>
                        <Table>
                            <TableBody>
                                {showNumberOfPlayers &&
                                    <TableRow>
                                        <TableCell>{t('components.matchplay.settings.MatchplayPhaseSettings.numberPlayersLabel')}</TableCell>
                                        <TableCell align="right">
                                            {t('components.matchplay.common.numberOfPlayers', { count: matchplay.numberPlayers })}
                                        </TableCell>
                                    </TableRow>}
                                {showNumberOfRegistrations &&
                                    <TableRow>
                                        <TableCell>{t('components.matchplay.settings.MatchplayPhaseSettings.numberRegistrationsLabel')}</TableCell>
                                        <TableCell align="right">
                                            {t('components.matchplay.common.numberOfPlayers', { count: matchplay.numberRegistrations })}
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                        <Box className={classes.actions} flexDirection="column">
                            {showOpenRegistrationAction &&
                                <Button
                                    onClick={this.openOpenRegistrationConfirmation}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    size="large">
                                    {t('components.matchplay.settings.MatchplayPhaseSettings.openRegistrationAction')}
                                </Button>}
                            {showCloseRegistrationAction &&
                                <Button
                                    onClick={this.showCloseRegistrationConfirmation}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    size="large">
                                    {t('components.matchplay.settings.MatchplayPhaseSettings.closeRegistrationAction')}
                                </Button>}
                            {showPreparationAction &&
                                <Button
                                    component={PreparationActionLink}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    size="large">
                                    {t('components.matchplay.settings.MatchplayPhaseSettings.preparationAction')}
                                </Button>}
                            {showGroupPlayoffAction &&
                                <Button
                                    onClick={this.showGroupPlayoffConfirmation}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    size="large">
                                    {t('components.matchplay.settings.MatchplayPhaseSettings.groupPlayoffAction')}
                                </Button>}
                            <CtaButton 
                                color="danger" 
                                variant="outlined"
                                onClick={this.showDeleteConfirmation}
                                fullWidth={true}>
                                {t('components.matchplay.settings.MatchplayPhaseSettings.deleteAction')}
                            </CtaButton>
                        </Box>
                    </Grid>
                </Grid>
                <Dialog open={openOpenRegistrationConfirmation} onClose={this.handleClose}>
                    <DialogTitle>
                        {t('components.matchplay.settings.MatchplayPhaseSettings.openRegistrationDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('components.matchplay.settings.MatchplayPhaseSettings.openRegistrationDialogContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>{t('common.cancel')}</Button>
                        <Button onClick={this.handleCommitConfirmation} color="secondary">{t('common.ok')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openCloseRegistrationConfirmation} onClose={this.handleClose}>
                    <DialogTitle>
                        {t('components.matchplay.settings.MatchplayPhaseSettings.closeRegistrationDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('components.matchplay.settings.MatchplayPhaseSettings.closeRegistrationDialogContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>{t('common.cancel')}</Button>
                        <Button onClick={this.handleCommitConfirmation} color="secondary">{t('common.ok')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDeleteConfirmation} onClose={this.handleClose}>
                    <DialogTitle>
                        {t('components.matchplay.settings.MatchplayPhaseSettings.deleteDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('components.matchplay.settings.MatchplayPhaseSettings.deleteDialogContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>{t('common.cancel')}</Button>
                        <CtaButton onClick={this.handleDeleteConfirmation} color="danger" variant="text">
                            {t('components.matchplay.settings.MatchplayPhaseSettings.deleteAction')}
                        </CtaButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={openGroupPlayoffConfirmation} onClose={this.handleClose}>
                    <DialogTitle>
                        {t('components.matchplay.settings.MatchplayPhaseSettings.groupPlayoffDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('components.matchplay.settings.MatchplayPhaseSettings.groupPlayoffDialogContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>{t('common.cancel')}</Button>
                        <Button onClick={this.handleGroupPlayoffConfirmation} color="secondary">
                            {t('components.matchplay.settings.MatchplayPhaseSettings.groupPlayoffAction')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Section>
        );
    }

    private handleClose = () => {
        this.setState({
            openOpenRegistrationConfirmation: false,
            openCloseRegistrationConfirmation: false,
            openDeleteConfirmation: false,
            openGroupPlayoffConfirmation: false,
        });
    };

    private openOpenRegistrationConfirmation = () => {
        this.setState({ openOpenRegistrationConfirmation: true });
    };

    private showCloseRegistrationConfirmation = () => {
        this.setState({ openCloseRegistrationConfirmation: true });
    };

    private handleCommitConfirmation = () => {
        this.props.commitPhase(this.props.matchplay)
        this.handleClose();
    };

    private showDeleteConfirmation = () => {
        this.setState({ openDeleteConfirmation: true });
    };

    private handleDeleteConfirmation = () => {
        this.props.deleteMatchplay(this.props.matchplay)
        this.handleClose();
    };

    private showGroupPlayoffConfirmation = () => {
        this.setState({ openGroupPlayoffConfirmation: true });
    };

    private handleGroupPlayoffConfirmation = () => {
        this.props.onStartGroupPlayoff(this.props.matchplay);
        this.handleClose();
    };
}

export default withStyles(styles)(withTranslation()(MatchplayPhaseSettings));
