import React, { Component } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

import {
    Box, Button, createStyles, Divider, Link, Theme, Typography, withStyles, WithStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Matchplay } from '../../../models/Matchplay';
import { Sponsor } from '../../../models/Sponsor';
import AdminSponsorsItem from '../../tenant/AdminSponsorsItem';
import { PageLink } from '../../utils/PageLink';
import Section from '../../utils/Section';
import MatchplaySponsorsLinkSponsor from './MatchplaySponsorsLinkSponsor';

const styles = (theme: Theme) => createStyles({
    sponsorFragment: {
        marginBottom: theme.spacing(1),
    },
});

interface ComponentProps {
    matchplay: Matchplay;
    sponsors: Sponsor[];
    onAddSponsor(sponsor: Sponsor): void;
    onRemoveSponsor(sponsor: Sponsor): void;
}

interface State {
    isAddingSponsor?: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class MatchplaySponsorsSettings extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const { sponsors, matchplay, classes, onRemoveSponsor, t } = this.props;
        const { isAddingSponsor } = this.state;

        return (
            <Section title="Matchplay Sponsoren">
                {sponsors.length === 0 &&
                    <Typography component="h6">
                        <Trans
                            i18nKey="components.matchplay.settings.MatchplaySponsorsSettings.empty"
                            components={{ adminLink: <Link component={PageLink('/admin')} /> }} />
                    </Typography>}
                {sponsors.length > 0 && matchplay.sponsors.map(sponsor => (
                    <React.Fragment key={sponsor.id}>
                        <div className={classes.sponsorFragment}>
                            <AdminSponsorsItem
                                sponsor={sponsor}
                                deleteSponsor={onRemoveSponsor}
                                hideDisplayArea={true} />
                        </div>
                        <Divider className={classes.sponsorFragment} />
                    </React.Fragment>
                ))}
                {isAddingSponsor && sponsors.length > 0 &&
                    <MatchplaySponsorsLinkSponsor
                        sponsors={sponsors}
                        onSave={this.onLinkSponsor}
                        onCancel={this.onCancelAdd} />}
                {!isAddingSponsor && sponsors.length > 0 &&
                    <Box>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={this.onAdd}>
                            {t('components.matchplay.settings.MatchplaySponsorsSettings.addSponsor')}
                        </Button>
                    </Box>}
            </Section>
        )
    }

    private onLinkSponsor = (sponsor: Sponsor) => {
        this.props.onAddSponsor(sponsor);
        this.setState({ isAddingSponsor: false });
    }

    private onAdd = () => {
        if (this.state.isAddingSponsor) {
            return;
        }
        this.setState({ isAddingSponsor: true });
    };

    private onCancelAdd = () => {
        this.setState({ isAddingSponsor: false });
    };
}

export default withStyles(styles)(withTranslation()(MatchplaySponsorsSettings));
