import React, { Component } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Radio,
    RadioGroup, WithStyles, withStyles
} from '@material-ui/core';
import { InnerMatch } from '../dashboard/MatchplayMatchList';
import { MatchActivityPayloadResult } from '../../../models/MatchActivity';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { MatchResult } from '../../../models/Match';
import CtaButton from '../../utils/CtaButton';

const styles = () => ({
    name: {
        fontWeight: 600,
    },
});

interface ComponentProps {
    isPlayer?: boolean;
}

interface State {
    open: boolean;
    disableOk: boolean;
    matchResult: MatchActivityPayloadResult | null;
    openFinalConfirmation: boolean;
}

type Props = ComponentProps & InnerMatch & WithStyles<typeof styles> & WithTranslation;

class MatchplayAbandonMatch extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            disableOk: true,
            matchResult: null,
            openFinalConfirmation: false,
        }
    }

    public render() {
        const { match, classes, t, isPlayer } = this.props;
        const { open, disableOk, openFinalConfirmation, matchResult } = this.state;

        if (!match || !match.playerTwo || !match.playerOne) {
            return null;
        }

        let buttonText;
        if (isPlayer) {
            buttonText = t('components.matchplay.settings.MatchplayAbandonMatch.actionAbandonUser');
        } else {
            buttonText = t('components.matchplay.settings.MatchplayAbandonMatch.actionAbandonAdmin');
        }

        return (
            <React.Fragment>
                <Button onClick={this.onEndGame} variant="outlined" size="small">
                    {buttonText}
                </Button>
                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{t('components.matchplay.settings.MatchplayAbandonMatch.confirmDialogTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Trans
                                i18nKey="components.matchplay.settings.MatchplayAbandonMatch.confirmDialogText"
                                values={{ playerOne: match.playerOne.user.name, playerTwo: match.playerTwo.user.name }}
                                components={{ player: <span className={classes.name}/> }}/>
                        </DialogContentText>
                        <FormControl component="fieldset">
                            <RadioGroup name="winner" onChange={this.handleChange}>
                                <FormControlLabel value={match.playerOne.user.email} control={<Radio/>}
                                                  label={match.playerOne.user.name}/>
                                <FormControlLabel value={match.playerTwo.user.email} control={<Radio/>}
                                                  label={match.playerTwo.user.name}/>
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">{t('common.cancel')}</Button>
                        <Button onClick={this.openFinalConfirmation} color="secondary" autoFocus
                                disabled={disableOk}>{t('common.ok')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openFinalConfirmation} onClose={this.handleCloseFinalConfirmation} maxWidth="xs">
                    <DialogTitle>
                        {t('components.matchplay.settings.MatchplayAbandonMatch.finalConfirmDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Trans
                                i18nKey="components.matchplay.settings.MatchplayAbandonMatch.finalConfirmDialogText"
                                values={{ winner: matchResult && matchResult.winner.name }}
                                components={{ player: <span className={classes.name}/> }}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseFinalConfirmation} color="primary" autoFocus>
                            {t('common.cancel')}
                        </Button>
                        <CtaButton onClick={this.handleEndGame} color="danger" variant="text">
                            {t('common.ok')}
                        </CtaButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    private onEndGame = () => {
        this.setState({ open: true, disableOk: true, matchResult: null });
    };

    private handleEndGame = () => {
        if (this.props.match && this.state.matchResult) {
            this.props.abandonMatch(this.props.match.id, this.state.matchResult);
            this.handleCloseFinalConfirmation();
            this.handleClose();
        }
    };

    private handleClose = () => {
        this.setState({ open: false });
    };

    private handleCloseFinalConfirmation = () => {
        this.setState({ openFinalConfirmation: false });
    }

    private openFinalConfirmation = () => {
        this.setState({ openFinalConfirmation: true });
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = (event.target as HTMLInputElement).value;
        const userPlayerOne = this.props.match?.playerOne?.user
        const userPlayerTwo = this.props.match?.playerTwo?.user
        const result = this.props.isPlayer ? MatchResult.ABANDONED_BY_PLAYER : MatchResult.ABANDONED_BY_ORGANIZER;

        if (userPlayerOne && userPlayerTwo) {
            if (userPlayerOne.email === email) {
                this.setState({
                    disableOk: false,
                    matchResult: { winner: userPlayerOne, loser: userPlayerTwo, result }
                });
            } else {
                this.setState({
                    disableOk: false,
                    matchResult: { winner: userPlayerTwo, loser: userPlayerOne, result }
                });
            }
        }
    };
}

export default withStyles(styles)(withTranslation()(MatchplayAbandonMatch));
