import { AnyAction } from 'redux';

import { createResourceGroup, createResourceIdMap, ResourceGroup, ResourceIdMap } from '../../lib/resourceSupport';
import { Errors } from '../../models/Errors';
import { Matchplay, MatchplayPhase } from '../../models/Matchplay';
import { MatchplayRegistration } from '../../models/MatchplayRegistration';

export const LIST_MATCHPLAYS_REQUEST = 'matchplay/LIST_REQUEST';
export const LIST_MATCHPLAYS_SUCCESS = 'matchplay/LIST_SUCCESS';
export const LIST_MATCHPLAYS_FAILURE = 'matchplay/LIST_FAILURE';
export const GET_MATCHPLAY_REQUEST = 'matchplay/GET_REQUEST';
export const GET_MATCHPLAY_SUCCESS = 'matchplay/GET_SUCCESS';
export const GET_MATCHPLAY_FAILURE = 'matchplay/GET_FAILURE';
export const GET_MATCHPLAY_REGISTRATIONS_REQUEST = 'matchplay/GET_REGISTRATIONS_REQUEST';
export const GET_MATCHPLAY_REGISTRATIONS_SUCCESS = 'matchplay/GET_REGISTRATIONS_SUCCESS';
export const GET_MATCHPLAY_REGISTRATIONS_FAILURE = 'matchplay/GET_REGISTRATIONS_FAILURE';
export const CREATE_MATCHPLAY_REQUEST = 'matchplay/CREATE_REQUEST';
export const CREATE_MATCHPLAY_SUCCESS = 'matchplay/CREATE_SUCCESS';
export const CREATE_MATCHPLAY_FAILURE = 'matchplay/CREATE_FAILURE';
export const UPDATE_MATCHPLAY_REQUEST = 'matchplay/UPDATE_REQUEST';
export const UPDATE_MATCHPLAY_SUCCESS = 'matchplay/UPDATE_SUCCESS';
export const UPDATE_MATCHPLAY_FAILURE = 'matchplay/UPDATE_FAILURE';
export const COMMIT_MATCHPLAY_PHASE_REQUEST = 'matchplay/COMMIT_PHASE_REQUEST';
export const COMMIT_MATCHPLAY_PHASE_SUCCESS = 'matchplay/COMMIT_PHASE_SUCCESS';
export const COMMIT_MATCHPLAY_PHASE_FAILURE = 'matchplay/COMMIT_PHASE_FAILURE';
export const REGISTER_MATCHPLAY_USER_REQUEST = 'matchplay/REGISTER_USER_REQUEST';
export const REGISTER_MATCHPLAY_USER_SUCCESS = 'matchplay/REGISTER_USER_SUCCESS';
export const REGISTER_MATCHPLAY_USER_FAILURE = 'matchplay/REGISTER_USER_FAILURE';
export const UNREGISTER_MATCHPLAY_USER_REQUEST = 'matchplay/UNREGISTER_USER_REQUEST';
export const UNREGISTER_MATCHPLAY_USER_SUCCESS = 'matchplay/UNREGISTER_USER_SUCCESS';
export const UNREGISTER_MATCHPLAY_USER_FAILURE = 'matchplay/UNREGISTER_USER_FAILURE';
export const INITIALIZE_PLAYOFF_MATCHPLAY_REQUEST = 'matchplay/INITIALIZE_PLAYOFF_MATCHPLAY_REQUEST';
export const INITIALIZE_PLAYOFF_MATCHPLAY_SUCCESS = 'matchplay/INITIALIZE_PLAYOFF_MATCHPLAY_SUCCESS';
export const INITIALIZE_PLAYOFF_MATCHPLAY_FAILURE = 'matchplay/INITIALIZE_PLAYOFF_MATCHPLAY_FAILURE';
export const INITIALIZE_GROUP_MATCHPLAY_REQUEST = 'matchplay/INITIALIZE_GROUP_MATCHPLAY_REQUEST';
export const INITIALIZE_GROUP_MATCHPLAY_SUCCESS = 'matchplay/INITIALIZE_GROUP_MATCHPLAY_SUCCESS';
export const INITIALIZE_GROUP_MATCHPLAY_FAILURE = 'matchplay/INITIALIZE_GROUP_MATCHPLAY_FAILURE';
export const DELETE_MATCHPLAY_REQUEST = 'matchplay/DELETE_MATCHPLAY_REQUEST';
export const DELETE_MATCHPLAY_SUCCESS = 'matchplay/DELETE_MATCHPLAY_SUCCESS';
export const DELETE_MATCHPLAY_FAILURE = 'matchplay/DELETE_MATCHPLAY_FAILURE';
export const START_GROUP_PLAYOFF_REQUEST = 'matchplay/START_GROUP_PLAYOFF_REQUEST';
export const START_GROUP_PLAYOFF_SUCCESS = 'matchplay/START_GROUP_PLAYOFF_SUCCESS';
export const START_GROUP_PLAYOFF_FAILURE = 'matchplay/START_GROUP_PLAYOFF_FAILURE';

interface MatchplayState {
    isLoading: boolean,
    items: Matchplay[];
    byId: ResourceIdMap<Matchplay>;
    registrations: MatchplayRegistration[];
    registrationsByMatchplayId: ResourceGroup<MatchplayRegistration>;
    lastError?: Errors;
}

const initialState: MatchplayState = {
    isLoading: false,
    items: [],
    byId: {},
    registrations: [],
    registrationsByMatchplayId: {},
};

export const matchplayReducer = (state: MatchplayState = initialState, action: AnyAction): MatchplayState => {
    switch (action.type) {
        case LIST_MATCHPLAYS_REQUEST:
        case GET_MATCHPLAY_REQUEST:
        case GET_MATCHPLAY_REGISTRATIONS_REQUEST:
        case CREATE_MATCHPLAY_REQUEST:
        case UPDATE_MATCHPLAY_REQUEST:
        case COMMIT_MATCHPLAY_PHASE_REQUEST:
        case REGISTER_MATCHPLAY_USER_REQUEST:
        case UNREGISTER_MATCHPLAY_USER_REQUEST:
        case INITIALIZE_PLAYOFF_MATCHPLAY_REQUEST:
        case INITIALIZE_GROUP_MATCHPLAY_REQUEST:
        case DELETE_MATCHPLAY_REQUEST:
        case START_GROUP_PLAYOFF_REQUEST:
            return { ...state, isLoading: true };
        case LIST_MATCHPLAYS_SUCCESS:
            return { ...state, isLoading: false, lastError: undefined, items: action.payload, byId: createResourceIdMap(action.payload) }
        case GET_MATCHPLAY_SUCCESS:
        case UPDATE_MATCHPLAY_SUCCESS:
        case COMMIT_MATCHPLAY_PHASE_SUCCESS: {
            const index = state.items.findIndex(item => item.id === action.payload.id);
            // create or update
            const items = index >= 0
                ? state.items.map(item => (item.id === action.payload.id) ? action.payload : item)
                : [...state.items, action.payload];
            const byId = createResourceIdMap(items);
            return { ...state, isLoading: false, lastError: undefined, items, byId };
        }
        case GET_MATCHPLAY_REGISTRATIONS_SUCCESS: {
            const payloadItems = action.payload as MatchplayRegistration[];
            const registrations = [
                ...state.registrations.filter(item => !payloadItems.find(x => x.id === item.id)),
                ...payloadItems,
            ];
            const registrationsByMatchplayId = createResourceGroup(registrations, (item: MatchplayRegistration) => item.matchplayId);
            return { ...state, isLoading: false, lastError: undefined, registrations, registrationsByMatchplayId };
        }
        case CREATE_MATCHPLAY_SUCCESS: {
            const items = state.items.slice().concat([action.payload]);
            const byId = createResourceIdMap(items);
            return { ...state, isLoading: false, lastError: undefined, items, byId };
        }
        case REGISTER_MATCHPLAY_USER_SUCCESS: {
            const registrations = [
                ...state.registrations.filter(item => item.id !== action.payload.id),
                action.payload,
            ];
            const registrationsByMatchplayId = createResourceGroup(registrations, (item: MatchplayRegistration) => item.matchplayId);
            return { ...state, isLoading: false, lastError: undefined, registrations, registrationsByMatchplayId };
        }
        case UNREGISTER_MATCHPLAY_USER_SUCCESS: {
            const registrations = state.registrations.filter(item => item.id !== action.payload);
            const registrationsByMatchplayId = createResourceGroup(registrations, (item: MatchplayRegistration) => item.matchplayId);
            return { ...state, isLoading: false, lastError: undefined, registrations, registrationsByMatchplayId };
        }
        case START_GROUP_PLAYOFF_SUCCESS: {
            const items = state.items.map(item => item.id === action.payload.id
                ? { ...item, phase: MatchplayPhase.EXECUTION }
                : item);
            const byId = createResourceIdMap(items);
            return { ...state, isLoading: false, lastError: undefined, items, byId };
        }
        case LIST_MATCHPLAYS_FAILURE:
        case GET_MATCHPLAY_FAILURE:
        case GET_MATCHPLAY_REGISTRATIONS_FAILURE:
        case CREATE_MATCHPLAY_FAILURE:
        case UPDATE_MATCHPLAY_FAILURE:
        case COMMIT_MATCHPLAY_PHASE_FAILURE:
        case REGISTER_MATCHPLAY_USER_FAILURE:
        case UNREGISTER_MATCHPLAY_USER_FAILURE:
        case INITIALIZE_PLAYOFF_MATCHPLAY_SUCCESS:
        case INITIALIZE_PLAYOFF_MATCHPLAY_FAILURE:
        case INITIALIZE_GROUP_MATCHPLAY_SUCCESS:
        case INITIALIZE_GROUP_MATCHPLAY_FAILURE:
        case DELETE_MATCHPLAY_SUCCESS:
        case DELETE_MATCHPLAY_FAILURE:
        case START_GROUP_PLAYOFF_FAILURE:
            return { ...state, isLoading: false, lastError: action.payload };
    }
    return state;
};
