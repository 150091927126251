import { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { Box, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormikTextField from '../utils/forms/FormikTextField';
import Section from '../utils/Section';
import { TenantInformation, UpdateTenantRequest } from '../../models/TenantInformation';

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        marginTop: theme.spacing(2)
    },
}));

interface Props {
    tenantInformation: TenantInformation;
    updateTenantInformation(payload: UpdateTenantRequest): void;
}

const AdminTenantDetails = ({ tenantInformation, updateTenantInformation }: Props) => {
    const { t } = useTranslation();

    const schema = Yup.object().shape({
        name: Yup.string()
            .min(2, t('validation.tenantName.min'))
            .max(50, t('validation.tenantName.max'))
            .required(t('validation.tenantName.required')),
    });

    const onSubmit = useCallback(async (tenantInformation: TenantInformation) => {
        updateTenantInformation({ name: tenantInformation.name })
    }, [updateTenantInformation]);

    return (
        <Section title={`${t('components.tenant.AdminTenantDetails.title')}`} id="admin-tenant-details">
            <Formik component={AdminTenantDetailsForm}
                alidationSchema={schema}
                initialValues={tenantInformation}
                onSubmit={onSubmit} />
        </Section>
    );
};

type AdminTenantDetailsFormProps = FormikProps<TenantInformation>;

const AdminTenantDetailsForm = (props: AdminTenantDetailsFormProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Form>
            <FormikTextField type="text" name="name" label="Name der Organisation" margin="normal" variant="outlined"
                fullWidth />
            <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.actions}>
                <Button variant="contained" color="primary" type="submit" className="right"
                    disabled={props.isSubmitting}>
                    {t('common.save')}
                </Button>
            </Box>
        </Form>
    );
};

export default AdminTenantDetails;
