import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import MomentUtils from '@date-io/moment';
import { Button, CardActions, CardContent } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface State {
    dateSelection: MaterialUiPickersDate | null;
}

interface Props {
    onSubmit(date: Date): void;

    onCancel?(): void;
}

class MatchActivityActionProposeDate extends Component<Props & WithTranslation, State> {

    public constructor(props: Props & WithTranslation) {
        super(props);
        this.state = { dateSelection: null };
    }

    public render() {
        const { onCancel, t } = this.props;
        const { dateSelection } = this.state;
        const actionEnabled = dateSelection !== null && dateSelection > (new MomentUtils().date());
        return (
            <>
                <CardContent>
                    <DatePicker
                        value={dateSelection}
                        onChange={this.onDateChange}
                        disablePast={true}
                        label={t('common.date')}
                        cancelLabel={t('common.cancel')}
                        format={t('dateTime.matchDate')}
                    />
                    &emsp;
                    <TimePicker
                        value={dateSelection}
                        onChange={this.onDateChange}
                        ampm={false}
                        label={t('common.time')}
                        cancelLabel={t('common.cancel')}
                    />
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!actionEnabled}
                        onClick={this.onSubmit}>
                        {t('components.match.activities.MatchActivityActionProposeDate.actionPropose')}
                    </Button>
                    {onCancel && <Button size="small" onClick={onCancel}>{t('common.cancel')}</Button>}
                </CardActions>
            </>
        );
    }

    private onDateChange = (dateSelection: MaterialUiPickersDate) => {
        this.setState({ dateSelection });
    }

    private onSubmit = () => {
        const { dateSelection } = this.state;
        if (dateSelection) {
            this.props.onSubmit(dateSelection.toDate());
        }
    }
}

export default withTranslation()(MatchActivityActionProposeDate);
