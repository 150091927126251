import React, { Component } from 'react';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import MatchContainer from '../match/MatchContainer';
import ContentContainer from './ContentContainer';

class MatchPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer/>
                <BreadcrumbsContainer/>
                <MatchContainer/>
            </ContentContainer>
        );
    }
}

export default MatchPage;
