import React from 'react';
import { Trans } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: 400,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid',

        '& > svg': {
            marginRight: theme.spacing(3),
        },
    },
}));

const MaintenancePage = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <ErrorIcon fontSize="large" color="error" />
            <Trans i18nKey="components.layout.MaintenancePage.message" />
        </div>
    );
};

export default MaintenancePage;
