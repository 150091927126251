import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect, useHistory } from 'react-router';

import useCurrentMatchplay from '../../../../hooks/useCurrentMatchplay';
import { GroupAssignment } from '../../../../models/GroupAssignment';
import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';
import { ReduxStoreState } from '../../../../store';
import { initializeGroupMatchplayAction } from '../../../../store/actions/matchplays';
import { firstComeFirstServe, shuffled, isPlayable as checkIsPlayable } from './GroupStrategies';
import MatchplayGroupsPreparation from './MatchplayGroupsPreparation';

const MatchplayGroupsPreparationContainer = () => {
    const [groups, setGroups] = useState<MatchplayRegistration[][]>([]);
    const [isPlayable, setIsPlayable] = useState(false);

    const matchplayRegistrationsById = useSelector((state: ReduxStoreState) => state.matchplay.registrationsByMatchplayId);
    const dispatch = useDispatch();
    const history = useHistory();

    const matchplay = useCurrentMatchplay();
    const registrations = useMemo(() => matchplayRegistrationsById[matchplay?.id ?? ''] || [], [matchplay, matchplayRegistrationsById]);

    useEffect(() => {
        const newValues = firstComeFirstServe({ registrations, matchplay });
        setGroups(newValues.groups);
        setIsPlayable(newValues.isPlayable);
    }, [registrations, matchplay]);

    const onStartMatchplay = useCallback(() => {
        if (!matchplay) {
            return;
        }
        const groupAssignments = groups.map<GroupAssignment>(x => ({
            players: x.map(y => y.user),
        }));
        initializeGroupMatchplayAction(dispatch)(matchplay, groupAssignments);
        history.replace(generatePath('/admin/matchplays/:id', { id: matchplay.id }));
    }, [matchplay, groups, dispatch, history]);

    const onMoveGroupRegistration = useCallback((registration: MatchplayRegistration, from: number, to: number) => {
        const newGroups = [...groups];
        newGroups[from] = newGroups[from].filter(x => x.id !== registration.id);
        newGroups[to] = newGroups[to].concat([registration]);
        setGroups(newGroups);
        setIsPlayable(checkIsPlayable(newGroups));
    }, [groups, setGroups]);

    const onRegistrationsShuffle = useCallback(() => {
        const newValues = shuffled({ registrations, matchplay });
        setGroups(newValues.groups);
        setIsPlayable(newValues.isPlayable);
    }, [registrations, matchplay]);

    const onRegistrationsFirstComeFirstServe = useCallback(() => {
        const newValues = firstComeFirstServe({ registrations, matchplay });
        setGroups(newValues.groups);
        setIsPlayable(newValues.isPlayable);
    }, [registrations, matchplay]);

    if (!matchplay || !matchplay.groupSettings) {
        return <Redirect to="/" />;
    }

    return (
        <MatchplayGroupsPreparation
            groups={groups}
            isPlayable={isPlayable}
            onStartMatchplay={onStartMatchplay}
            onMoveGroupRegistration={onMoveGroupRegistration}
            onRegistrationsShuffle={onRegistrationsShuffle}
            onRegistrationsFirstComeFirstServe={onRegistrationsFirstComeFirstServe} />
    );
};

export default MatchplayGroupsPreparationContainer;
