import React, { Component, Fragment } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import ReactMarkdownRenderer from '../../utils/ReactMarkdownRenderer';

interface ComponentProps {
    isJoined: boolean
    isDisabled: boolean;
    participationTermsText?: string;
    onClick(): void;
}

interface State {
    dialogOpen: boolean;
}

type Props = ComponentProps & WithTranslation;

class MatchplayJoinButton extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            dialogOpen: false,
        }
    }

    public render() {
        const { isJoined, isDisabled, onClick, t, participationTermsText } = this.props;
        const { dialogOpen } = this.state;
        const icon = isJoined ? <PersonAddDisabledIcon/> : <PersonAddIcon/>
        const showDialog = participationTermsText && participationTermsText.length > 0;

        return (<>
            <Button variant="contained" color="secondary"
                    disabled={isDisabled}
                    startIcon={icon}
                    onClick={!isJoined && showDialog ? this.openDialog : onClick}>
                {!isJoined && <Fragment>{t('components.matchplay.dashboard.MatchplayJoinButton.join')}</Fragment>}
                {isJoined && <Fragment>{t('components.matchplay.dashboard.MatchplayJoinButton.leave')}</Fragment>}
            </Button>
            <Dialog open={dialogOpen} onClose={this.closeDialog} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('components.matchplay.dashboard.MatchplayJoinButton.confirmDialogTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="subtitle1">
                            <Trans i18nKey="components.matchplay.dashboard.MatchplayJoinButton.confirmDialogText"/>
                        </Typography>
                        <Typography variant="subtitle2">
                            <ReactMarkdownRenderer value={participationTermsText}/>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDialog} color="primary">{t('common.cancel')}</Button>
                    <Button onClick={this.handleOnClick} color="secondary" autoFocus> {t('common.confirm')}</Button>
                </DialogActions>
            </Dialog>
        </>);
    }

    private handleOnClick = () => {
        this.props.onClick();
        this.closeDialog();
    }

    private openDialog = () => {
        this.setState({ dialogOpen: true });
    };

    private closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
}

export default withTranslation()(MatchplayJoinButton);
