import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link,
    Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { DisplayArea, Sponsor } from '../../models/Sponsor';
import { displayAreasToDisplayText } from '../utils/Sponsor';
import LogoPreview from './LogoPreview';

interface ComponentProps {
    sponsor: Sponsor;
    hideDisplayArea?: boolean;
    deleteSponsor(sponsor: Sponsor): void;
}

interface State {
    open: boolean;
}

type Props = ComponentProps & WithTranslation;

class AdminSponsorsItem extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    public render() {
        const { sponsor, hideDisplayArea, t } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item>
                        <LogoPreview
                            url={sponsor.logoUrl}
                            alt={sponsor.name}
                            useBorder={true} />
                    </Grid>
                    <Grid item xs>
                        <Box display="flex" height="100%" justifyContent="flex-start" flexDirection="column">
                            <Typography variant="h6" component="p">
                                {sponsor.name}
                            </Typography>
                            <Typography variant="body1" component="p">
                                <Link href={sponsor.targetUrl} target="_blank" rel="noopener">{sponsor.targetUrl}</Link>
                            </Typography>
                            {!hideDisplayArea && sponsor.displayArea && sponsor.displayArea.length > 0 ?
                                <Typography variant="body2">
                                    {t('components.tenant.AdminSponsorsItem.displayedOn')}&nbsp;
                                    {sponsor.displayArea.map((displayArea: DisplayArea, index: number) =>
                                        displayAreasToDisplayText(displayArea) + ((sponsor.displayArea && sponsor.displayArea?.length > index + 1) ? ', ' : '')
                                    )}
                                </Typography>
                                : null}
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display="flex" height="100%" justifyContent="center" flexDirection="column">
                            <Button variant="outlined" color="secondary" startIcon={<Delete />} component="span"
                                onClick={this.onDelete}>
                                {t('common.delete')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{t('components.tenant.AdminSponsorsItem.deleteDialogTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('components.tenant.AdminSponsorsItem.deleteDialogContent', { sponsorName: sponsor.name })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">{t('common.cancel')}</Button>
                        <Button onClick={this.handleDelete} color="secondary" autoFocus>{t('common.ok')}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    private onDelete = () => {
        this.setState({ open: true });
    };

    private handleDelete = () => {
        this.props.deleteSponsor(this.props.sponsor);
        this.handleClose();
    };

    private handleClose = () => {
        this.setState({ open: false });
    };
}

export default withTranslation()(AdminSponsorsItem);
