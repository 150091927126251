import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import { generatePath, Link, LinkProps } from 'react-router-dom';

export function PageLink(pattern: string, params?: { [paramName: string]: string | number | boolean | undefined }): ForwardRefExoticComponent<PropsWithoutRef<Omit<LinkProps, 'innerRef' | 'to'>> & RefAttributes<HTMLAnchorElement>> {
    return (
        React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>((props: any, ref: any) =>
            <Link innerRef={ref} to={generatePath(pattern, params)} {...props} />
        )
    );
}
