import {Formik, FormikProps} from 'formik';
import {Component} from 'react';
import {useTranslation, WithTranslation, withTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core';

import {getMatchWinnerEmail, Match} from '../../../models/Match';
import {User} from '../../../models/User';

const styles = (theme: Theme) => ({
  checkedContainer: {
    marginLeft: 48,
    marginTop: theme.spacing(2)
  },
  datePicker: {
    width: 250
  },
  warning: {
    fontWeight: 900,
    color: theme.palette.error.main,
  }
});

// const useStyles = makeStyles(styles);

export interface ChangeWinnerSubmitData {
  winner: string;
}

interface Props {
  match: Match;

  onSubmit(data: ChangeWinnerSubmitData): void;
}

export interface FormPayload {
  winner: string;
  player1: User;
  player2: User;
}

interface State {
  open: boolean;
  payload?: FormPayload;
}

class MatchActivityActionChangeWinner extends Component<Props & WithTranslation & WithStyles<typeof styles>, State> {

  constructor(props: Props & WithTranslation & WithStyles<typeof styles>) {
    super(props);
    this.state = {
      open: false,
    }
  }

  private schema = () => {
    const {t} = this.props;
    return Yup.object().shape({
      winner: Yup.string()
          .required(t('validation.winner.required'))
    });
  }

  public render() {
    const {match, classes, t} = this.props;
    const {open} = this.state;

    if (!match || !match.playerOne || !match.playerTwo) {
      return null;
    }
    const player1 = match.playerOne;
    const player2 = match.playerTwo;

    const initialValues: FormPayload = {
      winner: getMatchWinnerEmail(match),
      player1: player1.user,
      player2: player2.user,
    };
    return (
        <>
          <Formik validationSchema={this.schema}
                  initialValues={initialValues}
                  component={Form}
                  onSubmit={this.onSubmit}/>
          <Dialog open={open}
                  onClose={this.handleClose}
                  disableBackdropClick
                  disableEscapeKeyDown>
            <DialogTitle>
              {t('components.match.activities.MatchActivityActionChangeWinner.editDialog.title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.warning}>
                {t('components.match.activities.MatchActivityActionChangeWinner.editDialog.warning')}
              </DialogContentText>
              <DialogContentText>
                {t('components.match.activities.MatchActivityActionChangeWinner.editDialog.text')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                {t('common.cancel')}
              </Button>
              <Button onClick={this.handleChangeGame} color="secondary">
                {t('common.ok')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
    );
  }

  private handleClose = () => {
    this.setState({open: false});
  };

  private handleChangeGame = async () => {
    if (this.state.payload) {
      this.setState({open: false});
      await this.props.onSubmit({
        winner: this.state.payload.winner
      });
    }
  }

  private onSubmit = async (payload: FormPayload) => {
    await this.doSubmit(payload);
  }

  private doSubmit = async (payload: FormPayload) => {
    // open dialog for confirmation
    this.setState({open: true, payload});
  }
}

const Form = ({
                values, errors, touched, dirty, isSubmitting, isValid, isInitialValid,
                handleBlur, handleChange, handleSubmit
              }: FormikProps<FormPayload>) => {
  const {t} = useTranslation();
  const submitDisabled = (!dirty && !isInitialValid) || !isValid || isSubmitting;

  return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <FormControl component={'fieldset' as 'div'}
                           fullWidth
                           error={touched.winner && Boolean(errors.winner)}>
                <FormLabel component={'legend' as 'span'}>
                  {t('components.match.activities.MatchActivityActionChangeWinner.winner')}
                </FormLabel>
                {errors.winner && <FormHelperText>{errors.winner}</FormHelperText>}
                <RadioGroup id="winner" name="winner"
                            onChange={handleChange} onBlur={handleBlur}
                            value={values.winner}>
                  <FormControlLabel value={values.player1.email} control={<Radio/>}
                                    label={values.player1.name}/>
                  <FormControlLabel value={values.player2.email} control={<Radio/>}
                                    label={values.player2.name}/>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" type="submit" disabled={submitDisabled}>
            {t('common.save')}
          </Button>
        </CardActions>
      </form>
  );
};

export default withStyles(styles)(withTranslation()(MatchActivityActionChangeWinner));
