import React, { Component } from 'react';
import {
    Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Theme, Tooltip,
    Typography, withStyles, WithStyles
} from '@material-ui/core';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';

import { CreateOrUpdateNotification, Notification, NotificationState } from '../../../models/Notification';
import { stableSort } from '../../../lib/sorting';
import NotificationItemForm from './AdminNotificationItemFrom';
import ConfirmationButton from '../../utils/ConfirmationButton';

const styles = (theme: Theme) => ({
    root: {
        paddingTop: 0,
    },
    editMode: {
        padding: theme.spacing(2),
    },
    itemText: {
        paddingRight: theme.spacing(6)
    },
    draft: {
        color: theme.palette.error.main,
    }
})

interface ComponentProps {
    notifications: Notification[];
    editNotification(notification: Notification, payload: CreateOrUpdateNotification): void;
    deleteNotification(notification: Notification): void;
    publishNotification(notification: Notification): void;
}

interface State {
    isEditing?: boolean;
    notification?: Notification
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class AdminNotificationList extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {

        const { notifications, deleteNotification, publishNotification, classes, t } = this.props;
        const { isEditing, notification } = this.state;

        const now = moment().unix() * 1000;
        const visibilityOn = (n: Notification) => {
            if (!n.startAt && !n.endAt) {
                return true;
            } else if (n.startAt && now >= n.startAt && n.endAt && now <= n.endAt) {
                return true;
            } else if (!n.startAt && n.endAt && now <= n.endAt) {
                return true;
            } else if (n.startAt && now >= n.startAt && !n.endAt) {
                return true;
            }
            return false;
        };

        const notificationElements = stableSort(notifications, ['createdAt']).reverse().map((item: Notification) => {
            const editMode = isEditing && notification && item.id === notification.id;
            const createdAtFormatted = moment(item.createdAt).format(t('dateTime.matchDateTime'));
            const updatedAtFormatted = moment(item.updatedAt).format(t('dateTime.matchDateTime'));
            const secondaryText = t('components.tenant.notification.NotificationList.createdAt', {
                createdAt: createdAtFormatted, userName: item.author.name
            });
            const secondaryTextAddon = item.createdAt !== item.updatedAt &&
                <Trans i18nKey="components.tenant.notification.NotificationList.updatedAt"
                       values={{ updatedAt: updatedAtFormatted }}/>;
            let tooltipText = '';
            if (item.state === NotificationState.DRAFT) {
                tooltipText = t('components.tenant.notification.NotificationList.tooltip.draft');
            } else if (item.state === NotificationState.PUBLISHED && visibilityOn(item)) {
                tooltipText = t('components.tenant.notification.NotificationList.tooltip.visibilityOn');
            } else if (item.state === NotificationState.PUBLISHED && !visibilityOn(item)) {
                tooltipText = t('components.tenant.notification.NotificationList.tooltip.visibilityOff');
            }

            const draft =
                <Typography variant={'body2'} className={classes.draft} display="inline">
                    {item.state === NotificationState.DRAFT ? ' [' + t('components.tenant.notification.NotificationList.tooltip.draft') + ']' : ''}
                </Typography>
            const visible =
                <Typography variant={'body2'} display="inline">
                    {item.state === NotificationState.PUBLISHED && visibilityOn(item) ? ' [' + t('components.tenant.notification.NotificationList.tooltip.visible') + ']' : ''}
                </Typography>
            const invisible =
                <Typography variant={'body2'} display="inline">
                    {item.state === NotificationState.PUBLISHED && !visibilityOn(item) ? ' [' + t('components.tenant.notification.NotificationList.tooltip.invisible') + ']' : ''}
                </Typography>

            return (<>
                {!editMode && <ListItem key={item.id}
                                        role={undefined}
                                        divider dense
                                        button onClick={() => this.editNotification(item)}>
                    <ListItemAvatar>
                        <Tooltip title={tooltipText} placement="top">
                            <Avatar>
                                {item.state === NotificationState.DRAFT}
                                {item.state === NotificationState.PUBLISHED && visibilityOn(item) && <VisibilityIcon/>}
                                {item.state === NotificationState.PUBLISHED && !visibilityOn(item) &&
                                    <VisibilityOffIcon/>}
                            </Avatar>
                        </Tooltip>
                    </ListItemAvatar>
                    <ListItemText id={item.id}
                                  primary={<>
                                      {item.subject}
                                      {draft}
                                      {visible}
                                      {invisible}
                                  </>}
                                  secondary={<>
                                      {secondaryText}
                                      {secondaryTextAddon}
                                  </>}
                                  className={classes.itemText}/>
                    <ListItemSecondaryAction>
                        {item.state !== NotificationState.PUBLISHED &&
                            <ConfirmationButton icon={true} variant="secondary"
                                                onConfirm={() => publishNotification(item)}
                                                dialogTitle={t('components.tenant.notification.NotificationList.publish.dialogTitle')}
                                                dialogContentText={t('components.tenant.notification.NotificationList.publish.dialogContent')}>
                                <Tooltip
                                    title={t('components.tenant.notification.NotificationList.tooltip.publishButton') || ''}
                                    placement="top">
                                    <SendIcon/>
                                </Tooltip>
                            </ConfirmationButton>}
                        <ConfirmationButton icon={true} variant="secondary"
                                            onConfirm={() => deleteNotification(item)}
                                            dialogTitle={t('components.tenant.notification.NotificationList.delete.dialogTitle')}
                                            dialogContentText={t('components.tenant.notification.NotificationList.delete.dialogContent')}>
                            <Tooltip
                                title={t('components.tenant.notification.NotificationList.tooltip.deleteButton') || ''}
                                placement="top">
                                <DeleteIcon/>
                            </Tooltip>
                        </ConfirmationButton>
                    </ListItemSecondaryAction>
                </ListItem>}
                {editMode &&
                    <Box key={item.id}>
                        <Box className={classes.editMode}>
                            <NotificationItemForm onSave={this.saveNotification} onCancel={this.cancel}
                                                  notification={notification}/>
                        </Box>
                        <Divider/>
                    </Box>}
            </>);
        });

        return (
            <List className={classes.root}>
                {notificationElements}
            </List>
        );
    }

    private editNotification = (notification: Notification) => {
        if (this.state.isEditing) {
            return;
        }
        this.setState({ isEditing: true, notification });
    };

    private saveNotification = async (payload: CreateOrUpdateNotification) => {
        if (!this.state.notification) {
            return;
        }
        await this.props.editNotification(this.state.notification, payload);
        if (this.state.isEditing) {
            this.setState({ isEditing: false, notification: undefined });
        }
    }

    private cancel = () => {
        if (this.state.isEditing) {
            this.setState({ isEditing: false, notification: undefined });
        }
    }
}

export default withStyles(styles)(withTranslation()(AdminNotificationList));
