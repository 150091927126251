import { createMuiTheme } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';
import createSpacing from '@material-ui/core/styles/createSpacing';
import shadows, { Shadows } from '@material-ui/core/styles/shadows';

const spacing = createSpacing(8);
const theme = createMuiTheme({
    spacing,
    palette: {
        primary: {
            main: '#8fb055',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#222',
            contrastText: '#ffffff',
        },
        background: {
            default: '#fff',
            paper: '#f2f2f2',
        }
    },
    shadows: new Array(shadows.length).fill('none') as Shadows,
    typography: {
        h6: {
            textTransform: 'uppercase'
        }
    },
    overrides: {
        MuiMenu: {
            paper: {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#c4c4c4',
            },
        },
        MuiCardActions: {
            root: {
                padding: spacing(2),
                paddingTop: 0,
            },
        },
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: spacing(2),
                },
            },
        },
    }
}, deDE);

export default theme;
