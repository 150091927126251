import { useDragLayer, XYCoord } from 'react-dnd';

import { makeStyles } from '@material-ui/core';

import { DragItem, DragItemType } from './DragItem';
import { DragItemPreview } from './GroupPreparationItem';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    }
}));

function calculateItemStyle(currentOffset: XYCoord | null) {
    if (!currentOffset) {
        return { display: 'none' };
    }
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebKitTransform: transform,
    };
}

const GroupPreparationItemDragLayer = () => {
    const classes = useStyles();
    const { item, itemType, isDragging, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        isDragging: monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
    }));

    if (!isDragging || itemType !== DragItemType) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div style={calculateItemStyle(currentOffset)}>
                <DragItemPreview {...item as DragItem} />
            </div>
        </div>
    );
};

export default GroupPreparationItemDragLayer;
