import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';

import { User } from '../../models/User';
import AuthenticatedRoute from './AuthenticatedRoute';

interface ComponentProps {
    fallbackTo?: string
}

interface StateProps {
    currentUser?: User
}

type Props = ComponentProps & StateProps & RouteProps;

class AdminRoute extends Component<Props> {
    public render() {
        const { currentUser, fallbackTo, location } = this.props;
        if (currentUser?.isAdmin) {
            return <AuthenticatedRoute {...this.props} />;
        }
        return <Redirect to={{
            pathname: (fallbackTo) ? fallbackTo : '/',
            state: { from: location }
        }} />
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser
});

export default connect(mapStateToProps)(AdminRoute);
