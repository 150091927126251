import clsx from 'clsx';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    Theme, Tooltip, withStyles, WithStyles
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { User } from '../../../models/User';
import SearchTextField from '../../utils/SearchTextField';
import Section from '../../utils/Section';
import UserRoleSelect from './UserRoleSelect';
import { SortDirection } from '@material-ui/core/TableCell/TableCell';

const styles = (theme: Theme) => ({
    deactivated: {
        '& .MuiTableCell-root, & .MuiSelect-root': {
            color: theme.palette.text.disabled,
        },
    },
});

interface ComponentProps {
    users: User[];
    onToggleUserEnable(user: User): void;
    onChangeRole(user: User, isAdmin: boolean): void;
    onSearch(search: string): void;
}

interface State {
    page: number;
    orderBy: string;
    order: SortDirection;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

const rowsPerPage = 25;

class UserManagement extends Component<Props, State> {

    readonly state: State = { page: 0, orderBy: 'email', order: 'asc' };

    public render() {
        const { users, onToggleUserEnable, onChangeRole, onSearch, classes, t } = this.props;
        const { page, orderBy, order } = this.state;
        const rowStyles = (user: User) => clsx({ [classes.deactivated]: !user.accountEnabled });

        const handleRequestSort = (event: any, property: string) => {
            const isAsc = orderBy === property && order === 'asc';
            this.setOrder(isAsc ? 'desc' : 'asc');
            this.setOrderBy(property);
        };

        const createSortHandler = (property: string) => (event: any) => {
            handleRequestSort(event, property);
        };

        const headCells = [
            { id: 'name', label: t('components.tenant.usermanagement.UserManagement.colName') },
            { id: 'email', label: t('components.tenant.usermanagement.UserManagement.colEmail') },
            { id: 'isAdmin', label: t('components.tenant.usermanagement.UserManagement.colRole') },
            { id: 'accountEnabled', label: t('components.tenant.usermanagement.UserManagement.colStatus') },
        ];

        return (
            <Section title={t('components.tenant.usermanagement.UserManagement.title')}
                     actions={<SearchTextField onSearch={onSearch}
                                               placeholder={t('components.tenant.usermanagement.UserManagement.search')}/>}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell key={headCell.id}
                                               sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel active={orderBy === headCell.id}
                                                        direction={orderBy === headCell.id ? (order === 'asc' ? 'asc' : 'desc') : 'asc'}
                                                        onClick={createSortHandler(headCell.id)}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.stableSort(users, this.getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(user => (
                                    <TableRow key={user.email} className={rowStyles(user)}>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            <UserRoleSelect user={user} onChangeRole={onChangeRole}/>
                                        </TableCell>
                                        <TableCell>
                                            {user.accountEnabled ? t('common.enabled') : t('common.disabled')}
                                        </TableCell>
                                        <TableCell align="right" padding="none">
                                            <Tooltip
                                                title={`${user.accountEnabled ? t('common.disable') : t('common.enable')}`}
                                                placement="right">
                                                <IconButton onClick={() => onToggleUserEnable(user)}>
                                                    {user.accountEnabled &&
                                                        <RemoveCircleIcon/>}
                                                    {!user.accountEnabled &&
                                                        <CheckCircleOutlineIcon/>}
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    rowsPerPage={rowsPerPage}
                    count={users.length}
                    page={page}
                    component="div"
                    onChangePage={this.onChangePage}
                />
            </Section>
        );
    }

    private onChangePage = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        this.setState({ page });
    }

    private setOrder = (order: SortDirection) => {
        this.setState({ order });
    }

    private setOrderBy = (orderBy: string) => {
        this.setState({ orderBy });
    }

    private descendingComparator = (a: any, b: any, orderBy: string) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    private getComparator = (order: SortDirection, orderBy: string) => {
        return order === 'desc'
            ? (a: any, b: any) => this.descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -this.descendingComparator(a, b, orderBy);
    }

    private stableSort = (array: User[], comparator: any) => {
        const stabilizedThis = array.map((el: any, index: number) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el: any) => el[0]);
    }
}

export default withStyles(styles)(withTranslation()(UserManagement));
