import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';

import { matchReducer as match } from './reducers/match';
import { matchplayReducer as matchplay } from './reducers/matchplay';
import { pageReducer as page } from './reducers/page';
import { sponsorsReducer as sponsors } from './reducers/sponsors';
import { tenantReducer as tenant } from './reducers/tenant';
import { userReducer as user } from './reducers/user';
import { userManagementReducer as userManagement } from './reducers/usermanagement';
import { errorReducer as error } from './reducers/error';
import { notificationReducer as notification } from './reducers/notification';

const reducers = combineReducers({
    matchplay,
    match,
    page,
    sponsors,
    tenant,
    user,
    userManagement,
    error,
    notification,
});

const middlewares = [];
if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));
export default store;
export type ReduxStoreState = ReturnType<typeof store.getState>;
