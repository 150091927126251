export interface User {
    id: string;
    email: string;
    name: string;
    hcp: number;
    status: string;
    imageUrl: string;
    isAdmin: boolean;
    accountEnabled: boolean;
    club: string;
    deleted: boolean;
}

export interface UserRegistration {
    email: string;
    name: string;
    password: string;
}

export interface UserLogin {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface UserMatchStats {
    matchesPlayed: number;
    matchesWon: number;
    matchesLost: number;
    winRatio: number;
}

export interface UserChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export const botUser = { email: 'bot@matchplaytime.com' } as User;
