import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, WithStyles, withStyles } from '@material-ui/core';

import { TenantInformation } from '../../models/TenantInformation';
import { User } from '../../models/User';
import TenantLogo from '../tenant/TenantLogo';
import MainNavigationLink from './MainNavigationLink';
import UserMenu from './UserMenu';

const styles = (theme: Theme) => ({
    root: {
        minHeight: 64,
        display: 'flex' as const,
        flexDirection: 'row' as const,
        paddingTop: theme.spacing(1),
    },
    menu: {
        display: 'flex' as const,
        flexDirection: 'row' as const,
        margin: 0,
        marginLeft: 'auto',
        padding: 0,
    },
});

interface ComponentProps {
    tenantInformation: TenantInformation,
    currentUser?: User,
}

type Props = ComponentProps & WithStyles<typeof styles>;

const MainNavigationBar = ({ tenantInformation, currentUser, classes }: Props) => {
    const { t } = useTranslation();
    return (
        <nav className={classes.root}>
            <TenantLogo tenantInformation={tenantInformation} />
            <div className={classes.menu}>
                <MainNavigationLink to="/">{t('components.navigation.MainNavigationBar.home')}</MainNavigationLink>
                <MainNavigationLink to="/matchplays">{t('components.navigation.MainNavigationBar.matchplays')}</MainNavigationLink>
                <UserMenu currentUser={currentUser} />
            </div>
        </nav>
    );
}

export default withStyles(styles)(MainNavigationBar);
