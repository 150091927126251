import React, { Component, Fragment } from 'react';
import ContentContainer from './ContentContainer';
import { Box, Grid, Link, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { PageLink } from '../utils/PageLink';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';

const styles = (theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid',
    },
    logo: {
        maxHeight: 72,
        maxWidth: 256,
    },
});

type Props = WithStyles<typeof styles> & WithTranslation & RouteComponentProps;

class RedirectPage extends Component<Props> {

    public render() {
        const { classes, location } = this.props;
        const params = queryString.parse(location.search)

        if (!params.href || Array.isArray(params.href)) {
            return;
        }

        setTimeout(() => params.href && !Array.isArray(params.href) && (window.location.href = params.href), 10000);

        return (
            <Fragment>
                <ContentContainer>
                    <Box marginTop={1} marginBottom={2}>
                        <Link component={PageLink('/')}>
                            <img src="/tenant-logo-fallback.png" alt="MatchplayTime" className={classes.logo}/>
                        </Link>
                    </Box>
                    <Grid container direction="column" className={classes.root}>
                        <Typography variant="h5">
                            <Trans i18nKey="components.layout.RedirectPage.title"/>
                        </Typography>
                        <Box marginTop={2} marginBottom={2}>
                            <Typography>
                                <Trans i18nKey="components.layout.RedirectPage.message1"/>
                                &nbsp;
                                <a href={params.href} rel="nofollow noopener noreferrer">
                                    {params.href}
                                </a>
                            </Typography>
                            <Typography variant="subtitle2">
                                <Trans i18nKey="components.layout.RedirectPage.message2"/>
                            </Typography>
                        </Box>
                        <Typography variant="subtitle1">
                            <Trans i18nKey="components.layout.RedirectPage.disclaimer"/>
                        </Typography>
                    </Grid>
                </ContentContainer>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(withTranslation()(RedirectPage)));
