import { fetchOrThrow, HeadersBuilder, mptFetch } from '../lib/mptFetch';
import config from '../config';
import { NewSponsor, Sponsor } from '../models/Sponsor';

const sponsorsUrl = `${config.baseUrl}/sponsors`;

const getSponsors = async () => {
    const sponsors = await mptFetch(sponsorsUrl, {
        method: 'GET',
    });
    return await sponsors.json() as Sponsor[];
};

const createSponsor = async (sponsor: NewSponsor): Promise<void> => {
    if (!sponsor.logo) {
        return;
    }
    const payload: Sponsor = {
        name: sponsor.name,
        targetUrl: sponsor.targetUrl,
        displayArea: sponsor.displayArea,
    };
    const formData = new FormData();
    formData.append('file', sponsor.logo, sponsor.logo?.name);
    formData.append('payload', JSON.stringify(payload));
    await fetchOrThrow(sponsorsUrl, {
        method: 'POST',
        body: formData,
        headers: new HeadersBuilder()
            .withTenant()
            .withAuthorization()
            .headers
    });
};

const deleteSponsor = async (sponsor: Sponsor): Promise<void> => {
    if (!sponsor) {
        return;
    }
    const deleteUrl = sponsorsUrl + `/${sponsor.id}`;
    await mptFetch(deleteUrl, { method: 'DELETE' });
};

export const sponsorService = {
    getSponsors,
    createSponsor,
    deleteSponsor,
};
