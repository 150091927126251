import React, { Component } from 'react';
import Section from '../utils/Section';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography
} from '@material-ui/core';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface ComponentProps {
    deleteCurrentUser(): void;
}

interface State {
    dialogOpen: boolean;
}

type Props = ComponentProps & WithTranslation;

class DeleteAccount extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            dialogOpen: false,
        }
    }

    public render() {
        const { t } = this.props;
        const { dialogOpen } = this.state;
        return (
            <Section title="Konto Löschen" color="secondary">
                <Button variant="contained" color="secondary" onClick={this.confirmAction}>
                    {t('components.account.DeleteAccount.buttonText')}
                </Button>
                <Dialog open={dialogOpen} onClose={this.handleClose} fullWidth={true} maxWidth="sm">
                    <DialogTitle>{t('components.account.DeleteAccount.confirmDialogTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                <Trans i18nKey="components.account.DeleteAccount.confirmDialogText"/>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">{t('common.cancel')}</Button>
                        <Button onClick={this.handleConfirm} color="secondary" autoFocus> {t('common.confirm')}</Button>
                    </DialogActions>
                </Dialog>
            </Section>
        );
    }

    private confirmAction = () => {
        this.setState({ dialogOpen: true });
    };

    private handleConfirm = () => {
        this.props.deleteCurrentUser();
        this.handleClose();
    }

    private handleClose = () => {
        this.setState({ dialogOpen: false });
    };
}

export default withTranslation()(DeleteAccount);
