/* tslint:disable: max-classes-per-file */

import { Form, Formik, FormikProps } from 'formik';
import React, { ChangeEvent, Component } from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Box, Button, FormControl, FormGroup, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { DisplayArea, NewSponsor } from '../../models/Sponsor';
import FormikCheckboxField from '../utils/forms/FormikCheckboxField';
import FormikTextField from '../utils/forms/FormikTextField';
import { displayAreasToCreateText } from '../utils/Sponsor';
import LogoPreview from './LogoPreview';

const styles = (theme: Theme) => ({
    sponsorLogoButton: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column' as const,
        marginTop: theme.spacing(1),
    },
    fileInput: {
        display: 'none',
    },
    actionButton: {
        marginRight: theme.spacing(1),
    },
    displayArea: {}
});

const initialValues: NewSponsor = {
    name: '',
    targetUrl: '',
    displayArea: []
};

interface Props {
    onSave(sponsor: NewSponsor): void;
    onCancel(): void;
}

const AdminSponsorsItemForm = ({ onSave, onCancel }: Props) => {
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        name: Yup
            .string()
            .required(t('validation.sponsorName.required')),
        targetUrl: Yup
            .string()
            .required(t('validation.sponsorTargetUrl.required'))
            .url(t('validation.sponsorTargetUrl.url')),
        logo: Yup
            .mixed()
            .test('fileType', t('validation.sponsorLogo.fileType'), value => ['image/png', 'image/jpg', 'image/jpeg'].includes(value?.type))
            .required(t('validation.sponsorLogo.required')),
    });

    return (
        <Formik
            component={InnerForm}
            onSubmit={onSave}
            onReset={onCancel}
            initialValues={initialValues}
            validationSchema={schema}/>
    );
}

interface InnerFormState {
    preview?: string;
}

type InnerFormProps = FormikProps<NewSponsor> & WithStyles<typeof styles> & WithTranslation;

class InnerFormType extends Component<InnerFormProps, InnerFormState> {
    public constructor(props: InnerFormProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const { classes, isValid, dirty, t } = this.props;
        const { preview } = this.state;
        const disableSubmitButton = !(dirty && isValid);

        return (
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm>
                        <Box display="flex" flexDirection="column">
                            <FormikTextField name="name" label="Sponsorenname" margin="normal" variant="outlined"/>
                            <FormikTextField name="targetUrl" label="Link URL" margin="normal" variant="outlined"/>
                            <FormGroup row>
                                {Object.keys(DisplayArea).map((displayArea) =>
                                    <FormikCheckboxField name="displayArea" key={displayArea}
                                                         label={displayAreasToCreateText(displayArea)}
                                                         value={displayArea}/>
                                )}
                            </FormGroup>
                        </Box>
                    </Grid>
                    <Grid item xs="auto">
                        <FormControl margin="normal">
                            <input
                                id="sponsor-logo-file-input"
                                type="file"
                                accept="image/png,image/jpeg"
                                className={classes.fileInput}
                                onChange={this.onFileInputChange}
                            />
                            <LogoPreview url={preview} useBorder={true}/>
                            <label htmlFor="sponsor-logo-file-input" className={classes.sponsorLogoButton}>
                                <Button variant="outlined" startIcon={<PhotoCamera/>} component="span">
                                    {t('components.tenant.AdminTenantLogo.selectLogo')}
                                </Button>
                            </label>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" color="primary" variant="contained" disabled={disableSubmitButton}
                                className={classes.actionButton}>
                            {t('common.save')}
                        </Button>
                        <Button type="reset" variant="outlined">
                            {t('common.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        );
    }

    private onFileInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget && event.currentTarget.files && event.currentTarget.files.length > 0) {
            const file = event.currentTarget.files[0];
            const preview = await this.preview(file);
            this.props.setFieldValue('logo', file);
            this.setState({ preview });
        }
    };

    private preview = async (file: File): Promise<string | undefined> => {
        if (file) {
            return await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = reject;
                reader.onload = () => resolve(reader.result as string);
                reader.readAsDataURL(file);
            });
        }
    };
}

const InnerForm = withStyles(styles)(withTranslation()(InnerFormType));

export default AdminSponsorsItemForm;
