import React, { Component } from 'react';

import MatchplayDashboardContainer from '../matchplay/dashboard/MatchplayDashboardContainer';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';

class MatchplayListPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer />
                <BreadcrumbsContainer />
                <MatchplayDashboardContainer />
            </ContentContainer>
        );
    }
}

export default MatchplayListPage;
