import React, { Component } from 'react';

import MatchplayOverview from '../matchplay/MatchplayOverview';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';

class MatchplayListPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer />
                <MatchplayOverview />
            </ContentContainer>
        );
    }
}

export default MatchplayListPage;
