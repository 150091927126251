import { MatchplayPhase } from '../models/Matchplay';

/**
 * Returns true if the current matchplay phase allows editing.
 * @param phase the current matchplay phase
 */
export function permitsEditing(phase: MatchplayPhase) {
    return phase === MatchplayPhase.INITIALIZATION
        || phase === MatchplayPhase.REGISTRATION_OPEN
        || phase === MatchplayPhase.REGISTRATION_CLOSED;
}
