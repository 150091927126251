import { ChangeEvent, Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button, CardActions, CardContent, TextField } from '@material-ui/core';

interface State {
    commentValue: string;
}

interface Props {
    onSubmit(comment: string): void;
}

class MatchActivityActionComment extends Component<Props & WithTranslation, State> {

    public constructor(props: Props & WithTranslation) {
        super(props);
        this.state = { commentValue: '' };
    }

    public render() {
        const { commentValue } = this.state;
        const { t } = this.props;
        const actionEnabled = !!commentValue;
        return (
            <>
                <CardContent>
                    <TextField multiline fullWidth
                        rows={3}
                        variant="outlined"
                        label={t('components.match.activities.MatchActivityActionComment.label')}
                        value={commentValue}
                        onChange={this.onCommentChange} />
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onSubmit}
                        disabled={!actionEnabled}>
                        {t('common.save')}
                    </Button>
                </CardActions>
            </>
        );
    }

    private onCommentChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        const commentValue = evt.currentTarget.value;
        this.setState({ commentValue });
    }

    private onSubmit = () => {
        const { commentValue } = this.state;
        if (commentValue) {
            this.setState({ commentValue: '' });
            this.props.onSubmit(commentValue);
        }
    }
}

export default withTranslation()(MatchActivityActionComment);
