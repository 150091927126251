import { useTranslation } from 'react-i18next';
import { MenuList, MenuItem, Typography, makeStyles, Theme } from '@material-ui/core';
import { PageLink } from '../utils/PageLink';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(3),
        borderRightWidth: 1,
        borderRightColor: theme.palette.divider,
        borderRightStyle: 'solid',
    },
    icon: {
        minWidth: 0,
        marginRight: theme.spacing(1),
    },
    title: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
    },
}));

const AdminMenu = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <nav className={classes.root}>
            <Typography className={classes.title}>{t('components.tenant.AdminMenu.title')}</Typography>
            <MenuList>
                <MenuItem onClick={onClickScrollTo('admin-matchplays')}>
                    <Typography variant="inherit" noWrap>{t('components.tenant.AdminMatchplays.title')}</Typography>
                </MenuItem>
                <MenuItem onClick={onClickScrollTo('admin-sponsors')}>
                    <Typography variant="inherit" noWrap>{t('components.tenant.AdminSponsors.title')}</Typography>
                </MenuItem>
                <MenuItem onClick={onClickScrollTo('admin-logo')}>
                    <Typography variant="inherit" noWrap>{t('components.tenant.AdminTenantLogo.title')}</Typography>
                </MenuItem>
                <MenuItem onClick={onClickScrollTo('admin-tenant-details')}>
                    <Typography variant="inherit" noWrap>{t('components.tenant.AdminTenantDetails.title')}</Typography>
                </MenuItem>
                <MenuItem component={PageLink('/admin/users')}>
                    <Typography variant="inherit" noWrap>{t('components.tenant.AdminMiscMenu.users')}</Typography>
                </MenuItem>
                <MenuItem component={PageLink('/admin/plugins')}>
                    <Typography variant="inherit" noWrap>{t('components.tenant.AdminMiscMenu.plugins')}</Typography>
                </MenuItem>
                <MenuItem component={PageLink('/admin/notifications')}>
                    <Typography variant="inherit"
                        noWrap>{t('components.tenant.AdminMiscMenu.notifications')}</Typography>
                </MenuItem>
            </MenuList>
        </nav>
    );
};

const onClickScrollTo = (id: string) => () => document.getElementById(id)?.scrollIntoView();

export default AdminMenu;
