import { Dispatch } from 'redux';
import {
    CREATE_NOTIFICATION_FAILURE, CREATE_NOTIFICATION_REQUEST, CREATE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_REQUEST, DELETE_NOTIFICATION_SUCCESS, LIST_NOTIFICATIONS_FAILURE, LIST_NOTIFICATIONS_REQUEST,
    LIST_NOTIFICATIONS_SUCCESS, PUBLISH_NOTIFICATION_FAILURE, PUBLISH_NOTIFICATION_REQUEST,
    PUBLISH_NOTIFICATION_SUCCESS, UPDATE_NOTIFICATION_FAILURE, UPDATE_NOTIFICATION_REQUEST, UPDATE_NOTIFICATION_SUCCESS
} from '../reducers/notification';
import { notificationService } from '../../services/notification';
import { CreateOrUpdateNotification, Notification } from '../../models/Notification';

export const getNotificationsAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const items = (await notificationService.getNotifications());
            dispatch(success(items));
        } catch (e) {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: LIST_NOTIFICATIONS_REQUEST }; }
    function success(items: Notification[]) { return { type: LIST_NOTIFICATIONS_SUCCESS, payload: items }; }
    function failure() { return { type: LIST_NOTIFICATIONS_FAILURE }; }
    // @formatter:on
}

export const createNotificationAction = (dispatch: Dispatch) => {
    return async (payload: CreateOrUpdateNotification) => {
        dispatch(request());
        try {
            const item = await notificationService.createNotification(payload);
            dispatch(success(item));
        } catch (e) {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: CREATE_NOTIFICATION_REQUEST }; }
    function success(item: Notification) { return { type: CREATE_NOTIFICATION_SUCCESS, payload: item }; }
    function failure() { return { type: CREATE_NOTIFICATION_FAILURE }; }
    // @formatter:on
}

export const updateNotificationAction = (dispatch: Dispatch) => {
    return async (notification: Notification, payload: CreateOrUpdateNotification) => {
        dispatch(request());
        try {
            const item = await notificationService.updateNotification(notification.id, payload);
            dispatch(success(item));
        } catch (e) {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: UPDATE_NOTIFICATION_REQUEST }; }
    function success(item: Notification) { return { type: UPDATE_NOTIFICATION_SUCCESS, payload: item }; }
    function failure() { return { type: UPDATE_NOTIFICATION_FAILURE }; }
    // @formatter:on
}

export const deleteNotificationAction = (dispatch: Dispatch) => {
    return async (notification: Notification) => {
        dispatch(request());
        try {
            await notificationService.deleteNotification(notification.id);
            dispatch(success(notification));
            // Fetch all after deletion
            await getNotificationsAction(dispatch)();
        } catch (e) {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: DELETE_NOTIFICATION_REQUEST }; }
    function success(item: Notification) { return { type: DELETE_NOTIFICATION_SUCCESS, payload: item }; }
    function failure() { return { type: DELETE_NOTIFICATION_FAILURE }; }
    // @formatter:on
}

export const publishNotificationAction = (dispatch: Dispatch) => {
    return async (notification: Notification) => {
        dispatch(request());
        try {
            const item = await notificationService.publishNotification(notification.id);
            dispatch(success(item));
        } catch (e) {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: PUBLISH_NOTIFICATION_REQUEST }; }
    function success(item: Notification) { return { type: PUBLISH_NOTIFICATION_SUCCESS, payload: item }; }
    function failure() { return { type: PUBLISH_NOTIFICATION_FAILURE }; }
    // @formatter:on
}
