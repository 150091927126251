import { Formik, FormikProps } from 'formik';
import { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
    Button, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel, FormHelperText, FormLabel, Grid, makeStyles, MenuItem, Radio, RadioGroup, TextField, Theme,
    WithStyles, withStyles
} from '@material-ui/core';

import { getMatchResult, getMatchWinnerEmail, Match, MatchResult } from '../../../models/Match';
import { User } from '../../../models/User';
import FormikDateTimeField from '../../utils/forms/FormikDateTimeField';

const styles = (theme: Theme) => ({
    checkedContainer: {
        marginLeft: 48,
        marginTop: theme.spacing(2)
    },
    datePicker: {
        width: 250
    },
    warning: {
        fontWeight: 900,
        color: theme.palette.error.main,
    }
});
const useStyles = makeStyles(styles);

export interface PlayoffFormSubmitData {
    winner: string;
    result: MatchResult;
    playedAt: null | Date;
}

interface Props {
    match: Match;
    changeResult?: boolean;

    onSubmit(data: PlayoffFormSubmitData): void;
}

export interface FormPayload {
    winner: string;
    player1: User;
    player2: User;
    result: MatchResult;
    playedAt: null | Date;
}

interface State {
    open: boolean;
    payload?: FormPayload;
}

class MatchActivityActionPlayoffResult extends Component<Props & WithTranslation & WithStyles<typeof styles>, State> {

    constructor(props: Props & WithTranslation & WithStyles<typeof styles>) {
        super(props);
        this.state = {
            open: false,
        }
    }

    private schema = () => {
        const { t } = this.props;
        return Yup.object().shape({
            winner: Yup.string()
                .required(t('validation.winner.required')),
            result: Yup.string()
                .required(t('validation.result.required')),
            playedAt: Yup.date()
                .max(new Date(), t('validation.common.dateMax'))
                .nullable()
                .required(t('validation.common.required'))
                .transform(function (castValue, originalValue) {
                    return this.isType(castValue) ? castValue : new Date(originalValue)
                }),
        });
    }

    public render() {
        const { match, classes, t } = this.props;
        const { open } = this.state;

        if (!match || !match.playerOne || !match.playerTwo) {
            return null;
        }
        const player1 = match.playerOne;
        const player2 = match.playerTwo;

        const initialValues: FormPayload = {
            winner: getMatchWinnerEmail(match),
            result: match.result ? match.result : MatchResult.EMPTY,
            player1: player1.user,
            player2: player2.user,
            playedAt: match.scheduledAt ?
                (new Date(match.scheduledAt) > new Date() ? new Date() : new Date(match.scheduledAt))
                : null,
        };
        return (
            <>
                <Formik validationSchema={this.schema}
                        initialValues={initialValues}
                        component={Form}
                        onSubmit={this.onSubmit}/>
                <Dialog open={open}
                        onClose={this.handleClose}
                        disableBackdropClick
                        disableEscapeKeyDown>
                    <DialogTitle>
                        {t('components.match.activities.MatchActivityPlayoffResult.editDialog.title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.warning}>
                            {t('components.match.activities.MatchActivityPlayoffResult.editDialog.warning')}
                        </DialogContentText>
                        <DialogContentText>
                            {t('components.match.activities.MatchActivityPlayoffResult.editDialog.text')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            {t('common.cancel')}
                        </Button>
                        <Button onClick={this.handleChangeGame} color="secondary">
                            {t('common.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    private onChangeMatch = (payload: FormPayload) => {
        this.setState({ open: true, payload });
    }

    private handleClose = () => {
        this.setState({ open: false });
    };

    private handleChangeGame = async () => {
        if (this.state.payload) {
            await this.doSubmit(this.state.payload, true);
        }
    }

    private onSubmit = async (payload: FormPayload) => {
        await this.doSubmit(payload);
    }

    private doSubmit = async (payload: FormPayload, isApproved = false) => {
        if (this.props.changeResult && !isApproved) {
            this.onChangeMatch(payload);
            return;
        }

        this.setState({ open: false });
        await this.props.onSubmit({
            winner: payload.winner,
            result: payload.result,
            playedAt: payload.playedAt
        });
    }
}

const Form = ({
                  values, errors, touched, dirty, isSubmitting, isValid, isInitialValid,
                  handleBlur, handleChange, handleSubmit
              }: FormikProps<FormPayload>) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const submitDisabled = (!dirty && !isInitialValid) || !isValid || isSubmitting;

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl error={(touched.playedAt) && Boolean(errors.playedAt)}>
                            <FormikDateTimeField name="playedAt"
                                                 label={t('components.match.activities.MatchActivityPlayoffResult.matchPlayedAt')}
                                                 maxDateMessage={t('components.match.activities.MatchActivityPlayoffResult.matchInFutureError')}
                                                 format={t('dateTime.matchDateTime')}
                                                 className={classes.datePicker}
                                                 disableFuture={true}/>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl component={'fieldset' as 'div'}
                                     fullWidth
                                     error={touched.winner && Boolean(errors.winner)}>
                            <FormLabel component={'legend' as 'span'}>
                                {t('components.match.activities.MatchActivityPlayoffResult.winner')}
                            </FormLabel>
                            {errors.winner && <FormHelperText>{errors.winner}</FormHelperText>}
                            <RadioGroup id="winner" name="winner"
                                        onChange={handleChange} onBlur={handleBlur}
                                        value={values.winner}>
                                <FormControlLabel value={values.player1.email} control={<Radio/>}
                                                  label={values.player1.name}/>
                                <FormControlLabel value={values.player2.email} control={<Radio/>}
                                                  label={values.player2.name}/>
                            </RadioGroup>
                        </FormControl>
                        <FormControl component={'fieldset' as 'div'} fullWidth>
                            <TextField type="number" id="result" name="result" select={true} fullWidth
                                       label={t('components.match.activities.MatchActivityPlayoffResult.result')}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.result}
                                       helperText={touched.result ? errors.result : ''}
                                       error={touched.result && Boolean(errors.result)}>
                                {['1 Up', '2 Up', '2 & 1', '3 & 1', '3 & 2', '4 & 2', '4 & 3', '5 & 3', '5 & 4', '6 & 4', '6 & 5', '7 & 5', '7 & 6', '8 & 6', '8 & 7', '9 & 7', '9 & 8', '10 & 8'].map(x =>
                                    <MenuItem key={getMatchResult(x)} value={getMatchResult(x)}>{x}</MenuItem>)}
                            </TextField>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitDisabled}>
                    {t('common.save')}
                </Button>
            </CardActions>
        </form>
    );
};

export default withStyles(styles)(withTranslation()(MatchActivityActionPlayoffResult));
