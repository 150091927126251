import { Dispatch } from 'redux';

import { MptError } from '../../lib/mptFetch';
import { Errors } from '../../models/Errors';
import { GroupAssignments } from '../../models/GroupAssignment';
import { MatchEncounter } from '../../models/MatchEncounter';
import { GroupView, PlayoffTree } from '../../models/MatchViews';
import { Matchplay } from '../../models/Matchplay';
import { MatchplayRegistration } from '../../models/MatchplayRegistration';
import { NewSponsor, Sponsor } from '../../models/Sponsor';
import { matchplayService } from '../../services/matchplay';
import {
    GET_GROUP_MATCHES_FAILURE, GET_GROUP_MATCHES_REQUEST, GET_GROUP_MATCHES_SUCCESS, GET_PLAYOFF_MATCHES_FAILURE,
    GET_PLAYOFF_MATCHES_REQUEST, GET_PLAYOFF_MATCHES_SUCCESS
} from '../reducers/match';
import {
    COMMIT_MATCHPLAY_PHASE_FAILURE, COMMIT_MATCHPLAY_PHASE_REQUEST, COMMIT_MATCHPLAY_PHASE_SUCCESS,
    CREATE_MATCHPLAY_FAILURE, CREATE_MATCHPLAY_REQUEST, CREATE_MATCHPLAY_SUCCESS, DELETE_MATCHPLAY_FAILURE,
    DELETE_MATCHPLAY_REQUEST, DELETE_MATCHPLAY_SUCCESS, GET_MATCHPLAY_FAILURE, GET_MATCHPLAY_REGISTRATIONS_FAILURE,
    GET_MATCHPLAY_REGISTRATIONS_REQUEST, GET_MATCHPLAY_REGISTRATIONS_SUCCESS, GET_MATCHPLAY_REQUEST,
    GET_MATCHPLAY_SUCCESS, INITIALIZE_GROUP_MATCHPLAY_FAILURE, INITIALIZE_GROUP_MATCHPLAY_REQUEST,
    INITIALIZE_GROUP_MATCHPLAY_SUCCESS, INITIALIZE_PLAYOFF_MATCHPLAY_FAILURE, INITIALIZE_PLAYOFF_MATCHPLAY_REQUEST,
    INITIALIZE_PLAYOFF_MATCHPLAY_SUCCESS, LIST_MATCHPLAYS_FAILURE, LIST_MATCHPLAYS_REQUEST, LIST_MATCHPLAYS_SUCCESS,
    REGISTER_MATCHPLAY_USER_FAILURE, REGISTER_MATCHPLAY_USER_REQUEST, REGISTER_MATCHPLAY_USER_SUCCESS,
    START_GROUP_PLAYOFF_FAILURE, START_GROUP_PLAYOFF_REQUEST, START_GROUP_PLAYOFF_SUCCESS,
    UNREGISTER_MATCHPLAY_USER_FAILURE, UNREGISTER_MATCHPLAY_USER_REQUEST, UNREGISTER_MATCHPLAY_USER_SUCCESS,
    UPDATE_MATCHPLAY_FAILURE, UPDATE_MATCHPLAY_REQUEST, UPDATE_MATCHPLAY_SUCCESS
} from '../reducers/matchplay';

export const listMatchplaysAction = (dispatch: Dispatch) => {
    return async () => {
        dispatch(request());
        try {
            const items = await matchplayService.listMatchplays();
            dispatch(success(items));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: LIST_MATCHPLAYS_REQUEST }; }
    function success(items: Matchplay[]) { return { type: LIST_MATCHPLAYS_SUCCESS, payload: items }; }
    function failure() { return { type: LIST_MATCHPLAYS_FAILURE }; }
    // @formatter:on
};

export const getMatchplayAction = (dispatch: Dispatch) => {
    return async (id: string) => {
        dispatch(request());
        try {
            const matchplay = await matchplayService.getMatchplay(id);
            dispatch(success(matchplay));
        } catch (ex) {
            (ex instanceof MptError && ex.response.status === 404)
                ? dispatch(failure(Errors.NotFound))
                : dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_MATCHPLAY_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: GET_MATCHPLAY_SUCCESS, payload: matchplay }; }
    function failure(error?: Errors) { return { type: GET_MATCHPLAY_FAILURE, payload: error }; }
    // @formatter:on
};

export const getMatchplayRegistrationsAction = (dispatch: Dispatch) => {
    return async (matchplayId: string) => {
        dispatch(request());
        try {
            const registrations = await matchplayService.getMatchplayRegistrations(matchplayId);
            dispatch(success(registrations));
        } catch (ex) {
            (ex instanceof MptError && ex.response.status === 404)
                ? dispatch(failure(Errors.NotFound))
                : dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_MATCHPLAY_REGISTRATIONS_REQUEST }; }
    function success(items: MatchplayRegistration[]) { return { type: GET_MATCHPLAY_REGISTRATIONS_SUCCESS, payload: items }; }
    function failure(error?: Errors) { return { type: GET_MATCHPLAY_REGISTRATIONS_FAILURE, payload: error }; }
    // @formatter:on
};

export const getMatchplayPlayoffMatchesAction = (dispatch: Dispatch) => {
    return async (matchplayId: string) => {
        dispatch(request());
        try {
            const view = await matchplayService.getMatchplayPlayoffMatches(matchplayId);
            dispatch(success(view));
        } catch (e) {
            (e.response.status === 404)
                ? dispatch(failure(Errors.NotFound))
                : dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_PLAYOFF_MATCHES_REQUEST }; }
    function success(view: PlayoffTree) { return { type: GET_PLAYOFF_MATCHES_SUCCESS, payload: view }; }
    function failure(error?: Errors) { return { type: GET_PLAYOFF_MATCHES_FAILURE, payload: error }; }
    // @formatter:on
};

export const getMatchplayGroupMatchesAction = (dispatch: Dispatch) => {
    return async (matchplayId: string) => {
        dispatch(request());
        try {
            const view = await matchplayService.getMatchplayGroupMatches(matchplayId);
            dispatch(success(view, matchplayId));
        } catch (e) {
            (e.response.status === 404)
                ? dispatch(failure(Errors.NotFound))
                : dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: GET_GROUP_MATCHES_REQUEST }; }
    function success(view: GroupView, matchplayId: string) { return { type: GET_GROUP_MATCHES_SUCCESS, payload: { view, matchplayId } }; }
    function failure(error?: Errors) { return { type: GET_GROUP_MATCHES_FAILURE, payload: error }; }
    // @formatter:on
};

export const createMatchplayAction = (dispatch: Dispatch) => {
    return async (matchplayName: string) => {
        dispatch(request());
        try {
            const matchplay = await matchplayService.createMatchplay(matchplayName);
            dispatch(success(matchplay));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: CREATE_MATCHPLAY_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: CREATE_MATCHPLAY_SUCCESS, payload: matchplay }; }
    function failure() { return { type: CREATE_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const updateMatchplayAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay) => {
        dispatch(request());
        try {
            const item = await matchplayService.updateMatchplay(matchplay);
            dispatch(success(item));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: UPDATE_MATCHPLAY_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: UPDATE_MATCHPLAY_SUCCESS, payload: matchplay }; }
    function failure() { return { type: UPDATE_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const commitMatchplayPhaseAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay) => {
        dispatch(request());
        try {
            const item = await matchplayService.commitMatchplayPhase(matchplay);
            dispatch(success(item));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: COMMIT_MATCHPLAY_PHASE_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: COMMIT_MATCHPLAY_PHASE_SUCCESS, payload: matchplay }; }
    function failure() { return { type: COMMIT_MATCHPLAY_PHASE_FAILURE }; }
}

export const registerMatchplayUserAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay) => {
        dispatch(request());
        try {
            const registration = await matchplayService.registerMatchplayUser(matchplay.id);
            dispatch(success(registration));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: REGISTER_MATCHPLAY_USER_REQUEST }; }
    function success(registration: MatchplayRegistration) { return { type: REGISTER_MATCHPLAY_USER_SUCCESS, payload: registration }; }
    function failure() { return { type: REGISTER_MATCHPLAY_USER_FAILURE }; }
    // @formatter:on
}

export const unregisterMatchplayUserAction = (dispatch: Dispatch) => {
    return async (registration: MatchplayRegistration) => {
        dispatch(request());
        try {
            await matchplayService.unregisterMatchplayUser(registration.matchplayId);
            dispatch(success(registration.id));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: UNREGISTER_MATCHPLAY_USER_REQUEST }; }
    function success(registrationId: MatchplayRegistration['id']) { return { type: UNREGISTER_MATCHPLAY_USER_SUCCESS, payload: registrationId }; }
    function failure() { return { type: UNREGISTER_MATCHPLAY_USER_FAILURE }; }
    // @formatter:on
}

export const signOutPlayerAction = (dispatch: Dispatch) => {
    return async (registration: MatchplayRegistration) => {
        dispatch(request());
        try {
            await matchplayService.unregisterMatchplayUser(registration.matchplayId, registration.user.email);
            dispatch(success(registration.id));
        } catch {
            dispatch(failure());
        }
    }

    // @formatter:off
    function request() { return { type: UNREGISTER_MATCHPLAY_USER_REQUEST }; }
    function success(registrationId: MatchplayRegistration['id']) { return { type: UNREGISTER_MATCHPLAY_USER_SUCCESS, payload: registrationId }; }
    function failure() { return { type: UNREGISTER_MATCHPLAY_USER_FAILURE }; }
    // @formatter:on
}

export const initializePlayoffMatchplayAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay, matches: MatchEncounter[]) => {
        dispatch(request());
        try {
            await matchplayService.initializePlayoffMatchplay(matchplay, matches);
            dispatch(success());
            // Fetch matchplay again. Its phase has been changed.
            await getMatchplayAction(dispatch)(matchplay.id);
            await getMatchplayRegistrationsAction(dispatch)(matchplay.id);
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: INITIALIZE_PLAYOFF_MATCHPLAY_REQUEST }; }
    function success() { return { type: INITIALIZE_PLAYOFF_MATCHPLAY_SUCCESS }; }
    function failure() { return { type: INITIALIZE_PLAYOFF_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const initializeGroupMatchplayAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay, groupAssignments: GroupAssignments) => {
        dispatch(request());
        try {
            await matchplayService.initializeGroupMatchplay(matchplay, groupAssignments);
            dispatch(success());
            // Fetch matchplay again. Its phase has been changed.
            await getMatchplayAction(dispatch)(matchplay.id);
            await getMatchplayRegistrationsAction(dispatch)(matchplay.id);
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: INITIALIZE_GROUP_MATCHPLAY_REQUEST }; }
    function success() { return { type: INITIALIZE_GROUP_MATCHPLAY_SUCCESS }; }
    function failure() { return { type: INITIALIZE_GROUP_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const deleteMatchplayAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay) => {
        dispatch(request());
        try {
            await matchplayService.deleteMatchplay(matchplay);
            dispatch(success());
            // Fetch all matchplays again.
            await listMatchplaysAction(dispatch)();
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: DELETE_MATCHPLAY_REQUEST }; }
    function success() { return { type: DELETE_MATCHPLAY_SUCCESS }; }
    function failure() { return { type: DELETE_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const deleteMatchplaySponsor = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay, sponsor: Sponsor) => {
        dispatch(request());
        try {
            const item = await matchplayService.deleteMatchplaySponsor(matchplay, sponsor);
            dispatch(success(item));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: UPDATE_MATCHPLAY_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: UPDATE_MATCHPLAY_SUCCESS, payload: matchplay }; }
    function failure() { return { type: UPDATE_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const addMatchplaySponsor = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay, sponsor: NewSponsor) => {
        dispatch(request());
        try {
            const item = await matchplayService.addMatchplaySponsor(matchplay, sponsor);
            dispatch(success(item));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: UPDATE_MATCHPLAY_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: UPDATE_MATCHPLAY_SUCCESS, payload: matchplay }; }
    function failure() { return { type: UPDATE_MATCHPLAY_FAILURE }; }
    // @formatter:on
}

export const startGroupPlayoffAction = (dispatch: Dispatch) => {
    return async (matchplay: Matchplay) => {
        dispatch(request());
        try {
            await matchplayService.startGroupPlayoff(matchplay);
            dispatch(success(matchplay));
        } catch {
            dispatch(failure());
        }
    };

    // @formatter:off
    function request() { return { type: START_GROUP_PLAYOFF_REQUEST }; }
    function success(matchplay: Matchplay) { return { type: START_GROUP_PLAYOFF_SUCCESS, payload: matchplay }; }
    function failure() { return { type: START_GROUP_PLAYOFF_FAILURE }; }
}
