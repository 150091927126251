import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { PasswordReset } from '../../models/PasswordReset';
import { TenantInformation } from '../../models/TenantInformation';
import { passwordResetAction, resetUserStoreAction } from '../../store/actions/user';
import PasswordResetComplete from './PasswordResetComplete';
import PasswordResetForm from './PasswordResetForm';

interface StoreProps {
    tenantInformation?: TenantInformation;
    isPasswordResetSuccessful: boolean;

    onPasswordReset(passwordReset: PasswordReset): void;
    resetUserStore(): void;

}

type Props = StoreProps;

class PasswordResetContainer extends Component<Props> {
    public render() {
        const { tenantInformation, isPasswordResetSuccessful } = this.props;
        if (!tenantInformation) {
            return null;
        }
        return (
            <React.Fragment>
                {!isPasswordResetSuccessful && <PasswordResetForm
                    tenantInformation={tenantInformation}
                    onPasswordReset={this.onPasswordReset} />}
                {isPasswordResetSuccessful && <PasswordResetComplete />}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        this.props.resetUserStore();
    }


    private onPasswordReset = (passwordReset: PasswordReset) => {
        this.props.onPasswordReset(passwordReset);
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
    isPasswordResetSuccessful: state.user.isPasswordResetSuccessful,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onPasswordReset: passwordResetAction(dispatch),
    resetUserStore: resetUserStoreAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer);
