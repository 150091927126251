import React, { Component } from 'react';
import NotificationsList from './NotificationsList';
import { connect } from 'react-redux';
import { Notification } from '../../models/Notification';
import { Dispatch } from 'redux';
import Section from '../utils/Section';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getPublishedNotificationsAction } from '../../store/actions/user';

interface StoreProps {
    publishedNotifications: Notification[];
    getPublishedNotifications(): void;
}

type Props = StoreProps & WithTranslation;

class NotificationsContainer extends Component<Props> {
    public render() {
        const { publishedNotifications, t } = this.props;
        return (
            <>
                {publishedNotifications && publishedNotifications.length > 0 &&
                <Section title={t('components.appHomePage.notifications')} noPadding={true}>
                    <NotificationsList notifications={publishedNotifications}/>
                </Section>}
            </>
        );
    }

    public componentDidMount() {
        this.props.getPublishedNotifications();
    }
}

const mapStateToProps = (state: any) => ({
    publishedNotifications: state.user.publishedNotifications
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPublishedNotifications: getPublishedNotificationsAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationsContainer));
