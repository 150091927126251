import React, { Component } from 'react';

import PasswordResetContainer from '../login/PasswordResetContainer';
import ContentContainer from './ContentContainer';

class ConfirmPasswordResetPage extends Component {
    public render() {
        return (
            <ContentContainer isLoginPage={true}>
                <PasswordResetContainer />
            </ContentContainer>
        );
    }
}

export default ConfirmPasswordResetPage;
