import React, { Component } from 'react';

import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import UserManagementContainer from '../tenant/usermanagement/UserManagementContainer';
import ContentContainer from './ContentContainer';

class UserManagementPage extends Component {
    public render() {
        return (
            <ContentContainer>
                <MainNavigationContainer />
                <BreadcrumbsContainer />
                <UserManagementContainer />
            </ContentContainer>
        );
    }
}

export default UserManagementPage;
