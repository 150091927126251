import { AnyAction } from 'redux';
import { Errors } from '../../models/Errors';

export const SET_ERROR = 'error/SET';
export const RESET_ERROR = 'error/RESET';

interface ErrorState {
    error?: Errors
}

const initialState: ErrorState = {
    error: undefined,
};

export const errorReducer = (state: ErrorState = initialState, action: AnyAction): ErrorState => {
    const { error } = action;
    if (error) {
        return { ...state, error };
    } else if (action.type === RESET_ERROR) {
        return { ...state, error: undefined };
    }
    return state;
};
