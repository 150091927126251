import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box, Button, Divider, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { CreateOrUpdateNotification, Notification } from '../../../models/Notification';
import Section from '../../utils/Section';
import NotificationItemForm from './AdminNotificationItemFrom';
import AdminNotificationList from './AdminNotificationList';

const styles = (theme: Theme) => ({
    divider: {
        marginTop: theme.spacing(2),
    },
});

interface ComponentProps {
    notifications: Notification[];
    createNotification(payload: CreateOrUpdateNotification): void;
    editNotification(notification: Notification, payload: CreateOrUpdateNotification): void;
    deleteNotification(notification: Notification): void;
    publishNotification(notification: Notification): void;
}

interface State {
    isAdding?: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class AdminNotifications extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {

        const { notifications, editNotification, deleteNotification, publishNotification, classes, t } = this.props;
        const { isAdding } = this.state;

        return (
            <Section title={t('components.tenant.notification.Notifications.title')}>
                {!isAdding &&
                <Box>
                    <Button variant="outlined" startIcon={<AddIcon/>} onClick={this.addNotification}>
                        {t('components.tenant.notification.Notifications.create')}
                    </Button>
                </Box>}
                {isAdding &&
                <NotificationItemForm onSave={this.save} onCancel={this.cancel}/>}
                <Divider className={classes.divider}/>
                <AdminNotificationList notifications={notifications}
                                       editNotification={editNotification}
                                       deleteNotification={deleteNotification}
                                       publishNotification={publishNotification}/>
                {notifications.length === 0 &&
                <Typography>
                    {t('components.tenant.notification.Notifications.empty')}
                </Typography>}
            </Section>
        );
    }

    private addNotification = () => {
        if (this.state.isAdding) {
            return;
        }
        this.setState({ isAdding: true });
    };

    private cancel = () => {
        if (this.state.isAdding) {
            this.setState({ isAdding: false });
        }
    }

    private save = async (notification: CreateOrUpdateNotification) => {
        await this.props.createNotification(notification);
        this.setState({ isAdding: false });
    }
}

export default withStyles(styles)(withTranslation()(AdminNotifications));
