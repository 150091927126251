import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';

import config from '../../config';

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(2),
    },
}));

const FreshchatWidget = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const scriptElement = document.createElement('script');
        scriptElement.id = 'freshchat-js-sdk';
        scriptElement.src = 'https://wchat.eu.freshchat.com/js/widget.js';
        scriptElement.async = true;
        scriptElement.onload = () => {
            window.fcWidget?.init(config.freshchat);
            setIsInitialized(true);
        };
        document.body.appendChild(scriptElement);
        return () => { document.body.removeChild(scriptElement); }
    });

    const onChatButtonClick = useCallback(() => {
        window.fcWidget?.open();
    }, []);

    if (!isInitialized) {
        return null;
    }

    if (!isMobile) {
        return <OriginalWidgetButton hidden={false} />;
    }

    return (
        <>
            <OriginalWidgetButton hidden={true} />
            <Button
                startIcon={<ChatIcon />}
                onClick={onChatButtonClick}
                className={classes.button}>
                {t('components.freshchat.FreshchatWidget.buttonLabel')}
            </Button>
        </>
    );
};

const OriginalWidgetButton = ({ hidden }: { hidden: boolean }) => {
    if (!hidden) {
        return null;
    }

    return (
        <style dangerouslySetInnerHTML={{
            __html: `
                #fc_frame.fc-widget-small:not(.fc-open) {
                    display: none;
                }        
        `}} />
    );
};

export default FreshchatWidget;

