import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Moment from 'react-moment';

import {
    Box, ButtonBase, Grid, Theme, Typography, WithStyles, withStyles
} from '@material-ui/core';

import { getMatchWinnerEmail, Match } from '../../models/Match';
import { Matchplay } from '../../models/Matchplay';
import { PageLink } from '../utils/PageLink';
import PlayerImage from '../utils/PlayerImage';
import Username from '../utils/Username';

const styles = (theme: Theme) => ({
    root: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        padding: theme.spacing(2),

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
    },
    player: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        textAlign: 'center' as const,

        '& > *:not(:first-child)': {
            marginTop: theme.spacing(1),
        },
    },
    versus: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        justifyContent: 'center' as const,
        textAlign: 'center' as const,
    },
    details: {
        marginTop: theme.spacing(2),
        textAlign: 'center' as const,
    },
});

interface ComponentProps {
    match: Match;
    matchplay: Matchplay;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class MatchCardSingle extends Component<Props> {
    public render() {
        const { match, matchplay, classes, t } = this.props;
        const linkTo = PageLink('/matches/:matchId', { matchId: match.id });
        const matchWinner = getMatchWinnerEmail(match);
        const winnerPlayerOne = (matchWinner && match.playerOne)
            ? (matchWinner === match.playerOne?.user.email)
            : false;
        const winnerPlayerTwo = (matchWinner && match.playerTwo)
            ? (matchWinner === match.playerTwo?.user.email)
            : false;
        return (
            <ButtonBase className={classes.root} component={linkTo}>
                <Grid container>
                    <Grid item xs={5} className={classes.player}>
                        <PlayerImage user={match.playerOne?.user} size={48} />
                        <Typography variant="body1">
                            {winnerPlayerOne && <>🏆&nbsp;</>}
                            {match.playerOne?.user && <Username user={match.playerOne.user}/>}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box className={classes.versus} height={48}>
                            &ndash; vs &ndash;
                        </Box>
                    </Grid>
                    <Grid item xs={5} className={classes.player}>
                        <PlayerImage user={match.playerTwo?.user} size={48} />
                        <Typography variant="body1">
                            {winnerPlayerTwo && <>🏆&nbsp;</>}
                            {match.playerTwo?.user && <Username user={match.playerTwo.user}/>}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.details}>
                    <Typography variant="subtitle2">{matchplay.name}</Typography>
                    <Typography>
                        {!!match.scheduledAt && !match.result &&
                            <Moment unix format={t('dateTime.matchDateTime')}>{match.scheduledAt / 1000}</Moment>}
                        {!match.scheduledAt && !match.result &&
                            t('components.match.MatchCardSingle.notScheduled')}
                        {match.result &&
                            `${t('components.match.MatchCardSingle.result')} ${t(match.result)}`}
                    </Typography>
                </div>
            </ButtonBase>
        );
    }
}

export default withStyles(styles)(withTranslation()(MatchCardSingle));
