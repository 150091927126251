import React, { Component } from 'react';
import BreadcrumbsContainer from '../navigation/BreadcrumbsContainer';
import MainNavigationContainer from '../navigation/MainNavigationBarContainer';
import ContentContainer from './ContentContainer';
import MatchplayPlayoffContainer from '../matchplay/playoff/MatchplayPlayoffContainer';

class MatchplayPlayoffPage extends Component {

    public render() {
        return (
            <ContentContainer fullWidth={true}>
                <MainNavigationContainer/>
                <BreadcrumbsContainer/>
                <MatchplayPlayoffContainer/>
            </ContentContainer>
        );
    }
}

export default MatchplayPlayoffPage;
