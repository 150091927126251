import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createApiKeyAction, deleteApiKeyAction, getTenantSettingsAction } from '../../store/actions/tenant';
import { TenantSettings } from '../../models/TenantSettings';
import AdminWebPlugins from './AdminWebPlugins';

interface StoreProps {
    tenantSettings: TenantSettings;
    getTenantSettings(): void;
    createApiKey(): void;
    deleteApiKey(): void;
}

type Props = StoreProps;

class AdminWebPluginsContainer extends Component<Props> {

    render() {
        const { tenantSettings, createApiKey, deleteApiKey } = this.props;
        return (
            <AdminWebPlugins tenantSettings={tenantSettings}
                             activateAction={createApiKey}
                             deactivateAction={deleteApiKey}
                             renewAction={createApiKey}/>
        );
    }

    public componentDidMount() {
        this.props.getTenantSettings();
    }
}

const mapStateToProps = (state: any) => ({
    tenantSettings: state.tenant.tenantSettings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTenantSettings: getTenantSettingsAction(dispatch),
    createApiKey: createApiKeyAction(dispatch),
    deleteApiKey: deleteApiKeyAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminWebPluginsContainer);
