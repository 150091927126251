import React, { Component } from 'react';

import RequestPasswordResetContainer from '../login/RequestPasswordResetContainer';
import ContentContainer from './ContentContainer';

class RequestPasswordResetPage extends Component {
    public render() {
        return (
            <ContentContainer isLoginPage={true}>
                <RequestPasswordResetContainer />
            </ContentContainer>
        );
    }
}

export default RequestPasswordResetPage;
