import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { TenantInformation, UpdateTenantRequest } from '../../models/TenantInformation';
import { updateTenantInformationAction, uploadTenantLogoAction } from '../../store/actions/tenant';
import AdminTenantLogo from './AdminTenantLogo';
import AdminTenantDetails from './AdminTenantDetails';

interface StoreProps {
    tenantInformation: TenantInformation;
    uploadLogo(file: File): void;
    updateTenantInformation(payload: UpdateTenantRequest): void;
}

type Props = StoreProps;

class AdminTenantInformationContainer extends Component<Props> {

    public render() {
        const { tenantInformation, uploadLogo, updateTenantInformation } = this.props;

        return (
            <>
                <AdminTenantLogo tenantInformation={tenantInformation} uploadLogo={uploadLogo}/>
                <AdminTenantDetails tenantInformation={tenantInformation}
                                    updateTenantInformation={updateTenantInformation}/>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    tenantInformation: state.tenant.tenantInformation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    uploadLogo: uploadTenantLogoAction(dispatch),
    updateTenantInformation: updateTenantInformationAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTenantInformationContainer);
