import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Box, Button, Divider, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import MatchplayListContainer from '../matchplay/MatchplayListContainer';
import Section from '../utils/Section';
import AdminMatchplaysItemForm from './AdminMatchplaysItemForm';

const styles = (theme: Theme) => ({
    actionArea: {
        marginTop: theme.spacing(2),
    },
});

interface ComponentProps {
    onCreateMatchplay(matchplayName: string): void;
}

interface State {
    isAddingMatchplay?: boolean;
}

type Props = ComponentProps & WithStyles<typeof styles> & WithTranslation;

class AdminMatchplays extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const { classes, t } = this.props;
        const { isAddingMatchplay } = this.state;

        return (
            <Section title={t('components.tenant.AdminMatchplays.title')} id="admin-matchplays">
                <MatchplayListContainer
                    linkPattern="/admin/matchplays/:id"
                    emptyContent={<Typography>{t('components.tenant.AdminMatchplays.empty')}</Typography>} />
                <Divider />
                {isAddingMatchplay &&
                    <AdminMatchplaysItemForm
                        onSave={this.onSaveAddMatchplay}
                        onCancel={this.onCancelAddMatchplay} />}
                {!isAddingMatchplay &&
                    <Box className={classes.actionArea}>
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={this.onAddMatchplay}>
                            {t('components.tenant.AdminMatchplays.create')}
                        </Button>
                    </Box>}
            </Section>
        );
    }

    private onAddMatchplay = () => {
        if (this.state.isAddingMatchplay) {
            return;
        }
        this.setState({ isAddingMatchplay: true });
    };

    private onCancelAddMatchplay = () => {
        this.setState({ isAddingMatchplay: false });
    };

    private onSaveAddMatchplay = (matchplayName: string) => {
        this.props.onCreateMatchplay(matchplayName);
        this.setState({ isAddingMatchplay: false });
    }
}

export default withStyles(styles)(withTranslation()(AdminMatchplays));
