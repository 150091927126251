import { useCallback, useMemo, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
    Button, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, makeStyles, TextField, Theme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { isGroupMatch, Match } from '../../../models/Match';
import { getMaxHoles, Matchplay } from '../../../models/Matchplay';
import FormikDateTimeField from '../../utils/forms/FormikDateTimeField';

const useStyles = makeStyles((theme: Theme) => ({
    datePicker: {
        width: 250
    },
    players: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
}));

interface Props {
    match: Match;
    matchplay: Matchplay;
    changeResult?: boolean;
    onSubmit(data: GroupFormSubmitData): void;
}

export interface GroupFormSubmitData {
    holesWonPlayerOne: number;
    holesWonPlayerTwo: number;
    playedAt: null | Date;
}

type FormPayload = GroupFormSubmitData;

interface CustomFormikErrors {
    maxHolesWon: never;
}

const MatchActivityGroupResult = ({ match, matchplay, changeResult, onSubmit }: Props) => {
    const { t } = useTranslation();
    const [confirmChangeData, setConfirmChangeData] = useState<FormPayload | undefined>(undefined);

    const schema = useMemo(() => Yup.object().shape({
        holesWonPlayerOne: Yup.number()
            .min(0, t('validation.common.numberMin', { value: 0 }))
            .max(18, t('validation.common.numberMax', { value: 18 }))
            .required(t('validation.holesPlayer.required')),
        holesWonPlayerTwo: Yup.number()
            .min(0, t('validation.common.numberMin', { value: 0 }))
            .max(18, t('validation.common.numberMax', { value: 18 }))
            .required(t('validation.holesPlayer.required')),
        playedAt: Yup.date()
            .max(new Date(), t('validation.common.dateMax'))
            .nullable()
            .required(t('validation.common.required'))
            .transform(function (castValue, originalValue) {
                return this.isType(castValue) ? castValue : new Date(originalValue)
            }),
    }).test('maxHolesWon', '', function (values) {
        const maxHolesWon = getMaxHoles(matchplay);
        const isValid = (values.holesWonPlayerOne ?? 0) + (values.holesWonPlayerTwo ?? 0) <= maxHolesWon;
        if (isValid) return true;
        return this.createError({
            path: 'maxHolesWon',
            message: t('components.match.activities.MatchActivityGroupResult.maxHolesWonError', { maxHolesWon }),
        });
    }), [t, matchplay]);

    const onSubmitForm = useCallback((data: GroupFormSubmitData, helpers: FormikHelpers<FormPayload>) => {
        if (changeResult) {
            setConfirmChangeData(data);
            helpers.setSubmitting(false);
            return;
        }
        onSubmit(data);
    }, [onSubmit, changeResult]);

    const onConfirmChange = useCallback(() => {
        if (confirmChangeData) {
            onSubmit(confirmChangeData);
        }
        setConfirmChangeData(undefined);
    }, [onSubmit, confirmChangeData]);

    const onCancelChange = useCallback(() => {
        setConfirmChangeData(undefined);
    }, []);

    if (!isGroupMatch(match)) {
        return null;
    }

    const initialValues: FormPayload = {
        holesWonPlayerOne: changeResult ? (match.holesWonPlayerOne ?? 0) : 0,
        holesWonPlayerTwo: changeResult ? (match.holesWonPlayerTwo ?? 0) : 0,
        playedAt: match.scheduledAt ?
            (new Date(match.scheduledAt) > new Date() ? new Date() : new Date(match.scheduledAt))
            : null,
    };

    return (
        <>
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                component={(formProps) => (
                    <ResultForm
                        {...formProps}
                        isSubmitting={formProps.isSubmitting || !!confirmChangeData}
                        match={match}
                        matchplay={matchplay}
                    />)}
                onSubmit={onSubmitForm}
            />
            <ConfirmChangeDialog
                open={!!confirmChangeData}
                onConfirm={onConfirmChange}
                onCancel={onCancelChange}
            />
        </>
    );
};

const ResultForm = ({
    values, errors, touched, dirty, isSubmitting, isValid, isInitialValid,
    handleBlur, handleChange, handleSubmit,
    match, matchplay,
}: FormikProps<FormPayload> & Pick<Props, 'match' | 'matchplay'>) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const submitDisabled = (!dirty && !isInitialValid) || !isValid || isSubmitting;
    const maxHoles = getMaxHoles(matchplay);
    const allFormikErrors = errors as FormikErrors<FormPayload & CustomFormikErrors>;

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl error={(touched.playedAt) && Boolean(errors.playedAt)}>
                            <FormikDateTimeField
                                name="playedAt"
                                label={t('components.match.activities.MatchActivityGroupResult.matchPlayedAt')}
                                maxDateMessage={t('components.match.activities.MatchActivityGroupResult.matchInFutureError')}
                                format={t('dateTime.matchDateTime')}
                                className={classes.datePicker}
                                disableFuture={true} />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.players}>
                        <FormControl
                            component={'fieldset' as 'div'}
                            fullWidth
                            error={Boolean(errors.holesWonPlayerOne)}>
                            <TextField
                                type="number"
                                name="holesWonPlayerOne"
                                inputProps={{ min: 0, max: maxHoles }}
                                value={values.holesWonPlayerOne}
                                label={(
                                    <Trans
                                        i18nKey={'components.match.activities.MatchActivityGroupResult.holesWonPlayer'}
                                        values={{ playerName: match.playerOne?.user.name }}
                                        components={{ player: <strong /> }}
                                    />
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.holesWonPlayerOne}
                                error={Boolean(errors.holesWonPlayerOne)}
                            />
                        </FormControl>
                        <FormControl
                            component={'fieldset' as 'div'}
                            fullWidth
                            error={Boolean(errors.holesWonPlayerOne)}>
                            <TextField
                                type="number"
                                name="holesWonPlayerTwo"
                                inputProps={{ min: 0, max: maxHoles }}
                                value={values.holesWonPlayerTwo}
                                label={(
                                    <Trans
                                        i18nKey={'components.match.activities.MatchActivityGroupResult.holesWonPlayer'}
                                        values={{ playerName: match.playerTwo?.user.name }}
                                        components={{ player: <strong /> }}
                                    />
                                )}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.holesWonPlayerTwo}
                                error={Boolean(errors.holesWonPlayerTwo)}
                            />
                        </FormControl>
                        {allFormikErrors.maxHolesWon && (
                            <Alert severity="error">{allFormikErrors.maxHolesWon}</Alert>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitDisabled}
                >
                    {t('common.save')}
                </Button>
            </CardActions>
        </form>
    );
};

interface ConfirmChangeDialogProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmChangeDialog = ({ open, onConfirm, onCancel }: ConfirmChangeDialogProps) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open}
            onClose={onCancel}
            disableBackdropClick
            disableEscapeKeyDown>
            <DialogTitle>
                {t('components.match.activities.MatchActivityGroupResult.editDialog.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('components.match.activities.MatchActivityGroupResult.editDialog.warning')}
                </DialogContentText>
                <DialogContentText>
                    {t('components.match.activities.MatchActivityGroupResult.editDialog.text')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary" autoFocus>
                    {t('common.cancel')}
                </Button>
                <Button onClick={onConfirm} color="secondary">
                    {t('common.ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MatchActivityGroupResult;
