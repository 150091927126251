import React, { Component } from 'react';

import { Button, createStyles, Grid, Hidden, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
    footer: {
        borderTop: '1px solid',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: '90px',
    },
});

type Props = WithStyles<typeof styles>;

class Footer extends Component<Props> {

    public render() {
        const { classes } = this.props;
        return (
            <Hidden xsDown>
                <div className={classes.footer}>
                    <Grid container className={classes.container}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" align="left" color="textSecondary" component="p"
                                        style={{ lineHeight: '40px' }}>
                                Built with <span role="img" aria-label="love">❤️</span> by MatchplayTime.com
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right" component="div">
                                <Button href="//support.matchplaytime.com" target="_blank" rel="noopener noreferrer">
                                    <Trans i18nKey="components.layout.Footer.support"/>
                                </Button>
                                <Button href="//matchplaytime.com" target="_blank" rel="noopener noreferrer">
                                    <Trans i18nKey="components.layout.Footer.website"/>
                                </Button>
                                <Button href="//yoomani.me/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
                                    <Trans i18nKey="components.layout.Footer.privacyPolicy"/>
                                </Button>
                                <Button href="//matchplaytime.com/impressum" target="_blank" rel="noopener noreferrer">
                                    <Trans i18nKey="components.layout.Footer.imprint"/>
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Hidden>
        );
    }
}

export default withStyles(styles)(Footer);
