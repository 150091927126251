import React, { FC } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormLabel, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import { CreateOrUpdateNotification, Notification } from '../../../models/Notification';
import FormikTextField from '../../utils/forms/FormikTextField';
import FormikDateTimeField from '../../utils/forms/FormikDateTimeField';

const useStyles = makeStyles((theme: Theme) => ({
    formField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    actionGrid: {
        marginTop: theme.spacing(2),
    },
}));

interface ComponentProps {
    notification?: Notification;
    onSave(notification: CreateOrUpdateNotification): void;
    onCancel(): void;
}

type Props = ComponentProps;

const NotificationItemForm: FC<Props> = (props) => {

    const { onSave, onCancel, notification } = props;
    const { t } = useTranslation();

    const schema = Yup.object().shape({
        subject: Yup.string()
            .required(t('validation.common.required')),
        message: Yup
            .string()
            .required(t('validation.common.required')),
        startAt: Yup.date()
            .nullable()
            .transform(function (castValue, originalValue) {
                return this.isType(castValue) ? castValue : new Date(originalValue)
            }),
        endAt: Yup.date()
            .nullable()
            .min(Yup.ref('startAt'), t('validation.common.endDate'))
            .transform(function (castValue, originalValue) {
                return this.isType(castValue) ? castValue : new Date(originalValue)
            }),
    });

    const initialValues: CreateOrUpdateNotification = {
        subject: '',
        message: '',
        startAt: null,
        endAt: null,
    }

    if (notification) {
        initialValues.subject = notification.subject;
        initialValues.message = notification.message;
        initialValues.startAt = notification.startAt;
        initialValues.endAt = notification.endAt;
    }

    return (
        <Formik component={InnerForm}
                onSubmit={onSave}
                onReset={onCancel}
                initialValues={initialValues}
                validationSchema={schema}/>
    );
};

const InnerForm: FC<FormikProps<CreateOrUpdateNotification>> = (props) => {

    const { isValid, dirty } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const disableSubmitButton = !(dirty && isValid);

    return (
        <Form>
            <Box display="flex" flexDirection="column">
                <FormikTextField name="subject"
                                 label={t('components.tenant.notification.NotificationItemForm.subject')}
                                 margin="dense" variant="outlined" fullWidth/>
                <FormikTextField name="message"
                                 label={t('components.tenant.notification.NotificationItemForm.message')}
                                 multiline rows={7} rowsMax={25}
                                 margin="dense" variant="outlined" fullWidth/>
                <FormFragmentStartEnd {...props} />
            </Box>
            <Grid container spacing={1} className={classes.actionGrid}>
                <Grid item>
                    <Button type="submit" color="primary" variant="contained" disabled={disableSubmitButton}>
                        {t('common.save')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button type="reset" variant="outlined">
                        {t('common.cancel')}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}

const FormFragmentStartEnd: FC<FormikProps<CreateOrUpdateNotification>> = (props) => {

    const { t } = useTranslation();
    const { touched, errors } = props;
    const classes = useStyles();

    return (
        <Box marginTop={2}>
            <FormLabel component={'legend' as 'span'}>
                {t('components.tenant.notification.NotificationItemForm.startEndDate')}
            </FormLabel>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <FormControl error={(touched.startAt) && Boolean(errors.startAt)} fullWidth>
                        <FormikDateTimeField name="startAt"
                                             label={t('components.tenant.notification.NotificationItemForm.startAt')}
                                             format={t('dateTime.matchDateTime')}
                                             className={classes.formField} fullWidth/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl error={(touched.endAt) && Boolean(errors.endAt)} fullWidth>
                        <FormikDateTimeField name="endAt"
                                             label={t('components.tenant.notification.NotificationItemForm.endAt')}
                                             format={t('dateTime.matchDateTime')}
                                             className={classes.formField} fullWidth/>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotificationItemForm;
