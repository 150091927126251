import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

import { ResourceGroup, ResourceIdMap } from '../../../../lib/resourceSupport';
import { MatchEncounter } from '../../../../models/MatchEncounter';
import { Matchplay, MatchplayPhase } from '../../../../models/Matchplay';
import { MatchplayRegistration } from '../../../../models/MatchplayRegistration';
import { Player } from '../../../../models/Player';
import { User } from '../../../../models/User';

import {
    getMatchplayAction, getMatchplayRegistrationsAction, initializePlayoffMatchplayAction
} from '../../../../store/actions/matchplays';
import { firstComeFirstServe, shuffled } from './EncounterStrategies';
import MatchplayPreparation from './MatchplayPlayoffPreparation';

interface State {
    encounters: MatchEncounter[];
    usersNotPlaying: MatchplayRegistration[];
    isPlayable: boolean;
    shouldFillWithBots: boolean;
}

interface RouteParams {
    matchplayId: string | undefined;
}

interface StoreProps {
    currentUser?: User;
    matchplaysById: ResourceIdMap<Matchplay>;
    registrationsByMatchplayId: ResourceGroup<MatchplayRegistration>;
    getMatchplay(id: string): void;
    getMatchplayRegistrations(id: string): void;
    initializeMatchplay(matchplay: Matchplay, matches: MatchEncounter[]): void;
}

type Props = StoreProps & RouteComponentProps<RouteParams>;

class MatchplayPlayoffPreparationContainer extends Component<Props, State> {

    public static getDerivedStateFromProps(props: Props, currentState: State): Partial<State> {
        const registrations = MatchplayPlayoffPreparationContainer.getRegistrationsFromProps(props);
        if (registrations && !currentState.encounters.length) {
            const matchplay = MatchplayPlayoffPreparationContainer.getMatchplayFromProps(props);
            if (matchplay?.playoff) {
                const { encounters, usersNotPlaying, isPlayable } = firstComeFirstServe({ registrations, matchplay });
                return { encounters, usersNotPlaying, isPlayable };
            }
            return { encounters: [], usersNotPlaying: registrations };
        }
        return {};
    }

    private static getRegistrationsFromProps(props: Props): MatchplayRegistration[] | undefined {
        const { currentUser, registrationsByMatchplayId, match } = props;
        if (!currentUser) {
            return undefined;
        }
        const matchplayId = match.params.matchplayId;
        return (matchplayId && registrationsByMatchplayId[matchplayId]) || undefined;
    }

    private static getMatchplayFromProps(props: Props): Matchplay | undefined {
        const { matchplaysById, match } = props;
        const matchplayId = match.params.matchplayId;
        return !!matchplayId ? matchplaysById[matchplayId] : undefined;
    }

    public constructor(props: Props) {
        super(props);
        this.state = { encounters: [], usersNotPlaying: [], isPlayable: false, shouldFillWithBots: false };
    }

    public render() {
        const { currentUser } = this.props;
        const { encounters, usersNotPlaying, isPlayable, shouldFillWithBots } = this.state;
        const matchplay = this.getMatchplay();
        return (
            <React.Fragment>
                {currentUser && matchplay &&
                    <MatchplayPreparation currentUser={currentUser}
                        encounters={encounters}
                        matchplay={matchplay}
                        usersNotPlaying={usersNotPlaying}
                        isPlayable={isPlayable}
                        shouldFillWithBots={shouldFillWithBots}
                        shuffle={this.shuffle}
                        firstComeFirstServe={this.firstComeFirstServe}
                        onFillWithBots={this.onFillWithBots}
                        swapPlayers={this.swapPlayers}
                        commitPhase={this.commitPhase} />
                }
            </React.Fragment>);
    }

    public async componentDidMount() {
        const { getMatchplay, getMatchplayRegistrations, match, history } = this.props;
        const matchplayId = match.params.matchplayId;
        if (!matchplayId) {
            return;
        }
        await getMatchplay(matchplayId);
        const matchplay = this.getMatchplay();
        if (matchplay && matchplay.phase !== MatchplayPhase.REGISTRATION_CLOSED) {
            history.replace(generatePath('/admin/matchplays/:id', { id: matchplay.id }));
        }
        await getMatchplayRegistrations(matchplayId);
    }

    private getMatchplay = () => MatchplayPlayoffPreparationContainer.getMatchplayFromProps(this.props);
    private getRegistrations = () => MatchplayPlayoffPreparationContainer.getRegistrationsFromProps(this.props);

    private shuffle = () => {
        const registrations = this.getRegistrations();
        const matchplay = this.getMatchplay();
        if (matchplay?.playoff && registrations) {
            const { encounters, usersNotPlaying, isPlayable } = shuffled({ registrations, matchplay });
            this.setState({ encounters, usersNotPlaying, isPlayable });
        }
    };

    private firstComeFirstServe = () => {
        const registrations = this.getRegistrations();
        const matchplay = this.getMatchplay();
        if (matchplay?.playoff && registrations) {
            const { encounters, usersNotPlaying, isPlayable } = firstComeFirstServe({ registrations, matchplay });
            this.setState({ encounters, usersNotPlaying, isPlayable });
        }
    }

    private onFillWithBots = (shouldFillWithBots: boolean) => {
        this.setState({ shouldFillWithBots });
    }

    private swapPlayers = (player1: Player, player2: Player) => {
        const { encounters } = this.state;
        const newEncounters = encounters.map(encounter => {
            const newEncounter: MatchEncounter = {
                playerOne: encounter.playerOne,
                playerTwo: encounter.playerTwo
            };
            if (encounter.playerOne === player1) {
                newEncounter.playerOne = player2;
            }
            if (encounter.playerTwo === player1) {
                newEncounter.playerTwo = player2;
            }
            if (encounter.playerOne === player2) {
                newEncounter.playerOne = player1;
            }
            if (encounter.playerTwo === player2) {
                newEncounter.playerTwo = player1;
            }
            return newEncounter;
        });
        this.setState({ encounters: newEncounters });
    };

    private commitPhase = async () => {
        const { history } = this.props;
        const matchplay = this.getMatchplay();
        if (matchplay) {
            // eslint-disable-next-line
            await this.props.initializeMatchplay(matchplay, this.state.encounters);
            history.replace(generatePath('/admin/matchplays/:id', { id: matchplay.id }));
        }
    };
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    matchplaysById: state.matchplay.byId,
    registrationsByMatchplayId: state.matchplay.registrationsByMatchplayId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMatchplay: getMatchplayAction(dispatch),
    getMatchplayRegistrations: getMatchplayRegistrationsAction(dispatch),
    initializeMatchplay: initializePlayoffMatchplayAction(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchplayPlayoffPreparationContainer));
